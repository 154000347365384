<template>
  <div>
      <el-steps :active="5" align-center>
        <el-step title="1 导入商品 "></el-step>
        <el-step title="2 导入店铺 "></el-step>
        <el-step title="3 查看结果"></el-step>
        <el-step title="4 导出结果"></el-step>
      </el-steps>
      <div class="table-top-step-op" align-center>
        <div>
          <ImportDialog
            v-loading="importGoodsLoading"
            buttonName="1 导入商品"
            buttonSize="small"
            fileName="导入商品"
            fileType="upload_pricecheck_item"
            @closeDialog="goodsFinderRefrsh"
          >
          </ImportDialog>
        </div>
        <div style="margin-left: 24px;">
          <ImportDialog
            v-loading="importShopLoading"
            buttonName="2 导入店铺"
            buttonSize="small"
            fileName="导入店铺"
            fileType="upload_pricecheck_distributor"
            @closeDialog="shopFinderRefrsh"
          >
          </ImportDialog>
        </div>
        <div>
          <el-button style="margin-left: 24px;" size="small" type="primary"  @click="handleViewRecord" >3 查看结果</el-button>
          <el-button style="margin-left: 24px;" size="small" type="success" @click="handleDownRecord">4 导出结果</el-button>
        </div>
      </div>


  <div class="goods-unmount">
    <div class="goods-unmount-action">
      <!-- <div>
        <el-button size="small" type="primary" @click="handleShelfstore">查看价格结果</el-button>
        <el-button size="small" type="primary" @click="handleViewRecord">查看执行记录</el-button>
      </div> -->
      <div>
      </div>
    </div>


    <div class="goods-unmount-wrapper">
      <div class="good-wrapper">
        <div class="common-title">商品信息</div>
        <SpFinder
          ref="goodFinder"
          :url="goods_list"
          noSelection
          :setting="goodSetting"
          fixed-row-action
          :hooks="{ afterSearch: goodsAfterSearch }"
        >
          <template #tableTop>
            <div class="table-top">
              <!-- <div>
              <ImportDialog
                v-loading="importGoodsLoading"
                buttonName="导入商品"
                buttonSize="small"
                fileName="导入商品"
                fileType="upload_item_by_distributor_item_sale_status"
                @closeDialog="goodsFinderRefrsh"
              >
              </ImportDialog>
            </div> -->
              <div>
                <el-input class="input-b" placeholder="商品货号" v-model="item_bn">
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="hanldeRefreshGoods"
                  ></el-button>
                </el-input>
              </div>
            </div>
          </template>
        </SpFinder>
      </div>
      <div class="shop-wrapper">
        <div class="common-title">店铺信息</div>
        <SpFinder
          ref="shopFinder"
          :url="shop_list"
          noSelection
          :setting="shopSetting"
          fixed-row-action
          :hooks="{ afterSearch: shopAfterSearch }"
        >
          <template #tableTop>
            <div class="table-top">
              <!-- <div>
              <ImportDialog
                v-loading="importShopLoading"
                buttonName="导入店铺"
                buttonSize="small"
                fileName="导入店铺"
                fileType="upload_distributor_by_distributor_item_sale_status"
                @closeDialog="shopFinderRefrsh"
              >
              </ImportDialog>
            </div> -->
              <div>
                <el-input class="input-b" placeholder="店铺Apple ID" v-model="shop_code">
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="hanldeRefreshShop"
                  ></el-button>
                </el-input>
              </div>
            </div>
          </template>
        </SpFinder>
      </div>
    </div>
  </div>
  </div> 

</template>

<script>
import { goodSetting, shopSetting } from "./config/index";
import ImportDialog from "@/components/importDialog";
import { useDialog } from "@shopex/finder";
import { itemsUnmount, goods_list, shop_list,downloadPriceCheckList } from "./api";

export default {
  components: {
    ImportDialog,
  },
  computed: {
    goodSetting() {
      return goodSetting(this);
    },
    shopSetting() {
      return shopSetting(this);
    },
  },
  data() {
    return {
      goods_list,
      shop_list,
      item_bn: "",
      shop_code: "",
      importGoodsLoading: false,
      importShopLoading: false,
      time: 5 * 1000,
      goodsTimer: null,
      shopTimer: null,
    };
  },
  mounted() {},
  methods: {
    // 点击刷新商品列表
    hanldeRefreshGoods() {
      this.goods_list = "";
      this.goods_list = `${goods_list}?item_bn=${this.item_bn}`;
    },
    // 点击刷新店铺列表
    hanldeRefreshShop() {
      this.shop_list = "";
      this.shop_list = `${shop_list}?shop_code=${this.shop_code}`;
    },
    // // 上架
    // async handleShelfstore() {
    //   await itemsUnmount({ log_type: 'on_sale' })
    //   this.$message.success('上架成功!')
    //   this.goodsFinderRefrsh()
    //   this.shopFinderRefrsh()
    // },
    // // 下架
    // async handleDownStore() {
    //   await itemsUnmount({ log_type: 'off_sale' })
    //   this.$message.success('下架成功!')
    //   this.goodsFinderRefrsh()
    //   this.shopFinderRefrsh()
    // },
    // 查看记录
    handleViewRecord() {
      useDialog(null, {
        title: `价格复核列表`,
        width: "80%",
        component: () => import("./record.vue"),
        actions: [],
      });
    },
    goodsAfterSearch(res) {
      const { data } = res.data;
      const status = data.status;
      if (status === "processing") {
        this.importGoodsLoading = true;
        if (this.goodsTimer) {
          clearInterval(this.goodsTimer);
        }
        this.goodsTimer = setInterval(() => {
          this.$refs.goodFinder.refresh();
        }, this.time);
      } else {
        this.importGoodsLoading = false;
        if (this.goodsTimer) {
          clearInterval(this.goodsTimer);
        }
      }
    },
    shopAfterSearch(res) {
      const { data } = res.data;
      const status = data.status;
      if (status === "processing") {
        this.importShopLoading = true;
        if (this.shopTimer) {
          clearInterval(this.shopTimer);
        }
        this.shopTimer = setInterval(() => {
          this.$refs.shopFinder.refresh();
        }, this.time);
      } else {
        this.importShopLoading = false;
        if (this.shopTimer) {
          clearInterval(this.shopTimer);
        }
      }
    },
    goodsFinderRefrsh() {
      this.$refs.goodFinder.refresh();
    },
    shopFinderRefrsh() {
      this.$refs.shopFinder.refresh();
    },
    handleDownRecord (params) {
      downloadPriceCheckList(params).then(response => {


        // this.accountPasswordVisible = false
        // this.accountPassword = ''

        // var a = document.createElement("a");
        // a.href = response.data.data.csv_data;
        // a.download = response.data.data.file_name;
        // document.body.appendChild(a);
        // a.click();
        // a.remove();

        // const url = this.genUrl(response.data.data.csv_data, {});//{}指的是表头，response.data.data.csv_data是后台返回来的数据
        // const a = document.createElement('a');
        // a.href = url;
        // console.log("exportlogfiledown:::::::response:")
        // console.log(response)
        // a.download = response.data.data.file_name;
        // a.click();
        // window.URL.revokeObjectURL(url);
      }).catch(err => {
        // this.$message.error("文件过期～")
      })
    },


  },
  beforeDestroy() {
    if (this.goodsTimer) {
      clearInterval(this.goodsTimer);
    }
    if (this.shopTimer) {
      clearInterval(this.shopTimer);
    }
  },
};
</script>

<style scoped lang="scss">
.goods-unmount {
  &-action {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    button:first-child {
      margin-right: 16px;
    }
  }
  &-wrapper {
    display: flex;
    justify-content: space-between;
    .good-wrapper {
      width: 42%;
      min-width: 400px;
    }
    .shop-wrapper {
      width: 42%;
      min-width: 400px;
    }
  }
  .common-title {
    font-size: 16px;
    height: 28px;
    display: flex;
    align-items: center;
    color: #333;
  }
  .common-title::before {
    content: "";
    display: inline-block;
    margin-right: 8px;
    border: 1px solid $dominant_hue;
    background-color: $dominant_hue;
    width: 2px;
    height: 28px;
  }

  .table-top {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }


}
.table-top-step-op {
    margin-bottom: 20px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }  

::v-deep .sp-finder .cell .sp-finder-actions__wrap {
  justify-content: center !important;
}
</style>
