<template>
  <div>
    <el-tabs @change="getMsgConfig" v-model="activeName" type="border-card">
      <el-tab-pane label="基础设置" name="base">
        <el-form label-width="60px">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>接口限制    <el-tag >【0则表示不做限制】</el-tag></span>
            </div>
            <div>
              <el-form-item label="">
                <div class="el-col-12">
                  <el-input placeholder="" v-model="form.query_limit" type="number">
                    <template slot="prepend"><span style="color: #67C23A">模糊</span>查询企业信息接口,限制</template>
                    <template slot="append">次/天 【默认 5】</template>
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="el-col-12">
                  <el-input placeholder="" v-model="form.query_exact_limit" type="number">
                    <template slot="prepend"><span style="color: #E6A23C">精准</span>查询企业信息接口,限制</template>
                    <template slot="append">次/天 【默认 5】</template>
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="el-col-12">
                  <el-input placeholder="" v-model="form.email_send" type="number">
                    <template slot="prepend">&nbsp;&nbsp;企业邮箱发送验证码,限制&nbsp;&nbsp;</template>
                    <template slot="append">次/天 【默认 0】</template>
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="el-col-12">
                  <el-input placeholder="" v-model="form.member_query" type="number">
                    <template slot="prepend">&nbsp;&nbsp;用户查询ec企业信息,限制&nbsp;&nbsp;</template>
                    <template slot="append">次/天 【默认 0】</template>
                  </el-input>
                </div>
              </el-form-item>
            </div>
          </el-card>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="saveBaseSetting">保存配置</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="注册页" name="register">
        <el-form label-width="200px">
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="顶部背景图" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.register_bg_top" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.register_bg_top" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('register_bg_top')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleRBTChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsRBTDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picRBTPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="表单头部" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.register_form_top" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.register_form_top" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('register_form_top')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleRFTChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsRFTDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picRFTPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
<!--          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="表单底部EC" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.register_form_b_ec" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.register_form_b_ec" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('register_form_b_ec')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleRFBEChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsRFBEDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picRFBEPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="表单底部ENT" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.register_form_b_ent" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.register_form_b_ent" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('register_form_b_ent')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleRFBENChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsRFBENDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picRFBENPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>-->
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="saveRegisterSetting">保存配置</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="注册成功页" name="register_suc">
        <el-form label-width="200px">
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="顶部背景图" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.register_sc_bg_top" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.register_sc_bg_top" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('register_sc_bg_top')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleRSBTChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsRSBTDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picRSBTPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="ENT推荐内容" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.register_sc_b_ent" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.register_sc_b_ent" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('register_sc_b_ent')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleRSBEChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsRSBEDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picRSBEPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="注册成功优惠券文案" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box">
              <el-input placeholder="" v-model="form.register_sc_c_t"  class="mb_15" style="width: 45%">
              </el-input>
            </div>
          </el-card>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="saveRegisterSucSetting">保存配置</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="弹窗内容" name="alert">
        <el-form label-width="200px">
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="商品详情页弹窗" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.pdp_alert" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.pdp_alert" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('pdp_alert')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handlePAChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsPADialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picPAPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="订单详情页弹窗" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.order_alert" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.order_alert" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('order_alert')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleOAChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsOADialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picOAPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="saveAlertSetting">保存配置</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="分享相关" name="share">
        <el-form label-width="200px">
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="专营店ENT分享内容" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.aar_share_ent" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.aar_share_ent" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('aar_share_ent')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleASEChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsASEDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picASEPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="订单ENT分享内容" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;" >
              <span v-if="form.order_share_ent" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.order_share_ent" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('order_share_ent')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleOSENChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsOSENDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picOSENPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="订单EC分享内容" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box el-col-14" style="width: 160px;">
              <span v-if="form.order_share_ec" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.order_share_ec" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('order_share_ec')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleOSECChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsOSECDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picOSECPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="页面分享小程序卡片封面图" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.share_card_bg" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.share_card_bg" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('share_card_bg')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleSCBChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsSCBDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picSCBPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="saveShareSetting">保存配置</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="海报生成" name="poster">
        <el-form label-width="200px">
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="专营店海报" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.aar_poster" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.aar_poster" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('aar_poster')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleAPChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsAPDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picAPPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card">
            <el-alert class='mb_15 ' type="info" title="订单EC海报" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box   el-col-16" style="width: 160px;">
              <span v-if="form.order_poster_ec" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.order_poster_ec" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('order_poster_ec')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleOPECChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsOPECDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picOPECPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <div >
              <el-alert type="error" title="以下信息涉及页面排版，请勿随意修改！"  :closable="false" class="mb_15"  style="width: 45%">
              </el-alert>
              <el-input placeholder="" v-model="form.order_poster_ec_y" type="number" class="mb_15" style="width: 45%">
                <template slot="prepend">【Y轴】太阳码距离顶部</template>
                <template slot="append">PX（像素）</template>
              </el-input>
              <el-input placeholder="" v-model="form.order_poster_ec_x" type="number" style="width: 45%">
                <template slot="prepend">【X轴】太阳码距离左侧</template>
                <template slot="append">PX（像素）</template>
              </el-input>
            </div>

            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card  ">
            <el-alert class='mb_15 ' type="info" title="订单ENT海报" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box el-col-16" style="width: 160px;">
              <span v-if="form.order_poster_ent" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.order_poster_ent" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('order_poster_ent')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleOPENChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsOPENDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picOPENPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <div >
              <el-alert type="error" title="以下信息涉及页面排版，请勿随意修改！"  :closable="false" class="mb_15"  style="width: 45%">
              </el-alert>
              <el-input placeholder="" v-model="form.order_poster_ent_y" type="number" class="mb_15" style="width: 45%">
                <template slot="prepend">【Y轴】太阳码距离顶部</template>
                <template slot="append">PX（像素）</template>
              </el-input>
              <el-input placeholder="" v-model="form.order_poster_ent_x" type="number" style="width: 45%">
                <template slot="prepend">【X轴】太阳码距离左侧</template>
                <template slot="append">PX（像素）</template>
              </el-input>
            </div>

            <el-form-item label="">
            </el-form-item>
          </el-card>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="savePosterSetting">保存配置</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="个人中心" name="member">
        <el-form label-width="200px">
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="顶部背景图" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.member_bg" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.member_bg" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('member_bg')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleMBChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsMBDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picMBPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="底图广告图" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.member_b_end" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.member_b_end" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('member_b_end')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleMBEChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsMBEDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picMBEPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="saveMemberSetting">保存配置</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="客服相关" name="kefu">
        <el-form label-width="200px">
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="客服图片" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.customer_service_bg" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.customer_service_bg" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('customer_service_bg')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleCSBChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsCSBDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picCSBPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="客服LoadingPage图" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.customer_service_lp" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.customer_service_lp" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('customer_service_lp')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleCSLChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsCSLDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picCSLPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="saveServiceSetting">保存配置</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="购买资格页" name="buy_qua">
        <el-form label-width="200px">
          <el-card class="box-card">
            <el-alert class='mb_15' type="info" title="页面背景图" show-icon :closable="false" >
            </el-alert>
            <div class="pics-box" style="width: 160px;">
              <span v-if="form.buy_qua_bg" class="goodspic-wrap">
                <span  class="goodspic">
                    <img  :src="wximageurl + form.buy_qua_bg" />
                    <div class="goodspic-mask" >
                      <div class="iconfont icon-trash-alt" @click="removePicsImg('buy_qua_bg')"></div>
                    </div>
                </span>
                </span>
              <div v-else class="upload-box"  @click="handleBQBChange">
                <i class="iconfont icon-camera"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="picsBQBDialog" :sc-status="isGetImage" :is-most="multiple"
                       @chooseImg="picBQBPics" @closeImgDialog="closePicsDialog"></imgPicker>
            <el-form-item label="">
            </el-form-item>
          </el-card>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="saveBuyPageSetting">保存配置</el-button>
        </div>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>
<script>
import imgPicker from '@/components/imageselect'
import {saveEcSetting, getEcSetting,getJoinCity} from '../../../api/common'

export default {
  components: {
    imgPicker
  },
  data() {
    return {
      //注册页
      picsRBTDialog:false,
      picsRFTDialog:false,
      picsRFBEDialog:false,
      picsRFBENDialog:false,
      //注册成功页
      picsRSBTDialog:false,
      picsRSBEDialog:false,
      //弹窗
      picsPADialog:false,
      picsOADialog:false,

      //分享
      picsASEDialog:false,
      picsOSENDialog:false,
      picsOSECDialog:false,
      picsSCBDialog:false,

      //海报生成
      picsAPDialog:false,
      picsOPECDialog:false,
      picsOPENDialog:false,

      //我的
      picsMBDialog:false,
      picsMBEDialog:false,

      //客服
      picsCSBDialog:false,
      picsCSLDialog: false,

      //购买资格
      picsBQBDialog: false,


      isGetImage: false,
      multiple: false,
      form: {
        //注册页
        register_bg_top: "",//顶部背景图
        register_form_top: "",//表单头部
        register_form_b_ec: "",//表单底部ec
        register_form_b_ent: "",//表单底部ent

        //注册成功页
        register_sc_bg_top:"",//顶部背景图
        register_sc_b_ent:"",//ent推荐内容
        register_sc_c_t:"",//注册成功优惠券文案

        //弹窗内容
        pdp_alert:"",
        order_alert:"",

        //分享页
        aar_share_ent:"",//专营店ent分享内容
        order_share_ent:"",//订单ent分享内容
        order_share_ec:"",//订单ec分享内容
        share_card_bg:"",//页面分享小程序卡片封面图
        //海报生成
        aar_poster:"",
        order_poster_ec:"",
        order_poster_ent:"",
        order_poster_ent_y: 0,//订单ent分享海报-太阳码距离顶部距离
        order_poster_ent_x: 0,//订单ent分享海报-太阳码距离左侧距离
        order_poster_ec_y: 0,//订单ec分享海报-太阳码距离顶部距离
        order_poster_ec_x: 0,//订单ec分享海报-太阳码距离左侧距离

        //我的
        member_bg: "",
        member_b_end:"",

        //客服
        customer_service_bg:"",
        customer_service_lp:"",

        //购买资格页面
        buy_qua_bg: "",
      },
      activeName: 'base'

    }
  },
  methods: {
    //注册页
    handleRBTChange() {
      this.picsRBTDialog = true
      this.isGetImage = true
    },
    picRBTPics(data) {
      if (data && data.url !== '') {
        this.form.register_bg_top = data.url
      }
      // }
      this.picsRBTDialog = false
    },
    handleRFTChange() {
      this.picsRFTDialog = true
      this.isGetImage = true
    },
    picRFTPics(data) {
      if (data && data.url !== '') {
        this.form.register_form_top = data.url
      }
      // }
      this.picsRFTDialog = false
    },
    handleRFBEChange() {
      this.picsRFBEDialog = true
      this.isGetImage = true
    },
    picRFBEPics(data) {
      if (data && data.url !== '') {
        this.form.register_form_b_ec = data.url
      }
      // }
      this.picsRFBEDialog = false
    },
    handleRFBENChange() {
      this.picsRFBENDialog = true
      this.isGetImage = true
    },
    picRFBENPics(data) {
      if (data && data.url !== '') {
        this.form.register_form_b_ent = data.url
      }
      // }
      this.picsRFBENDialog = false
    },

    //注册成功页
    handleRSBTChange(){
      this.picsRSBTDialog = true
      this.isGetImage = true
    },
    picRSBTPics(data) {
      if (data && data.url !== '') {
        this.form.register_sc_bg_top = data.url
      }
      // }
      this.picsRSBTDialog = false
    },
    handleRSBEChange(){
      this.picsRSBEDialog = true
      this.isGetImage = true
    },
    picRSBEPics(data) {
      if (data && data.url !== '') {
        this.form.register_sc_b_ent = data.url
      }
      // }
      this.picsRSBEDialog = false
    },

    //弹窗
    handlePAChange(){
      this.picsPADialog = true
      this.isGetImage = true
    },
    picPAPics(data) {
      if (data && data.url !== '') {
        this.form.pdp_alert = data.url
      }
      // }
      this.picsPADialog = false
    },
    handleOAChange(){
      this.picsOADialog = true
      this.isGetImage = true
    },
    picOAPics(data) {
      if (data && data.url !== '') {
        this.form.order_alert = data.url
      }
      // }
      this.picsOADialog = false
    },
    //分享
    handleASEChange(){
      this.picsASEDialog = true
      this.isGetImage = true
    },
    picASEPics(data) {
      if (data && data.url !== '') {
        this.form.aar_share_ent = data.url
      }
      // }
      this.picsASEDialog = false
    },
    handleOSENChange(){
      this.picsOSENDialog = true
      this.isGetImage = true
    },
    picOSENPics(data) {
      if (data && data.url !== '') {
        this.form.order_share_ent = data.url
      }
      // }
      this.picsOSENDialog = false
    },
    handleOSECChange(){
      this.picsOSECDialog = true
      this.isGetImage = true
    },
    picOSECPics(data) {
      if (data && data.url !== '') {
        this.form.order_share_ec = data.url
      }
      // }
      this.picsOSECDialog = false
    },
    handleSCBChange(){
      this.picsSCBDialog = true
      this.isGetImage = true
    },
    picSCBPics(data) {
      if (data && data.url !== '') {
        this.form.share_card_bg = data.url
      }
      // }
      this.picsSCBDialog = false
    },

    //海报生成
    handleAPChange() {
      this.picsAPDialog = true
      this.isGetImage = true
    },
    picAPPics(data) {
      if (data && data.url !== '') {
        this.form.aar_poster = data.url
      }
      // }
      this.picsAPDialog = false
    },
    handleOPECChange() {
      this.picsOPECDialog = true
      this.isGetImage = true
    },
    picOPECPics(data) {
      if (data && data.url !== '') {
        this.form.order_poster_ec = data.url
      }
      // }
      this.picsOPECDialog = false
    },
    handleOPENChange() {
      this.picsOPENDialog = true
      this.isGetImage = true
    },
    picOPENPics(data) {
      if (data && data.url !== '') {
        this.form.order_poster_ent = data.url
      }
      // }
      this.picsOPENDialog = false
    },
    //我的
    handleMBChange() {
      this.picsMBDialog = true
      this.isGetImage = true
    },
    picMBPics(data) {
      if (data && data.url !== '') {
        this.form.member_bg = data.url
      }
      // }
      this.picsMBDialog = false
    },
    handleMBEChange() {
      this.picsMBEDialog = true
      this.isGetImage = true
    },
    picMBEPics(data) {
      if (data && data.url !== '') {
        this.form.member_b_end = data.url
      }
      // }
      this.picsMBEDialog = false
    },
    //客服
    handleCSBChange() {
      this.picsCSBDialog = true
      this.isGetImage = true
    },
    picCSBPics(data) {
      if (data && data.url !== '') {
        this.form.customer_service_bg = data.url
      }
      // }
      this.picsCSBDialog = false
    },
    handleCSLChange() {
      this.picsCSLDialog = true
      this.isGetImage = true
    },
    picCSLPics(data) {
      if (data && data.url !== '') {
        this.form.customer_service_lp = data.url
      }
      // }
      this.picsCSLDialog = false
    },
    handleBQBChange() {
      this.picsBQBDialog = true
      this.isGetImage = true
    },
    picBQBPics(data) {
      if (data && data.url !== '') {
        this.form.buy_qua_bg = data.url
      }
      // }
      this.picsBQBDialog = false
    },


    closePicsDialog() {
      this.picsRBTDialog = false
      this.picsRFTDialog = false
      this.picsRFBEDialog = false
      this.picsRFBENDialog = false
      //注册成功页
      this.picsRSBTDialog = false
      this.picsRSBEDialog = false
      //弹窗
      this.picsPADialog = false
      this.picsOADialog = false
      //分享
      this.picsASEDialog = false
      this.picsOSENDialog = false
      this.picsOSECDialog = false
      this.picsSCBDialog = false

      //海报生成
      this.picsAPDialog = false
      this.picsOPECDialog = false
      this.picsOPENDialog = false

      //我的
      this.picsMBDialog = false
      this.picsMBEDialog = false

      //客服
      this.picsCSBDialog = false
      this.picsCSLDialog = false

      //购买资格
      this.picsBQBDialog = false
    },
    removePicsImg: function (type) {
      this.form[type] = ''
      return
    },
    getMsgConfig() {
      let params = {type: this.activeName}
    },

    saveBaseSetting() {
      let post = {
        query_exact_limit: this.form.query_exact_limit,
        query_limit: this.form.query_limit,
        email_send: this.form.email_send,
        member_query: this.form.member_query,
        tag: 'base'
      }
      if (this.form.query_limit < 0) {
        this.$message({type: 'error', message: '请填写正确的模糊查询限制次数'})
        return
      }
      if (this.form.query_exact_limit < 0) {
        this.$message({type: 'error', message: '请填写正确的精准查询限制次数'})
        return
      }
      this.saveEcSetting(post)
    },
    saveRegisterSetting() {
      let post = {
        register_bg_top: this.form.register_bg_top,
        register_form_top: this.form.register_form_top,
        // register_form_b_ec: this.form.register_form_b_ec,
        // register_form_b_ent: this.form.register_form_b_ent,
        tag: 'register'
      }
      this.saveEcSetting(post)
    },
    saveRegisterSucSetting() {
      let post = {
        register_sc_bg_top: this.form.register_sc_bg_top,
        register_sc_b_ent: this.form.register_sc_b_ent,
        register_sc_c_t: this.form.register_sc_c_t,
        tag: 'register_suc'
      }
      this.saveEcSetting(post)
    },
    saveAlertSetting() {
      let post = {
        pdp_alert: this.form.pdp_alert,
        order_alert: this.form.order_alert,
        tag: 'alert'
      }
      this.saveEcSetting(post)
    },
    saveShareSetting() {
      let post = {
        aar_share_ent: this.form.aar_share_ent,
        order_share_ent: this.form.order_share_ent,
        share_card_bg: this.form.share_card_bg,
        order_share_ec: this.form.order_share_ec,
        tag: 'share'
      }
      this.saveEcSetting(post)
    },
    savePosterSetting() {
      let post = {
        aar_poster: this.form.aar_poster,
        order_poster_ec: this.form.order_poster_ec,
        order_poster_ec_y: this.form.order_poster_ec_y,
        order_poster_ec_x: this.form.order_poster_ec_x,
        order_poster_ent: this.form.order_poster_ent,
        order_poster_ent_y: this.form.order_poster_ent_y,
        order_poster_ent_x: this.form.order_poster_ent_x,
        tag: 'poster'
      }
      this.saveEcSetting(post)
    },
    saveMemberSetting() {
      let post = {
        member_bg: this.form.member_bg,
        member_b_end: this.form.member_b_end,
        tag: 'member'
      }
      this.saveEcSetting(post)
    },
    saveBuyPageSetting() {
      let post = {
        buy_qua_bg: this.form.buy_qua_bg,
        tag: 'buy_qua'
      }
      this.saveEcSetting(post)
    },
    saveServiceSetting() {
      let post = {
        customer_service_bg: this.form.customer_service_bg,
        customer_service_lp: this.form.customer_service_lp,
        tag: 'service'
      }
      this.saveEcSetting(post)
    },
    saveEcSetting(post) {
      saveEcSetting(post).then(response => {
        if (response.data.data.status === true) {
          this.$message({type: 'success', message: '保存成功'})
          this.getEc()
        } else {
          this.$message({type: 'error', message: '保存失败'})
        }
      })
    },
    getEc() {
      getEcSetting().then(response => {
        this.form = response.data.data
      })
    }
  },
  mounted () {
    this.getEc()
  }
}
</script>
<style scoped type="text/css" lang="scss">
.el-tab-pane {
  min-height: 700px;
}

.message-content {
  button {
    margin-left: 20px;
  }
}

.message-count {
  margin: 0 5px 0 20px;
  font-size: 24px;
  color: #ff5000;
}

.message-template {
  .item {
    display: inline-block;
    width: 210px;
    margin: 0 10px;
    border: 1px solid #dfdfdf;

    &-title {
      padding: 10px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      cursor: pointer;

      i {
        float: right;
        margin-top: 4px;
      }
    }

    &-content {
      height: 140px;
      padding: 20px 10px;
      color: #333;
      font-size: 12px;
    }

    &-footer {
      // border-top: 1px solid #dfdfdf;
      padding: 10px 0;
      text-align: center;

      button {
        width: 50%;

        &:hover {
          border: 1px solid #c4c4c4;
          color: #1f2d3d;
        }
      }
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    }

    &.not-open-sms {
      .item-title {
        background-color: #FF4949;
      }
    }

    &.succ-open-sms {
      .item-title {
        background-color: #13CE66;
      }
    }
  }
}

.message-autograph {
  .el-input {
    width: 240px;
    margin-right: 10px;
  }

  span {
    margin: 0 10px;
  }

  button {
    width: 80px;
  }
}

.message-prompt {
  margin-top: 30px;
  padding-left: 20px;

  .prompt {
    &-title {
      margin-bottom: 10px;
      font-size: 18px;
      color: #333;

      span {
        border-left: 3px solid #20A0FF;
        padding-left: 10px;
      }
    }

    &-content {
      padding-left: 8px;
      line-height: 1.6;
      color: #666;

      .item-title {
        margin-bottom: 5px;
      }

      .item-content {
        padding-left: 14px;
        margin-bottom: 10px;
      }
    }
  }
}

.mb_15 {
  margin-bottom: 15px;
}

.span-tip {
  color: #8c939d;
  font-size: small;
}
.pics-box {
  overflow: hidden;
  .goodspic-wrap {
    float: left;
    margin-right: 5px;
    overflow: hidden;
    .goodspic {
      z-index: 1;
      position: relative;
      float: left;
      width: 120px;
      height: 120px;
      margin: 0 5px 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      .goodspic-mask {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.2);
        cursor: pointer;
        &.on {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .iconfont {
          margin: 0 8px;
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }

  .upload-box {
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    .iconfont {
      font-size: 30px;
      color: #ccc;
    }
  }
}
</style>
