<template>
  <div>
    <el-form label-width="100px" :inline="true">
      <el-form-item>
        <el-col :span="25">
          <el-date-picker
            v-model="vdate"
            type="daterange"
            alue-format="yyyy-MM-dd"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 100%;"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            @change="dateChange">
          </el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="20">
          <el-select v-model="params.tpl_id" placeholder="请选择模板">
            <el-option label="全部" key="0" value="0">全部</el-option>
            <el-option
                    v-for="item in tplList"
                    :key="item.id"
                    :label="item.tpl_name"
                    size="mini"
                    :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item>
        <el-col>
          <el-button type="primary" @click="searchData"> 搜索</el-button>
          <el-button type="primary" @click="exportData" v-loading="exportloading" style="margin-left: 10px">导出</el-button>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </el-col>
      </el-form-item>

    </el-form>
    <el-tabs>
        <template>
          <el-table :data="allListData" stripe border style="width: 100%" :height="wheight-230">
            <el-table-column prop="date" label="统计日期"></el-table-column>
            <el-table-column prop="tpl_name" label="模板名称"></el-table-column>
            <el-table-column prop="visit_count" label="打开数量"></el-table-column>
            <el-table-column prop="submit_count" label="完成数量"></el-table-column>
            <el-table-column label="操作" width="230">
              <template slot-scope="scope">
                <el-button  size="small" type="primary" @click="exportDetail(scope.row)">下载详细</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
    </el-tabs>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { getQuestionnaireStatisticsList, exportQuestionnaireDetail } from '@/api/datacube'
  import { getQuestionnairetplList } from '@/api/wxa'
  export default {
    data () {
      return {
        vdate: '',
        loading: true,
        exportloading: false,
        params: {
          start:'',
          end: '',
          tpl_id: '',
        },
        tplList: [],
        allListData: [],
        fields: ['date', 'tpl_name', 'visit_count', 'submit_count'],
        pickerOptions: {
          shortcuts: [{
            text: '最近一天',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一周',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          }]
        }
      }
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    },
    methods: {
      getTplList () {
        let tpl_params = {page : '1', page_size : '-1'}
        getQuestionnairetplList(tpl_params).then(res => {
          this.tplList = res.data.data.list
        }).catch( error => {
          this.$message({
            type: 'error',
            message: '获取模板信息出错'
          })
        })
      },
      searchData () {
        this.getDataList()
      },
      exportData () {
        this.exportloading = true
        this.params.export = 1
        getQuestionnaireStatisticsList(this.params).then(res => {
          this.exportloading = false
          if (res.data.data.status) {
                    this.$message({
                        type: 'success',
                        message: '已加入执行队列，请在设置-导出列表中下载'
                    })
                    return
                } else if (res.data.data.url) {
                    this.downloadUrl = res.data.data.url
                    this.downloadName = res.data.data.filename
                    this.downloadView = true
                } else {
                  this.$message({
                      type: 'error',
                      message: '无内容可导出 或 执行失败，请检查重试'
                  })
                  return
                }
        }).catch( error => {
          this.exportloading = false
        })
      },
      exportDetail (row) {
        this.exportloading = true
        let detail_params = {date: row.date, tpl_id: row.tpl_id}
        exportQuestionnaireDetail(detail_params).then(res => {
          this.exportloading = false
          if (res.data.data.status) {
                    this.$message({
                        type: 'success',
                        message: '已加入执行队列，请在设置-导出列表中下载'
                    })
                    return
                } else if (res.data.data.url) {
                    this.downloadUrl = res.data.data.url
                    this.downloadName = res.data.data.filename
                    this.downloadView = true
                } else {
                  this.$message({
                      type: 'error',
                      message: '无内容可导出 或 执行失败，请检查重试'
                  })
                  return
                }
        }).catch( error => {
          this.exportloading = false
        })
      },
      dateChange (val) {
        if (!val) {
          return false
        }
        this.params.start = val[0]
        this.params.end = val[1]
      },
      getDataList () {
        this.loading = true
        getQuestionnaireStatisticsList(this.params).then(res => {
          this.allListData = res.data.data.list
          this.loading = false
        }).catch( error => {
          this.$message({
            type: 'error',
            message: '获取统计信息出错'
          })
        })
      },
    },
    mounted () {
      this.getTplList()
      var start = new Date();
      var end = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
      this.vdate = [start, end]
      this.getDataList()
    }
  }
</script>
