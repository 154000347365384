<template>
  <div class="container">
    <el-form ref="form" label-width="110px" v-loading="formLoading">
      <el-card shadow="never" header="服务评价设置">
        <el-row>
          <el-col :span="10">
            <div class="headline">评价功能开关</div>
            <div class="effective-time">
              <el-switch v-model="form.evaluate_is_open" active-color="#13ce66"
                     inactive-color="#ff4949">
              </el-switch>
              <span>{{ form.evaluate_is_open ? '已开启' : '已关闭' }}</span>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <div class="headline">订单可评价时效设置</div>
            <div class="effective-time">
              <span>订单完成后</span>
              <el-input type="number" v-model="form.order_finish_allow_evaluation_time"
                :disabled="!this.form.evaluate_is_open"
                placeholder="请输入评价时效天数"></el-input>
              <span>天内可评价</span>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <div class="headline">专营店小程序店铺评分展示</div>
            <div class="effective-time">
              <el-switch v-model="form.show_distributor_score" active-color="#13ce66"
                :disabled="!this.form.evaluate_is_open"
                inactive-color="#ff4949">
              </el-switch>
              <span>{{ form.show_distributor_score ? '已开启' : '已关闭' }}</span>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <div class="headline">门店展示评分需达到服务评价数量</div>
            <div class="effective-time">
              <span>服务评价数达到</span>
              <el-input type="number" v-model="form.show_distributor_score_evaluation_number"
                :disabled="!this.form.evaluate_is_open"
                placeholder="请输入评价时效天数"></el-input>
              <span>条，评分可见</span>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <div class="headline">订单列表服务评价弹窗提示</div>
            <div class="effective-time">
              <el-switch v-model="form.order_list_alter_evaluation" active-color="#13ce66"
                :disabled="!this.form.evaluate_is_open"
                inactive-color="#ff4949">
              </el-switch>
              <span>{{ form.order_list_alter_evaluation ? '已开启' : '已关闭' }}</span>
            </div>
            <div class="effective-time">
              <span>距下次提示间隔</span>
              <el-input type="number" v-model="form.order_list_alter_evaluation_space"
                :disabled="!this.form.evaluate_is_open"
                placeholder="请输入评价时效天数"></el-input>
              <span>天</span>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <div class="headline">用户评价标签必选数量</div>
            <div class="effective-time">
              <span>评价标签至少选择</span>
              <el-input type="number" v-model="form.evaluation_tag_min_select"
                :disabled="!this.form.evaluate_is_open"
                placeholder="请输入评价时效天数"></el-input>
              <span>项</span>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </el-form>
    <div class="preservation">
      <el-button type="primary" @click="onPreservation" :loading="btnLoading">保存配置</el-button>
    </div>
  </div>
</template>

<script>
import { getEvaluationConfig, postEvaluationConfig } from '@/api/marketing'


export default {
  data() {
    return {
      formLoading: true,
      btnLoading: false,
      form: {
        evaluate_is_open: false,
        order_finish_allow_evaluation_time: 1,
        show_distributor_score: true,
        show_distributor_score_evaluation_number: 1,
        order_list_alter_evaluation: true,
        order_list_alter_evaluation_space: 14,
        evaluation_tag_min_select: 2
      }
    }
  },
  methods: {
    evaluationConfig() {
      this.formLoading = true
      getEvaluationConfig().then((res) => {
        if (res.data.data.order_list_alter_evaluation == 'true') {
          res.data.data.order_list_alter_evaluation = true
        } else {
          res.data.data.order_list_alter_evaluation = false
        }
        if (res.data.data.show_distributor_score == 'true') {
          res.data.data.show_distributor_score = true
        } else {
          res.data.data.show_distributor_score = false
        }
        if (res.data.data.evaluate_is_open == 'true') {
          res.data.data.evaluate_is_open = true
        } else {
          res.data.data.evaluate_is_open = false
        }
        this.form = res.data.data
        this.formLoading = false
      })
    },
    onPreservation() {
      this.btnLoading = true
      postEvaluationConfig(this.form).then((res) => {
        if (res.data.data.success) {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
        }
        this.btnLoading = false
        this.evaluationConfig();
      })
    }
  },
  mounted() {
    this.evaluationConfig()
  }
}

</script>
<style scoped lang="scss">
.effective-time {
  display: flex;
  align-items: center;
  margin: 10px 0;

  .el-input {
    width: 200px;
    margin: 0 10px;
  }
}

.headline {
  font-weight: 700;
}

.preservation {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
