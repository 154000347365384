<template>
  <div>
    <el-form label-width="80px" :model="formData" ref="formData">
      <el-form-item label="礼品名称" prop="name" :rules="[
        { required: true, message: '名称不能为空' },
      ]">
        <el-input type="text" placeholder="请输入内容" v-model="formData.name" maxlength="12" show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item label="礼品描述" prop="gift_describe">
        <el-input type="textarea" placeholder="请输入内容" v-model="formData.gift_describe" maxlength="50" show-word-limit>
        </el-input>
      </el-form-item>

      <el-form-item label="礼品类型">
        <el-radio-group :disabled="isDisabled" v-model="formData.type">
          <el-radio-button label="1">常规礼品</el-radio-button>
          <el-radio-button label="2">限定礼品</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="礼品主图" prop="pics" :rules="[
        { required: true, message: '主图不能为空' },
      ]">
        <img v-if="formData.pics != ''" :src="formData.pics" class="banner-uploader" @click="addImgPreview('HOST')" />
        <div class="banner-uploader" v-else @click="addImgPreview('HOST')">
          <i class="iconfont icon-camera"></i>
          上传图片
        </div>
      </el-form-item>
      <!-- <el-form-item label="上架状态">
        <el-switch v-model="formData.status" active-value="1" inactive-value="0" active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </el-form-item> -->

      <!-- <el-form-item label="礼品描述">
        <vue-html5-editor :content="formData.intro" ref="editor" :height="360"></vue-html5-editor>
        <span class="tpl_item img" @click="addImgPreview('HTML')" style="">
          <i class="iconfont icon-image"></i>图片
        </span>
      </el-form-item> -->

      <el-form-item>
        <el-button type="primary" @click="onSave('formData')">保存</el-button>
      </el-form-item>
    </el-form>

    <imgPicker :dialog-visible="imgsVisible" :sc-status="isGetImage" @chooseImg="pickImg"
      @closeImgDialog="closeimgsVisible"></imgPicker>

  </div>
</template>
<script>
import imgPicker from "@/components/imageselect";

export default {
  components: {
    imgPicker
  },
  props: {
    info: {
      type: Object,
      default: {}
    }
  },
  watch: {
    info: {
      deep: true,
      handler(value) {
        if (value) {
          this.setData(value)
        }
      }
    }
  },
  data() {
    return {
      formData: {
        name: '',
        gift_describe: '',
        type: '1',
        pics: '',
        status: '1',
        intro: '',
      },
      imgTypes: '',
      imgsVisible: false,
      isGetImage: false,
      isDisabled: false
    }
  },
  methods: {
    setData(val) {
      if (val.id) {
        this.$refs['formData'].resetFields();
        this.isDisabled = true
        this.formData.name = val.name
        this.formData.gift_describe = val.gift_describe
        this.formData.type = val.type
        this.formData.pics = val.pics
        this.formData.status = val.status
        this.formData.intro = val.intro
      } else {
        this.$refs['formData'].resetFields();
        this.formData.type = '1'
        this.formData.status = '1'
        this.isDisabled = false
      }
    },
    addImgPreview(i) {
      this.imgTypes = i
      this.imgsVisible = true
      this.isGetImage = true
    },
    pickImg(data) {
      if (this.imgTypes == 'HOST') {
        this.formData.pics = data.url
      } else {
        var loc = this.$refs.editor
        var img = new Image()
        img.src = this.wximageurl + data.url
        if (loc.range) {
          loc.range.insertNode(img)
        } else {
          loc.$refs.content.appendChild(img)
          loc.focus()
          loc.restoreSelection()
        }
        this.formData.intro = loc.$refs.content.innerHTML
      }
      this.imgsVisible = false;
    },
    closeimgsVisible() {
      this.imgsVisible = false;
    },
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = { ...this.formData }
          if (this.info.id) {
            params.id = this.info.id
          }
          this.$emit('onSave', params)
        }
      });
    }
  },
  mounted() {
    this.setData(this.info)
  }
}
</script>
<style scoped lang="scss"></style>
