import { render, staticRenderFns } from "./temporaryDeliveryPlace.vue?vue&type=template&id=5b268c63&scoped=true&v-loading=lzLoading&element-loading-text=%E5%8A%A0%E8%BD%BD%E4%B8%AD..."
import script from "./temporaryDeliveryPlace.vue?vue&type=script&lang=js"
export * from "./temporaryDeliveryPlace.vue?vue&type=script&lang=js"
import style0 from "./temporaryDeliveryPlace.vue?vue&type=style&index=0&id=5b268c63&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b268c63",
  null
  
)

export default component.exports