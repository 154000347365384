<template>
  <div>
    <SpFinder
      ref="finder"
      :data="tableData"
      :setting="setting"
      no-selection
      :show-pager="false"
      style="max-width: 900px;"
      class="clear-finder-style"
    />
    <el-button style="margin-top: 12px;" type="primary" @click="handleAddGoods">添加商品</el-button>
  </div>
</template>
<script>
import { tableSetting } from './config'

export default {
  components: {},
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  model: {
    prop: 'value',
    event: 'update',
  },
  data () {
    return {
      tableData: []
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (val) {
        this.tableData = val
      }
    },
    tableData: {
      deep: true,
      immediate: true,
      handler (val) {
        this.$emit('update', val)
      }
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {},
  methods: {
    async handleAddGoods() {
      const { data } = await this.$picker.goodsitem({
        data: this.tableData,
        paramsFieldExclude: ['distributor_id'],
        queryParams: {
          is_sku: true,
        },
        rowKey: 'item_id'
      })
      this.filterData(data)
    },
    /**
     * @description 格式化追加数据 保留数据状态
     * @description 以picker数据为主 table存在相同数据 则将picker同id数据覆盖 最后替换到table
     * @param {Array} data picker选中数据
     * @param {Array} this.tableData table数据
     */
    filterData(data) {
      const orgData = JSON.parse(JSON.stringify(data))
      orgData.forEach(item => {
        // 设置默认值
        item.isValidSwitch = false
      })
      this.tableData.forEach(item => {
        const findex = orgData.findIndex(f => f.item_id === item.item_id)
        if(findex !== -1) {
          // 保留状态
          orgData[findex].isValidSwitch = item.isValidSwitch
        }
      })
      this.tableData = [...orgData]
    },
    handleCommand(command) {
      if(command === '1') {
        this.tableData.forEach(item => {
          item.isValidSwitch = true
        })
      } else {
        this.tableData.forEach(item => {
          item.isValidSwitch = false
        })
      }
    },
    handleDelete(row) {
      this.tableData = this.tableData.filter(item => item.item_id !== row[0].item_id)
    }
  }
}
</script>

<style scoped>

</style>
