<template>
  <div>

      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="普通快递配送商品" name="normal">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-button type="primary" class="el-icon-plus" @click="addItems('normal')" size="mini">添加商品</el-button>
            </el-col>
          </el-row>
          <el-alert
            title="此列表配置的商品，用户结算将只支持普通快递配送，请谨慎操作！"
            type="error">
          </el-alert>
          <el-table :data="goodsLists" :height="wheight-200" v-loading="loading">
            <!-- <el-table-column prop="old_id" label="ID" width="80"></el-table-column> -->
            <el-table-column prop="item_id" label="ID" min-width="80"></el-table-column>
            <el-table-column prop="item_name" label="商品名称" min-width="180"></el-table-column>
            <el-table-column prop="price" label="销售价" min-width="100"></el-table-column>
            <el-table-column prop="approve_status" label="状态" min-width="100"></el-table-column>
            <el-table-column prop="itemMainCatName" label="商品分类" min-width="150"></el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="remove(scope.row.item_id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div v-if="total_count > params.pageSize" class="content-center content-top-padded">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :page-sizes="[10,20,50]"
            :current-page.sync="params.page"
            :total="total_count" :page-size="params.pageSize">
          </el-pagination>
        </div>
        </el-tab-pane>
        <el-tab-pane label="商品配送距离" name="distance">
          <el-alert
            title="此列表用于配置商品小时达的配送范围"
            type="success">
          </el-alert><br>
          <el-row :gutter="20">
            <el-col :span="4">
              <el-button type="primary" class="el-icon-plus" @click="addItems('distance')" size="mini">添加商品</el-button>
            </el-col>
            <el-col :span="5">
                <el-input placeholder="请输入商品名称" v-model="keywords">
                  <el-button slot="append" icon="el-icon-search"  @click="searchList()"></el-button>
                </el-input>
            </el-col>

          </el-row>
          <el-divider></el-divider>
          <el-row :gutter="20">
          <el-col :span="6">
                  <el-input v-model="defaultDistance"  @change="updatedDefaultDistance()">
                    <template slot="prepend"><span style="color:#409EFF;">小时达默认配送</span></template>
                    <template slot="append"><span  style="color:#409EFF;">km</span></template>
                  </el-input>
              </el-col>
           </el-row>
          <el-table :data="goodsLists" :height="wheight-200" v-loading="loading">
            <!-- <el-table-column prop="old_id" label="ID" width="80"></el-table-column> -->
            <el-table-column prop="item_id" label="ID" min-width="80"></el-table-column>
            <el-table-column prop="item_name" label="商品名称" min-width="180"></el-table-column>
            <el-table-column prop="price" label="销售价" min-width="100"></el-table-column>
            <el-table-column prop="approve_status" label="状态" min-width="100"></el-table-column>
            <el-table-column prop="distance" label="小时达支持范围" min-width="150">
              <template  slot-scope="scope">
                <el-input placeholder="" v-model="scope.row.setting_values" type="number" style="width: 70%" @change="updateValue(scope.row)" >
                  <template slot="append">km</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="remove(scope.row.item_id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div v-if="total_count > params.pageSize" class="content-center content-top-padded">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :page-sizes="[10,20,50]"
            :current-page.sync="params.page"
            :total="total_count" :page-size="params.pageSize">
          </el-pagination>
        </div>
        </el-tab-pane>
        <el-tab-pane label="商品加购设置" name="addcart">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-button type="primary" class="el-icon-plus" @click="addCartItems('normal')" size="mini">添加商品</el-button>
            </el-col>
          </el-row>
          <el-alert
            title="此列表配置的商品，购买时不可加入购物车，只能直接购买!"
            type="error">
          </el-alert>
          <el-table :data="cartLists" :height="wheight-200" v-loading="loading">
            <!-- <el-table-column prop="old_id" label="ID" width="80"></el-table-column> -->
            <el-table-column prop="item_id" label="ID" min-width="80"></el-table-column>
            <el-table-column prop="item_name" label="商品名称" min-width="180"></el-table-column>
            <el-table-column prop="price" label="销售价" min-width="100"></el-table-column>
            <el-table-column prop="approve_status" label="状态" min-width="100"></el-table-column>
            <el-table-column prop="itemMainCatName" label="商品分类" min-width="150"></el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="removeCart(scope.row.item_id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div v-if="total_count > params.pageSize" class="content-center content-top-padded">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              :page-sizes="[10,20,50]"
              :current-page.sync="params.page"
              :total="total_count" :page-size="params.pageSize">
            </el-pagination>
          </div>
        </el-tab-pane>

      </el-tabs>
    <GoodsSelect ref="empty_select" rowKey="goods_id" :items-visible="selectGoodsVisible"  :item-tier="itemNormal" :get-status="setItemStatus" :rel-items-ids="relItemsIds" @chooseStore="chooseItemsAction" @closeStoreDialog="closeItemDialogAction"></GoodsSelect>
    <GoodsSelect ref="empty_select" :items-visible="cartGoodsVisible" :item-tier="itemNormal" :get-status="setItemStatus" :rel-items-ids="relItemsIds" @chooseStore="chooseItemsCartAction" @closeStoreDialog="closeItemDialogCartAction"></GoodsSelect>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  getDeliverySettingList,
  addDeliverySettingGoods,
  delDeliverySettingGoods,
  updateDeliverySettingValues,
  updateDeliverySettingDefaultValues,
  delNotCartGoods,
  addNotCartGoods,
  getNotCartList
} from '@/api/goods'
import GoodsSelect from '@/components/goodsSelect'
import TemplatePreview from "../../../components/function/templatePreview";

export default {
  props: ['getStatus'],
  provide () {
    return {
      refresh: this.refresh
    }
  },
  components: {
    TemplatePreview,
    GoodsSelect
  },
  data () {
    return {
      selectGoodsVisible: false,
      cartGoodsVisible: false,
      setItemStatus: false,
      relItemsIds: [],
      after_goods_status: 0,
      create_time: '',
      keywords:'',
      activeName: 'normal',
      goodsLists: [],
      cartLists: [],
      distanceLists:[],
      defaultDistance:20,
      downloadfilename: '',
      downloadUrl: '',
      loading: false,
      total_count: 0,
      params: {
        page: 1,
        pageSize: 20,
        status: '',
        activity_name: '',
        time_start_begin: '',
        seckill_type: 'limited_time_sale',
        time_start_end: '',
        type:'normal',
        search:''
      },
      itemNormal: [
        'normal'
      ],
      communityVisible: false,
      couponVisible: false,
      goodsVisible: false,
      goodsList: [],
      goodsCount: 0,
      distributors: [],
      goodsPageSize: 10,
      goodsPage:1,
      nowActivity: '',
      cursymbol: '',
      dialogVisible: false,
      currency: {},
      is_addcart_goods: ''
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    handleClick(tab, event) {
      this.params.type = this.activeName
      this.params.page =1
      if(this.activeName !=='addcart'){
        this.getDeliverySettingList();

      }else{
        this.getNotCartList();

      }
    },
    searchList(){
      this.params.type = this.activeName
      this.params.page =1
      this.params.search = this.keywords
      this.getDeliverySettingList();
    },
    remove (id) {
      let form = {}
      form = {
        item_id: id,
        type:this.activeName
      }
      delDeliverySettingGoods(form).then(response => {
        this.loading = false
        this.$refs.empty_select.cancelAction()
        this.getDeliverySettingList()
      })
    },
    removeCart (id) {
      let form = {}
      form = {
        item_id: id,
      }
      delNotCartGoods(form).then(response => {
        this.loading = false
        this.$refs.empty_select.cancelAction()
        this.getNotCartList()
      })
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getDeliverySettingList()
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getDeliverySettingList()
    },
    addItems (type) {
      this.closeItemDialogAction()
      if (type == 'normal') {
        this.is_addcart_goods = '0'
      } else if (type == 'distance') {
        this.is_addcart_goods = ''
      }
      this.selectGoodsVisible = true
      this.setItemStatus = true
    },
    addCartItems (type) {
      this.closeItemDialogAction()
      if (type == 'normal') {
        this.is_addcart_goods = '0'
      } else if (type == 'distance') {
        this.is_addcart_goods = ''
      }
      this.cartGoodsVisible = true
      this.setItemStatus = true
    },
    updateValue(row){
      if(row.setting_values <= 0){
        this.$message.error('距离必须大于0');
        return false;
      }
      let put ={
        item_id : row.item_id,
        value : row.setting_values,
        type : 'distance'
      }
      updateDeliverySettingValues(put).then(response => {
          this.loading = false
          this.$refs.empty_select.cancelAction()
          this.getDeliverySettingList()
      })
    },
    updatedDefaultDistance(){
      let put ={
        default : this.defaultDistance,
        type : 'distance'
      }
      updateDeliverySettingDefaultValues(put).then(response => {
          this.loading = false
          this.$refs.empty_select.cancelAction()
          this.getDeliverySettingList()
      })
    },
    getDeliverySettingList () {
      this.loading = true
      console.log(this.params)
      getDeliverySettingList(this.params).then(response => {
        this.goodsLists = response.data.data.list
        this.total_count = response.data.data.total_count
        this.defaultDistance = response.data.data.default
        this.loading = false
        this.relItemsIds = [...this.goodsLists]
      })

      // console.log("this.goodsLists:",this.goodsLists)
    },
    getNotCartList () {
      this.loading = true
      getNotCartList(this.params).then(response => {
        this.cartLists = response.data.data.list
        this.total_count = response.data.data.total_count
        this.loading = false
      })

      // console.log("this.goodsLists:",this.goodsLists)
    },
    dateStrToTimeStamp (str) {
      return Date.parse(new Date(str))/1000
    },
    closeItemDialogAction () {
      this.selectGoodsVisible = false
      this.setItemStatus = false
      this.is_addcart_goods = ''
    },
    closeItemDialogCartAction () {
      this.cartGoodsVisible = false
      this.setItemStatus = false
      this.is_addcart_goods = ''
    },
    chooseItemsAction (data) {
      /* if (this.form.recommend_items.length > 0 && data.length <= 0) {
         data = this.form.recommend_items
       } */
      if (data === null || data.length <= 0) return
      let arr = []//
      data.forEach((item, index) => {
        console.log(item)
        arr.push(item.goods_id)
      })
      this.selectGoodsVisible = false
      this.setItemStatus = false
      this.is_addcart_goods = ''
      console.log('arr_11', arr)
      if (arr.length > 0) {
        let form = {}
        form = {
          itemIds: arr,
          type:this.activeName
        }
        addDeliverySettingGoods(form).then(response => {
          this.getDeliverySettingList()
        })
      }
    },
    chooseItemsCartAction (data) {
      /* if (this.form.recommend_items.length > 0 && data.length <= 0) {
         data = this.form.recommend_items
       } */
      if (data === null || data.length <= 0) return
      let arr = []//
      data.forEach((item, index) => {
        console.log(item)
        arr.push(item.goods_id)
      })
      this.cartGoodsVisible = false
      this.setItemStatus = false
      this.is_addcart_goods = ''
      console.log('arr_11', arr)
      if (arr.length > 0) {
        let form = {}
        form = {
          itemIds: arr,
          type:this.activeName
        }
        addNotCartGoods(form).then(response => {
          this.getNotCartList()
        })
      }
    },
    refresh () {
      this.getDeliverySettingList()
    }
  },
  mounted () {
    this.getDeliverySettingList()
  },
  beforeRouteUpdate (to, from, next) {
    next()
    if (to.path.indexOf('_editor') === -1 && to.path.indexOf('_detail') === -1) {
      this.getDeliverySettingList()
    }
  },
  watch: {
    getStatus (val) {
      if (val) {
        this.getDeliverySettingList()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 10px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.icon-search-plus {
  cursor: pointer;
}
.text-muted {
  color: #999;
}
</style>
