<style scoped lang="scss">
.store-name {
  padding-bottom: 5px;
  font-size: 16px;
  color: #333;
}
.store-contact {
  color: #888;
  span {
    margin-right: 10px;
  }
}
.store-address {
  color: #888;
}
.tip_style {
  color: #ff5000;
}
</style>
<template>
  <div>
    <div
      v-if="
        $route.path.indexOf('_editor') === -1 &&
        $route.path.indexOf('_detail') === -1 &&
        $route.path.indexOf('_template') === -1
      "
    >
      <el-row :gutter="20">
        <el-col>
          <el-select
            v-model="params.is_valid"
            @change="isValidSelectHandle"
            placeholder="是否启用"
          >
            <el-option
              v-for="(item, index) in isValidList"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
          <!--          <el-cascader-->
          <!--            placeholder="根据地区筛选"-->
          <!--            :options="regions"-->
          <!--            filterable-->
          <!--            :props="{ checkStrictly: true }"-->
          <!--            @change="RegionChangeSearch"-->
          <!--          ></el-cascader>-->
          <el-input
            class="input-m"
            placeholder="经销商名称"
            v-model="params.name"
            clearable
            @clear="numberSearch"
          ></el-input>
          <el-input
            class="input-m"
            placeholder="经销商HQID"
            v-model="params.dealer_hqid"
            clearable
            @clear="numberSearch"
          ></el-input>
          <el-input
            class="input-m"
            placeholder="分销商HQID"
            v-model="params.distributor_hqid"
            clearable
            @clear="numberSearch"
          ></el-input>
          <el-input
            class="input-m"
            placeholder="分销商VAD"
            v-model="params.distributor_vad"
            clearable
            @clear="numberSearch"
          ></el-input>
          <el-input
            class="input-m"
            placeholder="渠道RTM"
            v-model="params.channel_rtm"
            clearable
            @clear="numberSearch"
          ></el-input>
          <!--          <el-cascader-->
          <!--            v-if="system_mode === 'platform'"-->
          <!--            placeholder="选择标签"-->
          <!--            :options="tag.list"-->
          <!--            :props="{value:'tag_id', label: 'tag_name'}"-->
          <!--            v-model="select_tags_value"-->
          <!--            clearable-->
          <!--            @change="TagChangeAction"-->
          <!--          ></el-cascader>-->
          <!--          <el-input class="input-m" placeholder="经销商手机号" v-model="params.mobile">-->
          <!--            <el-button slot="append" icon="el-icon-search" @click="numberSearch"></el-button>-->
          <!--          </el-input>-->
          <el-button
            icon="el-icon-search"
            @click="numberSearch"
            type="primary"
            class="mt10"
            >搜索</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col  v-if="this.operator_type != 'admin'" style="display: flex">
          <el-button-group v-if="!is_distributor">
            <el-button
              type="primary"
              @click="addDistributorSelf()"
              v-if="!distributor_self && login_type !== 'dealer'"
              >新增经销商</el-button
            >
          </el-button-group>
          <ImportDialog
            v-if="this.operator_type == 'admin_del' || this.operator_type == 'staff' || this.operator_type == 'disti'"
            style="margin-left: 10px"
            buttonName="导入经销商"
            buttonSize="default"
            fileName="导入经销商"
            fileType="upload_dealer"
          ></ImportDialog>

          <el-button type="primary" @click="exportData" v-loading="exportloading" style="margin-left: 10px">导出</el-button>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>

        </el-col>
      </el-row>
      <el-card>
        <el-table
          align="center"
          :data="list"
          @selection-change="handleSelectionChange"
          v-loading="loading"
        >
          <el-table-column
            type="selection"
            align="center"
            label="全选"
          ></el-table-column>
          <el-table-column
            width="50"
            prop="dealer_id"
            label="ID"
          ></el-table-column>
          <el-table-column label="经销商名称">
            <template slot-scope="scope">
              <div class="store-name">{{ scope.row.name }}</div>
              <div class="store-contact">
                <span>
                  <i class="el-icon-s-shop"></i>
                  {{ scope.row.dealer_hqid }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="distributor_hqid"
            width="150"
            label="分销商HQID"
          ></el-table-column>
          <el-table-column
            prop="distributor_vad"
            width="150"
            label="分销商VAD"
          ></el-table-column>
          <el-table-column
            prop="channel_rtm"
            width="150"
            label="渠道RTM"
          ></el-table-column>
          <el-table-column width="70" label="状态"  v-if="login_type !== 'dealer' && login_type !== 'admin'">
            <template slot-scope="scope">
              <el-button
                @click="editValid(scope.row)"
                v-if="scope.row.is_valid !== 'delete'"
                type="text"
              >
                <span class="green" v-if="scope.row.is_valid == 'true'">
                  启用
                  <i class="el-icon-s-tools"></i>
                </span>
                <span class="red" v-else>
                  禁用
                  <i class="el-icon-s-tools"></i>
                </span>
              </el-button>
              <span v-else class="muted">废弃</span>
            </template>
          </el-table-column>
          <el-table-column label="下载小程序码"  v-if="login_type !== 'dealer'">
            <template slot-scope="scope">
              <el-button type="text" @click="downQrCode('online', scope.row)">线上</el-button>
            </template>
          </el-table-column>
          <el-table-column label="分期支持">
            <template slot-scope="scope">
              <el-button v-if="scope.row.open_fq_pay_hua_bei == '1'" type="primary" plain size="mini">花呗分期</el-button>
              <el-button v-if="scope.row.open_fq_pay_credit_card == '1'" type="primary" plain size="mini">支付宝信用卡分期</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link :underline="false" size="medium"   v-if="login_type !== 'dealer' && login_type != 'admin'">
              <router-link
                v-if="scope.row.is_valid !== 'delete'"
                :to="{
                  path: matchHidePage('editor'),
                  query: { dealer_id: scope.row.dealer_id },
                }"
                >编辑
              </router-link>
              </el-link>
              <!--<el-button type="text" @click="downDistributor(scope.row, 'scancode')">扫码购页面码(微商城)</el-button>-->
              <!-- <router-link :to="{  path: matchInternalRoute('Storeshopitemanagement'), query: {distributor_id: scope.row.distributor_id}}">商品码</router-link> -->
              <!--router-link :to="{ path: matchHidePage('detail'), query: { distributor_id: scope.row.distributor_id, distributor_name: scope.row.name,parentPath: '/mall/marketing/distributor'}}">商品码</router-link-->
              <el-link v-if="login_type != 'admin'" type="primary"  title="AdaPay支付配置" size="medium" :underline="false" @click="getAdapaySetting(scope.row)">支付配置</el-link>
              <el-link  type="primary"  v-if="login_type === 'dealer'"  title="AdaPay提现" size="medium" :underline="false" @click="getAdaPayCashSetting(scope.row)">提现</el-link>
              <el-link
                type="primary"
                title="分期配置"
                size="medium"
                :underline="false"
                @click="handleStagingConfig(scope.row)"
                >
                 分期配置
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total_count"
          ></el-pagination>
        </div>
      </el-card>
      <el-dialog
        title="修改状态"
        width="18%"
        :visible.sync="editValidDialog"
        :before-close="handleCancelLabelsDialog"
        :open="(is_valid_updated = false)"
      >
        <template>
          <el-radio-group v-model="editValidData.is_valid">
            <el-radio label="true">启用</el-radio>
            <el-radio label="false">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-dialog>
    </div>
    <router-view></router-view>
    <el-drawer
      :title="title"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="50%"
    >
      <div class="demo-drawer__content">
        <el-form ref="adapayForm" :model="adapayForm" label-width="200px" :rules="rules">
          <el-form-item label="名称(商城系统)" prop='name'>
            <span class="frm-tips">{{adapayForm.name}}</span>
          </el-form-item>
          <el-form-item label="名称(支付拉取)" prop='mer_name' v-if="adapayForm.mer_name ">
            <span class="frm-tips">{{adapayForm.mer_name}}</span>
            <div>
            <span class="red frm-tips">* 请检查商户号拉取的商户名称！</span>
            </div>
          </el-form-item>

          <el-form-item label="AdaPay APP ID" prop='app_id'>
            <el-input :disabled="adapayFormRes.app_id" v-model="adapayForm.app_id" style="width:450px"/>
            <br/>
            <span class="frm-tips">汇付商户后台调用支付的应用ID</span>
          </el-form-item>
          <el-form-item label="AdaPay APP KEY" prop='api_key_live'>
            <el-input :disabled="adapayFormRes.api_key_live" v-model="adapayForm.api_key_live" style="width:450px"/>
            <br/>
            <span class="frm-tips">prod 环境下交易密钥</span>
          </el-form-item>
          <el-form-item label="商户 PRIVATE KEY(RSA)" prop='rsa_private_key'>
            <el-input :disabled="adapayFormRes.rsa_private_key"
              type="textarea"
              :rows="18"
              placeholder="请输入内容"
              style="width:450px"
              maxlength="1500"
              show-word-limit
              v-model="adapayForm.rsa_private_key"
            />
            <br/>
            <p class="frm-tips" style="margin-left:0px">用于商户发起交易接口请求时, AdaPay 对请求对象进行 RSA 加密验签使用</p>
          </el-form-item>
          <el-form-item label="AdaPay PUBLIC KEY(RSA)" prop='rsa_public_key'>
            <el-input :disabled="adapayFormRes.rsa_public_key"
              type="textarea"
              :rows="6"
              maxlength="500"
              placeholder="请输入内容"
              style="width:450px"
              show-word-limit
              v-model="adapayForm.rsa_public_key"
            />
            <br/>
            <span class="frm-tips">用于 AdaPay 异步回调时，商户 Server 验证请求对象的正确性</span>
          </el-form-item>
          <div class="section-footer">
            <el-form-item>
              <div>
              <span v-if="adapayForm.mer_name " class="red frm-tips">* 请检查商户号拉取的商户名称,确认无误后保存</span>
              </div>
              <el-button type="primary" v-loading="loading" @click="onSubmitFrom('adapayForm')">保存</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-drawer>
    <el-drawer
      :title="cashTitle"
      :visible.sync="cashDialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="50%"
    >
      <div class="demo-drawer__content">
        <el-form ref="adapayForm" :model="adapayForm" label-width="200px" :rules="rules">

          <el-form-item style="display: none" label="账户应用ID">
            <span>{{ adapayForm.app_id }}</span>
          </el-form-item>

          <el-form-item label="可提现余额">
            <span>{{ adapayForm.acct_balance || '0.00' }} 元</span>
          </el-form-item>

          <el-form-item style="display: none" label="是否自动">
            <el-switch v-model="adapayForm.auto_cash" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
          </el-form-item>
          <el-form-item label="提现类型">
            <el-radio-group v-model="adapayForm.cash_type">
              <!--              <el-radio :label="'D0'">即时取现</el-radio>-->
              <!--              <el-radio :label="'D1'">D+1取现</el-radio>-->
              <el-radio :label="'T1'">T+1取现</el-radio>
            </el-radio-group>
          </el-form-item>
<!--          <el-form-item label="预留金">-->
<!--            <span>{{ adapayForm.remained_amt || '0.00' }} 元</span>-->
<!--            &lt;!&ndash;            <el-input-number v-model="adapayForm.remained_amt" controls-position="right" :precision="2" :min="0" @change="handleChange" :disabled="inputDisabled"></el-input-number>&ndash;&gt;-->
<!--          </el-form-item>-->
          <el-form-item label="提现金额">
            <div>
              <el-col :span="10">
                <el-input  v-model="amount" :placeholder=" '最大输入金额不得超过'+(adapayForm.acct_balance || '0.00') "></el-input>
                <span  class="tip_style ">不填金额则默认为全部提现</span>
                <!--                <el-input v-model="amount  ":placeholder="`最大输入金额不得超过${adapayForm.remained_amt || '0.00'}`"></el-input>-->
              </el-col>
            </div>
          </el-form-item>
          <div class="section-footer">
            <el-form-item>
              <el-button  v-if="doCash === true" type="primary" v-loading="loading" plain :disabled="isDisabled" @click="onSubmitFrom('adapayForm',1)"> 提现 </el-button>
              <el-button  v-if="doCash === false" type="info" v-loading="loading" plain :disabled="isDisabled" @click="disableDoCash()"> 提现 </el-button>
            </el-form-item>
          </div>
        </el-form>
        <el-table :data="cashList" stripe border>
          <el-table-column prop="cash_type" label="提现类型" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="cash_amt" label="提现金额" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="status" label="提现状态" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="create" label="提现时间" align="center" show-overflow-tooltip></el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            small
            background
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handleCashCurrentChange"
            @size-change="handleCashSizeChange"
            :current-page.sync="cashParams.page"
            :total="cash_total_count"
            :pager-count="5"
            :page-size="cashParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
          >
          </el-pagination>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { saveDealer, openDealer, getDealerList, getWxaDristributorCodeStream, setDefaultDistributor, saveOpen } from '@/api/marketing'
import { getTagList, distributorRelTags, getDistance, setDistance } from '@/api/marketing'
import { getDistributorMeiQia, setDistributorMeiQia } from '@/api/im'
import { getCodeUnlimit } from '@/api/wxa'
import district from '../../../../common/district.json'
import shopDecoration from '@/components/function/shopDecoration'
import pcDecoration from '@/view/pc/homePage/default'
import { getSetting } from '@/api/fenzhang'
import ImportDialog from '@/components/importDialog'
import store from '@/store'
import {getAdapayDetail, saveAdapayFrom, getAdaPayCashList,getAdaPayCapitalList} from '@/api/trade'
import { useDialog } from '@shopex/finder'
// 取选中地区的值
function getCascaderObj (val, opt) {
  return val.map(function (value, index, array) {
    for (var itm of opt) {
      if (itm.value === value) {
        opt = itm.children
        return itm
      }
    }
    return null
  })
}

export default {
  data () {
    return {
      isDisabled: true,
      inputDisabled: true,
      title:'',
      dialog: false,
      formLabelWidth: '180px',
      adapayForm: {
        name: '',
        mer_name: '',
        dealer_id: '',
        app_id: '',
        api_key_live: '',
        rsa_private_key: '',
        rsa_public_key: '',
        auto_cash: false,
        cash_type: 'T1',
        acct_balance: '0.00',
        remained_amt: '',
      },
      adapayFormRes: {},
      cashTitle: '',
      cashDialog: false,
      cashParams: {
        page: 1,
        pageSize: 10,
      },
      cash_total_count: 0,
      doCash: false,
      cashList: [],

      capitalDialog: false,
      capitalTitle: '',
      capitalParams: {
        page: 1,
        pageSize: 10,
      },
      capitalList: [],
      capitalTotalCount: 0,
      capitalType: [
        { name: '全部', value: '' },
        { name: '出账', value: 'outcome' },
        { name: '入账', value: 'income' }
      ],
      rules: {
        dealer_id: [
          {required: true, message: '请检查经销商ID', trigger: 'blur'}
        ],
        app_id: [
          {required: true, message: '请输入 应用ID', trigger: 'blur'}
        ],
        api_key_live: [
          {required: true, message: '请输入 API_KEY', trigger: 'blur'}
        ],
        rsa_private_key: [
          {required: true, message: '请输入 商户RSA私钥', trigger: 'blur'}
        ],
        rsa_public_key: [
          {required: true, message: '请输入 AdaPay RSA公钥', trigger: 'blur'}
        ],
        auto_cash: [
          {required: false, message: '自动提现', trigger: 'blur'}
        ],
        cash_type: [
          {required: false, message: '提现类型', trigger: 'blur'}
        ],
        remained_amt: [
          {required: false, message: '预留金', trigger: 'blur'}
        ],
      },
      is_distributor: false,
      dialogVisible: false,
      exportloading: false,
      current: '', // 当前店铺id
      isValidList: [
        { name: '全部', value: '' },
        { name: '启用', value: 'true' },
        { name: '禁用', value: 'false' }
      ],
      changeStatus: true,
      activeName: 'first',
      total_count: 0,
      distributor_self: '',
      detailDialog: false,
      pcDialogVisible: false,
      dialogTitle: '',
      select_tags_value: '',
      loading: false,
      amount: '',
      params: {
        page: 1,
        pageSize: 20,
        name: '',
        mobile: '',
        is_valid: '',
        province: '',
        city: '',
        area: '',
        dealer_hqid: '',
        distributor_hqid: '',
        distributor_vad: '',
        channel_rtm: ''
      },
      form: {
        dealer_id: null,
        mobile: '',
        address: '',
        name: '',
        is_valid: 'true',
        regions_id: [],
        regions: [],
        shop_id: 0,
        contact: '',
        is_distributor: 'true'
      },
      list: [],
      regions: district,
      codetype: 'index',
      rowdata: {},
      downDistributorVal: false,
      distributor_id: [],
      tag: {
        dialog: false,
        editItem: [],
        list: [],
        form: {
          tag_ids: [],
          dealer_id: []
        },
        currentTags: [],
        tags: []
      },
      editValidDialog: false,
      editValidData: {
        dealer_id: '',
        is_valid: ''
      },
      setMeiQiaVisible: false,
      setMeiQiaDialog: false,
      meiqia_form: {
        dealer_id: '',
        meiqia_id: '',
        meiqia_token: ''
      },
      setDistanceVisible: false,
      setDistanceDialog: false,
      distanceForm: {
        distance: ''
      },
      isOpen: false,
      operator_type: '',
      dealer_id: []
    }
  },
  components: { shopDecoration, pcDecoration, ImportDialog },
  computed: {
    ...mapGetters([
      'wheight',
      'wxapp_id',
      'template_name',
      'login_type'
    ])
  },
  watch: {
    'editValidData.is_valid': function (val, oldVal) {
      if (val && oldVal && !this.is_valid_updated) {
        this.editValidSubmit(val, oldVal)
      } else {
        this.is_valid_updated = false
      }
    }
  },
  methods: {
    handleChange(value, oldValue) {
      console.log(value);
    },
    getAdaPayCashSetting(row) {
      this.cashDialog = true;
      this.cashTitle = row.name + '的AdaPay提现配置及成功记录';
      this.adapayForm.dealer_id = row.dealer_id;
      this.cashParams.dealer_id = row.dealer_id;
      this.onGetDetail(row.dealer_id, row.name, 'cash');
      this.getCashList();
    },
    getAdapaySetting(row) {
      this.dialog = true;
      this.title = row.name + ' 的AdaPay支付配置';
      this.adapayForm.dealer_id = row.dealer_id;
      this.onGetDetail(row.dealer_id, row.name, 'setting');
    },
    onGetDetail(dealer_id, name, type) {
      getAdapayDetail({dealer_id,type}).then(res => {
        let result = res.data.data
        this.adapayFormRes = result;

        this.adapayForm.name     = name;
        this.adapayForm.mer_name = result.mer_name;

        this.adapayForm.app_id   = result.app_id;
        this.adapayForm.api_key_live = result.api_key_live;
        this.adapayForm.rsa_private_key = result.rsa_private_key;
        this.adapayForm.rsa_public_key = result.rsa_public_key;
        this.adapayForm.acct_balance = result.acct_balance;
        if (result.acct_balance) {
          if (this.adapayForm.acct_balance > this.adapayForm.remained_amt) {
            this.isDisabled = false;
          }
        }
      })
    },
    onSubmitFrom(adapayForm, isCash = 0) {
      if ( isCash ) { // 提现
        if (!this.adapayForm.app_id || !this.adapayForm.api_key_live || !this.adapayForm.rsa_private_key || !this.adapayForm.rsa_public_key) {
          this.isDisabled = true;
          this.title = '请检查' + this.adapayForm.name + '的AdaPay支付配置';
          this.$confirm(this.title, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.dialog = true;
          }).catch(() => {
          });

          return false;
        }

        if ( this.adapayForm.acct_balance) {// 确保账户里有钱
          if (this.adapayForm.acct_balance > this.adapayForm.remained_amt) {
            this.isDisabled = false;
          } else {
            this.isDisabled = true;
            this.$message.error('请确认账户金额，去除预留金后仍有足够的金额！');
            return false;
          }
        } else {
          this.$message.error('请确认账户里有足够的金额！');
          return false;
        }

      }
      this.$refs[adapayForm].validate(valid => {
        if (valid) {
          this.loading = true
          this.adapayForm.is_cash = isCash;
          var cash_amount = this.amount;
          if (!cash_amount || cash_amount === '') {
            cash_amount = adapayForm.acct_balance;
          }
          this.adapayForm.amount = cash_amount;
          saveAdapayFrom(this.adapayForm).then(response => {
            let res = response.data, type = 'error', msg = '操作失败';
            console.log(res, '========');
            if (res) {
              type = 'success';
              msg = res.message ? res.message : '操作成功';
            }
            this.$message({
              type: type,
              message: msg,
              duration: 3 * 1000
            });
            this.getCashList();
          }).catch(error => {
            // this.$message.error('操作失败，请检查AdaPay支付配置或联系管理员');
          })
        }
        this.loading = false
      })
    },
    disableDoCash() {
      this.$message.error('请等待其他提现请求处理完成后再进行提现！')
    },
    handleCashCurrentChange(page_num) {
      this.cashParams.page = page_num;
      this.getCashList();
    },
    handleCashSizeChange (pageSize) {
      this.cashParams.page = 1
      this.cashParams.pageSize = pageSize
      this.getCashList();
    },
    getCashList(){
      getAdaPayCashList(this.cashParams).then((response) => {
        if (response.data.data.list) {
          this.cashList = response.data.data.list
          this.doCash = response.data.data.doCash
          this.cash_total_count = response.data.data.total_count
        }
      });
    },

    exportData () {
      this.exportloading = true
      getDealerList({...this.params,is_export:true, dealer_id: this.dealer_id}).then(res => {
        this.exportloading = false
        if (res.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          return
        } else if (res.data.data.url) {
          this.downloadUrl = res.data.data.url
          this.downloadName = res.data.data.filename
          this.downloadView = true
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return
        }
      }).catch(error => {
        this.exportloading = false
      })
    },
    linkTemplates (distributor) {
      const { distributor_id, address, name } = distributor
      this.$store.dispatch('setTemplateName', 'yykweishop')
      this.$router.push({
        path: this.matchHidePage('template'),
        query: { distributor_id, address, name }
      })
    },
    async downQrCode (way, row) {
      let isNomo = row.channel_rtm.toUpperCase() === 'MONO'
      let params = {
        wxaAppId: this.wxapp_id,
        way,
        source_type: 'dealer_hqid',
        dealer_hqid: isNomo ? row.distributor_hqid : row.dealer_hqid
      }
      const res = await getCodeUnlimit(params)
      const elemt = document.createElement('a')
      elemt.href = res.data.data.base64Image
      elemt.download = `${row.name}_${row.dealer_hqid}_平台首页.png`
      elemt.click()
    },
    dialogShow (id, type) {
      this.current = id
      type === 'pc' ? (this.pcDialogVisible = true) : (this.dialogVisible = true)
    },
    closeDialog () {
      this.dialogVisible = false
      this.pcDialogVisible = false
    },
    isValidSelectHandle () {
      this.params.page = 1
      this.getList()
    },
    numberSearch (e) {
      this.params.page = 1
      this.getList()
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getList()
    },

    handleDistance () {
      this.distanceForm.distance = ''
      // this.resetInfo();
      // this.operate = "create";
      // this.dialogVisible = true;
    },
    addDistributorSelf () {
      this.$router.push({ path: this.matchHidePage('editor') })
    },
    editDistributorSelf () {
      this.$router.push({ path: this.matchHidePage('editor'), query: { distributor_type: 'distributor_self', distributor_id: this.distributor_self } })
    },
    getList () {
      this.loading = true
      getDealerList(this.params).then((response) => {
        if (response.data.data.list) {
          this.list = response.data.data.list
          this.total_count = response.data.data.total_count
          this.distributor_self = response.data.data.distributor_self
        }
        this.loading = false
      })
    },
    downDistributorCode (row, template_name) {
      let params = { distributor_id: row.distributor_id, codetype: this.codetype }
      if (template_name) {
        params.template_name = template_name
      }
      getWxaDristributorCodeStream(params).then((response) => {
        var a = document.createElement('a')
        var temp = '微商城'
        a.href = response.data.data.base64Image
        if (response.data.data.tempname) {
          var temp = response.data.data.tempname
        }
        a.download = temp + row.name + '.png'
        a.click()
      })
    },
    RegionChangeSearch (value) {
      var vals = getCascaderObj(value, this.regions)
      if (vals.length == 1) {
        this.params.province = vals[0].label
        this.params.city = ''
        this.params.area = ''
      } else if (vals.length == 2) {
        this.params.province = vals[0].label
        this.params.city = vals[1].label
        this.params.area = ''
      } else if (vals.length == 3) {
        this.params.province = vals[0].label
        this.params.city = vals[1].label
        this.params.area = vals[2].label
      }
      this.params.page = 1
      this.getList()
    },
    TagChangeAction (value) {
      this.params.tag_id = value
      this.params.page = 1
      this.getList()
    },
    downDistributor (row, codetype) {
      this.codetype = codetype
      this.rowdata = row
      this.downDistributorVal = true
    },
    defaultSwitchChange (row) {
      var params = {
        distributor_id: row.distributor_id
      }
      setDefaultDistributor(params).then((response) => {
        if (response.data.data.status) {
          for (var i = this.list.length - 1; i >= 0; i--) {
            if (this.list[i].distributor_id != row.distributor_id) {
              this.list[i].is_default = false
            }
          }
        }
      })
    },
    switchChangeAuditGoods (index, row) {
      var params = {
        distributor_id: row.distributor_id,
        is_audit_goods: row.is_audit_goods
      }
      saveDistributor(params).then((response) => {
        this.detailDialog = false
        this.getList()
        this.$message({
          type: 'success',
          message: '保存成功'
        })
      })
    },
    switchChangeAutoSyncGoods (index, row) {
      var params = {
        distributor_id: row.distributor_id,
        auto_sync_goods: row.auto_sync_goods
      }
      saveDistributor(params).then((response) => {
        this.detailDialog = false
        this.getList()
        this.$message({
          type: 'success',
          message: '保存成功'
        })
      })
    },
    switchChangeOpen (index, row) {
      var params = {
        distributor_id: row.distributor_id,
        is_open: row.is_open
      }
      saveOpen(params).then((response) => {
        this.detailDialog = false
        this.getList()
        this.$message({
          type: 'success',
          message: '保存成功'
        })
      })
    },
    switchChange (index, row) {
      var params = {
        distributor_id: row.distributor_id,
        is_ziti: row.is_ziti,
        is_delivery: row.is_delivery
      }
      saveDistributor(params).then((response) => {
        this.detailDialog = false
        this.getList()
        this.$message({
          type: 'success',
          message: '保存成功'
        })
      })
    },
    handleSelectionChange (val) {
      let distributor_id = []
      for (let i in val) {
        distributor_id.push(val[i].distributor_id)
      }
      this.distributor_id = distributor_id
      this.dealer_id = val.map(item => item.dealer_id)
    },
    getAllTagList () {
      let params = {
        page: 1,
        pageSize: 500
      }
      getTagList(params).then((response) => {
        this.tag.list = response.data.data.list
      })
    },
    handleCancelLabelsDialog () {
      this.editValidDialog = false
      this.tag.dialog = false
    },
    tagUpdate (row) {
      this.tag.editItem = [row.itemName]
      this.tag.currentTags = row.tagList || []
      this.tag.form.distributor_id = row.distributor_id
      this.showTags()
    },
    addDistributorTag () {
      this.tag.currentTags = []
      if (this.distributor_id.length) {
        this.showTags()
        this.tag.form.distributor_id = this.distributor_id
      } else {
        this.$message({
          type: 'error',
          message: '请选择至少一个店铺!'
        })
      }
    },
    showTags () {
      this.tag.tags = [...this.tag.list]
      this.tag.tags.forEach((item, index) => {
        let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
        if (isInArr != -1) this.tag.tags.splice(index, 1)
      })
      this.tag.dialog = true
    },
    tagRemove (index) {
      this.tag.tags.unshift(this.tag.currentTags[index])
      this.tag.currentTags.splice(index, 1)
    },
    tagAdd (item, index) {
      let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
      if (isInArr == -1) {
        this.tag.currentTags.push(item)
        this.tag.tags.splice(index, 1)
      }
    },
    submitItemTag () {
      this.tag.form.tag_ids = []
      this.tag.currentTags.forEach((item) => {
        this.tag.form.tag_ids.push(item.tag_id)
      })
      if (this.tag.form.tag_ids.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择店铺标签'
        })
        return false
      }
      this.tag.dialog = false
      distributorRelTags(this.tag.form).then((res) => {
        if (res.data.data.status) {
          this.$message({
            type: 'success',
            message: '打标签完成'
          })
        }
      })
    },
    tagSearchUserChange () {
      this.currentPage = 1
      this.getParams()
      this.getItemsList(this.params)
      this.loading = false
    },
    editValid (row) {
      this.editValidDialog = true
      this.editValidData = row
      this.is_valid_updated = true
    },
    editValidSubmit (val, oldVal) {
      let msg = ''
      if (val === 'true') {
        msg = '确定开启经销商？'
      } else if (val === 'false') {
        msg = '确定禁用经销商？'
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            dealer_id: this.editValidData.dealer_id,
            is_valid: this.editValidData.is_valid
          }
          openDealer(params).then((response) => {
            this.detailDialog = false
            this.getList()
            this.$message({
              type: 'success',
              message: '已修改'
            })
          })
        })
        .catch(() => {
          this.editValidData.is_valid = oldVal
          this.is_valid_updated = true
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    showSettingMeiQia (distributor_id) { // 设置美洽参数
      this.setMeiQiaVisible = true
      let that = this
      getDistributorMeiQia(distributor_id).then(response => {
        that.meiqia_form.meiqia_id = response.data.data.meiqia_id
        that.meiqia_form.meiqia_token = response.data.data.meiqia_token
      })
      that.meiqia_form.distributor_id = distributor_id
      console.log(this.meiqia_form)
    },
    handleMeiQiaCancel () { // 美洽设置窗口关闭
      this.setMeiQiaVisible = false
      this.meiqia_form.distributor_id = ''
      this.meiqia_form.meiqia_id = ''
      this.meiqia_form.meiqia_token = ''
    },
    handleSubmitMeiQia () { // 提交美洽配置
      let params = {
        meiqia_id: this.meiqia_form.meiqia_id,
        meiqia_token: this.meiqia_form.meiqia_token
      }
      setDistributorMeiQia(this.meiqia_form.distributor_id, params).then(response => {
        this.$message({
          type: 'success',
          message: '已提交'
        })
        this.handleMeiQiaCancel()
      })
    },
    showSettingDistance () { // 设置距离参数
      this.setDistanceVisible = true
      let that = this
      getDistance().then(response => {
        that.distanceForm.distance = response.data.data.distance
      })
      console.log(this.distanceForm)
    },
    handleDistanceCancel () { // 距离设置窗口关闭
      this.setDistanceVisible = false
      this.distanceForm.distance = ''
    },
    handleSubmitDistance () { // 提交距离配置
      let params = {
        distance: this.distanceForm.distance
      }
      setDistance(params).then(response => {
        this.$message({
          type: 'success',
          message: '提交成功'
        })
        this.handleDistanceCancel()
      })
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getList()
    },
    onCopy () {
      this.$notify.success({
        message: '复制成功',
        showClose: true
      })
    },
    /** 分期配置 */
    handleStagingConfig(row) {
      const { dealer_id, open_fq_pay_hua_bei, open_fq_pay_credit_card, fq_pay_min_price } = row
      useDialog(null, {
        title: `分期配置`,
        width: '600px',
        detailData: {
          dealer_id,
          open_fq_pay_hua_bei,
          open_fq_pay_credit_card,
          fq_pay_min_price
        },
        component: () => import('./staging-config.vue'),
        actions: [
          { label: '取消', key: 'close', size: 'default' },
          { label: '确定', key: 'save', type: 'primary', size: 'default' }
        ]
      }).then(arg => {
        if(arg) this.getList()
      })
    }
  },

  mounted () {
    if (store.getters.login_type === 'distributor') {
      this.is_distributor = true
    }
    this.getList()
    this.getAllTagList()
    getSetting().then(res => {
      let data = res.data.data
      this.isOpen = data.is_open == 'true'
    })
    this.operator_type = store.getters.login_type
  },
  beforeRouteUpdate (to, from, next) {
    next()
    if (to.path.indexOf('_editor') === -1 && to.path.indexOf('_detail') === -1) {
      this.getList()
      this.getAllTagList()
    }
  },
}
</script>
