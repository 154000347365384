<template>
  <div class="container">

    <el-form :inline="true" :model="pagination_info" ref="pagination_info" class="demo-form-inline">
      <el-form-item label="标签名" prop="tag_name">
        <el-input v-model="pagination_info.tag_name" placeholder="请输入标签名"></el-input>
      </el-form-item>
      <el-form-item label="评星" prop="relation_star">
        <el-select v-model="pagination_info.relation_star" placeholder="请选择">
          <el-option v-for="(item, index) in relationStar" :key="'num' + index" :label="item.title"
            :value="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务类型" prop="order_receipt_type">
        <el-select v-model="pagination_info.order_receipt_type" placeholder="请选择">
          <el-option v-for="(item, index) in orderReceiptType" :key="'num' + index" :label="item.title"
            :value="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="evaluationTagList">查询</el-button>
        <el-button @click="resetForm('pagination_info')">重置</el-button>
        <el-button type="primary" @click="onDialog" plain>添加标签</el-button>
      </el-form-item>
    </el-form>

    <el-card>
      <el-table :data="tableData" style="width: 100%" v-loading="tableLoading">
        <el-table-column prop="tag_name" label="标签名">
          <!-- <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.tag_name" :key="'nm' + index">{{ item }}</span>
        </template> -->
        </el-table-column>
        <el-table-column prop="name" label="关联评价级别">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.relation_star" :key="'star' + index">
<!--              {{ index > 0 ? '、' : ''}}
              {{ filter(item) }}-->
              <el-rate
                v-model="scope.row.relation_star[index]"
                disabled
                show-text
                :texts="rateText"
                :colors="['#479CE7', '#479CE7', '#479CE7']"
                text-color="#000">
              </el-rate>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="关联服务类型" width="180">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.order_receipt_type" :key="'type' + index">
              {{ index > 0 ? '、' : ''}}
              {{ item == 'ziti' ? '到店即提' : '同城速递' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="onedit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="onDeleteTag(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]" :page-size="pagination_info.page_size" layout="total, sizes, prev, pager, next, jumper"
        :total="pagination_info.total">
      </el-pagination>
    </div>

    <el-dialog :title="operation_type == 1 ? '创建评价标签' : '编辑评价标签'" :visible.sync="dialogFormVisible">
      <el-form :model="dialog_form" ref="dialog_form" :rules="rules" label-width="80px">

        <el-form-item label="服务类型" prop="order_receipt_type">
          <el-checkbox-group v-model="dialog_form.order_receipt_type">
            <el-checkbox v-for="(item, index) in orderReceiptType" :key="'receipt' + index"
              :label="item.label" name="order_receipt_type">{{ item.title }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="评价级别" prop="relation_star">
          <el-checkbox-group v-model="dialog_form.relation_star">
            <el-checkbox v-for="(item, index) in relationStar" :key="'receipt' + index" :label="item.label"
              name="relation_star">{{ item.title }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item :label="tagIndex == 0 ? '标签' : ''" :prop="'tag_name.' + tagIndex"
          v-for="(tag, tagIndex) in dialog_form.tag_name" :key="'tag' + tagIndex" :rules="{
            required: true, message: '请填写标签内容', trigger: 'blur'
          }">

          <div class="tag_name">
            <el-input v-model="dialog_form.tag_name[tagIndex]" placeholder="请输入内容"
              :name="'tag_name.' + tagIndex">
            </el-input>
            <i v-if="tagIndex != 0" class="el-icon-remove" @click="onDelete(tagIndex)"></i>
          </div>

        </el-form-item>

        <el-form-item v-if="operation_type === 1" label="">
          <el-row>
            <el-col :span="16">
              <el-button type="primary" @click="onAddTag('2')">添加标签</el-button>
            </el-col>
          </el-row>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddSubmit">{{ operation_type == 1 ? '添加' : '保存'
        }}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { postEvaluationTag, getEvaluationTagList, deleteEvaluationTag, putEvaluationTag } from '@/api/marketing'


export default {
  data() {
    return {
      tableLoading: false,
      tableData: [],
      dialogFormVisible: false,
      radio: '',
      orderReceiptType: [
        { title: '到店即提', label: 'ziti' },
        { title: '同城速递', label: 'logistics' }
      ],
      relationStar: [
        { title: '不太满意（一星）', label: 1 },
        { title: '有待改进（二星）', label: 2 },
        { title: '还不错（三星）', label: 3 },
        { title: '满意（四星）', label: 4 },
        { title: '非常满意（五星）', label: 5 }
      ],
      rateText: [
        '不太满意（一星）',
        '有待改进（二星）',
        '还不错（三星）',
        '满意（四星）',
        '非常满意（五星）',
      ],
      dialog_form: {
        order_receipt_type: [],
        relation_star: [],
        tag_name: []
      },
      operation_type: 1,
      rules: {
        order_receipt_type: [
          { required: true, message: '请选择至少一个选项', trigger: 'change' },
        ],
        relation_star: [
          { required: true, message: '请选择至少一个选项', trigger: 'change' }
        ]
      },
      pagination_info: {
        page: 1,
        page_size: 10,
        total: 0,
        relation_star: '',
        tag_name: '',
        order_receipt_type: ''
      }
    }
  },

  methods: {
    onDialog() {
      this.dialogFormVisible = true
      this.operation_type = 1
      setTimeout(() => {
        this.$refs['dialog_form'].resetFields();
        this.onAddTag('1')
      },500)
    },
    resetForm(i) {
      this.$refs[i].resetFields();
      this.evaluationTagList()
    },
    onAddTag(v) {
      var arr = v == 1 ? [] : [...this.dialog_form.tag_name]
      arr.push('')
      this.dialog_form.tag_name = arr
    },
    onDelete(i) {
      var arr = [...this.dialog_form.tag_name]
      arr.splice(i, 1)
      this.dialog_form.tag_name = arr
    },
    onedit(i) {
      this.operation_type = 2
      let obg = { ...i }
      obg.tag_name = [obg.tag_name]
      this.dialog_form = obg
      this.dialogFormVisible = true
    },
    onAddSubmit() {
      this.$refs['dialog_form'].validate((valid) => {
        if (valid) {
          if (this.operation_type == 1) {
            // 添加标签
            var params = {
              order_receipt_type: this.dialog_form.order_receipt_type,
              relation_star: this.dialog_form.relation_star,
              tag_name: this.dialog_form.tag_name
            }
            postEvaluationTag(params).then((res) => {
              if (res.data.data.success) {
                this.dialogFormVisible = false
                this.$message({
                  message: '添加成功',
                  type: 'success'
                });
                this.handleCurrentChange(this.pagination_info.page)
              }

            })
          } else {
            // 修改标签
            var params = {
              order_receipt_type: this.dialog_form.order_receipt_type,
              relation_star: this.dialog_form.relation_star,
              tag_name: this.dialog_form.tag_name,
              id: this.dialog_form.id
            }
            putEvaluationTag(params).then((res) => {
              if (res.data.data.success) {
                this.dialogFormVisible = false
                this.$message({
                  message: '保存成功',
                  type: 'success'
                });
                this.handleCurrentChange(this.pagination_info.page)
              }
            })

          }
        }
      });
    },
    evaluationTagList() {
      this.tableLoading = true
      getEvaluationTagList(this.pagination_info).then((res) => {
        var data = res.data.data
        this.tableData = data.list
        this.pagination_info.total = data.total_count
        this.tableLoading = false
      })
    },
    handleCurrentChange(val) {
      this.pagination_info.page = val
      this.evaluationTagList()
    },
    handleSizeChange(v) {
      this.pagination_info.page_size = v
      this.evaluationTagList()

    },
    onDeleteTag(i) {
      deleteEvaluationTag({ id: i.id }).then((res) => {
        if (res.data.data.success) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.handleCurrentChange(this.pagination_info.page)
        }
      })
    },
    filter(i) {
      let text = ''
      switch (i) {
        case '1':
          text = '不太满意'
          break;
        case '2':
          text = '有待改进'
          break;
        case '3':
          text = '还不错'
          break;
        case '4':
          text = '满意'
          break;
        case '5':
          text = '非常满意'
          break;
        default:
      }
      return text
    }
  },
  mounted() {
    this.evaluationTagList()
  }
}

</script>
<style scoped lang="scss">
.tag_name {
  display: flex;
  align-items: center;

  .el-input {
    width: 200px;
  }

  .el-icon-remove {
    font-size: 24px;
    color: #53a3e8;
    margin-left: 20px;
  }
}

.pagination {
  margin-top: 20px;
  text-align: right
}
</style>
