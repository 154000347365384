<template>
  <div>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="账号管理" name="base" class="el-col-6"  v-loading="loading">
        <div style="margin-top: 15px;" class="el-col-24">
          <el-input placeholder="请输入Ecid或者邮箱" v-model="form.search" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="getBindInfo"></el-button>
          </el-input>
        </div>
        <div style="margin-top: 30px;" class="el-col-24" v-if="this.show">
          <el-card class="box-card" style="padding: 30px;" >
            <div slot="header" class="clearfix">
              <span>信息及操作</span>
            </div>
            <div class="text item">
              <div class="text item">
                Ecid：{{ this.form.member.user.ecid }}
              </div>
              <div class="text item">
                邮箱：{{ this.form.member.user.email }}
              </div>
              <div class="text item">
                所属企业：{{ this.form.member.user.enterprise.enterpriseName }}
              </div>
              <div class="text item">
                状态：
                <el-button type="success" size="mini" plain v-if="this.form.member.user.bindstatus===1">正常状态</el-button>
                <el-button type="info" size="mini" plain v-if="this.form.member.user.bindstatus!==1">解绑状态</el-button>
              </div>
              <div class="text item">
                注册时间：{{ this.form.member.user.created | datetime('yyyy-MM-dd hh:mm:ss')}}
              </div>
              <div class="text item" style="margin-top: 30px">
                <el-button type="danger" v-if="this.form.member.user.bindstatus===1"  size="small" @click="updateStatus(0)">解绑</el-button>
                <el-button type="warning" v-if="this.form.member.user.bindstatus!==1"  size="small"  @click="updateStatus(1)">绑定</el-button>
              </div>
            </div>
          </el-card>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import imgPicker from '@/components/imageselect'
import {getBindInfo, setBindStatus} from '../../../api/common'

export default {
  components: {
    imgPicker
  },
  data() {
    return {
      loading:false,
      show:false,
      activeName: 'base',
      form: {
        search: '',
        status: '',
        member: {}
      },
    }
  },
  methods: {
    getBindInfo() {
      this.loading =true
      getBindInfo(this.form).then(response => {
        this.loading =false
        this.form.member = response.data.data
        if (response.data.data.status === 1) {
          this.show = true
          return
        }
        this.show = false
        this.form.member = {}
        this.$message({
          type: 'error',
          message: response.data.data.errormsg
        })
      }).catch(function (error) {
        this.show = false
        this.loading = false
        this.form.member = {}
        this.$message({
          type: 'error',
          message: '信息查询失败'
        })
      })
    },
    updateStatus(status) {
      this.form.status = status
      this.form.ecid = this.form.member.user.ecid
      setBindStatus(this.form).then(response => {
        this.$message({
          type: 'success',
          message: '操作成功'
        })
        this.form.search = ''
        this.form.status = ''
        this.show = false
        this.form.member = {}
        // this.getBindInfo()
      })
    }

  },
  mounted() {
  }
}
</script>
<style scoped type="text/css" lang="scss">
.el-tab-pane {
  min-height: 700px;
}

.message-content {
  button {
    margin-left: 20px;
  }
}

.message-count {
  margin: 0 5px 0 20px;
  font-size: 24px;
  color: #ff5000;
}

.message-template {
  .item {
    display: inline-block;
    width: 210px;
    margin: 0 10px;
    border: 1px solid #dfdfdf;

    &-title {
      padding: 10px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      cursor: pointer;

      i {
        float: right;
        margin-top: 4px;
      }
    }

    &-content {
      height: 140px;
      padding: 20px 10px;
      color: #333;
      font-size: 12px;
    }

    &-footer {
      // border-top: 1px solid #dfdfdf;
      padding: 10px 0;
      text-align: center;

      button {
        width: 50%;

        &:hover {
          border: 1px solid #c4c4c4;
          color: #1f2d3d;
        }
      }
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    }

    &.not-open-sms {
      .item-title {
        background-color: #FF4949;
      }
    }

    &.succ-open-sms {
      .item-title {
        background-color: #13CE66;
      }
    }
  }
}

.message-autograph {
  .el-input {
    width: 240px;
    margin-right: 10px;
  }

  span {
    margin: 0 10px;
  }

  button {
    width: 80px;
  }
}

.message-prompt {
  margin-top: 30px;
  padding-left: 20px;

  .prompt {
    &-title {
      margin-bottom: 10px;
      font-size: 18px;
      color: #333;

      span {
        border-left: 3px solid #20A0FF;
        padding-left: 10px;
      }
    }

    &-content {
      padding-left: 8px;
      line-height: 1.6;
      color: #666;

      .item-title {
        margin-bottom: 5px;
      }

      .item-content {
        padding-left: 14px;
        margin-bottom: 10px;
      }
    }
  }
}

.mb_15 {
  margin-bottom: 15px;
}

.span-tip {
  color: #8c939d;
  font-size: small;
}

.pics-box {
  overflow: hidden;

  .goodspic-wrap {
    float: left;
    margin-right: 5px;
    overflow: hidden;

    .goodspic {
      z-index: 1;
      position: relative;
      float: left;
      width: 120px;
      height: 120px;
      margin: 0 5px 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }

      .goodspic-mask {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .2);
        cursor: pointer;

        &.on {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .iconfont {
          margin: 0 8px;
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }

  .upload-box {
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;

    .iconfont {
      font-size: 30px;
      color: #ccc;
    }
  }
}
</style>
