<template>
  <div>
    <SpFinder
      ref="finder"
      :data="tableData"
      :setting="setting"
      no-selection
      :show-pager="false"
      style="max-width: 900px;"
      class="clear-finder-style"
    />
    <el-button style="margin-top: 12px;" type="primary" @click="handleAddCoupon">设置赠券</el-button>
  </div>
</template>
<script>
import { tableSetting } from './config'

export default {
  components: {},
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  model: {
    prop: 'value',
    event: 'update',
  },
  data () {
    return {
      tableData: []
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (val) {
        this.tableData = val
      }
    },
    tableData: {
      deep: true,
      immediate: true,
      handler (val) {
        this.$emit('update', val)
      }
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {},
  methods: {
    async handleAddCoupon() {
      const { data } = await this.$picker.coupon({
        list: this.tableData,
        params: {
          receive: 'condition',
          not_exp: 1
        }
      })
      this.tableData = [...data]
    },
    handleDelete(row) {
      this.tableData = this.tableData.filter(item => item.card_id !== row[0].card_id)
    }
  }
}
</script>

<style scoped>

</style>
