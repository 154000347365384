<template>
  <div>
    <div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-button type="primary" icon="plus" @click="dialogVisible = true"
            >新增红包封面</el-button
          >
        </el-col>
      </el-row>
      <el-tabs v-model="activeName" type="border-card">
        <el-table :data="list" style="width: 100%">
          <el-table-column prop="title" label="标题"> </el-table-column>
          <el-table-column prop="ctoken" label="ctoken"> </el-table-column>
          <!-- <el-table-column prop="created" label="创建时间"> </el-table-column> -->
          <el-table-column prop="updated" label="更新时间"> </el-table-column>
        </el-table>
      </el-tabs>
      <el-dialog
        title="添加"
        width="900px"
        @close="resetForm(form)"
        :visible.sync="dialogVisible"
      >
        <el-form class="box-set" label-width="120px" ref="form" :model="form">
          <el-form-item
            label="标题"
            :rules="[{ required: true, message: '不能为空' }]"
          >
            <el-col :span="20">
              <el-input
                :maxlength="30"
                placeholder="必填"
                v-model="form.title"
              ></el-input>
            </el-col>
          </el-form-item>
          <el-form-item
            label="ctoken"
            :rules="{
              required: true,
              message: '活动名称必填',
              trigger: 'blur',
            }"
          >
            <el-col :span="20">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="必填"
                v-model="form.ctoken"
              ></el-input>
            </el-col>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetForm(form)">取 消</el-button>
          <el-button type="primary" @click="submitForm(form)">确 定</el-button>
        </span>
      </el-dialog>
      <div class="content-padded content-center" v-if="total > params.pageSize">
        <el-pagination
          :page-size="params.pageSize"
          background
          layout="prev, pager, next"
          :total="total"
          :current-page.sync="params.page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data () {
    return {
      loading: false,
      form: {
        title: '',
        ctoken: '',
        created: '',
        updated: ''

      },
      params: {
        pageSize: 10,
        page_size: 10,
        page: 1
      },
      total: 1,
      list: [],
      dialogVisible: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    pageChange (val) {
      console.log('val', val)
      this.params.page = val
      this.getList(this.params)
    },
    timestampToTime (timestamp) {
      // return timestamp
      var date = new Date(timestamp * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var YY = date.getFullYear() + '-'
      var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return YY + MM + DD + ' ' + hh + mm + ss
    },
    submitForm (form) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          api.marketing.addRedCover(form).then(res => {
            this.loading = false
            this.resetForm(form)
            this.getList()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.params.page = 1
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (form) {
      this.$refs.form.resetFields()
      this.dialogVisible = false
      this.form.title = ''
      this.form.ctoken = ''
    },
    getList (params) {
      api.marketing.getRedpacketList(params).then(res => {
        let list = []
        res.data.data.list.map(item => {
          list.push({
            id: item.id,
            title: item.title,
            ctoken: item.ctoken,
            created: this.timestampToTime(item.created),
            updated: this.timestampToTime(item.updated)
          })
        })
        this.list = list
        this.total = res.data.data.total_count
        this.loading = false
      })
    }
  }
}

</script>

<style scoped>
</style>
