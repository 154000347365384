<template>
  <div>
    <div class="zyk_page_register_Info" v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_contactShop') === -1 ">

      <!-- 搜索 -->
      <el-card class="box-card" shadow="never">
        <el-form label-width="80px">
          <div slot="header" class="clearfix">
            <span>搜索</span>
          </div>
          <el-row :gutter="20" v-if='filter_options'>
            <el-col :span="7">
              <el-form-item :label='filter_options.audit_state.name' >
                <el-select clearable  v-model="active_state" placeholder="请选择" style="width:100%"
                @change="audit_stateChangeHandle"
                @clear='audit_stateClearHandle'>
                  <el-option
                    v-for="(value,key) in filter_options.audit_state.options"
                    :key="key"
                    :label="value"
                    :value="key">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item :label='filter_options.member_type.name'>
                <el-select clearable  v-model="activeMember_type" placeholder="请选择" style="width:100%"
                 @change="member_typeChangeHandle"
                  @clear='member_typeClearHandle'>
                  <el-option
                    v-for="(value,key) in filter_options.member_type.options"
                    :key="key"
                    :label="value"
                    :value="key">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10" class="row_">

              <el-form-item label-width='0'>
                <el-select clearable  v-model="search_ValueType" placeholder="请选择" style="width:100%"
                 @change="searchChangeHandle"

                  >
                  <el-option
                    v-for="item in search_options"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width='0'>
                <el-input v-model="search_Value" placeholder="请输入搜索内容" >
                    <!-- <el-button style="padding-right:10px" slot="suffix" type="text" >搜索</el-button> -->
                    <el-button slot="append" @click="searchhandle">搜索</el-button>
                </el-input>
              </el-form-item>


            </el-col>

          </el-row>
        </el-form>
      </el-card>

      <!-- 经销商列表 -->
      <el-card class="box-card" shadow="never">
        <div slot="header" class="clearfix">
          <span>经销商列表</span>
          <el-button style="float: right; padding: 6px 10px" type="primary" @click="go">新增</el-button>
        </div>
        <el-table
          v-loading="loading"
          :data="list"
          style="width: 100%">
          <el-table-column
            prop="id"
            label="ID"
            >
          </el-table-column>
          <el-table-column
            prop="user_name"
            label="企业名称/姓名"
            >
          </el-table-column>
          <el-table-column
            prop="cert_id"
            label="营业执照号/身份证号"
            >
          </el-table-column>
          <el-table-column
            label="账户类型"
            >
             <template slot-scope="scope">
                <!-- 账户类型 corp -->
                <template v-if="scope.row.member_type=='corp'">
                  <span>企业</span>
                </template>

                <template v-else>
                  <span>个人</span>
                </template>
             </template>
          </el-table-column>
          <el-table-column
            label="审核状态"
            >
            <template slot-scope="scope">
              <!-- 0 待提交 -->
              <template v-if="scope.row.audit_state=='0'">
                <span style="color:#409eff">待提交</span>
              </template>
              <!-- 1 待审核 -->
              <template v-else-if="scope.row.audit_state=='1'">
                <span>待审核</span>
              </template>
              <!-- 2 审核通过 -->
              <template v-else-if="scope.row.audit_state=='2'">
                <span>审核通过</span>
              </template>
                <!-- 9 审核失败 -->
              <template v-else>
                <span style="color:red">审核失败</span>
              </template>
            </template>

          </el-table-column>
          <el-table-column
            label="创建时间"
            >
              <template slot-scope="scope">
                {{ timestampToTime(scope.row.create_time) }}
              </template>
          </el-table-column>
          <el-table-column
            prop="audit_desc"
            label="说明"
            >
          </el-table-column>
          <el-table-column
            label="操作"
            >
            <template slot-scope="scope">
                <el-button
                  @click.prevent="checkRow(scope.$index, scope.row)"
                  type="text"
                  size="small">
                  查看
                </el-button>
                <el-button
                  v-if="scope.row.audit_state==='9' || scope.row.audit_state=='0'"
                  @click.prevent="editRow(scope.$index, scope.row)"
                  type="text"
                  size="small">
                  编辑
                </el-button>
                <el-button
                  v-if="scope.row.audit_state==='9' || scope.row.audit_state=='0'"
                  @click.prevent="submitRow(scope.$index, scope.row)"
                  type="text"
                  size="small">
                  提交
                </el-button>
                <el-button
                  v-if="scope.row.audit_state==='2'"
                  @click.prevent="contact_shop(scope.$index, scope.row)"
                  type="text"
                  size="small">
                  关联店铺
                </el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-card>
      <div class="pagination" style="text-align: right;">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :current-page="page_info.page"
          :page-sizes="[10,20,50]"
          :page-size="page_info.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total_count">
        </el-pagination>
      </div>


    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { getList,submitReviewPerson } from '@/api/dealerReInfo';
export default {
  data() {
    return {
      // 多选字段
      filter_options:'',
      // 选中审核状态
      active_state:'',
      // 选中账户类型
      activeMember_type:'',
      search_options:['营业执照号/身份证','企业名称/姓名'],
      search_ValueType:'',
      search_Value:'',
      cert_id:'',
      user_name:'',
      // 经销商列表
      list:[],
      total_count:0,
      page_info:{
        page:1,
        page_size:20
      },
      loading:false

    };
  },
  mounted(){
    this.getConfig()
  },
  methods:{
    go(){
      this.$router.push({path: this.matchHidePage('editor')})
    },

    async getConfig(){
      this.loading = true;
      const obj = {
        ...this.page_info,
        audit_state:this.active_state,
        member_type:this.activeMember_type,
        user_name:this.user_name,
        cert_id:this.cert_id
      }
      const result = await getList(obj);
      this.loading = false
      this.filter_options = result.data.data.filter_options;
      this.list = result.data.data.list
      this.total_count = result.data.data.total_count
      console.log(result);
    },

    // 查看
    async checkRow(index,row){
      this.$router.push({
        path: this.matchHidePage('editor'),
        query:{
          id:row.id,
          member_type:row.member_type,
          type:'check'
        }
      })
      console.log('查看');
    },
    // 编辑
    editRow(index,row){
      this.$router.push({
        path: this.matchHidePage('editor'),
        query:{
          id:row.id,
          member_type:row.member_type,
          type:'edit'
        }
      })
      console.log('编辑');
    },
    // 提交
    async submitRow(index,row){
      console.log(row);
      // if (row.member_type=='corp') {
      //   const result = await submitReviewPerson({id:row.id});
      // }else{
      //   const result = await submitReviewPerson({id:row.id});
      // }
      const result = await submitReviewPerson({id:row.id});

      if (result.status==200) {
        this.$message.success('提交成功')
      }else{
         this.$message.error('提交失败')
      }
      this.getConfig();
      console.log(result);

      console.log('提交');
    },
    // 关联店铺
    contact_shop(index,row){
      this.$router.push({
        path: this.matchHidePage('contactShop'),
        query:{
          id:row.id
        }
      })
      console.log('关联店铺');
    },
    handleSizeChange(val){
      this.page_info.page = 1;
      this.page_info.page_size = val;
      this.getConfig();
    },
    handleCurrentChange(val) {
      this.page_info.page = val;
      this.getConfig();
    },
    // 选择审核状态
    audit_stateChangeHandle(val){
      console.log(`选中了 ${val}`);
      this.active_state = val;
      this.getConfig();
    },
    // 清空审核状态
    audit_stateClearHandle(){
      console.log('清空');
    },
    // 选择账户类型
    member_typeChangeHandle(val){
      console.log(`选中了 ${val}`);
       this.activeMember_type = val;
       this.getConfig();
    },
    // 清空账户类型
    member_typeClearHandle(){
      console.log('清空');
    },
    // 选择搜索身份证 、 名称
    searchChangeHandle(val){
      console.log(`选中了 ${val}`);
      if (val) {
        this.search_Value = '';
        this.search_ValueType = val;
      }else{
        this.search_ValueType = '';
      this.search_Value = '';
      this.cert_id = '';
      this.user_name=''
      this.getConfig();
        // this.getConfig();
      }

    },
    searchhandle(){
      console.log(this.search_ValueType);
      if(this.search_Value){
        if (this.search_ValueType=='营业执照号/身份证') {
          this.cert_id = this.search_Value
        }else if(this.search_ValueType=='企业名称/姓名'){
          this.user_name = this.search_Value;
        }
        this.getConfig();
      }else{
        this.$message.error('请输入')
      }


    },
    // 清空选择搜索身份证 、 名称
    // searchClearHandle(){
    //   this.search_ValueType = '';
    //   this.search_Value = '';
    //   this.getConfig();
    // },
    timestampToTime(timestamp){
      // return timestamp
      var date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var YY = date.getFullYear() + '-'
      var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return YY + MM + DD + ' ' + hh + mm + ss
    },


  },
  watch:{
      '$route' (to, from) {
         this.getConfig();
      },
  },

};
</script>

<style lang="scss" scoped>
.zyk_page_register_Info {
  padding-bottom: 50px;
  .flex{
    display: flex;
    align-items: center;
    i{
      margin-left: 10px;
      font-size: 18px;

    }
  }
}
</style>

<style lang="scss">
.zyk_page_register_Info {
  label{
    font-size: 12px;
    color:#000;
  }
  .el-select .el-input {
    width: 100%;
  }
  input{
    height:40px;
    line-height:40px;
  }
  .el-row{
    margin-bottom:0px;
  }
  .row_{
    display:flex;
    align-items: center;
    .el-form-item{
      width: 30%;
      &:nth-child(2){
        width: 70%;
      }
    }
  }


}
</style>
