<template>
  <div v-loading="pageLoading" lock>
    <el-button @click="getjoincityName"  style="margin-bottom: 10px" type="primary" plain>查看参与城市</el-button>
    <div v-for="(item, i) in cityList">
      <el-checkbox-group v-model="selected">
        <el-card class="box-card" style="margin-bottom: 10px" :loading="pageLoading">
          <div slot="header" class="clearfix">
            <span style="font-size: 14px;font-weight: bolder">{{item.title}}</span>
          </div>
          <div class="text item">
            <div style="display: inline-block">
              <el-checkbox
                v-for="(value,index) in item.items"
                :key="index"
                :label="value.id">
                {{value.label}}
              </el-checkbox>
            </div>
          </div>
        </el-card>
      </el-checkbox-group>
    </div>
    <div style="text-align: center; margin: 30px 0">
      <el-button type="primary" @click="saveHotCity">保存</el-button>
    </div>
    <el-drawer
      title="参与城市列表"
      :visible.sync="showCity"
      :with-header="false">
      <br>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>参与城市列表</span>
        </div>
        <span v-for="name in joinCity"  class="text item">
          <el-tag type="success" style="margin-bottom: 10px">{{name}}</el-tag>&nbsp;&nbsp;
        </span>
      </el-card>
    </el-drawer>
  </div>

</template>
<script>
import { getCityList } from '@/api/company'
import {getJoinCity,setJoinCity} from '../../../api/common'

export default {
  data () {
    return {
      cityList: [],
      selected: [],
      pageLoading: true,
      params: [],
      joinCity:[],
      showCity: false
    }
  },
  computed: {
  },
  methods: {
    getCityList () {
      let query = {'first_charter': 1}
      getCityList(query).then(response => {
        this.cityList = response.data.data;
        if (response.data.data.length >0) {
          getJoinCity().then(res => {
            this.selected = res.data.data;

            this.pageLoading = false
          }).catch(err => {
            this.pageLoading = false
          })
        } else {
          this.pageLoading = false
        }
      }).catch(error => {
        this.pageLoading = false
      })
    },
    getjoincityName () {
      this.joinCity = []
      this.selected.forEach((it,ix) => {
        this.cityList.forEach((items, index) => {
          items.items.forEach((city, key) => {
          if (it == city.value) {
            this.joinCity.push(city.label)
          }
        })
      })
      })
      this.showCity = true
    },
    saveHotCity () {
      let params = {}
      params.cities = JSON.parse(JSON.stringify(this.selected));
      setJoinCity(params).then(response => {
        if (response.data.data.success === true) {
          this.$message({type:'success', 'message':'保存成功'});
        } else {
          this.$message({type:'error', 'message':'保存失败'});
        }
      })
    },
  },
  mounted () {
    this.getCityList();
  }
}
</script>
