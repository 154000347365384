<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="5">
        <el-input placeholder="售后单ID" @change="getList(0)" v-model="searchInput.repair_bn" clearable/>
      </el-col>
      <el-col :span="4">
        <el-cascader placeholder="请选择 （单选）" :props="props" :options="options" clearable @change="handleChangeProduct" />
        <!-- <el-select placeholder="请选择 （单选）" style="width: 100%;"></el-select> -->
      </el-col>
      <el-col :span="14">
        <el-date-picker v-model="date" value-format="timestamp" type="datetimerange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="handleChangeDate">
        </el-date-picker>
        <el-button v-if="login_type !== 'distributor'" class="btn_input" @click="onDialog('shop')">{{ shop_num }} <i
          class="el-icon-circle-plus icon"></i></el-button>
        <el-button v-if="login_type != 'distributor'" class="btn_input" @click="onDialog('sgency')">{{ agency_num }} <i
          class="el-icon-circle-plus icon"></i></el-button>
        <el-button type="primary" @click="exportList">导出报表</el-button>
      </el-col>
    </el-row>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="item in tabs" :label="item.label + (item.count ? '(' + item.count + ')' : '')" :name="item.name" :key="item.name">
        <el-table :data="tableData" v-loading="tableLoading">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="repair_bn" label="售后单ID" width="140" />
          <el-table-column prop="product_name" label="维修事项" min-width="150">
            <template slot-scope="scope">
              {{ scope.row.product_name }} /
              {{ scope.row.content_name }}
            </template>
          </el-table-column>
          <el-table-column v-if="login_type === 'distributor'" prop="reservation_person_name" label="用户信息" width="120">
            <template slot-scope="scope">
              {{ scope.row.reservation_person_name }}
              <PiiInfo pageType='6' memberType='receiver_name' optionScene="after_detail"  :associatedId='scope.row.id'/>
              <br />
              {{ scope.row.reservation_person_mobile }}
              <span v-if="scope.row.reservation_person_mobile">
                <PiiInfo pageType='6' memberType='receiver_mobile' optionScene="after_detail"  :associatedId='scope.row.id'/>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="fault_type" label="维修类型" width="60">
            <template slot-scope="scope">
              <span>{{ scope.row.fault_type == 'software' ? '软件' : scope.row.fault_type == 'hardware' ? '硬件' : '--'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="状态" width="80">
            <template slot-scope="scope">
<!--              {{ formmater(scope.row.status) }}-->
              {{scope.row.progress ? scope.row.progress.progress : ''}}
            </template>
          </el-table-column>
          <el-table-column v-if="login_type !== 'distributor'" prop="name" label="服务门店" min-width="100">
            <template slot-scope="scope">
              <div>{{scope.row.distributor_info ? scope.row.distributor_info.name : ''}}</div>
              <div>{{scope.row.distributor_info ? scope.row.distributor_info.shop_code : ''}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="预约场次" min-width="120">
            <template slot-scope="scope">
              {{ setDay(scope.row.session_time, "DD") }}日 / {{ scope.row.session_name }}
            </template></el-table-column>
          <el-table-column prop="name" label="时间" min-width="220px">
            <template slot-scope="scope">
              <div>
                <span style="padding-right:15px">预约时间</span>
                <span>{{ setDay(scope.row.created, "YYYY-MM-DD HH:mm:ss") }}</span>
              </div>
              <div>
                <span style="padding-right:15px">核销时间</span>
                <span v-if="scope.row.sign_in_time > 0">{{ setDay(scope.row.sign_in_time, "YYYY-MM-DD HH:mm:ss") }}</span>
                <span v-else>-</span>
              </div>
              <div>
                <span style="padding-right:15px">完成时间</span>
                <span v-if="scope.row.finish_time > 0">{{ setDay(scope.row.finish_time, "YYYY-MM-DD HH:mm:ss") }}</span>
                <span v-else>-</span>
              </div>
<!--              <div><span style="padding-right:15px">变更时间</span><span>{{ setDay(scope.row.updated, "YYYY-MM-DD HH:MM:ss")
              }}</span></div>-->
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" min-width="150">
            <template slot-scope="scope">
<!--              <span v-if="login_type == 'distributor'">
                <el-button size="mini" type="primary" v-if="scope.row.status == 0 && scope.row.progress.status_desc== 'waiting_sign_in'"
                           @click="handleShowDialog(scope.row, '核销预约')">核销预约</el-button>
                <el-button size="mini" type="primary" v-else-if="scope.row.status == 10"
                           @click="handleShowDialog(scope.row, '完成诊断')">完成诊断</el-button>
                <el-button size="mini" type="warning" v-else-if="scope.row.status == 20"
                           @click="handleShowDialog(scope.row, '通知取回')">通知取回</el-button>
                <el-button size="mini" @click="handleFinish(scope.row)" type="success"
                           v-else-if="scope.row.status == 30">已取回</el-button>
              </span>
              <el-popover placement="top-start" title="店员备注" width="200" trigger="hover"
                :content="scope.row.retrieve_remark" v-if="scope.row.status == 40">
                <el-button slot="reference" type="text">查看备注</el-button>
              </el-popover>-->
              <el-popover
                v-if="scope.row.repair_detail"
                placement="right"
                width="400"
                transition="el-zoom-in-bottom"
                trigger="click">
                <div v-for="item in scope.row.repair_detail">
                  <div style="line-height: 40px; font-weight: bolder">{{item.title}}：{{item.content}}</div>
                </div>
                <el-button slot="reference" type="text">详情</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="text-align: center; margin: 15px 0"
          background
          @current-change="handlePageChange"
          :current-page="searchInput.page"
          :page-size="searchInput.page_size"
          layout="total, prev, pager, next"
          :total="totalCount"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="600px">
      <el-form label-position="left" ref="form" label-width="80px" :model="form" :rules="rules">
        <el-form-item label="核销码" v-if="type == 0" prop="code">
          <el-input placeholder="请输入6位核销码" v-model="form.code" />
        </el-form-item>
        <el-form-item label="故障类型" prop="fault_type" v-if="type == 10">
          <el-radio-group v-model="form.fault_type">
            <el-radio :label="'hardware'">硬件</el-radio>
            <el-radio :label="'software'">软件</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="店员备注" v-if="type == 20">
          <el-input v-model="form.remark" placeholder="请输入内容" type="textarea" :rows="3" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAction">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <DistributorSelect :store-visible="distributorVisible" :is-valid="true" :relDataIds="distributor_info"
       :get-status="setDistributorStatus" @chooseStore="chooseDistributorAction" @closeStoreDialog="closeDialogAction"
       returnType="selectRow"></DistributorSelect>
    <DealerSelect :store-visible="DealerVisible" :is-valid="isValid" :rel-data-ids="relDealers"
                  :get-status="DealerStatus" @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogAction"
                  :isSingleSelect="isSingleSelect"></DealerSelect>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getRepairList, repairSignIn, repairDiagnostic, repairNotifyPickUp, repairFinish, getRepairProductcontent } from '../../../api/aftersales'
import moment from 'moment'
import DistributorSelect from '@/components/function/distributorSelect'
import DealerSelect from '@/components/function/dealerSelect'
import PiiInfo from '@/components/piiInfo/index'

export default {
  components: {
    DistributorSelect,
    DealerSelect,
    PiiInfo
  },
  data() {
    return {
      DealerVisible: false,
      isValid: true,
      relDealers: [],
      DealerStatus: false,
      rolesListData: [],
      distributorVisible: false,
      distributor_info: [],
      setDistributorStatus: false,
      shop_num: '归属店铺',
      agency_num: '归属经销商',
      tableLoading: false,
      date: [],
      totalCount: 0,
      tableData: [{}],
      activeName: '0',
      dialogVisible: false,
      type: '',
      title: '核销预约',
      tabs: [{
        label: '全部',
        name: "0"
      }, {
        label: '待签到',
        name: "1"
      }, {
        label: '维修中',
        name: "2"
      }, {
        label: '待取机',
        name: "3"
      }, {
        label: '已完成',
        name: "4"
      }, {
        label: '已过期',
        name: "5"
      }],
      searchInput: {
        repair_bn: '',// 维修单号
        product_id: '',// 维修产品id
        content_id: '',// 维修内容id
        start_time: '',// 开始时间
        end_time: '',// 结束时间
        list_tab: '',// 列表筛选tab，1待签到，2维修中，3待取机，4已完成，5已过期
        page: 1,
        page_size: 10,
        distributor_ids: [],
      },
      statusOptions: [
        {
          lable: '已预约',
          value: 0
        },
        {
          lable: '诊断中',
          value: 10
        },
        {
          lable: '处理中',
          value: 20
        },
        {
          lable: '待取机',
          value: 30
        },
        {
          lable: '已完成',
          value: 40
        },
        {
          lable: '已取消',
          value: 50
        }
        // 0已预约未核销，10已核销（诊断中），20已诊断（处理中），30待取机，40已完成，50已取消
      ],
      form: {
        repair_bn: '',
        code: '',
        fault_type: '',
        remark: ''
      },
      rules: {
        code: [
          { required: true, message: '请输入核销码', trigger: 'blur' },
          { min: 6, max: 6, message: '请输入6位核销码', trigger: 'blur' }
        ],
        fault_type: [
          { required: true, message: '请选择故障类型', trigger: 'change' }
        ]
      },
      options: [],
      props: {
        label: 'content_name',
        value: 'content_id',
        children: 'repair_contents',
        checkStrictly: true
      }
    }
  },
  mounted() {
    this.getList() //获取table
    this.getRepairProductcontent()
  },
  methods: {
    onDialog(type) {
      if (type === 'shop') {
        this.dialog_type = 1
        this.distributorVisible = true
      } else {
        this.dialog_type = 2
        this.DealerVisible = true
        this.DealerStatus = true
      }
    },
    DealerChooseAction(val) {
      this.relDealers = val
      let arr = []
      val.forEach(i => {
        arr.push(i.dealer_id)
      });
      this.searchInput.dealer_ids = arr.join(',')
      if (val.length > 0) {
        this.agency_num = `已选中${val.length}个经销商`
      } else {
        this.agency_num = '归属经销商'
      }
      this.closeDialogAction()
    },
    chooseDistributorAction(val) {
      this.distributor_info = val
      let arr = []
      val.forEach(i => {
        arr.push(i.distributor_id)
      });
      this.searchInput.distributor_ids = arr.join(',')
      if (val.length > 0) {
        this.shop_num = `已选择${val.length}门店`
      } else {
        this.shop_num = '归属店铺'
      }
      this.closeDialogAction()
    },
    closeDialogAction() {
      this.searchInput.page = 1
      if (this.dialog_type == 1) {
        this.distributorVisible = false
      } else {
        this.DealerStatus = false
        this.DealerVisible = false
      }
      this.getList()
    },
    handlePageChange(value) {
      this.searchInput.page = value
      this.getList()
    },
    searchList() {
      this.searchInput.page = 1
      this.getList()
    },
    getRepairProductcontent() {
      getRepairProductcontent().then(res => {
        this.options = res.data.data.map(item => {
          item['content_name'] = item.product_name;
          item['content_id'] = item.id
          return item
        })
      })
    },
    exportList() {
      this.getList(1)
    },
    getList(is_export = 0) {
      this.tableLoading = true
      let params = JSON.parse(JSON.stringify(this.searchInput))
      if (is_export) {
        params.is_export = is_export
      }
      getRepairList(params).then(res => {
        if (is_export) {
          this.$message.success('导出成功')
        } else {
          this.tableData = res.data.data.list
          this.totalCount = res.data.data.total_count
          const {waiting_sign_in,handling,waiting_pick_up} = res.data.data.tab_count;
          this.tabs.map(item=>{
            if(item.name==1){
              // item.label+=`(${waiting_sign_in})`
              item.count = waiting_sign_in
            }
            if(item.name==2){
              // item.label+=`(${handling})`
              item.count = handling
            }
            if(item.name==3){
              // item.label+=`(${waiting_pick_up})`
              item.count = waiting_pick_up
            }
            return item
          })
          console.log(this.tabs,'----');
        }
        this.tableLoading = false
      })
    },
    handleChangeProduct(e) {
      this.searchInput.product_id = e[0]
      this.searchInput.content_id = e[1]
      this.searchInput.page = 1
      this.getList()
    },
    handleChangeDate(val) {
      if (val && val.length > 0) {
        this.searchInput.start_time = val[0] / 1000
        this.searchInput.end_time = val[1] / 1000
      } else {
        this.searchInput.start_time = ''
        this.searchInput.end_time = ''
      }
      this.searchInput.page = 1
      this.getList()

    },
    handleShowDialog(row, title) {
      this.form = {
        repair_bn: row.repair_bn
      }
      this.title = title
      this.type = row.status
      this.dialogVisible = true
    },
    handleClick(e) {
      this.searchInput.page = 1
      this.searchInput.list_tab = e.name===0?'':e.name
      this.getList()
    },
    handleFinish(row) {
      repairFinish({ repair_bn: row.repair_bn }).then(res => {
        this.dialogVisible = false
        this.$message.success(`操作成功`)
        this.getList()
      })
    },
    cancelAction() {
      this.dialogVisible = false
    },
    async handleSubmit() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          if (this.type == 0) {
            await repairSignIn({ repair_bn: this.form.repair_bn, code: this.form.code })
          }
          if (this.type == 10) {
            await repairDiagnostic({ repair_bn: this.form.repair_bn, fault_type: this.form.fault_type })
          }
          if (this.type == 20) {
            await repairNotifyPickUp({ repair_bn: this.form.repair_bn, remark: this.form.remark })
          }
          this.getList()
          this.dialogVisible = false
          this.$message.success(`操作成功`)
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    setDay(day, type) {
      return day ? moment.unix(day).format(type) : '--'
    },
    // 状态：0已预约未核销，10已核销（诊断中），20已诊断（处理中），30待取机，40已完成，50已取消

    formmater(status) {
      const item = this.statusOptions.find(item => item.value == status)
      return item && item.lable
    }
  },
  computed: {
    ...mapGetters(['login_type']),
    isSingleSelect() {
      const role = this.rolesListData.find(item => item.role_id === this.form.role_id)
      return role && role.role_name !== 'VAD_Admin'
    }
  },
}
</script>
<style lang="scss" scoped></style>
