<template>
  <div>
    <div class="name_text">赠品：{{ info.name }}</div>
    <el-row class="filter-header">
      <el-col :span="24">
        <el-button class="btn_input" @click="onDialog('shop')">{{ shopNum }} <i
            class="el-icon-circle-plus icon"></i></el-button>
        <el-button class="btn_input" @click="onDialog('sgency')">{{ agencyNum }} <i
            class="el-icon-circle-plus icon"></i></el-button>
        <div class="inventory">
          <div>可用库存 </div>
          <div class="interval">
            <el-input type="number" @blur="onBlurinterval('min')" :max="listParams.max_stock"
              v-model="listParams.min_stock" placeholder="请输入内容"></el-input>
            <div>~</div>
            <el-input type="number" @blur="onBlurinterval('max')" :min="listParams.min_stock"
              v-model="listParams.max_stock" placeholder="请输入内容"></el-input>
          </div>
        </div>
        <el-button size="small" type="primary" @click="onQuery">查询</el-button>
        <ImportDialog buttonSize="small" buttonName="导入库存" fileName="导入库存" fileType="lottery_gift_shop_stock" :relId="listParams.gid"></ImportDialog>
      </el-col>
    </el-row>

    <el-table :data="ItemsList" row-key="goods_id">
      <el-table-column prop="name" label="门店" width="200"></el-table-column>
      <el-table-column prop="freeze" label="锁定库存" width="150"></el-table-column>
      <el-table-column prop="stock" label="可用库存" width="150"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popover v-model="scope.row.visible" placement="right" width="200">
            <el-radio-group v-model="stockType">
              <el-radio label="1">增加</el-radio>
              <el-radio label="2">减少</el-radio>
            </el-radio-group>
            <el-input class="numipt" v-model="stockNum" type="number" placeholder="礼品库存"></el-input>
            <div>
              <el-button @click="onIsVisible(scope.row)">取消</el-button>
              <el-button type="primary" @click="onConfirm(scope.row)">确认</el-button>
            </div>
            <el-button type="text" slot="reference">设置库存</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div class="content-center content-top-padded">
      <el-pagination background layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" :current-page.sync="listParams.page" :page-sizes="[10, 20, 50]"
        :total="total_count" :page-size="listParams.page_size">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getLotteryGiftShopstock, getLotteryGiftStock } from '@/api/lotteryGift'
import ImportDialog from '@/components/importDialog'

export default {
  props: {
    shopNum: {
      type: String,
      default: ''
    },
    agencyNum: {
      type: String,
      default: ''
    },
    distributorInfo: {
      type: Array,
      default: []
    },
    relDealers: {
      type: Array,
      deaflut: []
    },
    info: {
      type: Object,
      default: {}
    }
  },
  watch: {
    info: {
      deep: true,
      handler(value) {
        if (value) {
          this.setData(value)
        }
      }
    }
  },
  components: {
    ImportDialog
  },
  data() {
    return {
      ItemsList: [],
      listParams: {
        gid: '',
        page: 1,
        page_size: 10,
        dealer_id : '',
        distributor_id: '',
        min_stock: '',
        max_stock: ''
      },
      total_count: 0,
      isLoading: false,
      stockType: '1',
      stockNum: 1,
    }
  },
  methods: {
    setData(val) {
      if (val.id) {
        this.listParams.gid = val.id
        this.lotteryGiftShopstock()
      }
    },
    lotteryGiftShopstock() {
      let params = { ...this.listParams }
      if (this.relDealers.length > 0) {
        let arr = []
        this.relDealers.forEach(i => {
          arr.push(i.dealer_id)
        });
        params.dealer_id = arr.join(',')
      }
      if (this.distributorInfo.length > 0) {
        let arr = []
        this.distributorInfo.forEach(i => {
          arr.push(i.distributor_id)
        });
        params.distributor_id = arr.join(',')
      }
      getLotteryGiftShopstock(params).then((res) => {
        let data = res.data.data
        if (data.total_count > 0) {
          data.list.forEach((item) => {
            item.visible = false
          })
          this.ItemsList = data.list
          this.total_count = data.total_count
        } else {
          this.ItemsList = []
          this.total_count = 0
        }
      })
    },
    onBlurinterval(i) {
      if (this.listParams.min_stock != '' && this.listParams.max_stock != '') {
        let minStock = Number(this.listParams.min_stock)
        let maxStock = Number(this.listParams.max_stock)
        if (i == 'min') {
          if (minStock > maxStock) {
            this.listParams.min_stock = this.listParams.max_stock
          }
        } else if (i == 'max') {
          if (maxStock < minStock) {
            this.listParams.max_stock = this.listParams.min_stock
          }
        }
      }
    },
    onDialog(type) {
      this.$emit('onDialogType', type)
    },
    onIsVisible(item) {
      item.visible = false
    },
    onQuery() {
      this.lotteryGiftShopstock()
    },
    onConfirm(item) {
      if (this.isLoading) return
      this.isLoading = true
      let params = {
        gid: this.listParams.gid,
        stock: this.stockType == '1' ? this.stockNum : -this.stockNum,
        type: '1',
        distributor_id: item.distributor_id
      }
      getLotteryGiftStock(params).then((res) => {
        if (res.data.data.success) {
          item.visible = false
          this.lotteryGiftShopstock()
          this.stockType = '1'
          this.stockNum = 1
          this.isLoading = false
          this.$emit('refresh') //更改完成刷新一下活动列表
        }
      })
    },
    addInventory() {

    },
    handleCurrentChange(page_num) {
      this.listParams.page = page_num
      this.lotteryGiftShopstock()
    },
    handleSizeChange(pageSize) {
      this.listParams.page = 1
      this.listParams.page_size = pageSize
      this.lotteryGiftShopstock()
    }
  },
  mounted() {
    this.setData(this.info)
  },

}
</script>
<style scoped lang="scss">
.inventory {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;

  .interval {
    display: flex;
    align-items: center;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    margin-left: 6px;
  }

  .el-input {
    width: 150px;
  }

  .el-input__inner {
    border: none;
  }
}

.numipt {
  margin: 20px 0;
}

.name_text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px
}
</style>
