import { render, staticRenderFns } from "./add.vue?vue&type=template&id=1bc71694&scoped=true"
import script from "./add.vue?vue&type=script&lang=js"
export * from "./add.vue?vue&type=script&lang=js"
import style0 from "./add.vue?vue&type=style&index=0&id=1bc71694&prod&lang=scss"
import style1 from "./add.vue?vue&type=style&index=1&id=1bc71694&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bc71694",
  null
  
)

export default component.exports