<template>
  <div>
    <el-row :gutter="40">
      <el-col>
        <el-input class="input-b" placeholder="品牌名称" v-model="params.attribute_name" @change="brandSearch">
          <el-button slot="append" icon="el-icon-search" @click="brandSearch"></el-button>
        </el-input>
        <el-button-group v-if="$store.getters.login_type == 'admin' ||$store.getters.login_type == 'staff' || $store.getters.login_type == 'disti'">
          <el-button type="primary" size="medium" icon="el-icon-circle-plus" @click="handleNew">新增品牌</el-button>
<!--          <el-button type="primary" @click="syncBrand">同步品牌</el-button>-->
        </el-button-group>
        <ImportDialog
          buttonName="导入品牌"
          buttonSize="medium"
          fileName="导入品牌"
          fileType="upload_goods_brand"
        />
        <div style="display: inline-block">
          <el-button type="primary" size="medium" @click="handleExport" style="" icon="el-icon-upload2">导出数据</el-button>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </div>
      </el-col>
    </el-row>
    <el-card>
      <el-table :data="list" :height="wheight-170" v-loading="loading" element-loading-text="数据加载中" :default-sort = "{prop: 'bind_date', order: 'descending'}">
        <el-table-column label="操作" width="150" v-if="$store.getters.login_type == 'admin' ||$store.getters.login_type == 'staff'||$store.getters.login_type == 'disti'">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope)">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column label="品牌图片" width="150">
          <template slot-scope="scope">
            <el-image
              style="width: 70px; height: 70px"
              :src="scope.row.image_url"
              fit="cover">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="attribute_id" label="品牌ID" />
        <el-table-column prop="attribute_name" label="品牌名称">
          <template slot-scope="scope">
            <div v-if="!scope.row.attribute_id"><el-input v-model="scope.row.attribute_name" placeholder="请输入品牌名称"></el-input></div>
            <div v-else>{{scope.row.attribute_name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="is_original" label="原厂品牌">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_original"
              :active-value="true"
              :inactive-value="false"
              :active-text="scope.row.is_original === true ? '是' : '否'"
              @change="(v) => handleChangeBrand(scope.row, v)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div class="content-padded content-center">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="params.page"
        :page-sizes="[10,20,50]"
        :total="total_count"
        :page-size="params.pageSize">
      </el-pagination>
    </div>
    <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog"></imgPicker>
    <sideBar :visible.sync="show_sideBar" :title="'新增品牌'">
      <el-form>
        <el-form-item label="品牌名">
          <el-input v-model="form.attribute_name" />
        </el-form-item>
        <el-form-item label="品牌logo">
          <div class="frm-tips">只能上传jpg/png文件，且不超过2M （建议尺寸：200px * 200px）</div>
          <div @click="handleImgPicker" class="upload-box">
            <img v-if="form.image_url" :src="form.image_url" class="avatar"/>
            <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">提交</el-button>
        </el-form-item>
      </el-form>
    </sideBar>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import imgPicker from '@/components/imageselect'
  import { getGoodsAttr, addGoodsAttr, deleteGoodsAttr, syncBrand, updateGoodsAttrNew } from '../../../../api/goods'
  import sideBar from '@/components/element/sideBar'
  import ImportDialog from '@/components/importDialog'

  export default {
    components: {
      sideBar,
      imgPicker,
      ImportDialog
    },
    data () {
      return {
        form: {
          attribute_type: 'brand',
          attribute_id: '',
          attribute_name: '',
          image_url: '',
        },
        params: {
          page: 1,
          pageSize: 10,
          attribute_type: 'brand',
          attribute_name:''
        },
        list: [],
        loading: false,
        imgDialog: false,
        isGetImage: false,
        imgIndex: 0,
        brand_name: '',
        total_count: 0,
        show_sideBar: false
      }
    },
    methods: {
      handleCurrentChange (page_num) {
        this.params.page = page_num
        this.getList()
      },
      handleSizeChange (pageSize) {
        this.params.page = 1
        this.params.pageSize = pageSize
        this.getList()
      },
      handleDelete(data){
        this.$confirm('确认删除该品牌？')
          .then(_ => {
            deleteGoodsAttr(data.row.attribute_id).then(res => {
              this.list.splice(data.$index, 1)
              this.$message({ type: 'success', message: '操作成功' })
            })
          })
          .catch(_ => {});
      },
      handleNew() {
        this.show_sideBar = true
        this.resetData()
      },
      resetData() {
        this.form = {
          attribute_id: '',
          attribute_type: 'brand',
          attribute_name: '',
          image_url: ''
        }
      },
      handleEdit(data) {
        this.show_sideBar = true
        this.form = {
          attribute_id: data.attribute_id,
          attribute_type: data.attribute_type,
          attribute_name: data.attribute_name,
          image_url: data.image_url
        }
      },
      save() {
        // 如果没有id，则表示为新增
        if (!this.form.attribute_id) {
          addGoodsAttr(this.form).then(res =>{
            this.$message({ type: 'success', message: '操作成功' })
            this.params.page = 1
            this.resetData()
            this.getList()
          })
        } else {
          updateGoodsAttrNew(this.form).then(res =>{
            this.$message({ type: 'success', message: '操作成功' })
            this.getList()
          })
        }
      },
      getList(is_export = 0) {
        this.loading = true
        let params = {
          ...this.params,
          is_export
        }
        getGoodsAttr(params).then(res => {
          if (is_export) {
            this.$message.success('导出成功，请前往导出列表查看')
          } else {
            this.list = res.data.data.list
            this.total_count = res.data.data.total_count
          }
          this.loading = false
        })
      },
      // 品牌搜索
      brandSearch(){
         this.getList()
      },
      handleImgChange (data) {
        this.imgDialog = true
        this.isGetImage = true
        this.imgIndex = data.$index
      },
      handleImgPicker () {
        this.imgDialog = true
        this.isGetImage = true
      },
      pickImg (data) {
        this.form.image_url = data.url
        this.imgDialog = false
      },
      closeImgDialog () {
        this.imgDialog = false
        this.isGetImage = false
      },
      syncBrand() {
        syncBrand().then(res => {
          if (res.data.data.status == true) {
            this.$message({
              type: 'success',
              message: '已加入执行队列'
            });
          } else {
            this.$message({
              type: 'error',
              message: '同步失败'
            });
          }
        });
      },
      handleExport() {
        this.getList(1)
      },
      async handleChangeBrand(row, v) {
        const { attribute_id, attribute_name } = row
        const data = {
          attribute_id,
          attribute_name,
          is_original: v
        }
        await updateGoodsAttrNew(data)
        this.$message.success('操作成功')
        this.getList()
      }
    },
    mounted () {
      this.getList()
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    }
  }
</script>
<style scoped lang="scss">
  .upload-box {
    width: 100px;
    height: 100px;
    .avatar-uploader-icon {
      line-height: 100px;
    }
  }
</style>
