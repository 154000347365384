<template>
  <div>
    <div class="zyk_page_register_Info_edit">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane name="corp" v-if="isShow_person" label="企业">
          <el-form
            :model="form"
            ref="ruleForm"
            label-width="130px"
            class="demo-ruleForm"
            :rules="rules"
          >
            <!-- 企业信息 -->
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span>企业信息</span>
              </div>

              <div class="content">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="企业名称" prop="name">
                      <el-input v-model="form.name" :disabled="disabled_user"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="营业执照号" prop="social_credit_code">
                      <el-input
                        v-model="form.social_credit_code"
                        :disabled="disabled_user"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="商户有效日期" prop="social_credit_code_expires">
                      <el-date-picker
                        :disabled="disabled_user"
                        v-model="form.social_credit_code_expires"
                        type="date"
                        placeholder="选择日期"
                        style="width: 100%"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="企业电话" prop="telphone">
                      <el-input :disabled="disabled_user" v-model="form.telphone"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="企业邮箱" prop="email">
                      <el-input :disabled="disabled_user" v-model="form.email"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="邮编" prop="zip_code">
                      <el-input :disabled="disabled_user" v-model="form.zip_code"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item label="所在省市" prop="area">
                      <el-cascader
                        style="width: 100%"
                        :disabled="disabled_user"
                        v-model="form.area"
                        :options="AllArea"
                        clearable
                        :props="{
                          value: 'value',
                          label: 'title',
                          children: 'cities'
                        }"
                      >
                      </el-cascader>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="企业地址" prop="address">
                      <el-input :disabled="disabled_user" v-model="form.address"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="16">
                    <el-form-item label="经营范围" prop="business_scope">
                      <el-input
                        :disabled="disabled_user"
                        maxlength="500"
                        show-word-limit
                        v-model="form.business_scope"
                        type="textarea"
                        placeholder="请输入内容"
                        rows="7"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-card>
            <!-- 法人信息 -->
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span>法人信息</span>
              </div>
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="法人姓名" prop="legal_person">
                    <el-input :disabled="disabled_user" v-model="form.legal_person"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="法人身份证号码" prop="legal_cert_id">
                    <div class="flex">
                      <el-input :disabled="disabled_user" v-model="form.legal_cert_id"></el-input>
                      <el-tooltip
                        content="身份证信息字母X需大写"
                        placement="top-end"
                        effect="light"
                      >
                        <i class="el-icon-warning-outline"></i>
                      </el-tooltip>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="法人手机号码" prop="legal_mp">
                    <el-input :disabled="disabled_user" v-model="form.legal_mp"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="法人证件有效期" prop="legal_cert_id_expires">
                    <el-date-picker
                      :disabled="disabled_user"
                      v-model="form.legal_cert_id_expires"
                      type="date"
                      placeholder="选择日期"
                      style="width: 100%"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <!-- 结算账户信息 -->
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span>结算账户信息</span>
              </div>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="结算银行账户类型" prop="bank_acct_type">
                    <el-radio-group v-model="form.bank_acct_type">
                      <el-radio :disabled="disabled_account" label="1">对公</el-radio>
                      <el-radio :disabled="disabled_account" label="2">对私</el-radio>
                    </el-radio-group>
                    <el-tooltip
                      :style="{ 'margin-left': 30 + 'px' }"
                      content="提现到账银行卡账户类型"
                      placement="top-end"
                      effect="light"
                    >
                      <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="结算银行卡所属银行" prop="bank_name">
                    <div class="flex">
                      <!-- <el-select :disabled=' disabled_account' v-model="form.bank_code" placeholder="请选择" style="width:100%">
                        <el-option
                          v-for="value in AllBank"
                          :key="value.id"
                          :label="value.bank_name"
                          :value="value.bank_code">
                        </el-option>
                      </el-select> -->
                      <el-autocomplete
                        :disabled="disabled_account"
                        class="inline-input"
                        v-model="form.bank_name"
                        :fetch-suggestions="querySearch"
                        placeholder="请输入选择内容"
                        @select="handleSelectBank"
                        @change="handleChangeBank"
                      ></el-autocomplete>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="结算银行卡开户名" prop="card_name">
                    <el-input :disabled="disabled_account" v-model="form.card_name"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="结算银行卡号" prop="card_no">
                    <el-input :disabled="disabled_account" v-model="form.card_no"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span>其他信息</span>
              </div>
              <div class="other_content">
                <div class="cover2">
                  <el-form-item label="上传附件" prop="attach_file">
                    <div class="cover">
                      <el-upload
                        :disabled="disabled_user"
                        class="upload-demo"
                        drag
                        :limit="1"
                        action=""
                        :on-change="uploadChange"
                        :on-exceed="oploadExceed"
                        :auto-upload="false"
                        multiple
                      >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <span v-if="form.attach_file_name">{{ form.attach_file_name }}</span>
                      </el-upload>
                    </div>
                  </el-form-item>
                </div>

                <div class="opload_rules">
                  <p>内容须包括：</p>
                  <p>营业执照：三证合一证件照；</p>
                  <p>法人信息：法人身份证正面照、法人身份证反面照；</p>
                  <p>结算账户信息：开户银行许可证照</p>
                  <p>请确保上传的图片信息清晰、准确、一致性；</p>
                  <p>请压缩为Zip文件进行上传，整包最大限制为8M。</p>
                </div>
              </div>
            </el-card>
            <el-form-item style="text-align: center; margin: 50px 0; margin-left: 0">
              <el-button type="primary" v-if="!type" @click="submitForm('ruleForm', 'N', 'submit')"
                >保存</el-button
              >
              <el-button
                type="primary"
                v-if="type == 'edit'"
                @click="submitForm('ruleForm', 'N', 'update')"
                >更新</el-button
              >
              <el-button type="primary" v-if="!type" @click="submitForm('ruleForm', 'Y', 'submit')"
                >保存并提交审核</el-button
              >
              <el-button @click="goback">取消</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <!-- 个人 -->
        <el-tab-pane name="person" v-if="isShow_corp" label="个人">
          <el-form
            ref="personForm"
            :model="personForm"
            label-width="130px"
            class="demo-ruleForm"
            :rules="personRules"
          >
            <!-- 基本信息-->
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span>基本信息</span>
              </div>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="用户姓名" prop="user_name">
                    <el-input :disabled="disabled_user" v-model="personForm.user_name"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="用户手机号码" prop="tel_no">
                    <div class="flex">
                      <el-input :disabled="disabled_account" v-model="personForm.tel_no"></el-input>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="用户身份证号码" prop="cert_id">
                    <el-input :disabled="disabled_user" v-model="personForm.cert_id"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="身份证有效日期" prop="date1">
                      <el-date-picker type="date" placeholder="选择日期" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row> -->
            </el-card>
            <!-- 结算账户信息 -->
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span>结算账户信息</span>
              </div>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="开户人姓名" prop="bank_card_name">
                    <el-input
                      :disabled="disabled_account"
                      v-model="personForm.bank_card_name"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="银行预留手机号" prop="bank_tel_no">
                    <div class="flex">
                      <el-input
                        :disabled="disabled_account"
                        v-model="personForm.bank_tel_no"
                      ></el-input>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="银行账号" prop="bank_card_id">
                    <el-input
                      :disabled="disabled_account"
                      v-model="personForm.bank_card_id"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="开户人证件号码" prop="bank_cert_id">
                    <el-input
                      :disabled="disabled_account"
                      v-model="personForm.bank_cert_id"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <el-form-item style="text-align: center; margin: 50px 0; margin-left: 0">
              <el-button
                v-if="!type"
                type="primary"
                @click="submitFormPerson('personForm', 'N', 'submit')"
                >保存</el-button
              >
              <el-button
                type="primary"
                v-if="type == 'edit'"
                @click="submitFormPerson('personForm', 'N', 'update')"
                >更新</el-button
              >
              <el-button
                v-if="!type"
                type="primary"
                @click="submitFormPerson('personForm', 'Y', 'submit')"
                >保存并审核提交</el-button
              >
              <el-button @click="goback">取消</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {
  getBankList,
  createCorp,
  createPerson,
  updateCorp,
  updatePerson,
  checkPerson,
  checkCorp
} from '@/api/dealerReInfo'
import areaData from '@/common/area.json'
import { forEach } from 'jszip'
export default {
  data() {
    return {
      // 初始打开tab
      activeName: '',
      // 审核状态
      ApprovalStatus: '',
      // 全部状态
      AllStatus: '',
      // 全部地区
      AllArea: areaData,
      // 全部银行
      AllBank: [],
      // 选中地区
      area: '',
      form: {
        name: '', //企业名称
        area: '', // 省份编码地区编码

        business_scope: '', //经营范围
        social_credit_code: '', // 统一社会信用码
        social_credit_code_expires: '', //统一社会信用证有效期(1121),
        telphone: '', //企业电话
        zip_code: '', //邮编
        email: '', // 企业邮箱
        legal_person: '', //法人姓名
        legal_cert_id: '', //法人身份证号码
        legal_cert_id_expires: '', // 法人身份证有效期(20220112)
        legal_mp: '', // 法人手机号
        address: '', //企业地址
        attach_file: '', //上传附件
        bank_code: '', // 银行代码
        bank_name: '',
        bank_acct_type: '', //银行账户类型：1-对公；2-对私
        card_no: '', //银行卡号
        card_name: '', //银行卡对应的户名，若银行账户类型是对公，必须与企业名称一致,
        submit_review: '', //是否提交审核(Y/N)
        isUploadFile: true, // 加这个走formdata格式
        attach_file_name: ''
      },
      // form:{
      //   name:'阿里巴巴', //企业名称
      //   area:['0011','1100'], // 省份编码地区编码
      //   bank:'01040000', //银行
      //   business_scope:'扫黑除恶专项斗争', //经营范围
      //   social_credit_code:'110WZA', // 统一社会信用码
      //   social_credit_code_expires:'', //统一社会信用证有效期(1121),
      //   telphone:'', //企业电话
      //   zip_code:'047500', //邮编
      //   email:'110@110.com', // 企业邮箱
      //   legal_person:'法外狂徒张三', //法人姓名
      //   legal_cert_id:'123456789123456789',//法人身份证号码
      //   legal_cert_id_expires:'', // 法人身份证有效期(20220112)
      //   legal_mp:'13935521111', // 法人手机号
      //   address:'上海市公安局', //企业地址
      //   attach_file:'', //上传附件
      //   bank_code:'090909090', // 银行代码
      //   bank_acct_type:'2', //银行账户类型：1-对公；2-对私
      //   card_no:'8888888888', //银行卡号
      //   card_name:'阿里巴巴',//银行卡对应的户名，若银行账户类型是对公，必须与企业名称一致,
      //   submit_review:'' ,//是否提交审核(Y/N)
      //   isUploadFile:true,// 加这个走formdata格式
      //   attach_file_name:'',
      // },
      rules: {
        name: { required: true, message: '请输入', trigger: 'blur' },
        social_credit_code: { required: true, message: '请输入', trigger: 'blur' },
        social_credit_code_expires: {
          type: 'date',
          required: true,
          message: '选择日期',
          trigger: 'blur'
        },
        area: { type: 'array', required: true, message: '请选择', trigger: 'change' },
        address: { required: true, message: '请输入', trigger: 'blur' },
        business_scope: { required: true, message: '请输入', trigger: 'blur' },
        legal_person: { required: true, message: '请输入', trigger: 'blur' },
        legal_cert_id: { required: true, message: '请输入', trigger: 'blur' },
        legal_cert_id_expires: {
          type: 'date',
          required: true,
          message: '选择日期',
          trigger: 'blur'
        },
        legal_mp: { required: true, message: '请输入', trigger: 'blur' },
        bank_acct_type: { required: true, message: '请选择', trigger: 'change' },
        bank_name: { required: true, message: '请选择', trigger: 'change' },
        card_name: { required: true, message: '请输入', trigger: 'blur' },
        card_no: { required: true, message: '请输入', trigger: 'blur' },
        attach_file: { required: true, message: '请上传', trigger: 'change' }
      },
      // personForm:{
      //   user_name:'王五', //用户姓名
      //   tel_no:'10923459080', // 用户手机号码
      //   cert_id:'123456789987654321', // 用户身份证号码
      //   bank_card_name:'王五',//开户人姓名
      //   bank_tel_no:'10923459080', //银行预留手机号
      //   bank_card_id:'21321321321321', //银行账号
      //   bank_cert_id:'123456789987654321', // 开户人证件号码
      //   submit_review:''//是否提交审核(Y/N)
      // },
      personForm: {
        user_name: '', //用户姓名
        tel_no: '', // 用户手机号码
        cert_id: '', // 用户身份证号码
        bank_card_name: '', //开户人姓名
        bank_tel_no: '', //银行预留手机号
        bank_card_id: '', //银行账号
        bank_cert_id: '', // 开户人证件号码
        submit_review: '' //是否提交审核(Y/N)
      },
      personRules: {
        user_name: { required: true, message: '请输入', trigger: 'blur' },
        tel_no: { required: true, message: '请输入', trigger: 'blur' },
        cert_id: { required: true, message: '请输入', trigger: 'blur' },
        bank_card_name: { required: true, message: '请输入', trigger: 'blur' },
        bank_tel_no: { required: true, message: '请输入', trigger: 'blur' },
        bank_card_id: { required: true, message: '请输入', trigger: 'blur' },
        bank_cert_id: { required: true, message: '请输入', trigger: 'blur' }
      },
      isShow_corp: false,
      isShow_person: false,
      disabled_user: false,
      disabled_account: false,
      type: '',
      id: ''
    }
  },
  async created() {
    const { id, type, member_type } = this.$route.query
    console.log(id, type, member_type)
    this.type = type
    this.id = id
    this.getResult(id, type, member_type)
  },
  mounted() {
    this.get_bank()
  },
  methods: {
    // 中国标准时间  ----> yyyymmdd
    getFormatDate(date) {
      console.log(date)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let nowDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (nowDate >= 0 && nowDate <= 9) {
        nowDate = '0' + nowDate
      }
      var newDate = year + month + nowDate
      return newDate
    },
    // yyyymmdd------> 中国标准时间
    FormatDate(date) {
      const year = date.slice(0, 4)
      let month = date.slice(4, 6)
      if (month[0] == 0) {
        month = month[1]
      }
      let day = date.slice(6)
      if (day[0] == 0) {
        day = day[1]
      }
      return `${year},${month},${day}`
    },
    submitForm(formName, isSubmit, type) {
      this.form.submit_review = isSubmit
      this.form.isUploadFile = true
      console.log(this.form)
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const obj = {
            ...this.form,
            social_credit_code_expires: this.getFormatDate(this.form.social_credit_code_expires),
            legal_cert_id_expires: this.getFormatDate(this.form.legal_cert_id_expires)
          }
          console.log(obj)
          if (type == 'submit') {
            const result = await createCorp(obj)
            if (result.data.data.status) {
              this.$message.success('提交成功')
              this.$router.back()
            }
          } else {
            const result = await updateCorp(this.id, obj)
            if (result.data.data.status) {
              this.$message.success('更新成功')
            }
          }
          this.$router.back()
        } else {
          this.$message.error('请完善您的信息~')
          return false
        }
      })
    },
    submitFormPerson(formName, isSubmit, type) {
      this.personForm.submit_review = isSubmit
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(this.personForm)
          if (type == 'update') {
            const result = await updatePerson(this.id, this.personForm)
            if (result.data.data.status) {
              this.$message.success('更新成功')
            }
          } else {
            const result = await createPerson(this.personForm)
            if (result.data.data.status) {
              this.$message.success('提交成功')
            }
          }
          this.$router.back()
        } else {
          this.$message.error('请完善您的信息哦~')
          return false
        }
      })
    },
    uploadChange(file) {
      let check = this.validatUpload(file)
      if (check !== true) {
        this.$message({
          message: check,
          type: 'error',
          duration: 5 * 1000
        })
        return
      }
      this.form.attach_file_name = ''
      this.form.attach_file = file.raw
      console.log(file)
    },
    oploadExceed(files) {
      // this.form.attach_file = files[0]
      // console.log(files);
    },
    goback() {
      this.$router.back(-1)
    },
    /* 上传文件验证 */
    validatUpload(file) {
      const MAX_IMG_SIZE = 8
      let isLt2M = file.raw.size / 1024 / 1024 <= MAX_IMG_SIZE
      if (!isLt2M) {
        return `上传图片大小不能超过 ${MAX_IMG_SIZE}MB!`
      }
      return true
    },
    // type 判断结果 （获取保存的结果）
    async getResult(id, type, member_type) {
      this.id = id
      if (type == 'check') {
        this.disabled_user = true
        this.disabled_account = true
      }
      if (id && member_type == 'corp') {
        this.activeName = member_type
        this.isShow_person = true
        const result = await checkCorp(id)
        this.form = result.data.data
        if (result.data.data.disabled_type == 'user') {
          this.disabled_user = true
        }
        const {
          area_code,
          prov_code,
          social_credit_code_expires,
          legal_cert_id_expires,
          attach_file_name,
          bank_code,
          bank_name
        } = result.data.data
        this.form.area = [prov_code, area_code]
        console.log(this.FormatDate(social_credit_code_expires))
        const date1 = new Date(this.FormatDate(social_credit_code_expires))
        const date2 = new Date(this.FormatDate(legal_cert_id_expires))
        this.form.social_credit_code_expires = date1
        console.log('date1:', this.form.social_credit_code_expires)
        this.form.legal_cert_id_expires = date2
        this.form.attach_file_name = attach_file_name
        // this.form.bank =
        // this.form.bank_code = bank_code

        console.log('corp---------', result)
      } else if (id && member_type == 'person') {
        this.isShow_corp = true
        this.activeName = member_type
        const result = await checkPerson(id)
        if (result.data.data.disabled_type == 'user') {
          this.disabled_user = true
        }

        this.personForm = result.data.data
        console.log('person---------', result)
      } else {
        console.log('增加')
        this.isShow_person = true
        this.isShow_corp = true

        this.activeName = 'corp'
      }
    },
    // 结算所属银行
    querySearch(queryString, cb) {
      var restaurants = this.AllBank.map((item) => {
        return {
          value: item.bank_name,
          bank_code: item.bank_code,
          id: item.id
        }
      })
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      //调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    async get_bank() {
      const result = await getBankList()
      this.AllBank = result.data.data
    },
    handleSelectBank(val) {
      console.log(val)
      this.form.bank_code = val.bank_code
      this.form.bank_name = val.value
    },
    handleChangeBank(val) {
      console.log(val)
      // let flag
      // this.AllBank.forEach(element => {
      //   if (element.bank_name===val) {
      //     flag = false
      //   }
      // });
      // if (!flag) {
      //   this.$message.error('请选择有效银行')
      // }
      // console.log(val);
    }
  }
}
</script>

<style lang="scss" scoped>
.zyk_page_register_Info_edit {
  .flex {
    display: flex;
    align-items: center;
    i {
      margin-left: 10px;
      font-size: 18px;
    }
  }
  .other_content {
    display: flex;
    align-items: flex-end;
    justify-content: left;
    position: relative;
    .cover2 {
      width: 660px;
      height: 100%;
    }
    .opload_rules {
      padding: 20px;
      font-size: 13px;
      color: #888;
      background: #f5f5f5;

      p {
        &:nth-child(5) {
          margin-bottom: 10px;
        }
        &:nth-child(4) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.zyk_page_register_Info_edit {
  padding-bottom: 100px;
  span {
    font-weight: 700;
  }
  label {
    font-size: 12px;
    color: #000;
  }
  input {
    height: 30px;
    line-height: 30px;
  }
  .el-row {
    margin-bottom: 0px;
  }
}
</style>
