<template>
  <div>
    <el-form ref=form :model=form class=box-set label-width=120px>
      <el-card header="基础信息">
        <el-form-item label="系列名称">
          <el-input v-model="form.series_name" class="input-b"></el-input>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-switch v-model="form.status"></el-switch>
        </el-form-item>
      </el-card>

      <el-card header="选择适用商品">
        <SkuSelector
          @change="getItems"
          :data="relItems"
          :selectSku="false"
        ></SkuSelector>
      </el-card>

      <div class=content-center style="margin: 20px 0">
        <el-button
          type=primary
          @click="submit"
          :loading="submitBtnLoading"
        >保存
        </el-button>
        <el-button @click.native=handleCancel>返回</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>

import {seriesInfo, seriesUpdate, seriesCreate} from '@/api/goods'
import SkuSelector from '@/components/function/skuSelector'

export default {
  components: {
    SkuSelector
  },
  data () {
    return {
      thumbDialog: false,
      isGetThumb: false,
      id: 0,
      is_edit: false,
      submitBtnLoading: false,
      form: {
        series_name: '',
        status: true,
        goods_ids: ''
      },
      relItems: []
    }
  },
  methods: {
    getItems (data) {
      // eslint-disable-next-line camelcase
      let goods_ids = []
      data.forEach(item => {
        goods_ids.push(item.goods_id)
      })
      console.log(goods_ids)
      // eslint-disable-next-line camelcase
      this.form.goods_ids = goods_ids
    },
    submit () {
      this.submitBtnLoading = true
      let form = {
        ...JSON.parse(JSON.stringify(this.form))
      }
      form.status = this.form.status ? '1' : '0'

      if (this.id) {
        seriesUpdate(this.id, form).then(response => {
          this.$message.success('更新成功')
          this.$router.go(-1)
          this.submitBtnLoading = false
          // eslint-disable-next-line handle-callback-err
        }).catch((error) => {
          // this.$message.error('更新失败')
          this.submitBtnLoading = false
        })
      } else {
        seriesCreate(form).then(response => {
          this.$message.success('创建成功')
          this.$router.go(-1)
          this.submitBtnLoading = false
          // eslint-disable-next-line handle-callback-err
        }).catch((error) => {
          // this.$message.error('创建失败')
          this.submitBtnLoading = false
        })
      }
    },
    handleCancel: function () {
      this.$router.go(-1)
    },

    getInfo () {
      let params = {
        'id': this.id
      }
      seriesInfo(params).then(response => {
        let result = response.data.data
        if (result) {
          this.form.series_name = result.series_name
          this.form.status = result.status === '1' || result.status === 1
          console.log(result.itemTreeLists)
          this.$nextTick(() => {
            this.relItems = result.itemTreeLists
          })
        }
      })
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.is_edit = true

      this.getInfo();
    }
    this.loader = false
  }
}
</script>
