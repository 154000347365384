import fetch from '@/utils/fetch'

// 获取上传的商品数据
export const goods_list = '/distributor/items/price_status/item_list'

// 获取上传的店铺数据
export const shop_list = '/distributor/items/price_status/distributor_list'

// 价格统计记录
export const pricechecklist = '/distributor/items/price_status/pricechecklist'

// 执行上下架操作
export function itemsUnmount (query) {
  return fetch({
    url: '/distributor/items/sale_status/update',
    method: 'post',
    data: query
  })
}


// 下载执行结果
export function downloadPriceCheckList (params) {
  return fetch({
    url: '/distributor/items/price_status/downloadPriceCheckList',
    method: 'get',
    params: params
  })
}

// 下载执行结果
export function downloadPriceCheckDetail (params) {
  return fetch({
    url: '/distributor/items/price_status/downloadPriceCheckDetail',
    method: 'get',
    params: params
  })
}

// 下载执行结果
export function download (id) {
  return fetch({
    url: '/distributor/items/sale_status/download',
    method: 'get',
    params: { id }
  })
}

// 删除上传的商品或店铺
export function delUploadInfo (query) {
  return fetch({
    url: '/distributor/items/sale_status/del_upload_info',
    method: 'post',
    data: query
  })
}
