<style scoped lang="scss">
.main {
  min-width: 1200px;
  //   overflow: auto;
}
.unbind-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 285px;
  .iconfont {
    display: block;
    margin-bottom: 20px;
    font-size: 70px;
    line-height: 1;
    color: #eaeaea;
  }
  p {
    margin-bottom: 20px;
  }
}

.section-card {
  padding: 15px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  &.realtime-statics {
    height: 285px;
  }
  &.chart-statics {
    height: 318px;
  }
  .section-card-header {
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
    .section-card-title {
      margin-right: 20px;
      font-size: 16px;
      font-weight: 600;
    }
    .header-small {
      flex: 1;
      font-size: 12px;
      color: #ccc;
    }
    .header-filters {
      display: flex;
      .filter-item {
        position: relative;
        padding: 0 15px;
        color: #999;
        font-size: 12px;
        &::after {
          position: absolute;
          left: 0;
          top: 20%;
          bottom: 20%;
          width: 1px;
          background: #efefef;
          content: "";
        }
        &:first-child::after {
          content: none;
        }
      }
    }
  }
}

.company {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 285px;
  .logo {
    margin-bottom: 20px;
    width: 70px;
    height: 70px;
    border-radius: 100%;
  }
  .name {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .operate {
    padding-bottom: 50px;
  }
  .validity-period {
    display: flex;
    font-size: 12px;
    color: #999;
    .iconfont {
      margin-right: 10px;
      font-size: 12px;
    }
  }
}

.analysis {
  padding-left: 20px;
  padding-top: 5px;
  .label {
    font-size: 12px;
    color: #999;
  }
  .real-data {
    font-size: 20px;
    font-family: "Helvetica Neue";
    font-weight: 500;
  }
  .history-data {
    font-size: 12px;
    color: #ccc;
  }
}

.notices {
  padding-left: 20px;
  .notices-group {
    padding: 10px 0;
  }
  .subtitle {
    padding-bottom: 10px;
    font-size: 14px;
    color: #666;
  }
  .notice-item {
    font-size: 12px;
    color: #999;
    cursor: pointer;
  }
}

.quick-link {
  padding-left: 20px;
  .link-item {
    float: left;
    width: 230px;
    margin: 10px 0;
    color: #666;
    cursor: pointer;
    .iconfont {
      display: inline-block;
      margin-right: 10px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
    }
  }
}
.fn-b-20 {
  margin-bottom: 20px;
}
.to-become {
  // float: left;
  // position: relative;
  // top:-30px;
  background-color: #fef4f3;
  border: 1px solid #f25d4c;
  //   min-width: 1200px;
  width: 75%;
  position: absolute;
  top: 5px;
  display: flex;
  //   justify-content: space-between;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  .text-icon {
    line-height: 38px;
    height: 40px;
    display: flex;
    // text-align: center;
  }
  .icon-wr {
    line-height: 38px;
    font-size: 20px;
    color: #f05e51;
  }
  .btn {
    padding-left: 40px;
    line-height: 38px;
    padding-bottom: 6px;
  }
}
.buy {
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
  .buy-item {
    width: 320px;
    padding: 0;
    .buy-item-hd {
      padding: 10px;
      height: 120px;
      text-align: center;
      color: white;
      h3 {
        margin: 10px;
        font-size: 24px;
        color: white;
      }
      &_s {
        // background: url('/static/img/saas/try_1.png');
      }
      &_b {
        // background: url('/static/img/saas/biaozun.png');
        color: white;
        h3 {
          color: white;
        }
      }
      &_l {
        // background: url('/static/img/saas/liansuo.png');

        h3 {
          color: white;
        }
      }
    }
    .buy-item-ft {
      min-height: 450px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .buy-item-btn {
        width: 100px;
      }
      .buy-item-ft-text {
        text-align: center;
        .suo {
          margin-top: 100px;
          margin-bottom: 30px;
          width: 40px;
          // height:150px;
        }
      }

      p {
        padding: 8px;
      }
      .check-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.buy-hd {
  text-align: center;
  margin-bottom: 40px;
  h3 {
    font-size: 24px;
    color: #333333;
    font-weight: bold;
  }
}
.card-right {
  width: 85%;
  background-color: #fafafa;
  text-align: center;
  .phone-card {
    width: 150px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .phone-icon {
    width: 16px;
    height: 17px;
    margin-right: 10px;
  }
  .phone-num {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    // // height: 25px;
    // word-wrap: break-word;
    // word-break: normal;
    margin-bottom: 10px;
  }
  .phone-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 110px;
    height: 30px;
    margin: 0 auto;
    border: 1px solid #5ea7ec;
    text-align: center;
    border-radius: 3px;
    padding: 0 10px;
    span {
      color: #5ea7ec;
    }
  }
}
.demo-img {
  // background: url('/static/img/saas/bcg_1.png');
  width: 100%;
  text-align: center;
  // padding: 40px 0;
  .sl-img-hd {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .img-demo {
    width: 70%;
    margin: 0 auto;
  }
}
.sl-img {
  // padding: 0;
  // background: url('/static/img/saas/bcg_2.png');
  width: 100%;
  text-align: center;
  // padding: 40px 0;
  .sl-img-hd {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    i {
      margin-right: 10px;
    }
  }
  .img-demo {
    width: 70%;
    margin: 0 auto;
  }
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.title-span {
  text-align: center;
  margin-top: -30px;
}
.dialog-footer {
  text-align: center;

  .btn-ft {
    margin: 0 20px;
    width: 140px;
  }
}
</style>

<template>
  <div>
    <el-form label-width="100px" :inline="true">
      <el-form-item label="选择日期范围">
        <el-col :span="25">
          <el-date-picker
            v-model="vdate"
            type="daterange"
            :picker-options="pickerOptions"
            alue-format="yyyy-MM-dd"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 100%;"
            value-format="yyyy-MM-dd"
            @change="dateChange"
            >
          </el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item label="商品名称">
        <el-col :span="20">
          <el-input v-model="params.item_name"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="经销商HQID">
        <el-col :span="20">
          <el-input v-model="params.dealer_hqid"></el-input>
        </el-col>
      </el-form-item>      
      <el-form-item label="经销商名称">
        <el-col :span="20">
          <el-input v-model="params.dealer_name"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="分销商HQID">
        <el-col :span="20">
          <el-input v-model="params.distributor_hqid"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="分销商名称">
        <el-col :span="20">
          <el-input v-model="params.distributor_vad"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="渠道RTM">
        <el-col :span="20">
          <el-input v-model="params.channel_rtm"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="配送方式">
        <el-col :span="20">
          <el-input v-model="params.receipt_type_zn"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col>
          <el-button type="primary" @click="searchData" style="margin-left: 10px">搜索</el-button>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col>
          <el-button type="primary" @click="exportData" v-loading="exportloading" :disabled="exportloading" style="margin-left: 10px">导出
          </el-button>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </el-col>
      </el-form-item>
      <template>

        <el-row :gutter="20" >
          <el-col :span="10">
            <section class="section-card">
              <!-- div class="section-card-header">
                <div class="section-card-title">合计</div>
              </div -->
              <div>
                <el-row class="analysis" :gutter="20">
                  <el-col :span="12">
                    <div class="view-flex">
                      <div class="view-flex-item">
                        <div class="label">成功付款</div>
                        <div class="real-data">
                         {{all_num_payed}}
                        </div>
                      </div>
                      <div class="view-flex-item">
                        <div class="label">销售数量</div>
                        <div class="real-data">
                         {{all_countorder}}
                        </div>
                      </div>
                      <div class="view-flex-item">
                        <div class="label">取消订单</div>
                        <div class="real-data">
                         {{all_num_cancel}}
                        </div>
                      </div>
                      <div class="view-flex-item">
                        <div class="label">销售金额</div>
                        <div class="real-data">
                         {{all_total_fee_all}}
                        </div>
                      </div>                                            
                    </div>
                  </el-col>
                </el-row>                                     
              </div>
                <!--成功付款: {{all_num_payed}}  销售数量: {{all_countorder}}  取消订单: {{all_num_cancel}}  成功支付金额: {{all_total_fee_all}} -->
            </section>
          </el-col>
        </el-row>          
        <div>
          <span style="float: left; margin-left: 200px; color: blue; font-size: 36px">
          </span>
        </div>
        <el-table
          ref='activityTable'
          :data="dataList" stripe border style="width: 100%">
          <el-table-column prop="order_date" label="日期"></el-table-column>
          <el-table-column label="经销商信息">
            <el-table-column prop="dealer_hqid" label="经销商HQID"></el-table-column>
            <el-table-column prop="dealer_name" label="经销商名称"></el-table-column>
            <el-table-column prop="distributor_hqid" label="分销商HQID"></el-table-column>
            <el-table-column prop="distributor_vad" label="分销商名称"></el-table-column>
            <el-table-column prop="channel_rtm" label="渠道RTM"></el-table-column>
          </el-table-column>
          <el-table-column label="商品信息">
            <el-table-column prop="item_bn" label="商品编码"></el-table-column>
            <el-table-column prop="item_name" label="商品名称"></el-table-column>
            <el-table-column prop="item_spec_desc" label="商品规格"></el-table-column>
            <el-table-column prop="price" label="商品价格"></el-table-column>
          </el-table-column>
          <el-table-column label="订单信息">
            <el-table-column prop="receipt_type_zn" label="订单类型"></el-table-column>
            <el-table-column prop="num_payed" label="成功付款"></el-table-column>
            <el-table-column prop="num_cancel" label="取消数量（付款后核销/发货前取消）"></el-table-column>
            <el-table-column prop="countorder" label="销售数量（已取货/已发货）"></el-table-column>
            <el-table-column prop="total_fee_all" label="销售金额"></el-table-column>
          </el-table-column>

        </el-table>
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="params.page"
          :page-sizes="[10,20,50]"
          :total="total_count"
          :page-size="params.pageSize">
        </el-pagination>
      </template>
    </el-form>
  </div>
</template>

<script>
  import { getorderdatahq } from '../../../api/datacube'

export default {
  name: "hqdata",
  data() {
    return {
      vdate: '',
      item_title: '',
      shop_code: '',
      shop_name: '',
      dealer_hqid: '',
      dealer_name: '',
      distributor_hqid: '',
      distributor_name: '',
      channel_rtm: '',
      receipt_type_zn: '',

      exportloading: false,

      params: {
        page: 1,
        pageSize: 10,
        start:'',
        end: '',

        item_name: '',
        dealer_name:'',
        distributor_hqid:'',
        distributor_vad:'',
        channel_rtm:'',
        receipt_type_zn:'',

      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            end.setTime(end.getTime() );//- 3600 * 1000 * 24 * 1
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            end.setTime(end.getTime() );//- 3600 * 1000 * 24 * 1
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            end.setTime(end.getTime() );//- 3600 * 1000 * 24 * 1
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      total_count: 0,
        all_num_payed: 0,
        all_countorder: 0,
        all_num_cancel: 0,
        all_total_fee_all: 0,      
      dataList:{}
    }
  },
  methods: {
    dateChange (val) {
      this.params.start = val[0]
      this.params.end = val[1]
      this.getDataList(true)
    },
    searchData() {

      var start = this.params.start ;
      var end   = this.params.end ;
      // start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
      // end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
      this.vdate = [start, end]
      this.getDataList()
    },
    handleCurrentChange(page_num) {
      this.$refs.activityTable.clearSelection();
      this.params.page = page_num;
      this.getDataList();
    },
    handleSizeChange(pageSize) {
      this.$refs.activityTable.clearSelection();

        console.log(pageSize)
        console.log(this.params.pageSize)

      this.params.page = 1;
      this.params.pageSize = pageSize;


        console.log(pageSize)
        console.log(this.params.pageSize)

      this.getDataList();
    },
    getDataList () {
      console.log(this.params)

      this.loading = true
      getorderdatahq(this.params).then(res => {
        this.dataList = res.data.data.list
        this.total_count = res.data.data.total_count
          this.all_num_payed     = res.data.data.sum_rec.all_num_payed
          this.all_countorder    = res.data.data.sum_rec.all_countorder
          this.all_num_cancel    = res.data.data.sum_rec.all_num_cancel
          this.all_total_fee_all = res.data.data.sum_rec.all_total_fee_all

        this.loading = false
      }).catch( error => {
        this.$message({
          type: 'error',
          message: '获取统计信息出错'
        })
      })
    },

    exportData() {
      this.exportloading = true
      if (this.dataList.length <= 0) {
        this.$message({
          type: 'error',
          message: '请确认导出的内容暂无数据~！'
        });
        this.exportloading = false
        return;
      }
      getorderdatahq({...this.params, is_export: true}).then(response => {
        if (response.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          this.exportloading = false;
          return true;
        } else if (response.data.data.url) {
          this.downloadUrl = response.data.data.url
          this.downloadName = response.data.data.filename
          this.downloadView = true
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return false;
        }
      }).catch(error => {
        this.exportloading = false
        console.log(error);
        return false;
      });
    },
  },
  mounted () {
    var start = new Date();
    var end = new Date();

    
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    end.setTime(end.getTime() ); //- 3600 * 1000 * 24 * 1

    this.params.start = start;
    this.params.end   = end;

    this.vdate = [start, end]
    this.getDataList()
  }
}
</script>

<style scoped>

</style>
