<style scoped lang="scss">
.store-name {
  padding-bottom: 5px;
  font-size: 16px;
  color: #333;
}
.store-contact {
  color: #888;
  span {
    margin-right: 10px;
  }
}
.store-address {
  color: #888;
}
.mt10 {
  margin-top: 10px;
}
.drawer-list{
  padding-left: 20px;
  .el-form-item {
    margin-bottom: 24px;
  }
}
.confirm-drawer{
  padding: 20px;
  display: flex;
  justify-content: center
}
.top-button-group {
  margin-right: 8px;
  ::v-deep .el-button {
    border-radius: 0;
    margin-right: 0 !important;
  }
  div:first-child {
    ::v-deep .el-button {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  div:last-child {
    ::v-deep .el-button {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
.top-button-group > ::v-deep .el-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.top-button-group > ::v-deep .el-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.ipt-tag {
  margin-right: 5px;
}
.shop-prop-tag {
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 4px;
  }
  .el-button {
    padding: 0;
  }
}
</style>
<template v-loading="lzLoading" element-loading-text="加载中...">
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1 && $route.path.indexOf('_template') === -1">
      <el-row :gutter="20">
        <el-col>
          <el-cascader
            v-model='areaData'
            placeholder="根据地区筛选"
            :options="regions"
            filterable
            :props="{ checkStrictly: true }"
            @change="RegionChangeSearch"
            class="mt10"
            clearable
          ></el-cascader>
          <el-input
            class="input-m mt10"
            placeholder="门店名称"
            v-model="params.name"
            clearable
            @change="numberSearch"/>
          <el-input
            class="input-m mt10"
            placeholder="门店AppleID"
            v-model="params.shop_code"
            clearable
            @change="numberSearch"/>
          <el-button class="btn_input" @click="onDealerDialog">
            {{ agency_num }}
            <i class="el-icon-circle-plus icon"></i>
          </el-button>
          <el-input
            class="input-m mt10"
            placeholder="归属分销商VAD"
            v-model="params.distributor_vad"
            clearable
            @clear="numberSearch"
            @change="numberSearch"/>
          <el-input
            class="input-m mt10"
            placeholder="归属渠道RTM"
            v-model="params.channel_rtm"
            clearable
            @clear="numberSearch"
            @change="numberSearch"/>
<!--          <el-cascader
            v-if="system_mode === 'platform'"
            placeholder="选择标签"
            :options="tag.list"
            :props="{value:'tag_id', label: 'tag_name'}"
            v-model="select_tags_value"
            clearable
            @change="TagChangeAction"
          ></el-cascader>
          <el-input class="input-m" placeholder="联系人手机号" v-model="params.mobile">
            <el-button slot="append" icon="el-icon-search" @click="numberSearch"></el-button>
          </el-input>-->

                    <el-button icon="el-icon-search" @click="numberSearch" size="medium" type="primary" class="mt10">搜索</el-button>
                    <el-button icon="el-icon-refresh-right" @click="clearSearch" size="medium" type="primary" class="mt10">重置</el-button>
                    <el-button type="text" style="margin-left: 12px;" @click="panelCollapse(true)">
                      <span>更多筛选项>></span>
                    </el-button>
                  </el-col>
                </el-row>
                <el-row v-if="dynamicTags?.length > 0">
                  <el-col>
                    其他筛选条件：
                    <el-tag class="ipt-tag" :key="index + 'index'" v-for="(tag, index) in dynamicTags" closable
                      :disable-transitions="false" @close="handleEmpty(tag)">
                      {{ tag.label ? tag.label + ':' + tag.value : tag.value }}
                    </el-tag>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col style="display: flex;flex-wrap: wrap;">
                    <span v-if="login_type !== 'distributor' && login_type !== 'dealer' && login_type !== 'admin'">
                      <el-button-group v-if="!is_distributor">
                        <!--            <el-button type="primary" @click="addDistributorSelf()" v-if="!distributor_self">新增总部自提点</el-button>-->
                        <!--            <el-button type="primary" @click="editDistributorSelf()" v-else>编辑总部自提点</el-button>-->
                        <el-button type="primary" v-if="system_mode === 'platform'" @click="addDistributorTag">打标签</el-button>
                      </el-button-group>

                      <el-button-group class="top-button-group">
                        <el-button v-if="!is_distributor" type="primary" size="medium" icon="el-icon-circle-plus" @click="dialogOpen()">添加店铺</el-button>
                        <ImportDialog v-if="this.operator_type != 'distributor' && this.operator_type != 'dealer'" buttonName="导入店铺"
                         fileName="导入店铺模版" fileType="upload_distributor"></ImportDialog>
                      </el-button-group>

                      <el-button-group class="top-button-group">
                        <ImportDialog
                          v-if="this.operator_type != 'distributor' && this.operator_type != 'dealer'"
                          buttonName="导入顺丰配置"
                          fileName="导入顺丰配置模版"
                          fileType="upload_distributor_sf_config"/>
                        <ImportDialog
                          v-if="this.operator_type != 'distributor' && this.operator_type != 'dealer'"
                          buttonName="导入更换经销商"
                          fileName="导入更换经销商模版"
                          fileType="upload_distributor_change_dealer"/>
                        <ImportDialog
                          v-if="this.operator_type != 'distributor' && this.operator_type != 'dealer'"
                          buttonName="导入维修范围"
                          fileName="导入维修范围模版"
                          fileType="upload_distributor_repairable"/>
                        <ImportDialog v-if="this.operator_type != 'distributor' && this.operator_type != 'dealer'"
                          buttonName="导入现场维修设置"
                          fileName="导入现场维修设置模版"
                          fileType="upload_distributor_field_repairable"/>
                      </el-button-group>

                      <!-- <el-button style="margin-left: 10px" v-if="!is_distributor" type="primary" icon="el-icon-s-tools"
                        @click="showSettingDistance()">店铺范围配置</el-button> -->
                      <!-- <el-button style="margin:0 3px" type="primary" size="medium" icon="el-icon-circle-plus"
                        @click="handleDistributorPreferential()">绑定店铺活动</el-button> -->

                    </span>
                    <el-button-group class="top-button-group">
                      <el-button v-if="login_type !== 'admin'" type="primary" size="medium" @click="exportData" v-loading="exportloading"
                        >导出门店</el-button>
                      <el-button v-if="login_type !== 'admin'" type="primary" size="medium" @click="exportRepairableData"
                        v-loading="exportloading">导出售后门店</el-button>
                    </el-button-group>
                    <el-popover v-if="login_type !== 'admin'" placement="top-start" width="200" trigger="hover"
                      content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
                      <i class="el-icon-question" slot="reference" style="margin-top: 10px;"></i>
                    </el-popover>
                    <!--          <span>
                      <el-button v-if="login_type !== 'admin'" type="primary" @click="exportData" v-loading="exportloading" style="margin-left: 10px">获取教育配置</el-button>
                      <el-popover v-if="login_type !== 'admin'"
                        placement="top-start"
                        width="200"
                        trigger="hover"
                        content="获取当前页教育优惠配置并自动保存">
                        <i class="el-icon-question" slot="reference"></i>
                      </el-popover>
                    </span>-->
                  </el-col>
                </el-row>
                <el-tabs v-model="params.is_valid" @tab-click="handleClick">
                  <el-tab-pane v-for="item of isValidList" :key="item.value" :label="item.name" :name="item.value"></el-tab-pane>
                  <el-table :data="list" @selection-change="handleSelectionChange" v-loading="loading" element-loading-text="处理中。。。"
                    row-key="distributor_id">
                    <!-- <el-table-column type="selection" align="center" label="全选" :reserve-selection="true"></el-table-column> -->
                    <el-table-column width="50" prop="distributor_id" label="ID"></el-table-column>
                    <el-table-column label="门店" minWidth="150px">
                      <template slot-scope="scope">
                        <div class="store-name">{{ scope.row.name }}</div>
                        <div class="store-contact">
                          <!--                <span v-if="scope.row.contact">
                  <i class="el-icon-user"></i>
                  {{scope.row.contact}}
                </span>
                <span>
                  <i class="el-icon-mobile"></i>
                  {{scope.row.mobile}}
                </span>-->
                          <span v-if="scope.row.shop_code">
                            <i class="el-icon-s-shop"></i>
                            {{ scope.row.shop_code }}
                          </span>
                        </div>
                        <div v-if="scope.row.store_address" class="store-address">
                          <i class="el-icon-place"></i>
                          {{ scope.row.store_address }}
                        </div>
                        <el-tag v-if="scope.row.shop_type === 'monopolized'" type="success" size="mini">授权专营店</el-tag>
                        <el-tag v-if="scope.row.shop_type === 'arrondi'" type="warning" size="mini">授权专区</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column width="150" label="所属经销商" minWidth="150px">
                      <template slot-scope="scope">
                        <p>{{ scope.row.dealer_name }}</p>
                        <p v-if="scope.row.dealer_hqid">HQID：{{ scope.row.dealer_hqid }}</p>
                      </template>
                    </el-table-column>
                    <el-table-column label="组织信息" minWidth="150px">
                      <template slot-scope="scope">
                        <div>分销商VAD：{{ scope.row.distributor_vad }}</div>
                        <div>渠道RTM：{{ scope.row.channel_rtm }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column minWidth="110px" label="启用平台标签">
                      <template slot-scope="scope">
                        <el-tag v-if="scope.row.show_wxapp === '1'" type="primary" size="mini">微信</el-tag>
                        <br/>
                        <el-tag v-if="scope.row.show_byte_mini === '1'" type="primary" size="mini">抖音</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column minWidth="150px" v-if="login_type !== 'distributor' && login_type !== 'dealer' && login_type !== 'admin'" label="门店属性标签">
                      <template slot-scope="scope">
                        <div class="shop-prop-tag">
                          <!-- <div v-if="scope.row.examine_status == 1">
                            <el-button type="text" @click="handleSubmitExamine(scope.row)">待提交审核</el-button>
                          </div> -->
                          <div v-if="scope.row.examine_status == 2">
                            <el-button type="text" @click="handleSubmitExamine(scope.row)">待审核</el-button>
                          </div>
                          <div v-if="scope.row.examine_status == 3">
                            <el-button type="text" @click="handleSubmitExamine(scope.row)">审核未通过</el-button>
                          </div>
                          <div v-if="scope.row.examine_status == 4">
                            <el-button type="text" @click="handleSubmitExamine(scope.row)">审核记录</el-button>
                          </div>
                          <div v-if="scope.row.is_open_substitute_delivery === '1'">
                            <el-tag type="primary" size="mini">代发货</el-tag>
                          </div>
                          <div v-if="scope.row.sf_call_transfer === '1'">
                            <el-tag type="warning" size="mini">呼叫转移</el-tag>
                          </div>
                          <div v-if="scope.row.is_delivery == true">
                            <el-tag type="success" size="mini">支持快递</el-tag>
                          </div>
                          <div v-if="scope.row.urgent_delivery == true">
                            <el-tag type="success" size="mini">支持小时达</el-tag>
                          </div>
                          <div v-if="scope.row.is_old == true">
                            <el-tag type="primary" size="mini">以旧换新</el-tag>
                          </div>
                          <div v-if="scope.row.auto_receiving == true">
                            <el-tag type="warning" size="mini">自动接单</el-tag>
                          </div>
                          <div v-if="scope.row.auto_call_delivery == true">
                            <el-tag type="warning" size="mini">自动呼叫快递</el-tag>
                          </div>
                          <!-- <div v-if="scope.row.support_scan_invoice == 1">
                            <el-tag type="info" size="mini">电子发票</el-tag>
                          </div> -->
                          <div v-if="scope.row.is_repair_asp == 1">
                            <el-tag type="info" size="mini">ASP门店</el-tag>
                          </div>
                          <div v-if="scope.row.open_edu == true">
                            <el-tag type="info" size="mini">教育优惠</el-tag>
                          </div>
                          <div v-if="scope.row.support_repair == true">
                            <el-tag type="info" size="mini">维修</el-tag>
                          </div>
                          <div v-if="scope.row.open_fq_pay == '1'">
                            <el-tag type="primary" size="mini">分期付款</el-tag>
                          </div>
                          <div v-if="scope.row.support_electronic_invoice == '1'">
                            <el-tag type="primary" size="mini">电子发票</el-tag>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <!--          <el-table-column width="80" label="是否分账" v-if="isOpen">
            <template slot-scope="scope" v-if="scope.row.is_valid !== 'delete'">
              <el-switch
                v-model="scope.row.is_open"
                active-color="#13ce66"
                inactive-color="#cccccc"
                active-value="true"
                inactive-value="false"
                @change="switchChangeOpen(scope.$index, scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column width="80" label="是否为总部库存" v-if="system_mode === 'standard'">
            <template slot-scope="scope" v-if="scope.row.is_valid !== 'delete'">
              <el-switch

                v-model="scope.row.auto_sync_goods"
                active-color="#13ce66"
                inactive-color="#cccccc"
                @change="switchChangeAutoSyncGoods(scope.$index, scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
           <el-table-column label="审核商品">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_audit_goods"  active-color="#13ce66" inactive-color="#cccccc" @change="switchChangeAuditGoods(scope.$index, scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column width="80" label="是否自提">
            <template slot-scope="scope" v-if="scope.row.is_valid !== 'delete'">
              <el-tooltip
                v-if="!scope.row.lng && !scope.row.lat"
                effect="dark"
                content="请先设置店铺坐标"
                placement="top-start"
              >
                <el-switch
                  disabled
                  v-model="scope.row.is_ziti"
                  active-color="#13ce66"
                  inactive-color="#cccccc"
                ></el-switch>
              </el-tooltip>
              <el-switch
                v-else
                v-model="scope.row.is_ziti"
                active-color="#13ce66"
                inactive-color="#cccccc"
                @change="switchChange(scope.$index, scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column width="80" label="快递配送">
            <template slot-scope="scope" v-if="scope.row.is_valid !== 'delete'">
              <el-switch
                v-model="scope.row.is_delivery"
                active-color="#13ce66"
                inactive-color="#cccccc"
                @change="switchChange(scope.$index, scope.row)"
              ></el-switch>
            </template>
          </el-table-column>-->
                    <el-table-column minWidth="100px" label="美团配置">
                      <template slot-scope="scope">
                        <el-button @click="onDetail(scope.row)"
                          v-if="scope.row.scope && scope.row.delivery_service_code" type="warning"
                          size="mini" plain>已配置</el-button>
                      </template>
                    </el-table-column>
                    <el-table-column minWidth="100px" v-if="login_type !== 'distributor' && login_type !== 'dealer' && login_type !== 'admin'" label="门店状态">
                      <template slot-scope="scope">
                        <el-button @click="editValid(scope.row)" v-if="scope.row.is_valid !== 'delete'" type="text">
                          <div v-if="scope.row.is_valid == 'true'">
                            <span class="green">
                              启用<i class="el-icon-s-tools"></i>
                            </span>
                          </div>
                          <div v-else>
                            <span class="red">
                              禁用
                              <i class="el-icon-s-tools"></i>
                            </span>
                          </div>
                        </el-button>
                        <span v-else class="muted">废弃</span>
                      </template>
                    </el-table-column>
                    <el-table-column minWidth="100px" v-if="login_type === 'disti' || login_type === 'dealer'" label="分销状态">
                      <template slot-scope="scope">
                        <el-button @click="editDistribution(scope.row)" type="text">
                          <div v-if="scope.row.is_op_distribution == 1 || scope.row.is_op_distribution == true">
                            <span class="green">
                              开启<i class="el-icon-open"></i>
                            </span>
                          </div>
                          <div v-else>
                            <span class="red">
                              关闭
                              <i class="el-icon-open"></i>
                            </span>
                          </div>
                        </el-button>
                      </template>
                    </el-table-column>
                    <!--          <el-table-column width="80" label="是否默认">
            <template slot-scope="scope" v-if="scope.row.is_valid !== 'delete'">
              <el-tooltip effect="dark" content="请先启用店铺" placement="top-start">
                <el-switch
                  v-model="scope.row.is_default"
                  active-color="#13ce66"
                  inactive-color="#cccccc"
                  :disabled="(scope.row.is_default || scope.row.is_valid != 'true') ? true : false"
                  @change="defaultSwitchChange(scope.row)"
                ></el-switch>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column v-if="system_mode === 'platform'" prop="tagList" label="标签">
            <template slot-scope="scope">
              <el-tag
                v-for="taglist in scope.row.tagList"
                :key="taglist.index"
                :color="taglist.tag_color"
                size="mini"
                :style="'color:'+ taglist.font_color"
                style="display: inline-block; margin-right:3px;"
              >{{taglist.tag_name}}</el-tag>
            </template>
          </el-table-column>-->
                    <el-table-column v-if="login_type !== 'distributor' && login_type !== 'dealer' && login_type !== 'admin'"
                      width="200" label="操作">
                      <template slot-scope="scope">
                        <el-button type="text" @click="getSFSetting(scope.row)">顺丰配置</el-button>
                        <el-button type="text" @click="getEduSetting(scope.row)">教育优惠配置</el-button>
                        <router-link
                          v-if="scope.row.is_valid !== 'delete'"
                          :to="{ path: matchHidePage('editor'), query: { distributor_id: scope.row.distributor_id } }">
                          编辑
                        </router-link>
                        <!-- <el-button type="text" @click="linkTemplates(scope.row)">店铺装修</el-button> -->
                        <!--               <el-button v-if="system_mode == 'platform'" type="text" @click="dialogShow(scope.row.distributor_id)">店铺装修</el-button>
              <el-button
                v-if="system_mode === 'platform'"
                type="text"
                @click="dialogShow(scope.row.distributor_id,'pc')"
                style="margin-left:0px;margin-top:5px;"
              >PC店铺装修</el-button>
              <el-button type="text" @click="showSettingMeiQia(scope.row.distributor_id)">客服</el-button>-->

                        <!--              <el-button type="text" @click="downDistributor(scope.row, 'scancode')">扫码购页面码(微商城)</el-button>
               <router-link :to="{  path: matchInternalRoute('Storeshopitemanagement'), query: {distributor_id: scope.row.distributor_id}}">商品码</router-link>
              router-link :to="{ path: matchHidePage('detail'), query: { distributor_id: scope.row.distributor_id, distributor_name: scope.row.name,parentPath: '/mall/marketing/distributor'}}">商品码</router-link-->

                        <el-dropdown @command="(command) => handleCommand(command, scope.row)">
                          <span class="el-dropdown-link">
                            更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="downStoreCd">下载店铺码</el-dropdown-item>
                            <el-dropdown-item  v-clipboard:copy="scope.row.link" v-clipboard:success="onCopy">复制店铺链接</el-dropdown-item>
                            <!-- <el-dropdown-item command="act">参与活动</el-dropdown-item> -->
                            <el-dropdown-item v-if="system_mode !== 'platform'" command="shopgoods">店铺商品</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="content-padded content-center">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                      :current-page="params.page" :page-sizes="[2, 10, 20, 50, 100]" :page-size="params.pageSize"
                      layout="total, sizes, prev, pager, next, jumper" :total="total_count"></el-pagination>
                  </div>
                </el-tabs>
                <!--      </el-card>-->
                <el-dialog title="下载店铺码" :visible.sync="downDistributorVal" :close-on-click-modal="false">
                  <!--
        <el-row v-if="login_type == 'disti'">
          <el-col :span="6">
            <el-button type="text" @click="downDistributorCodeUtm({way: 'offline', source_type: 'distributor'})">线下体验码（utm）</el-button>
          </el-col>
          <el-col :span="6">
            <el-button type="text" @click="downDistributorCodeUtm({way: 'online', source_type: 'distributor'})">线上体验码（utm）</el-button>
          </el-col>
        </el-row>
 -->
                  <el-row>
                    <el-col :span="6">
                      <el-button type="text"
                        @click="downDistributorCodeUtmOrder({ way: 'offline', source_type: 'distributor' })">订单中心-线下体验码（utm）</el-button>
                    </el-col>
                    <el-col :span="6">
                      <el-button type="text"
                        @click="downDistributorCodeUtmOrder({ way: 'online', source_type: 'distributor' })">订单中心-线上体验码（utm）</el-button>
                    </el-col>
                  </el-row>



                  <!-- <el-row>
                    <el-col :span="6">
                      <el-button type="text" @click="downDistributorCode">微商城小程序</el-button>
                    </el-col>
                  </el-row> -->
                  <!--
        <el-row v-if="login_type == 'disti'">
          <el-col :span="6">
            <el-button type="text" @click="downDistributorCode({way: 'offline', source_type: 'distributor'})">线下体验码</el-button>
          </el-col>
          <el-col :span="6">
            <el-button type="text" @click="downDistributorCode({way: 'online', source_type: 'distributor'})">线上体验码</el-button>
          </el-col>
        </el-row>
 -->

                </el-dialog>
                <el-dialog :visible.sync="dialogVisible" width="80%" title="编辑店铺首页" fullscreen lock-scroll>
                  <shopDecoration :id="current" @saved="closeDialog" />
                </el-dialog>
                <el-dialog :visible.sync="pcDialogVisible" width="80%" title="编辑PC店铺首页" fullscreen lock-scroll>
                  <pc-decoration v-if="pcDialogVisible" :id="current" @saved="closeDialog" usage="store" />
                </el-dialog>

                <el-dialog title="为商品打标签" class="right-dialog" :visible.sync="tag.dialog" :before-close="handleCancelLabelsDialog">
                  <div class="tag-users view-flex view-flex-middle">
                    <i class="iconfont icon-user-circle1"></i>
                    <div class="view-flex-item">
                      <span v-for="item in tag.editItem">{{ item }}，</span>
                    </div>
                  </div>
                  <div class="selected-tags view-flex">
                    <div class="label">已选中标签：</div>
                    <div class="view-flex-item">
                      <el-tag :key="index" v-for="(tag, index) in tag.currentTags" closable size="small"
                        :disable-transitions="false" @close="tagRemove(index)">{{ tag.tag_name }}</el-tag>
                    </div>
                  </div>
                  <div>
                    <el-tag class="tag-item" :key="index" v-for="(tag, index) in tag.tags" size="medium" color="#ffffff"
                      :disable-transitions="false" @click.native="tagAdd(tag, index)">{{ tag.tag_name }}</el-tag>
                  </div>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="tag.dialog = false">取 消</el-button>
                    <el-button type="primary" @click="submitItemTag">确 定</el-button>
                  </span>
                </el-dialog>
                <el-dialog title="修改状态" width="30%" :visible.sync="editValidDialog" :before-close="handleCancelLabelsDialog"
                  :open="is_valid_updated = false">
                  <template>
                    <el-radio-group v-model="editValidData.is_valid">
                      <el-radio label="true"
                        v-if="editValidData.examine_status == 4 || editValidData.examine_status == 0">启用</el-radio>
                      <el-radio label="false">禁用</el-radio>
                      <el-radio label="delete">废弃</el-radio>
                      <!-- <el-radio label="audit" v-if="login_type == 'disti' && (editValidData.examine_status == 0 || editValidData.examine_status == 4)">下架</el-radio> -->
                    </el-radio-group>
                  </template>
                </el-dialog>
                <el-dialog title="修改分销状态" width="30%" :visible.sync="editDisDialog" :before-close="handleCancelLabelsDialogDis"
                  :open="is_dis_updated = false">
                  <template>
                    <el-radio-group v-model="editDisData.is_op_distribution">
                      <el-radio label="1">开启</el-radio>
                      <el-radio label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                </el-dialog>
                <!-- 添加、编辑标识-开始 -->
                <el-dialog title="设置美洽参数" width="50%" :visible.sync="setMeiQiaVisible" :before-close="handleMeiQiaCancel">
                  <template>
                    <el-form ref="meiqia_form" :model="meiqia_form" class="demo-ruleForm" label-width="90px">
                      <el-form-item label="企业ID">
                        <el-col :span="14">
                          <el-input v-model="meiqia_form.meiqia_id" :maxlength="30" placeholder="企业ID"></el-input>
                        </el-col>
                      </el-form-item>
                      <el-form-item label="客服token">
                        <el-col :span="14">
                          <el-input v-model="meiqia_form.meiqia_token" :maxlength="50" placeholder="toekn"></el-input>
                        </el-col>
                      </el-form-item>
                    </el-form>
                  </template>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click.native="handleMeiQiaCancel">取消</el-button>
                    <el-button type="primary" @click="handleSubmitMeiQia">保存</el-button>
                  </div>
                </el-dialog>
                <!-- 添加、编辑基础物料-结束 -->

                <!-- 编辑距离-开始 -->
                <el-dialog title="店铺范围配置" width="50%" :visible.sync="setDistanceVisible" :before-close="handleDistanceCancel">
                  <template>
                    <el-form ref="distanceForm" :model="distanceForm" class="demo-ruleForm" label-width="90px">
                      <el-form-item label="距离">
                        <el-input min="1" type="number" oninput="value=value.replace(/[^\d.]/g,'')"
                          v-model.number="distanceForm.distance" placeholder="只能是大于0的数字" style="width: 30%;"></el-input>&nbsp;km
                      </el-form-item>
                    </el-form>
                  </template>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click.native="handleDistanceCancel">取消</el-button>
                    <el-button type="primary" @click="handleSubmitDistance">保存</el-button>
                  </div>
                </el-dialog>
                <!-- 编辑距离-结束 -->

                <!-- 绑定优惠活动 开始 -->
                <bind-preferential :visible="bindPreferentialVisible" :distributor_id="selectPreferentialDistributorId"
                  @cancel="handlePreferentialCancel"></bind-preferential>
                <!-- 绑定优惠活动 结束 -->

                <!-- 所有优惠活动列表 开始 -->
                <el-dialog title="绑定优惠活动" width="80%" :visible.sync="preferentialVisible"
                  :before-close="handleAllPreferentialCancel">
                  <template>
                    <el-table :data="preferentials" v-loading="preferentialLoading" row-key="id"
                      @selection-change="handlePreferentialSelectionChange" ref="multipleTable" style="width: 100%">
                      <el-table-column type="selection" align="center" label="全选" :reserve-selection="true"
                        width="50"></el-table-column>
                      <el-table-column prop="id" label="ID" width="80">
                      </el-table-column>
                      <el-table-column prop="activity_title" label="活动标题">
                      </el-table-column>
                      <el-table-column prop="start_time" label="开始时间" width="180">
                      </el-table-column>
                      <el-table-column prop="end_time" label="结束时间" width="180">
                      </el-table-column>
                    </el-table>
                    <el-pagination background @current-change="handleAllPreferentialPageChange"
                      :current-page="allPreferentialPageNation.page" :page-size="allPreferentialPageNation.page_size"
                      layout="total, prev, pager, next" :total="allPreferentialPageNation.total"></el-pagination>
                  </template>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click.native="handleAllPreferentialCancel">取消</el-button>
                    <el-button type="primary" @click="setDistributorPreferential">保存</el-button>
                  </div>
                </el-dialog>
                <!-- 绑定优惠活动 结束 -->

                <!-- 审核记录 开始 -->
                <el-dialog title="审核记录" :visible.sync="examineDialogVisible" @close="getList" width="60%">
                  <el-table v-loading="examineTableLoading" :data="logData" style="width: 100%">
                    <el-table-column prop="id" label="ID" width="100">
                    </el-table-column>
                    <el-table-column prop="examine_result" width="120" label="审核状态">
                      <template slot-scope="scope">
                        <el-tag effect="dark" v-if="scope.row.examine_result == 2">待审核</el-tag>
                        <el-tag effect="dark" type="warning" v-if="scope.row.examine_result == 3">审核未通过</el-tag>
                        <el-tag effect="dark" type="success" v-if="scope.row.examine_result == 4">审核已通过</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column prop="examine_remark" label="审核备注">
                    </el-table-column>
                    <el-table-column prop="created_at" label="提交时间" width="170">
                    </el-table-column>
                    <el-table-column prop="examine_time" label="审核时间" width="170">
                    </el-table-column>
                    <el-table-column v-if="login_type == 'disti'" label="操作" width="170">
                      <template slot-scope="scope">
                        <div v-if="scope.row.examine_result == 2">
                          <el-button type="text" @click="handleExamine(scope.row)">审核</el-button>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="block">
                    <el-pagination layout="prev, pager, next" @current-change="handleExamineLogCurrentChange"
                      :total="log_total_count">
                    </el-pagination>
                  </div>
                  <span slot="footer" class="dialog-footer">
                    <el-button :loading="submitExamineBtnLoading" type="primary"
                      v-if="(login_type != 'disti' && login_type != 'admin' && login_type !== 'staff') && (rowdata.examine_status == 1 || rowdata.examine_status == 3)"
                      @click="submitExamine()">提交审核</el-button>
                    <el-button @click="examineDialogVisible = false">关 闭</el-button>
                  </span>
                </el-dialog>
                <!-- 审核记录 结束 -->

                <!-- 审核操作 开始 -->
                <el-dialog title="审核模版" :visible.sync="examineDoVisible" :before-close="handleCloseExamineDialog" width="30%">
                  <el-form ref="form" :model="examineForm" label-width="100px">
                    <el-form-item label="审核结果">
                      <div>
                        <el-radio v-model="examineForm.examine_result" label="4" value="4" size="mini">审核通过</el-radio>
                        <el-radio v-model="examineForm.examine_result" label="3" value="3" size="mini">审核不通过</el-radio>
                      </div>
                    </el-form-item>
                    <el-form-item label="备注">
                      <el-input heigh="200" type="textarea" placeholder="请输入内容" v-model="examineForm.remark" maxlength="200"
                        show-word-limit :autosize="{ minRows: 4, maxRows: 10 }">
                      </el-input>
                    </el-form-item>
                  </el-form>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="handleCloseExamineDialog">取 消</el-button>
                    <el-button type="primary" @click="examineDistributor" :loading="btnLoading">确 定</el-button>
                  </span>
                </el-dialog>
                <!-- 审核操作 结束 -->

              </div>
              <router-view></router-view>
              <el-drawer :title="title" :visible.sync="dialog" direction="rtl" custom-class="demo-drawer" ref="drawer" size="620px">
                <div class="demo-drawer__content">
                  <el-form ref="SFForm" :model="SFForm" label-width="150px" :rules="rules">
                    <el-form-item label="项目编码" prop='project_code'>
                      <el-input placeholder="请输入项目编码" v-model="SFForm.project_code" style="width:400px" />
                    </el-form-item>
                    <el-form-item label="客户编码" prop="customer_code">
                      <el-input placeholder="请输入客户编码" v-model="SFForm.customer_code" style="width:400px" />
                      <br />
                    </el-form-item>
                    <el-form-item label="站点编码" prop="start_station_code">
                      <el-input placeholder="请输入站点编码" v-model="SFForm.start_station_code" style="width:400px" />
                    </el-form-item>
                    <el-form-item label="是否代发货点" prop="is_consignment">
                      <el-switch v-model="SFForm.is_open_substitute_delivery" active-color="#13ce66" inactive-color="#ff4949"
                        active-value="1" inactive-value="0">
                      </el-switch>
                    </el-form-item>
                    <el-form-item label="开启呼叫转移" prop="sf_call_transfer">
                      <el-switch v-model="SFForm.sf_call_transfer" active-color="#13ce66" inactive-color="#ff4949" active-value="1"
                        inactive-value="0">
                      </el-switch>
                    </el-form-item>
                    <el-form-item label="备用发货地" prop="">
                      <el-button @click="selectDeliverAlternatePlace" type="primary" size="mini" style="margin-bottom: 10px">
                        选择
                      </el-button>
                      <el-card style="margin-right: 20px; width: 400px"
                        v-if="this.alternatePlaceInfo !== undefined && JSON.stringify(this.alternatePlaceInfo) !== '{}'">
                        <div>
                          编码：{{ this.alternatePlaceInfo.code }}
                        </div>
                        <div>
                          名称：{{ this.alternatePlaceInfo.name }}
                        </div>
                        <div>
                          所属经销商：{{ this.alternatePlaceInfo.dealer_name }}
                        </div>
                        <div>
                          地址：{{ this.alternatePlaceInfo.address }}
                        </div>
                      </el-card>
                    </el-form-item>
                    <div class="section-footer">
                      <el-form-item>
                        <el-button type="primary" v-loading="loadingSF" @click="saveSFSetting('SFForm')">保存</el-button>
                      </el-form-item>
                    </div>
                  </el-form>
                </div>
              </el-drawer>

              <el-dialog title="选择备用发货地" :visible.sync="alternatePlaceDialog" width="60%">
                <div>
                  <el-input class="input-m" v-model="sf_alternate_params.name" clearable @change="getAlternatePlaceList"
                    placeholder="请输入名称">
                  </el-input>
                  <el-input v-if="alternatePlaceType === 'distributor'" class="input-m" v-model="sf_alternate_params.shop_code"
                    clearable @change="getAlternatePlaceList" placeholder="请输入店铺AppleID">
                  </el-input>
                  <el-input v-if="alternatePlaceType === 'temporaryDeliveryPlace'" clearable @change="getAlternatePlaceList"
                    class="input-m" v-model="sf_alternate_params.code" placeholder="请输入发货点编码">
                  </el-input>
                </div>
                <el-tabs v-model="alternatePlaceType" @tab-click="changeAlternatePlaceType">
                  <el-tab-pane label="店铺" name="distributor"></el-tab-pane>
                  <el-tab-pane label="临时发货点" name="temporaryDeliveryPlace"></el-tab-pane>
                </el-tabs>
                <el-table v-if="alternatePlaceType === 'distributor'" :data="alternatePlaceList"
                  v-loading="alternatePlaceTableLoading" highlight-current-row @current-change="handleAlternatePlaceChange">
                  <el-table-column label="ID" prop="distributor_id" width="70px"></el-table-column>
                  <el-table-column label="店铺名称" prop="name"></el-table-column>
                  <el-table-column label="AppleID" prop="shop_code"></el-table-column>
                  <el-table-column label="地址" prop="name">
                    <template slot-scope="scope">
                      {{ scope.row.address }}
                    </template>
                  </el-table-column>
                </el-table>

                <el-table v-if="alternatePlaceType === 'temporaryDeliveryPlace'" :data="alternatePlaceList"
                  v-loading="alternatePlaceTableLoading" highlight-current-row @current-change="handleAlternatePlaceChange">
                  <el-table-column label="ID" prop="id" width="70px"></el-table-column>
                  <el-table-column label="发货点名称" prop="name"></el-table-column>
                  <el-table-column label="发货点编号" prop="code"></el-table-column>
                  <el-table-column label="地址" prop="name">
                    <template slot-scope="scope">
                      {{ scope.row.address }}
                    </template>
                  </el-table-column>
                </el-table>

                <el-pagination background layout="total, prev, pager, next, jumper" @current-change="getAlternatePlaceList"
                  :current-page.sync="alternatePlacePageNation.page" :page-size="alternatePlacePageNation.page_size"
                  :total="alternatePlacePageNation.total_count">
                </el-pagination>

                <span slot="footer" class="dialog-footer">
                  <el-button @click="alternatePlaceDialog = false">取 消</el-button>
                  <el-button type="primary" @click="selectAlternatePlace">确 定</el-button>
                </span>
              </el-dialog>

              <el-drawer :visible.sync="edu_dialog" direction="rtl" custom-class="demo-drawer" ref="drawer" size="40%">
                <div class="demo-drawer__content">
                  <el-form ref="edu_form" :model="edu_form" label-width="200px" :rules="edu_rules" v-loading="edu_dialog_loading">
                    <el-form-item label="appid" prop='appid'>
                      <el-input placeholder="请输入appid" v-model="edu_form.appid" style="width:450px" />
                    </el-form-item>
                    <el-form-item label="secret" prop="secret">
                      <el-input placeholder="请输入secret" v-model="edu_form.secret" style="width:450px" />
                      <br />
                    </el-form-item>
                    <div class="section-footer">
                      <el-form-item>
                        <el-button type="primary" :loading="loading_edu_config" @click="getDistributorEduSecurity">获取</el-button>
                        <el-button type="primary" :loading="loading_edu" @click="saveEduSetting('edu_form')">保存</el-button>
                      </el-form-item>
                    </div>
                  </el-form>
                </div>
              </el-drawer>

              <el-dialog title="查看美团配置" :visible.sync="dialogScope" @close="closeDialogScope">

                <el-row>
                  <el-col :span="4">
                    <span>美团服务code</span>
                  </el-col>
                  <el-col :span="4">
                    <span>{{ mtInfo.delivery_service_code }}</span>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="4">
                    <span>电子围栏</span>
                  </el-col>
                  <el-col :span="6">
                    <div v-for="(item, index) in mtInfo.scope" :key="'scope' + index">
                      <span>{{ item.x }},{{ item.y }}</span>
                    </div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="4">
                    <span>围栏地图</span>
                  </el-col>
                  <el-col :span="20">
                    <div id="qqmap_tools"></div>
                  </el-col>
                </el-row>
              </el-dialog>
              <el-drawer title="店铺筛选" :visible.sync="formDrawer" direction="rtl" :before-close="handleCloseDrawer" size="35%">
                <el-form class="drawer-list" label-position="left" ref="params" :model="params" label-width="150px">

                  <el-form-item label="配送方式">
                    <el-radio-group v-model="params.delivery_type" @input="numberSearch()">
                      <el-radio-button v-for="item in deliveryList" :label="item.value" :key="item.value">
                        {{ item.label }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="开启以旧换新">
                    <el-radio-group v-model="params.is_old" @input="numberSearch()">
                      <el-radio-button v-for="item in commonTypeList" :label="item.value" :key="item.value">
                        {{ item.label }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="开启教育优惠">
                    <el-radio-group v-model="params.open_edu" @input="numberSearch()">
                      <el-radio-button v-for="item in commonTypeList" :label="item.value" :key="item.value">
                        {{ item.label }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="开启售后维修">
                    <el-radio-group v-model="params.support_repair" @input="numberSearch()">
                      <el-radio-button v-for="item in commonTypeList" :label="item.value" :key="item.value">
                        {{ item.label }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="开启分期付款">
                    <el-radio-group v-model="params.open_fq_pay" @input="numberSearch()">
                      <el-radio-button v-for="item in commonTypeList" :label="item.value" :key="item.value">
                        {{ item.label }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="开启电子发票">
                    <el-radio-group v-model="params.support_electronic_invoice" @input="numberSearch()">
                      <el-radio-button v-for="item in commonTypeList" :label="item.value" :key="item.value">
                        {{ item.label }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="门店维修类型">
                    <el-radio-group v-model="params.is_repair_asp" @input="numberSearch()">
                      <el-radio-button v-for="item in supportList" :label="item.value" :key="item.value">
                        {{ item.label }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="门店美团配置">
                    <el-radio-group v-model="params.mt_config_status" @input="numberSearch()">
                      <el-radio-button v-for="item in mtconfigList" :label="item.value" :key="item.value">
                        {{ item.label }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item label="展示平台">
                    <el-radio-group v-model="params.platform" @input="numberSearch()">
                      <el-radio-button v-for="item in platList" :label="item.value" :key="item.value">
                        {{ item.label }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>

                </el-form>

                <div class="confirm-drawer">
                  <el-button type="primary" @click="confirmDrawer">确认</el-button>
                </div>
                </el-drawer>

              <DealerSelect :store-visible="DealerVisible" :is-valid="isValid" :rel-data-ids="relDealers"
                :get-status="DealerStatus" @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogAction"
                ></DealerSelect>
            </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { saveDistributor, getDistributorList, getWxaDristributorCodeStream, setDefaultDistributor, saveOpen, saveSFConfig, getEduConfig, saveEduConfig, getTemporaryDeliveryPlaceList, getDistributorEduSecurity } from '../../../api/marketing'
import { getTagList, distributorRelTags, getDistance, setDistance, getDistributorRelPreferential, setDistributorRelPreferential, getExamineDistributorList, submitExamineDistributor, examineDistributor } from '@/api/marketing'
import { getActivityList } from '@/api/promotions'
import { getDistributorMeiQia, setDistributorMeiQia } from '@/api/im'
import district from '../../../common/district.json'
import shopDecoration from '@/components/function/shopDecoration'
import pcDecoration from '@/view/pc/homePage/default'
import { getSetting } from '@/api/fenzhang'
import ImportDialog from '@/components/importDialog'
import BindPreferential from './bind_preferential'
import {getDeliverySettingList} from '@/api/goods'
import DealerSelect from '@/components/function/dealerSelect'

import store from '@/store'
// 取选中地区的值
function getCascaderObj(val, opt) {
  return val.map(function (value, index, array) {
    for (var itm of opt) {
      if (itm.value === value) {
        opt = itm.children
        return itm
      }
    }
    return null
  })
}

export default {
  components: { shopDecoration, pcDecoration, ImportDialog, BindPreferential, DealerSelect },
  data() {
    return {
      alternatePlaceDialog: false,
      alternatePlaceType: 'distributor',
      alternatePlaceTableLoading: false,
      selectAlternatePlaceRow: {},
      alternatePlaceList: [],
      alternatePlaceInfo: {},
      alternatePlacePageNation: {
        total_count: 0,
        page: 1,
        page_size: 20,
      },
      dis_type: [
        {
          value: 'urgent_delivery',
          label: '支持小时达'
        }, {
          value: 'is_old',
          label: '以旧换新'
        }, {
          value: 'is_delivery',
          label: '支持快递'
        }
      ],
      examineDoVisible: false,
      examineDialogVisible: false,
      examineTableLoading: false,
      submitExamineBtnLoading: false,
      lzLoading: false,
      btnLoading: false,
      logData: [],
      examineForm: {
        log_id: 0,
        distributor_id: 0,
        examine_result: "",
        remark: ""
      },
      pageParams: {
        page: 1,
        pageSize: 20
      },
      is_distributor: false,
      dialogVisible: false,
      exportloading: false,
      current: '', // 当前店铺id
      isValidList: [
        { name: '全部', value: '' },
        { name: '启用', value: 'true' },
        { name: '禁用', value: 'false' },
        { name: '废弃', value: 'delete' },
        // { name: '下架整改', value: 'audit' },
      ],
      changeStatus: true,
      activeName: 'first',
      total_count: 0,
      log_total_count: 0,
      distributor_self: '',
      detailDialog: false,
      pcDialogVisible: false,
      bindPreferentialVisible: false,
      bindPreferentialLoading: false,
      selectPreferentialDistributorId: 0,
      dialogTitle: '',
      select_tags_value: '',
      loading: false,
      params: {
        page: 1,
        pageSize: 20,
        name: '',
        mobile: '',
        is_valid: '',
        is_op_distribution: '',
        province: '',
        city: '',
        area: '',
        dealer_name: '',
        dealer_hqid: '',
        distributor_vad: '',
        channel_rtm: '',
        support_scan_invoice: '',
        dis_type: '',
        select_status: '',
        platform: '',
        delivery_type: '',
        mt_config_status: '',
        is_repair_asp: '',
        is_old: '',
        open_edu: '',
        support_repair: '',
        open_fq_pay: '',
        support_electronic_invoice: '',
        mt_config_status: '',
      },
      sf_alternate_params: {
        name: '',
        shop_code: '',
        code: '',
        is_valid: 'true',
        is_open_substitute_delivery: 1
      },
      form: {
        distributor_id: null,
        mobile: '',
        address: '',
        name: '',
        is_valid: 'true',
        is_op_distribution: 'false',
        regions_id: [],
        regions: [],
        shop_id: 0,
        contact: '',
        is_distributor: 'true',
        dealer_id: '',
      },
      list: [],
      distributorRelPreferential: [],
      preferentialPageNation: {
        page: 1,
        page_size: 20,
        total: 0
      },
      allPreferentialPageNation: {
        page: 1,
        page_size: 20,
        total: 0
      },
      regions: district,
      codetype: 'index',
      rowdata: {},
      downDistributorVal: false,
      distributor_id: [],
      tag: {
        dialog: false,
        editItem: [],
        list: [],
        form: {
          tag_ids: [],
          distributor_id: []
        },
        currentTags: [],
        tags: []
      },
      editValidDialog: false,
      editDisDialog: false,
      editValidData: {
        distributor_id: '',
        is_valid: ''
      },
      editDisData: {
        distributor_id: '',
        is_op_distribution: ''
      },
      setMeiQiaVisible: false,
      setMeiQiaDialog: false,
      meiqia_form: {
        distributor_id: '',
        meiqia_id: '',
        meiqia_token: ''
      },
      setDistanceVisible: false,
      setDistanceDialog: false,
      distanceForm: {
        distance: ''
      },
      isOpen: false,
      dialog: false,
      title: '',
      SFForm: {
        distributor_id: '',
        project_code: '',
        customer_code: '',
        start_station_code: '',
        is_open_substitute_delivery: '',
        sf_call_transfer: '',
        alternate_place_type: '',
        alternate_place_id: 0,
      },
      SFFormRes: {},
      rules: {
        distributor_id: [
          { required: true, message: '请检查店铺ID', trigger: 'blur' }
        ],
        project_code: [
          { required: true, message: '请输入 项目编码', trigger: 'blur' }
        ],
        customer_code: [
          { required: true, message: '请输入 客户编码', trigger: 'blur' }
        ],
        start_station_code: [
          { required: true, message: '请输入 站点编码', trigger: 'blur' }
        ],
      },
      loadingSF: false,
      edu_dialog: false,
      edu_dialog_loading: false,
      edu_form: {
        distributor_id: '',
        appid: '',
        secret: ''
      },
      edu_rules: {
        appid: [
          { required: true, message: '请输入appid', trigger: 'blur' }
        ],
        secret: [
          { required: true, message: '请输入secret', trigger: 'blur' }
        ]
      },
      loading_edu: false,
      loading_edu_config: false,

      preferentialVisible: false,
      preferentials: [],
      preferentialLoading: false,
      preferential_ids: [],
      operator_type: '',
      selectStatus: [],
      deliveryList: [
        { label: '不分', value: '' },
        { label: '小时达', value: 'urgent_delivery' },
        { label: '仅快递', value: 'only_delivery' },
        { label: '仅自提', value: 'only_ziti' },
      ],
      meiTuan: [
        { label: '不分', value: '' },
        { label: '已配置美团', value: '1' },
        { label: '未配置美团', value: '2' },
      ],
      supportList: [
        { label: '不分', value: '' },
        { label: '非ASP门店', value: '0' },
        { label: 'ASP门店', value: '1' },
      ],
      commonTypeList: [
        { label: '不分', value: '' },
        { label: '是', value: 'true' },
        { label: '否', value: 'false' }
      ],
      mtconfigList: [
        { label: '不分', value: '' },
        { label: '已配置', value: '1' },
        { label: '未配置', value: '2' }
      ],
      platList: [
        { label: '不分', value: '' },
        { label: '全平台', value: 'all' },
        { label: '微信', value: 'wxapp' },
        { label: '抖音', value: 'byte_mini' }
      ],
      dialogScope: false,
      mtInfo: {},
      mapInfo: null,
      editor: null,
      defaultDistance: 0,
      formDrawer: false,
      dynamicTags: [],
      areaData: [],
      DealerVisible: false,
      isValid: true,
      relDealers: [],
      DealerStatus: false,
      agency_num: '归属经销商',
    }
  },
  computed: {
    ...mapGetters(['wheight', 'login_type'])
  },
  watch: {
    'editValidData.is_valid': function (val, oldVal) {
      if (val && oldVal && !this.is_valid_updated) {
        this.editValidSubmit(val, oldVal)
      } else {
        this.is_valid_updated = false
      }
    },
    'editDisData.is_op_distribution': function (val, oldVal) {
      if (val && oldVal && !this.is_dis_updated) {
        this.editDisSubmit(val, oldVal)
      } else {
        this.is_dis_updated = false
      }
    }
  },
  methods: {
    handleClick(tab, event) {
      this.params.page = 1
      this.getList()
    },
    selectDeliverAlternatePlace() {
      this.alternatePlaceDialog = true
      this.getAlternatePlaceList()
    },
    changeAlternatePlaceType() {
      this.getAlternatePlaceList()
    },
    getAlternatePlaceList() {
      this.alternatePlaceTableLoading = true
      if (this.alternatePlaceType === 'distributor') {
        this.getAlternateDistributors()
      } else if (this.alternatePlaceType === 'temporaryDeliveryPlace') {
        this.getTemporaryDeliveryPlace()
      } else {
        this.alternatePlaceList = []
      }
    },
    getAlternateDistributors() {
      let params = JSON.parse(JSON.stringify(this.sf_alternate_params))
      params.page = this.alternatePlacePageNation.page
      params.pageSize = this.alternatePlacePageNation.page_size
      params.dealer_id = this.SFSettingRow.dealer_id
      params.province = this.SFSettingRow.province
      params.city = this.SFSettingRow.city
      getDistributorList(params).then((response) => {
        if (response.data.data.list) {
          this.alternatePlaceList = response.data.data.list
          this.alternatePlacePageNation.total_count = response.data.data.total_count
        }
        this.alternatePlaceTableLoading = false
      })
    },
    getTemporaryDeliveryPlace() {
      let params = JSON.parse(JSON.stringify(this.sf_alternate_params))
      params.page = this.alternatePlacePageNation.page
      params.pageSize = this.alternatePlacePageNation.page_size
      params.dealer_id = this.SFSettingRow.dealer_id
      params.province = this.SFSettingRow.province
      params.city = this.SFSettingRow.city
      getTemporaryDeliveryPlaceList(params).then((response) => {
        if (response.data.data.list) {
          this.alternatePlaceList = response.data.data.list
          this.alternatePlacePageNation.total_count = response.data.data.total_count
        }
        this.alternatePlaceTableLoading = false
      })
    },
    handleAlternatePlaceChange(row) {
      this.selectAlternatePlaceRow = row
    },
    selectAlternatePlace() {
      if (JSON.stringify(this.selectAlternatePlaceRow || {}) === '{}') { // 未选择
        this.alternatePlaceInfo = {}
        this.SFForm.alternate_place_type = ''
        this.SFForm.alternate_place_id = 0
        this.alternatePlaceDialog = false

        return true;
      }

      this.SFForm.alternate_place_type = this.alternatePlaceType
      if (this.alternatePlaceType === 'distributor') {
        this.SFForm.alternate_place_id = this.selectAlternatePlaceRow.distributor_id
        this.alternatePlaceInfo = {
          name: this.selectAlternatePlaceRow.name,
          code: this.selectAlternatePlaceRow.shop_code,
          dealer_name: this.selectAlternatePlaceRow.dealer_name,
          address: this.selectAlternatePlaceRow.address,
        }
      } else {
        this.SFForm.alternate_place_id = this.selectAlternatePlaceRow.id
        this.alternatePlaceInfo = {
          name: this.selectAlternatePlaceRow.name,
          code: this.selectAlternatePlaceRow.code,
          dealer_name: this.selectAlternatePlaceRow.dealer_name,
          address: this.selectAlternatePlaceRow.address,
        }
      }
      this.selectAlternatePlaceRow = {}
      this.alternatePlaceDialog = false
    },
    getSFSetting(row) {
      this.SFSettingRow = JSON.parse(JSON.stringify(row))
      this.dialog = true;
      this.title = row.name + '的顺丰快递配置';
      this.SFForm.distributor_id = row.distributor_id;
      this.SFForm.project_code = row.project_code;
      this.SFForm.customer_code = row.customer_code;
      this.SFForm.start_station_code = row.start_station_code;
      this.SFForm.is_open_substitute_delivery = row.is_open_substitute_delivery
      this.SFForm.sf_call_transfer = row.sf_call_transfer
      this.SFForm.alternate_place_id = row.alternate_place_id
      this.SFForm.alternate_place_type = row.alternate_place_type
      this.alternatePlaceInfo = row.alternatePlaceInfo || {}
    },
    saveSFSetting(SFForm) {
      this.$refs[SFForm].validate(valid => {
        if (valid) {
          // 备用发货地校验
          if (this.SFForm.sf_call_transfer == '1' && !this.SFForm.alternate_place_id) {
            this.$message.error('请选择备用发货地')
            return false
          }

          this.loadingSF = true
          saveSFConfig(this.SFForm).then(response => {
            let res = response.data, type = 'error', msg = '操作失败';
            console.log(res, '========');
            if (res) {
              type = 'success';
              msg = res.message ? res.message : '操作成功';
            }
            this.$message({
              type: type,
              message: msg,
              duration: 3 * 1000
            });
            this.getList();
            this.dialog = false;
          }).catch(error => {
            // this.$message.error('操作失败，请检查AdaPay支付配置或联系管理员');
          })
        }
        this.loadingSF = false
      })
    },

    getEduSetting(row) {
      this.edu_dialog = true
      this.edu_dialog_loading = true
      this.edu_form.distributor_id = row.distributor_id;
      getEduConfig({ distributor_id: row.distributor_id }).then(res => {
        let result = res.data.data;
        this.edu_form.appid = result.appid;
        this.edu_form.secret = result.secret;
        this.edu_dialog_loading = false
      }).catch(error => {
        console.log(error);
        this.edu_dialog_loading = false
      });
    },
    saveEduSetting(edu_form) {
      this.$refs[edu_form].validate(valid => {
        if (valid) {
          this.loading_edu = true
          saveEduConfig(this.edu_form).then(response => {
            let res = response.data, type = 'error', msg = '操作失败';
            console.log(res, '========');
            if (res) {
              type = 'success';
              msg = res.message ? res.message : '操作成功';
            }
            this.$message({
              type: type,
              message: msg,
              duration: 3 * 1000
            });
            this.edu_dialog = false;
          }).catch(error => {
            // this.$message.error('操作失败，请检查AdaPay支付配置或联系管理员');
          })
        }
        this.loading_edu = false
      })
    },

    getDistributorEduSecurity() {
      this.loading_edu_config = true
      const form = {
        distributor_id: this.edu_form.distributor_id
      }
      getDistributorEduSecurity(form).then(response => {
        if (response.data.data.length > 0) {
          this.edu_form.appid = response.data.data[0].appid;
          this.edu_form.secret = response.data.data[0].secret;
          this.$message.success('获取成功')
        } else {
          this.$message.error('未获取到数据')
        }
        this.loading_edu_config = false
      }).catch(error => {
        this.loading_edu_config = false
      })
    },

    handleCloseExamineDialog() {
      this.examineDoVisible = false
      this.resetExamineForm()
    },

    resetExamineForm() {
      this.examineForm = {
        log_id: 0,
        distributor_id: 0,
        examine_result: "",
        remark: ""
      }
    },

    exportData() {
      this.exportloading = true
      var params = JSON.parse(JSON.stringify(this.params))
      params.export = 1
      params.distributor_id = this.distributor_id
      params.is_valid = 'true,false,delete'
      getDistributorList(params).then(res => {
        this.exportloading = false
        if (res.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          return
        } else if (res.data.data.url) {
          this.downloadUrl = res.data.data.url
          this.downloadName = res.data.data.filename
          this.downloadView = true
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return
        }
      }).catch(error => {
        this.exportloading = false
      })
    },
    exportRepairableData() {
      this.exportloading = true
      var params = JSON.parse(JSON.stringify(this.params))
      params.export = 1
      params.distributor_id = this.distributor_id
      params.is_valid = 'true,false,delete'
      params.is_repair_asp = 1
      getDistributorList(params).then(res => {
        this.exportloading = false
        if (res.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          return
        } else if (res.data.data.url) {
          this.downloadUrl = res.data.data.url
          this.downloadName = res.data.data.filename
          this.downloadView = true
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return
        }
      }).catch(error => {
        this.exportloading = false
      })
    },
    linkTemplates(distributor) {
      const { distributor_id, address, name } = distributor
      this.$store.dispatch('setTemplateName', 'yykweishop')
      this.$router.push({
        path: this.matchHidePage('template'),
        query: { distributor_id, address, name }
      })
    },
    dialogShow(id, type) {
      this.current = id
      type === 'pc' ? (this.pcDialogVisible = true) : (this.dialogVisible = true)
    },
    closeDialog() {
      this.dialogVisible = false
      this.pcDialogVisible = false
    },
    isValidSelectHandle() {
      this.params.page = 1
      this.getList()
    },
    numberSearch(e) {
      this.params.page = 1
      this.getList()
      this.tagData()
    },
    handleCurrentChange(page_num) {
      this.params.page = page_num
      this.getList()
    },
    dialogOpen(detail = null) {
      this.$router.push({ path: this.matchHidePage('editor') })
    },
    handleDistance() {
      this.distanceForm.distance = ''
      // this.resetInfo();
      // this.operate = "create";
      // this.dialogVisible = true;
    },
    addDistributorSelf() {
      this.$router.push({ path: this.matchHidePage('editor'), query: { distributor_type: 'distributor_self' } })
    },
    editDistributorSelf() {
      this.$router.push({ path: this.matchHidePage('editor'), query: { distributor_type: 'distributor_self', distributor_id: this.distributor_self } })
    },
    getList() {
      this.loading = true
      getDistributorList(this.params).then((response) => {
        if (response.data.data.list) {
          this.list = response.data.data.list
          this.total_count = response.data.data.total_count
          this.distributor_self = response.data.data.distributor_self
        }
        this.loading = false
      })
    },
    downDistributorCode(query) {
      let params = { distributor_id: this.rowdata.distributor_id, codetype: this.codetype, template_name: 'yykweishop', ...query }

      getWxaDristributorCodeStream(params).then((response) => {
        var a = document.createElement('a')
        var temp = '微商城'
        a.href = response.data.data.base64Image
        if (response.data.data.tempname) {
          var temp = response.data.data.tempname
        }
        a.download = temp + this.rowdata.name + '.png'
        if (query.way) {
          AbstractConsumer
          // 449517_门店首页_线下
          a.download = `${this.rowdata.shop_code}_门店首页_${query.way === 'online' ? '线上' : '线下'}.png`
        }
        a.click()
      })
    },

    // https://aarpre-zyd.yuanyuanke.cn/wechatAuth/wxapp/getWxaCode.png?
    // page=pages/store/index&st=off&appid=wxbb93f3bd270c6c43&id=1&cd=9901
    // &path=dXRtX21tbT0xMjM0NCZhcHBsZWlkPTEyMzQ=&filename=test123&wxaAppId=wxbb93f3bd270c6c43

    downDistributorCodeUtm(query) {
      let params = { distributor_id: this.rowdata.distributor_id, codetype: this.codetype, template_name: 'yykweishop', ...query }
      // console.log(this.rowdata.storeQrcodeUtmUrl)
      var a = document.createElement('a')
      var temp = 'Qr code'

      if (query.way === 'online') {
        a.href = this.rowdata.storeQrcodeUtmUrl.onlineImage.image_full_url
        a.download = this.rowdata.storeQrcodeUtmUrl.onlineImage.image_name

        var qrcodeurl = this.rowdata.storeQrcodeUtmUrl.onlineImage.image_full_url
        var qrcodename = this.rowdata.storeQrcodeUtmUrl.onlineImage.image_name

      } else {
        a.href = this.rowdata.storeQrcodeUtmUrl.offlineImage.image_full_url
        a.download = this.rowdata.storeQrcodeUtmUrl.offlineImage.image_name

        var qrcodeurl = this.rowdata.storeQrcodeUtmUrl.offlineImage.image_full_url
        var qrcodename = this.rowdata.storeQrcodeUtmUrl.offlineImage.image_name
        //qrcode_image
      }
      console.log("downDistributorCodeUtmZyd::::qrcodeurl::", qrcodeurl)
      console.log("downDistributorCodeUtmZyd::::qrcodename::", qrcodename)

      this.downloadIamge(qrcodeurl, qrcodename);
    },



    downDistributorCodeUtmOrder(query) {
      let params = { distributor_id: this.rowdata.distributor_id, codetype: this.codetype, template_name: 'yykweishop', ...query }
      // console.log(this.rowdata.storeQrcodeUtmUrl)
      var a = document.createElement('a')
      var temp = 'Qr code'

      if (query.way === 'online') {
        a.href = this.rowdata.storeQrcodeUtmUrlOrder.onlineImage.image_full_url
        a.download = this.rowdata.storeQrcodeUtmUrlOrder.onlineImage.image_name

        var qrcodeurl = this.rowdata.storeQrcodeUtmUrlOrder.onlineImage.image_full_url
        var qrcodename = this.rowdata.storeQrcodeUtmUrlOrder.onlineImage.image_name

      } else {
        a.href = this.rowdata.storeQrcodeUtmUrlOrder.offlineImage.image_full_url
        a.download = this.rowdata.storeQrcodeUtmUrlOrder.offlineImage.image_name

        var qrcodeurl = this.rowdata.storeQrcodeUtmUrlOrder.offlineImage.image_full_url
        var qrcodename = this.rowdata.storeQrcodeUtmUrlOrder.offlineImage.image_name
        //qrcode_image
      }
      console.log("downDistributorCodeUtmOrder::::qrcodeurl::", qrcodeurl)
      console.log("downDistributorCodeUtmOrder::::qrcodename::", qrcodename)
      this.downloadIamge(qrcodeurl, qrcodename);
    },

    downloadIamge(imgsrc, name) {
      console.log("downloadIamge:::imgsrc, name", imgsrc, name)
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据'
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        }); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },


    RegionChangeSearch(value) {
      var vals = getCascaderObj(value, this.regions)
      if (vals.length == 1) {
        this.params.province = vals[0].label
        this.params.city = ''
        this.params.area = ''
      } else if (vals.length == 2) {
        this.params.province = vals[0].label
        this.params.city = vals[1].label
        this.params.area = ''
      } else if (vals.length == 3) {
        this.params.province = vals[0].label
        this.params.city = vals[1].label
        this.params.area = vals[2].label
      } else {
        this.params.province = ''
        this.params.city = ''
        this.params.area = ''
      }
      this.params.page = 1
      this.getList()
    },
    TagChangeAction(value) {
      this.params.tag_id = value
      this.params.page = 1
      this.getList()
    },
    downDistributor(row, codetype) {
      this.codetype = codetype
      this.rowdata = row
      this.downDistributorVal = true
    },
    defaultSwitchChange(row) {
      var params = {
        distributor_id: row.distributor_id
      }
      setDefaultDistributor(params).then((response) => {
        if (response.data.data.status) {
          for (var i = this.list.length - 1; i >= 0; i--) {
            if (this.list[i].distributor_id != row.distributor_id) {
              this.list[i].is_default = false
            }
          }
        }
      })
    },
    switchChangeAuditGoods(index, row) {
      var params = {
        distributor_id: row.distributor_id,
        is_audit_goods: row.is_audit_goods
      }
      saveDistributor(params).then((response) => {
        this.detailDialog = false
        this.getList()
        this.$message({
          type: 'success',
          message: '保存成功'
        })
      })
    },
    switchChangeAutoSyncGoods(index, row) {
      var params = {
        distributor_id: row.distributor_id,
        auto_sync_goods: row.auto_sync_goods
      }
      saveDistributor(params).then((response) => {
        this.detailDialog = false
        this.getList()
        this.$message({
          type: 'success',
          message: '保存成功'
        })
      })
    },
    switchChangeOpen(index, row) {
      var params = {
        distributor_id: row.distributor_id,
        is_open: row.is_open
      }
      saveOpen(params).then((response) => {
        this.detailDialog = false
        this.getList()
        this.$message({
          type: 'success',
          message: '保存成功'
        })
      })
    },
    switchChange(index, row) {
      var params = {
        distributor_id: row.distributor_id,
        is_ziti: row.is_ziti,
        is_delivery: row.is_delivery
      }
      saveDistributor(params).then((response) => {
        this.detailDialog = false
        this.getList()
        this.$message({
          type: 'success',
          message: '保存成功'
        })
      })
    },
    handleSelectionChange(val) {
      let distributor_id = []
      for (let i in val) {
        distributor_id.push(val[i].distributor_id)
      }
      this.distributor_id = distributor_id
    },
    getAllTagList() {
      let params = {
        page: 1,
        pageSize: 500
      }
      getTagList(params).then((response) => {
        this.tag.list = response.data.data.list
      })
    },
    handleCancelLabelsDialog() {
      this.editValidDialog = false
      this.tag.dialog = false
    },
    handleCancelLabelsDialogDis() {
      this.editDisDialog = false
      this.tag.dialog = false
      // this.lzLoading = true;
    },
    tagUpdate(row) {
      this.tag.editItem = [row.itemName]
      this.tag.currentTags = row.tagList || []
      this.tag.form.distributor_id = row.distributor_id
      this.showTags()
    },
    addDistributorTag() {
      this.tag.currentTags = []
      if (this.distributor_id.length) {
        this.showTags()
        this.tag.form.distributor_id = this.distributor_id
      } else {
        this.$message({
          type: 'error',
          message: '请选择至少一个店铺!'
        })
      }
    },
    showTags() {
      this.tag.tags = [...this.tag.list]
      this.tag.tags.forEach((item, index) => {
        let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
        if (isInArr != -1) this.tag.tags.splice(index, 1)
      })
      this.tag.dialog = true
    },
    tagRemove(index) {
      this.tag.tags.unshift(this.tag.currentTags[index])
      this.tag.currentTags.splice(index, 1)
    },
    tagAdd(item, index) {
      let isInArr = this.tag.currentTags.findIndex((n) => n.tag_id == item.tag_id)
      if (isInArr == -1) {
        this.tag.currentTags.push(item)
        this.tag.tags.splice(index, 1)
      }
    },
    submitItemTag() {
      this.tag.form.tag_ids = []
      this.tag.currentTags.forEach((item) => {
        this.tag.form.tag_ids.push(item.tag_id)
      })
      if (this.tag.form.tag_ids.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择店铺标签'
        })
        return false
      }
      this.tag.dialog = false
      distributorRelTags(this.tag.form).then((res) => {
        if (res.data.data.status) {
          this.$message({
            type: 'success',
            message: '打标签完成'
          })
        }
      })
    },
    tagSearchUserChange() {
      this.currentPage = 1
      this.getParams()
      this.getItemsList(this.params)
      this.loading = false
    },
    editDistribution(row) {
      // is_op_distribution
      this.editDisDialog = true
      this.editDisData = row
      this.is_dis_updated = true
    },
    editValid(row) {
      this.editValidDialog = true
      this.editValidData = row
      this.is_valid_updated = true
    },
    editValidSubmit(val, oldVal) {
      let msg = ''
      if (val === 'true') {
        msg = '确定开启店铺？'
      } else if (val === 'false') {
        msg = '确定禁用店铺？'
      } else if (val === 'delete') {
        msg = '该店铺废弃后将不可再找回,确定废弃？'
      }
      //  else if (val === 'audit') {
      //   msg = '确认下架该店铺？下架后需审核并重新上架'
      // }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            distributor_id: this.editValidData.distributor_id,
            is_valid: this.editValidData.is_valid
          }
          saveDistributor(params).then((response) => {
            this.detailDialog = false
            this.getList()
            this.$message({
              type: 'success',
              message: '已修改'
            })
            this.editValidDialog = false
          })
        })
        .catch(() => {
          this.editValidData.is_valid = oldVal
          this.is_valid_updated = true
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    editDisSubmit(val, oldVal) {
      let msg = ''
      if (val === '1') {
        msg = '确定开启店铺分销吗？'
      } else if (val === '0') {
        msg = '确定关闭店铺分销吗？'
      } else {
        msg = '确定操作吗？'
      }
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {

          this.lzLoading = true;
          this.loading = true;
          let params = {
            distributor_id: this.editDisData.distributor_id,
            is_op_distribution: this.editDisData.is_op_distribution
          }

          saveDistributor(params).then((response) => {
            this.loading = false
            this.getList()
            this.lzLoading = false
            if (response.data.data.code === '0000') {
              this.$message({
                type: 'success',
                message: '已修改'
              })
            } else {
              this.$message({
                type: 'error',
                message: response.data.data.message
              })
              // this.lzLoading = false

            }
            // this.loading = false
          })
        })
        .catch(() => {
          this.editDisData.is_op_distribution = oldVal
          this.is_dis_updated = true
          // this.loading = false
          this.lzLoading = false
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      this.editDisDialog = false
      this.getList()
      this.lzLoading = false
      // this.getList();
    },
    showSettingMeiQia(distributor_id) { // 设置美洽参数
      this.setMeiQiaVisible = true
      let that = this
      getDistributorMeiQia(distributor_id).then(response => {
        that.meiqia_form.meiqia_id = response.data.data.meiqia_id
        that.meiqia_form.meiqia_token = response.data.data.meiqia_token
      })
      that.meiqia_form.distributor_id = distributor_id
    },
    handleMeiQiaCancel() { // 美洽设置窗口关闭
      this.setMeiQiaVisible = false
      this.meiqia_form.distributor_id = ''
      this.meiqia_form.meiqia_id = ''
      this.meiqia_form.meiqia_token = ''
    },
    handleSubmitMeiQia() { // 提交美洽配置
      let params = {
        meiqia_id: this.meiqia_form.meiqia_id,
        meiqia_token: this.meiqia_form.meiqia_token
      }
      setDistributorMeiQia(this.meiqia_form.distributor_id, params).then(response => {
        this.$message({
          type: 'success',
          message: '已提交'
        })
        this.handleMeiQiaCancel()
      })
    },
    showSettingDistance() { // 设置距离参数
      this.setDistanceVisible = true
      let that = this
      getDistance().then(response => {
        that.distanceForm.distance = response.data.data.distance
      })
    },
    handleDistanceCancel() { // 距离设置窗口关闭
      this.setDistanceVisible = false
      this.distanceForm.distance = ''
    },
    handleSubmitDistance() { // 提交距离配置
      let params = {
        distance: this.distanceForm.distance
      }
      setDistance(params).then(response => {
        this.$message({
          type: 'success',
          message: '提交成功'
        })
        this.handleDistanceCancel()
      })
    },
    handleSizeChange(pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getList()
    },
    onCopy() {
      this.$notify.success({
        message: '复制成功',
        showClose: true
      })
    },
    handlePreferentialCancel() { // 店铺绑定活动窗口关闭
      this.bindPreferentialVisible = false
    },
    handleBindPreferentialOpen(row) { // 打开店铺绑定窗口时
      this.bindPreferentialVisible = true
      this.selectPreferentialDistributorId = row.distributor_id
    },
    getPreferentialList() { // 获取所有优惠活动列表
      let page = this.allPreferentialPageNation.page
      let pageSize = this.allPreferentialPageNation.page_size
      let params = { page: page, pageSize: pageSize, type: ['service', 'activity', 'welfare'] }
      getActivityList(params).then(response => {
        this.preferentials = response.data.data.list
        this.allPreferentialPageNation.total = response.data.data.total_count
      })
    },
    handleAllPreferentialPageChange(page_num) { // 活动页数变化
      this.allPreferentialPageNation.page = page_num
      this.getPreferentialList()
    },
    handleDistributorPreferential() { // 设置店铺绑定活动
      this.preferentialVisible = true
      this.getPreferentialList()
    },
    handlePreferentialSelectionChange(val) {
      let preferential_ids = []
      for (let i in val) {
        preferential_ids.push(val[i].id)
      }
      this.preferential_ids = preferential_ids
    },
    handleAllPreferentialCancel() {
      this.preferentialVisible = false
      this.allPreferentialPageNation.page = 1
      this.preferential_ids = []
      this.$refs.multipleTable.clearSelection()
    },
    setDistributorPreferential() {
      let distributors = this.distributor_id.toString()
      let preferential = this.preferential_ids.toString()
      let params = { distributor: distributors, ids: preferential }
      setDistributorRelPreferential(params).then(response => {
        if (response.data.data.result == true) {
          this.$message.success('绑定成功')
          this.handleAllPreferentialCancel()
        } else {
          this.$message.error('绑定失败')
        }
      })
    },

    handleSubmitExamine(row) {
      this.rowdata = row
      this.examineDialogVisible = true
      this.getExamineLogList(row.distributor_id)
    },

    handleExamineLogCurrentChange(page_num) {
      this.pageParams.page = page_num
      this.getExamineLogList()
    },

    submitExamine() {
      this.submitExamineBtnLoading = true
      this.$confirm('确认提交审核？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          distributor_id: this.rowdata.distributor_id,
        }
        submitExamineDistributor(params).then(response => {
          if (response.data.data.success == true) {
            this.$message.success('提交成功');
            this.rowdata.examine_status = 2
            this.submitExamineBtnLoading = false;
          } else {
            this.$message.error('审核失败')
            this.submitExamineBtnLoading = false;
          }
          this.getExamineLogList(this.rowdata.distributor_id)
        }).catch(error => {
          this.$message.error('审核失败')
          this.getExamineLogList(this.rowdata.distributor_id)
          this.submitExamineBtnLoading = false;
        })
      }).catch(error => {
        this.$message.info('已取消')
        this.getExamineLogList(this.rowdata.distributor_id)
        this.submitExamineBtnLoading = false;
      })
    },

    handleExamine(row) {
      this.examineDoVisible = true
      this.examineForm.log_id = row.id
      this.examineForm.distributor_id = row.distributor_id
    },

    examineDistributor() {
      let params = this.examineForm
      examineDistributor(params).then(response => {
        console.log(response)
        if (response.data.data.success == true) {
          this.$message.success('审核成功')
          this.examineDoVisible = false
        } else {
          this.$message.error('审核失败')
        }
        this.getExamineLogList(params.distributor_id)
      }).catch(error => {
        this.$message.error('审核失败')
      })
    },

    getExamineLogList(distributor_id) {
      this.examineTableLoading = true
      let params = {
        page: this.pageParams.page,
        pageSize: this.pageParams.pageSize,
        distributor_id: distributor_id,
      }
      getExamineDistributorList(params).then(response => {
        this.logData = response.data.data.list
        this.log_total_count = response.data.data.total_count
        this.examineTableLoading = false;
      }).catch(error => {
        this.logData = [];
        this.log_total_count = 0;
        this.examineTableLoading = false;
      })
    },

    clearSearch() {
      this.resetFilter();
      this.dynamicTags = []
      this.areaData = []
      this.agency_num = '归属经销商'
      this.getList();
    },

    resetFilter() {
      const resetParams = {
        page: 1,
        pageSize: 20,
        name: '',
        mobile: '',
        province: '',
        city: '',
        area: '',
        dealer_name: '',
        dealer_hqid: '',
        distributor_vad: '',
        channel_rtm: '',
        dis_type: '',
        delivery_type: '',
        platform: '',
        dealer_id: '',
        mt_config_status: '',
        is_repair_asp: '',
        is_old: '',
        open_edu: '',
        support_repair: '',
        mt_config_status: '',
      }
      this.params = Object.assign(this.params, resetParams)
    },
    selectChange(val) {
      this.params.select_status = val.join(',')
      this.numberSearch()
    },
    clearSelectChange() {
      this.selectStatus = []
      this.params.select_status = ''
      this.numberSearch()
    },
    onDetail(item) {
      let info = {
        delivery_service_code: item.delivery_service_code,
        scope: JSON.parse(item.scope),
        lat: item.lat,
        lng: item.lng
      }
      this.mtInfo = info
      this.dialogScope = true
      setTimeout(() => {
        this.initMapTools(item)
      }, 1000)
    },
    closeDialogScope() {
      this.mapInfo.destroy();
    },
    initMapTools(item) {

      // 初始化地图
      var center = new TMap.LatLng(this.mtInfo.lat, this.mtInfo.lng);
      this.mapInfo = new TMap.Map(document.getElementById('qqmap_tools'), {
        zoom: 14, // 设置地图缩放级别
        center: center // 设置地图中心点坐标
      });

      var latlngBounds = new TMap.LatLngBounds();
      var simplePath = [];
      if (this.mtInfo.scope) {
        var list = this.mtInfo.scope
        list.forEach((item) => {
          simplePath.push(new TMap.LatLng(item.x, item.y))
          latlngBounds.extend(new TMap.LatLng(item.x, item.y));
        })
      }

      // 初始化几何图形及编辑器
      this.editor = new TMap.tools.GeometryEditor({
        map: this.mapInfo, // 编辑器绑定的地图对象
        overlayList: [ // 可编辑图层
          {
            overlay: new TMap.MultiPolygon({
              map: this.mapInfo,
              styles: {
                highlight: new TMap.PolygonStyle({
                })
              },
              geometries: [
                {
                  paths: simplePath
                }
              ]
            }),
            id: 'polygon',
            selectedStyleId: 'highlight'
          }
        ],
        actionMode: TMap.tools.constants.EDITOR_ACTION.INTERACT, // 编辑器的工作模式
        activeOverlayId: 'polygon', // 激活图层
        selectable: false, // 开启点选功能
        snappable: true // 开启吸附
      });
      if (item.delivery_service_code_ext == 100009) {
        var circle = new TMap.MultiCircle({
          map: this.mapInfo,
          styles: { // 设置圆形样式
            'circle': new TMap.CircleStyle({
              'color': 'rgba(79,1169,97,0.3)',
              'showBorder': true,
              'borderColor': 'rgba(79,1169,97,1)',
              'borderWidth': 2,
            }),
          },
          geometries: [{
            styleId: 'circle',
            center: center,
            radius: this.defaultDistance * 1000,
          }],
        });
      }

      //调用fitBounds自动调整地图显示范围
      this.mapInfo.fitBounds(latlngBounds);
    },
    getDeliverySettingList () {
      getDeliverySettingList({type: 'distance'}).then(response => {
        this.defaultDistance = response.data.data.default
      })

      // console.log("this.goodsLists:",this.goodsLists)
    },
    panelCollapse(type) {
      this.formDrawer = type
    },
    handleCloseDrawer() {
      this.formDrawer = false
    },
    confirmDrawer() {
      this.formDrawer = false
    },
    handleCommand(command, row) {
      switch (command) {
        case 'downStoreCd':
          if(this.system_mode === 'platform') {
            this.downDistributor(row, 'store')
          } else {
            this.downDistributor(row, 'index')
          }
          break;
        case 'act':
          this.handleBindPreferentialOpen(row)
          break;
        case 'shopgoods':
          this.$router.push({ path: this.matchInternalRoute('/store/storemanager/Storeshopitemanagement'), query: { distributor_id: row.distributor_id } })
          this.getEduSetting(row)
          break;
      }
    },
    // 已选择的经销商列表
    DealerChooseAction(val) {
      this.relDealers = val
      let arr = []
      val.forEach(i => {
        arr.push(i.dealer_id)
      });
      this.params.dealer_id = arr.join(',')
      if (val.length > 0) {
        this.agency_num = `已选中${val.length}个经销商`
      } else {
        this.agency_num = '归属经销商'
      }
      this.numberSearch()
      this.closeDialogAction()
    },
    closeDialogAction() {
      this.DealerStatus = false
      this.DealerVisible = false
    },
    onDealerDialog() {
      this.DealerVisible = true
      this.DealerStatus = true
    },
    handleEmpty(i) {
      this.params[i.key] = ''
      this.numberSearch()
    },
    tagData() {
      let arr = Object.keys(this.params)
      let list = []
      arr.forEach((item) => {
        let value = this.params[item]
        const data = {
          label: '',
          value,
          key: item
        }
        if (value) {
          switch (item) {
            case 'delivery_type':
              data.label = '配送方式'
              data.value = this.deliveryList?.find(item => item.value === value)?.label
              list.push(data)
              break;
            case 'is_old':
              data.label = '开启以旧换新'
              data.value = this.commonTypeList?.find(item => item.value === value)?.label
              list.push(data)
              break;
            case 'open_edu':
              data.label = '开启教育优惠'
              data.value = this.commonTypeList?.find(item => item.value === value)?.label
              list.push(data)
              break;
            case 'support_repair':
              data.label = '开启售后维修'
              data.value = this.commonTypeList?.find(item => item.value === value)?.label
              list.push(data)
              break;
            case 'open_fq_pay':
              data.label = '开启分期付款'
              data.value = this.commonTypeList?.find(item => item.value === value)?.label
              list.push(data)
              break;
            case 'support_electronic_invoice':
              data.label = '开启电子发票'
              data.value = this.commonTypeList?.find(item => item.value === value)?.label
              list.push(data)
              break;
            case 'is_repair_asp':
              data.label = '门店维修类型'
              data.value = this.supportList?.find(item => item.value === value)?.label
              list.push(data)
              break;
            case 'mt_config_status':
              data.label = '门店美团配置'
              data.value = this.mtconfigList?.find(item => item.value === value)?.label
              list.push(data)
              break;
            case 'platform':
              data.label = '展示平台'
              data.value = this.platList?.find(item => item.value === value)?.label
              list.push(data)
              break;
          }
        }
      })
      this.dynamicTags = list
    },

  },

  mounted() {
    if (store.getters.login_type === 'distributor') {
      this.is_distributor = true
    }
    this.getList()
    this.getAllTagList()
    this.getPreferentialList()
    this.getDeliverySettingList()
    getSetting().then(res => {
      let data = res.data.data
      this.isOpen = data.is_open == 'true'
    })
    this.operator_type = store.getters.login_type
  },
  beforeRouteUpdate(to, from, next) {
    next()
    if (to.path.indexOf('_editor') === -1 && to.path.indexOf('_detail') === -1) {
      this.getList()
      this.getAllTagList()
    }
  }
}
</script>
