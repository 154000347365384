<template>
  <div>
    <el-form label-width="100px" :inline="true">
      <el-form-item>
        <el-col :span="5">
          <el-date-picker
            v-model="dateTime"
            align="left"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyyMMdd"
            format="yyyy年MM月dd日"
            @change="dateChange"
          >
          </el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col :span="20">
          <el-select v-model="params.source_des" clearable placeholder="请选择来源类型" @change="sourceDesChange">
            <el-option label="全部" key="0" value="0">全部</el-option>
            <el-option
              v-for="item in sourceDes"
              :key="item.key"
              :label="item.source_des"
              size="mini"
              :value="item.source_des">
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item>
        <el-col>
          <el-button type="primary" @click="exportData" v-loading="exportloading" style="margin-left: 10px">导出</el-button>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </el-col>
      </el-form-item>

    </el-form>
    <el-tabs hidden v-if="$route.path.indexOf('_editor') === -1" v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="新增用户量" name="new">
        <section>
          <canvas id="new" height="120"></canvas>
        </section>
      </el-tab-pane>
      <el-tab-pane label="用户总量" name="add">
        <section>
          <canvas id="add" height="120"></canvas>
        </section>
      </el-tab-pane>
      <el-tab-pane label="访问总量" name="visit">
        <section>
          <canvas id="visit" height="120"></canvas>
        </section>
      </el-tab-pane>
    </el-tabs>
    <template>
      <el-table :data="allListData" stripe border>
        <el-table-column prop="statistics_date" label="统计日期" fixed align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="source" label="扫码来源类型" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="new" label="新增用户量(当日)" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="add" label="用户总量(截至当日)" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="visit" label="扫码总量(当日)" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="明细" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link @click="getStatisticsDetail(scope.row)" type="primary" title="明细" size="mini" :underline="false">明细</el-link>
            <el-link @click="exportData(scope.row,'detail')" type="primary" title="导出明细" size="mini" :underline="false">导出</el-link>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <el-drawer
      :title="detailTitle"
      :visible.sync="table"
      direction="rtl"
      size="50%">
      <el-table :data="detailListData">
        <el-table-column prop="statistics_date" label="统计日期" fixed align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="source" label="来源标识" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="source_id" label="来源ID" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="new" label="新增用户量" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="add" label="用户总量" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="visit" label="扫码总量" align="center" show-overflow-tooltip></el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import chart from 'chart.js'
import {getResourceStatisticsData} from '../../../api/datacube'
import CommonUtil from '@/common/js/util'

export default {
  data() {
    return {
      detailTitle: '',
      sourceDes: '',
      table: false,
      loading: true,
      exportloading: false,
      activeName: 'new',
      dateTime: [new Date(2021, 6, 10), new Date(2021, 7, 10)],
      params: {
        start: '',
        end: '',
        source_des: '',
      },
      allListData: [],
      detailListData: [],
      dataTimeArr: [],
      dataInfo: {
        new: {
          label: '新增用户量',
          data_list: [],
          total_num: 0,
          unit: '人'
        },
        add: {
          label: '全部用户量',
          data_list: [],
          total_num: 0,
          unit: '人'
        },
        visit: {
          label: '访问总量',
          data_list: [],
          total_num: 0,
          unit: '次'
        }
      },
      chartColors: {
        red: 'rgb(255, 99, 132)',
        orange: 'rgb(255, 159, 64)',
        yellow: 'rgb(255, 205, 86)',
        green: 'rgb(75, 192, 192)',
        blue: 'rgb(54, 162, 235)',
        purple: 'rgb(153, 102, 255)',
        grey: 'rgb(201, 203, 207)'
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 89);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  methods: {
    exportData(row = {}, type = 'collect') {
      this.exportloading = true;
      let query = {};
      if (type === 'detail') {
        query = {
          statistics_source: row.statistics_source,
          statistics_way: row.statistics_way,
          statistics_date: row.statistics_date,
          'is_export': true,
          type,
        };
      } else {
        query = {
          ...this.params,
          'is_export': true,
          type,
        };
      }
      getResourceStatisticsData(query).then(res => {
        this.exportloading = false;
        if (res.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
        } else if (res.data.data.url) {
          this.downloadUrl = res.data.data.url;
          this.downloadName = res.data.data.filename;
          this.downloadView = true;
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
        }
      }).catch(error => {
        this.exportloading = false
      })
      return;
    },

    getStatisticsDetail(row) {
      this.table = true;
      this.detailTitle = row.source;

      let params = {
        type: 'detail',
        statistics_source: row.statistics_source,
        statistics_way: row.statistics_way,
        statistics_date: row.statistics_date
      };
      getResourceStatisticsData(params).then(res => {
        this.detailListData = res.data.data.list
      });
    },
    sourceDesChange(val) {
      this.params.source_des = val
      this.getCompanyDataList(this.activeName)
    },
    handleClick(tab, event) {
      this.chartInit(tab.name)
    },
    dateChange(val) {
      this.params.start = val[0]
      this.params.end = val[1]
      this.getCompanyDataList(this.activeName)
    },
    getCompanyDataList(pane_name) {
      this.dataTimeArr = []
      this.dataInfo.new.data_list = []
      this.dataInfo.add.data_list = []
      this.dataInfo.visit.data_list = []

      getResourceStatisticsData(this.params).then(res => {
        this.allListData = res.data.data.list
        this.sourceDes = res.data.data.title;
        let companyDataList = res.data.data.list
        let newUser = 0
        let addUser = 0
        let visitUser = 0
        for (var key in companyDataList) {
          this.dataTimeArr.push(companyDataList[key].statistics_date)

          this.dataInfo.new.data_list.push(companyDataList[key].new)
          newUser += Number(companyDataList[key].new)

          this.dataInfo.add.data_list.push(companyDataList[key].add)
          addUser += Number(companyDataList[key].add)

          this.dataInfo.visit.data_list.push(companyDataList[key].visit)
          visitUser += Number(companyDataList[key].visit)
        }
        this.dataInfo.new.total_num = newUser
        this.dataInfo.add.total_num = addUser
        this.dataInfo.visit.total_num = visitUser
        this.loading = false
        if (pane_name) { // 首次加载
          this.$nextTick(() => {
            this.chartInit(pane_name)
          })
        }
      }).catch(error => {
        this.$message({
          type: 'error',
          message: '获取统计信息出错'
        })
      })
    },
    chartInit(pane_name) {
      var config = {
        type: 'line',
        data: {
          labels: this.dataTimeArr,
          datasets: [{
            label: this.dataInfo[pane_name].label,
            backgroundColor: this.chartColors.grey,
            borderColor: this.chartColors.red,
            data: this.dataInfo[pane_name].data_list,
            fill: false,
          }]
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: '合计(' + this.dataInfo[pane_name].total_num + this.dataInfo[pane_name].unit + ')'
          },
          tooltips: {
            mode: 'index',
            intersect: true,
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: '',
              }
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: false,
              }
            }]
          }
        }
      };
      var ctx = document.getElementById(pane_name).getContext("2d");
      window.myLine = new Chart(ctx, config);
    }
  },
  mounted() {
    var start = new Date();
    var end = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
    end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
    this.dateTime = [start, end];

    this.params.start = CommonUtil.formatDate.format(start, 'yyyyMMdd')
    this.params.end = CommonUtil.formatDate.format(end, 'yyyyMMdd')

    this.getCompanyDataList(this.activeName)
  }
}
</script>
