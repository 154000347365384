<template>
  <div>
    <el-row class="filter-header">
      <el-col :span="24">
        <el-input class="input-m" placeholder="赠品名称" v-model="listParams.search">
        </el-input>
        <el-select v-model="listParams.type" clearable  placeholder="请选择赠品类型">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button size="small" type="primary" @click="listSearch">查询</el-button>
        <el-button size="small" type="primary" @click="addGiftItem">添加赠品</el-button>
        <ImportDialog buttonSize="small" buttonName="导入赠品" fileName="导入赠品" fileType="lottery_gift"></ImportDialog>
        <el-button type="primary" size="small" @click="exportData">导出赠品</el-button>
      </el-col>
    </el-row>

    <!-- <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane v-for="(item, index) in tabList" :key="index" :label="item.name" :name="item.activeName"> -->
    <el-table :data="ItemsList" row-key="goods_id">
      <el-table-column prop="id" label="赠品ID" width="80"></el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button type="text" @click="editItems(scope.row)" class="btn-gap">
            编辑
          </el-button>
          <!-- <el-button type="text" @click="deleteItems(scope.row)" class="btn-gap">
            删除
          </el-button> -->
          <span>
            <el-button v-if="scope.row.type == '1'" type="text" @click="editStock(scope.row)" class="btn-gap">
              门店库存
            </el-button>
            <el-popover v-model="scope.row.visible" placement="top" width="200" v-else>
              <el-radio-group v-model="stockType">
                <el-radio label="1">增加</el-radio>
                <el-radio label="2">减少</el-radio>
              </el-radio-group>
              <el-input class="numipt" v-model="stockNum" type="number" placeholder="礼品库存"></el-input>
              <div>
                <el-button @click="onIsVisible(scope.row)">取消</el-button>
                <el-button type="primary" @click="onConfirm(scope.row)">确认</el-button>
              </div>
              <el-button type="text" slot="reference">限定库存</el-button>
            </el-popover>
          </span>
          <el-button type="text" @click="seekRecord(scope.row)" class="btn-gap">
            库存记录
          </el-button>
          <el-button v-if="scope.row.type == '1'" type="text" @click="exportDistributorStockData(scope.row)" class="btn-gap">
            导出库存
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="赠品名称"></el-table-column>
      <el-table-column prop="type_cn" label="赠品类型"></el-table-column>
      <!-- <el-table-column label="上架状态" width="180">
        <template slot-scope="scope">
          <el-switch @change="onItemStatus(scope.row)" v-model="scope.row.status" active-value="1" inactive-value="0"
            active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column> -->
      <el-table-column prop="store" label="可用库存"></el-table-column>
      <el-table-column prop="freeze" label="已锁定库存"></el-table-column>
    </el-table>
    <div class="content-center content-top-padded">
      <el-pagination background layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" :current-page.sync="listParams.page" :page-sizes="[10, 20, 50]"
        :total="total_count" :page-size="listParams.page_size">
      </el-pagination>
    </div>
    <!-- </el-tab-pane>
    </el-tabs> -->

    <el-dialog title="门店库存" :visible.sync="isInventoryShow">
      <Inventory v-if="isInventoryShow" @onDialogType="onDialog" :shopNum="shop_num" :distributorInfo="distributor_info" :agencyNum="agency_num"
        :relDealers="relDealers" :info="giftEditInfo" @refresh="init"></Inventory>
    </el-dialog>

    <el-dialog title="赠品编辑" :visible.sync="isGiftEditShow" :close-on-click-modal="false">
      <GiftEdit v-if="isGiftEditShow" :info="giftEditInfo" @onSave="saveGiftIiem"></GiftEdit>
    </el-dialog>
    <el-dialog title="库存记录" :visible.sync="isRecordChangesShow">
      <RecordChanges v-if="isRecordChangesShow" :info="giftEditInfo"></RecordChanges>
    </el-dialog>


    <DistributorSelect :store-visible="distributorVisible" :is-valid="true" :relDataIds="distributor_info"
      :get-status="setDistributorStatus" :distributor-valid="'true,false'" @chooseStore="chooseDistributorAction" @closeStoreDialog="closeDialogAction"
      returnType="selectRow"></DistributorSelect>
    <DealerSelect :store-visible="DealerVisible" :is-valid="isValid" :rel-data-ids="relDealers" :get-status="DealerStatus"
      @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogAction" :isSingleSelect="isSingleSelect">
    </DealerSelect>

    <router-view></router-view>
  </div>
</template>
<script>
import DistributorSelect from '@/components/function/distributorSelect'
import DealerSelect from '@/components/function/dealerSelect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Inventory from './template/inventory'
import GiftEdit from './template/giftEdit'
import RecordChanges from './template/recordChanges'
import { getLotteryGiftList, getLotteryGiftInfo, getLotteryGiftTotalStock, getLotteryGiftShopstock } from '@/api/lotteryGift'
import ImportDialog from '@/components/importDialog'

export default {
  components: {
    ImportDialog,
    Inventory,
    DistributorSelect,
    DealerSelect,
    GiftEdit,
    RecordChanges
  },
  data() {
    return {
      isInventoryShow: false,
      isGiftEditShow: false,
      isRecordChangesShow: false,
      tabList: [
        { name: "全部", value: null, activeName: 'first' },
        { name: "已上架", value: 'true', activeName: 'second' },
        { name: "已下架", value: 'true', activeName: 'third' },
      ],
      activeName: 'first',
      ItemsList: [],
      total_count: 0,
      form: {},
      shop_num: '归属店铺',
      agency_num: '归属经销商',
      distributorVisible: false,
      distributor_info: [],
      setDistributorStatus: false,
      DealerVisible: false,
      isValid: true,
      relDealers: [],
      DealerStatus: false,
      rolesListData: [],
      listParams: {
        type: '',
        search: '',
        page: 1,
        page_size: 10,
      },
      giftEditInfo: {},
      isLoading: false,
      options: [
        {
          value: '1',
          label: '常规礼品'
        }, {
          value: '2',
          label: '限定礼品'
        },
      ],
      stockType: '1',
      stockNum: 1,
    }
  },
  computed: {
    isSingleSelect() {
      const role = this.rolesListData.find(item => item.role_id === this.form.role_id)
      return role && role.role_name !== 'VAD_Admin'
    }
  },
  methods: {
    chooseDistributorAction(val) {
      this.distributor_info = val
      if (val.length > 0) {
        this.shop_num = `已选择${val.length}门店`
      } else {
        this.shop_num = '归属店铺'
      }
      this.closeDialogAction()
    },
    closeDialogAction() {
      if (this.dialog_type == 1) {
        this.distributorVisible = false
      } else {
        this.DealerStatus = false
        this.DealerVisible = false
      }
    },
    DealerChooseAction(val) {
      this.relDealers = val
      if (val.length > 0) {
        this.agency_num = `已选中${val.length}个经销商`
      } else {
        this.agency_num = '归属经销商'
      }
      this.closeDialogAction()
    },
    onDialog(type) {
      if (type == 'shop') {
        this.dialog_type = 1
        this.distributorVisible = true
      } else {
        this.dialog_type = 2
        this.DealerVisible = true
        this.DealerStatus = true
      }
    },
    listSearch() {
      this.listParams.page = 1
      this.init()
    },
    addCategory() {

    },
    addGiftItem() {
      this.giftEditInfo = {}
      this.isGiftEditShow = true
    },
    handleClick(tab, event) {
    },
    deleteItems() {

    },
    editItemsAction() {

    },
    editItems(item) {
      this.giftEditInfo = item
      this.isGiftEditShow = !this.isGiftEditShow
    },
    editStock(item) {
      this.giftEditInfo = {}
      this.giftEditInfo = item
      this.isInventoryShow = !this.isInventoryShow
    },
    handleCurrentChange(page_num) {
      this.listParams.page = page_num
      this.init()
    },
    handleSizeChange(pageSize) {
      this.listParams.page = 1
      this.listParams.page_size = pageSize
      this.init()
    },
    seekRecord(item) {
      this.giftEditInfo = {}
      this.giftEditInfo = item
      this.isRecordChangesShow = !this.isRecordChangesShow
    },
    init(isExport) {
      if (isExport === undefined) {
        isExport = false;
      }
      let params = { ...this.listParams }
      if (isExport) {
        params.is_export = true
      }
      getLotteryGiftList(params).then((res) => {
        if (!isExport) {
          let data = res.data.data
          if (data.total_count > 0) {
            data.list.forEach((item) => {
              item.visible = false
            })
            this.ItemsList = data.list
            this.total_count = data.total_count
          }
        }
      })
    },
    saveGiftIiem(info) {
      this.lotteryGiftInfo(info)
    },
    onItemStatus(item) {
      let params = { ...item }
      delete params.created
      delete params.updated
      delete params.freeze_store
      delete params.store
      this.lotteryGiftInfo(params)

    },
    lotteryGiftInfo(params) {
      if (this.isLoading) return
      this.isLoading = true
      getLotteryGiftInfo(params).then((res) => {
        if (res.data.data.success) {
          this.init()
          this.isGiftEditShow = false
          this.isLoading = false
        }
      })
    },
    onIsVisible(item) {
      item.visible = false
    },
    onConfirm(item) {
      let params = {
        gid: item.id,
        stock: this.stockType == '1' ? this.stockNum : -this.stockNum,
        type: '1',
      }
      getLotteryGiftTotalStock(params).then((res) => {
        if (res.data.data.success) {
          item.visible = false
          this.stockType = '1'
          this.stockNum = 1
          this.init()
        }
      })
    },
    exportData () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.init(true)
      this.$message({
        type: 'success',
        message: '已加入执行队列，请在设置-导出列表中下载'
      })
      loading.close()
    },
    exportDistributorStockData (item) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const params = {
        gid: item.id,
        is_export: true
      }
      getLotteryGiftShopstock(params).then((res) => {
      })
      this.$message({
        type: 'success',
        message: '已加入执行队列，请在设置-导出列表中下载'
      })
      loading.close()
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style scoped lang="scss">
.numipt {
  margin: 20px 0;
}
</style>
