/* eslint-disable */
<div style="max-width:403px;max-height:300px" id="container123"></div>
<template>
  <section class="section section-white content-padded" style="padding-bottom: 70px" onload="init()">
    <el-form :rules="rules" ref="form" :model="form" label-width="110px">
      <el-card>
        <div slot="header" class="clearfix">
          <span>基础信息</span>
        </div>
        <!--第一行 开始-->
        <el-row>
          <el-col :span="8">
            <el-form-item label="发货点名称" prop="name">
              <div>
                <el-input v-model="form.name"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发货点编码" prop="code">
              <div>
                <el-input v-model="form.code" :maxlength="10"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <!--第一行 结束-->

        <!--第二行 开始-->
        <el-row>
          <el-col :span="8" v-if="!distributor_type">
            <el-form-item label="是否启用">
              <el-radio-group v-model="form.is_valid">
                <el-radio label="true">启用</el-radio>
                <el-radio label="false">禁用</el-radio>
                <el-radio label="delete">废弃(相当于删除)</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="联系方式" prop="contact_phone">
              <el-input class="input-m" v-model="form.contact_phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <el-card>
        <div slot="header" class="clearfix">
          <span>经销商</span>
        </div>
        <el-form-item label="所属经销商">
          <div>
            <div v-if="relDealers[0] != undefined">
              <div class="text item">
                经销商名称：{{relDealers[0].name}}
              </div>
              <div class="text item">
                经销商HQID：{{relDealers[0].dealer_hqid}}
              </div>
              <div class="text item">
                分销商HQID：{{relDealers[0].distributor_hqid}}
              </div>
              <div class="text item">
                分销商VAD：{{relDealers[0].distributor_vad}}
              </div>
              <div class="text item">
                渠道RTM：{{relDealers[0].channel_rtm}}
              </div>
            </div>
            <el-col :span="8">
              <el-button size="small" @click="openDialog">选择经销商</el-button>
            </el-col>
          </div>
        </el-form-item>
      </el-card>

      <el-card>
        <div slot="header" class="clearfix">
          <span>顺丰配置</span>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目编码" prop="project_code">
              <div>
                <el-input v-model="form.project_code"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户编码" prop="customer_code">
              <div>
                <el-input v-model="form.customer_code"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="站点编码" prop="start_station_code">
              <div>
                <el-input v-model="form.start_station_code"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>

      <el-card>
        <div slot="header" class="clearfix">
          <span>位置</span>
        </div>
        <el-form-item label="地理位置">
          <el-cascader
            v-model="form.regions_id"
            :options="regions"
            @change="handleRegionChange"
            style="width:200px"
          ></el-cascader>
          <el-input v-model="form.address" @keydown.enter.native="searchKeyword" id="keyword" placeholder="请输入地址" style="width:500px"></el-input>
          <el-button type="primary" @click="searchKeyword">搜索定位</el-button>
        </el-form-item>
        <el-form-item label="地图定位">
          <div id="qqmap_container"></div>
        </el-form-item>
        <el-form-item inline="true" class="demo-form-inline" label="店铺经纬度">
          <el-col :span="3">
            <el-input v-model="form.lng" placeholder="经度" @input="setMapCenter"></el-input>
          </el-col>
          <el-col :span="1" class="content-center">-</el-col>
          <el-col :span="3">
            <el-input v-model="form.lat" placeholder="纬度" @input="setMapCenter"></el-input>
          </el-col>
        </el-form-item>
      </el-card>
    </el-form>

    <div class="global_footer section-footer with-border content-center">
      <el-button size="large" @click="cancelSubmit" style="margin-right: 15px">取消</el-button>
      <el-button
        type="primary"
        @click="submitItemsActionConfirm"
        :loading="submitLoading"
      >{{submitLoading ? '提交中' : '保存'}}</el-button>
    </div>
    <div id="qqmap_infowin" v-show="qqmap_infowin_flag">
      <el-col>
        <el-button type="primary" @click="imp_poi(poi_info)">导入该位置信息</el-button>
      </el-col>
      <el-col>{{poi_info.name}}</el-col>
      <div class="frm-tips">{{poi_info.address}}</div>
    </div>
    <imgPicker
      :dialog-visible="imgDialog"
      :sc-status="isGetImage"
      @chooseImg="pickImg"
      @closeImgDialog="closeImgDialog"
    ></imgPicker>

    <DealerSelect :store-visible="DealerVisible" :is-valid="false" :rel-data-ids="relDealers" :get-status="DealerStatus" @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogAction" :maxSelect="1"></DealerSelect>
  </section>
</template>
<script>

import imgPicker from '@/components/imageselect'
import district from '../../../common/district.json'
import { createTemporaryDeliveryPlace, updateTemporaryDeliveryPlace, getTemporaryDeliveryPlaceDetail, getDealerList } from '@/api/marketing'
import { getRegionauth } from '@/api/regionauth'
import DealerSelect from '@/components/function/dealerSelect'
import { dragIssuesOptions } from '@/consts'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'

// 取选中地区的值
function getCascaderObj(val, opt) {
  return val.map(function (value, index, array) {
    for (var itm of opt) {
      if (itm.value === value) {
        opt = itm.children
        return itm
      }
    }
    return null
  })
}

export default {
  components: {
    imgPicker,
    DealerSelect,
    draggable,
  },
  data() {
    return {
      imgDialog: false,
      isGetImage: false,
      DealerVisible: false,
      DealerStatus: false,
      relDealers: [],
      login_type: '',

      hours: [
        {
          startWeek: '周一',
          endWeek: '周日',
          startTime: '08:00',
          endTime: '21:00'
        }
      ],
      startWeek: '',
      endWeek: '',
      startTime: '08:00',
      endTime: '21:00',
      submitLoading: false,
      pickerImgType: 'logo',
      form: {
        name: '',
        contact_phone: '',
        login_type: '',
        is_valid: 'true',
        address: '',
        code: '',
        dealer_id: 0,
        lat: 39.916527,
        lng: 116.397128,
        project_code: '',
        customer_code: '',
        start_station_code: '',
      },
      id: 0,
      distributor_type: '',
      searchRegion: '',
      qqmap_infowin_flag: false,
      regions: district,
      searchService: [],
      markers: [],
      poi_info: [],
      rules: {
        name: [{ required: true, message: '发货点名称不能为空', trigger: 'blur' }],
        contact_phone: [{ required: true, message: '联系方式不能为空', trigger: 'blur' }],
        code: [
          { required: true, message: '发货点编码不能为空', trigger: 'blur' },
          { pattern: /^[A-Za-z0-9]+$/, message: '请输入正确格式的编号号' }
        ],
        project_code: [{ required: true, message: '项目编码不能为空', trigger: 'blur' }],
        customer_code: [{ required: true, message: '客户编码不能为空', trigger: 'blur' }],
        start_station_code: [{ required: true, message: '站点编码不能为空', trigger: 'blur' }],
        address: [{ required: true, message: '地址不能为空', trigger: 'blur' }],
      },
      regionauth_list: [],
      isOpen:false,
      options: [{
        value: '周一',
        label: '周一'
      }, {
        value: '周二',
        label: '周二'
      }, {
        value: '周三',
        label: '周三'
      }, {
        value: '周四',
        label: '周四'
      }, {
        value: '周五',
        label: '周五'
      }, {
        value: '周六',
        label: '周六'
      }, {
        value: '周日',
        label: '周日'
      }],
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    pickImg(data) {
      if (this.pickerImgType === 'logo') {
        this.form.logo = data.url !== undefined ? data.url : ''
      } else if (this.pickerImgType === 'wechat_img') {
        this.form.wechat_img = data.url !== undefined ? data.url : ''
      } else if (this.pickerImgType === 'business_license_img') {
        this.form.business_license_img = data.url !== undefined ? data.url : ''
      } else {
        this.form.shop_pics.push(data.url)
      }
      this.imgDialog = false
    },
    handleImgPicker(pickerImgType) {
      this.pickerImgType = pickerImgType
      this.imgDialog = true
      this.isGetImage = true
    },
    closeImgDialog() {
      this.imgDialog = false
      this.isGetImage = false
    },
    cancelSubmit() {
      this.$router.go(-1)
    },
    removePicsImg (index) {
      this.form.shop_pics.splice(index, 1)
    },
    submitItemsActionConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.searchRegion) {
            this.$message.error('请选择地区');
            return false
          }
          if (!this.form.address) {
            this.$message.error('请输入地址');
            return false
          }
          if (!this.form.lat || !this.form.lng) {
            this.$message.error('经纬度不能为空')
            return false
          }
          this.submitLoading = true
          this.form.dealer_id = this.relDealers[0] != undefined ? this.relDealers[0].dealer_id : 0
          if (this.id) {
            let form = {
              ...this.form,
              id: this.id
            }
            updateTemporaryDeliveryPlace(form)
              .then((res) => {
                this.submitLoading = false
                this.$message({ type: 'success', message: '修改临时发货点成功' })
                this.$router.go(-1);
              })
              .catch((error) => {
                this.submitLoading = false
              })
          } else {
            createTemporaryDeliveryPlace(this.form)
              .then((res) => {
                this.submitLoading = false
                this.$message({ type: 'success', message: '保存临时发货点成功' })
                this.$router.go(-1);
              })
              .catch((error) => {
                this.submitLoading = false
              })
          }
        } else {
          this.$message.error('请检查配置后重试')
          return false;
        }
      });
    },
    handleRegionChange: function (value) {
      var vals = getCascaderObj(value, this.regions)
      this.form.regions_id = []
      this.form.regions = []
      for (var i = 0; i < vals.length; i++) {
        this.form.regions_id[i] = vals[i].value
        this.form.regions[i] = vals[i].label
      }

      if (this.form.regions[0] == '北京市' || this.form.regions[0] == '天津市' || this.form.regions[0] == '上海市' || this.form.regions[0] == '重庆市') {
        this.searchRegion = this.form.regions[0] + this.form.regions[2]
      } else {
        if (this.form.regions[1] === this.form.regions[2]) {
          this.searchRegion = this.form.regions[0] + this.form.regions[1]
        } else {
          this.searchRegion = this.form.regions[0] + this.form.regions[1] + this.form.regions[2]
        }
      }
    },
    initMap (lat, lng) { // 初始化地图
      const center = new qq.maps.LatLng(lat, lng)
      this.map = new qq.maps.Map(document.getElementById('qqmap_container'), {
        center: center,
        zoom: 13,
      })

      this.initSearchService()
      this.setMarker(center)
    },
    setMapCenter () {
      this.clearOverlays(this.markers)
      const center = new qq.maps.LatLng(this.form.lat,this.form.lng)
      this.map.setCenter(center)
      this.setMarker(center)
    },
    setMarker (center) {
      var marker = new qq.maps.Marker({
        position: center,
        map: this.map,
      })
      this.markers.push(marker)
    },
    initSearchService () {
      var that = this
      //设置Poi检索服务，用于本地检索、周边检索
      this.searchService = new qq.maps.SearchService({
        panel: document.getElementById('qqmap_container'),
        //检索成功的回调函数
        complete: function (results) {
          //设置回调函数参数
          var pois = results.detail.pois
          var infoWin = new qq.maps.InfoWindow({
            map: that.map,
          })
          var latlngBounds = new qq.maps.LatLngBounds()
          for (var i = 0, l = pois.length; i < l; i++) {
            var poi = pois[i]
            //扩展边界范围，用来包含搜索到的Poi点
            latlngBounds.extend(poi.latLng)
            ;(function (n) {
              var marker = new qq.maps.Marker({
                map: that.map,
              })
              marker.setPosition(pois[n].latLng)

              marker.setTitle(i + 1)
              that.markers.push(marker)
              qq.maps.event.addListener(marker, 'click', function () {
                infoWin.open()
                that.qqmap_infowin_flag = true
                that.poi_info = pois[n] //将选点位置信息存入poi_info
                infoWin.setContent(document.getElementById('qqmap_infowin'))
                infoWin.setPosition(pois[n].latLng)
              })
            })(i)
          }
          that.map.fitBounds(latlngBounds)
        },
        //若服务请求失败，则运行以下函数
        error: function (e) {
          this.$message.error('未查询到数据')
        },
      })

      console.log(this.searchService, 485)
    },
    clearOverlays: function (overlays) {
      //清除地图上的marker
      var overlay
      while ((overlay = overlays.pop())) {
        overlay.setMap(null)
      }
    },
    searchKeyword () {
      //设置搜索的范围和关键字等属性
      if (!this.searchRegion) {
        this.$message({
          message: '请选择地区',
          type: 'warning',
        })
        return ''
      }
      if (!this.form.address) {
        this.$message({
          message: '请输入具体位置',
          type: 'warning',
        })
        return ''
      }
      var region = this.searchRegion
      var keyword = this.form.address
      var pageIndex = 0
      var pageCapacity = 5
      this.clearOverlays(this.markers)
      //根据输入的城市设置搜索范围
      this.searchService.setLocation(region)
      //设置搜索页码
      this.searchService.setPageIndex(pageIndex)
      //设置每页的结果数
      this.searchService.setPageCapacity(pageCapacity)
      //根据输入的关键字在搜索范围内检索
      this.searchService.search(keyword)
    },
    imp_poi: function (poi_info) {
      this.form.lng = poi_info.latLng.lng
      this.form.lat = poi_info.latLng.lat
      this.form.address = poi_info.name
    },
    removeByValue: function (arr, val) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] == val) {
          arr.splice(i, 1)
          break
        }
      }
      return arr
    },
    getRegionauthList() {
      this.loading = true
      getRegionauth().then((res) => {
        this.regionauth_list = res.data.data.list
        this.regionauth_total = res.data.data.total_count
        this.loading = false
      })
    },
    openDialog () {
      this.DealerVisible = true;
    },
    DealerChooseAction (data) {
      console.log(541, data)
      this.DealerVisible = false
      this.DealerStatus = false
      // if (data === null || data.length <= 0) return
      this.relDealers = data
    },
    closeDialogAction () {
      this.DealerStatus = false
      this.DealerVisible = false
    },
    getDealer(ids) {
      if (ids > 0) {
        let param = {dealer_id: ids}
        getDealerList(param).then( res => {
          this.relDealers = res.data.data.list
        })
      }
    },
  },
  mounted() {
    this.getRegionauthList()

    this.id = this.$route.query.id
    this.login_type = this.$store.getters.login_type

    if (this.id) {
      getTemporaryDeliveryPlaceDetail({ id: this.id }).then((res) => {
        this.form = {
          ...res.data.data,
        }

        this.searchRegion = this.form.area
        if (!this.form.lat) {
          this.initMap()
        } else {
          this.initMap(this.form.lat, this.form.lng)
        }
        this.getDealer(res.data.data.dealer_id)
      })
    } else {
      // 添加门店时初始化地图
      this.initMap(this.form.lat, this.form.lng)
    }
    this.distributor_type = this.$route.query.distributor_type
    if (this.distributor_type) {
      this.form.distributor_self = 1
    }
  },
  destroyed () {
    this.map = null
  }
}
</script>

<style lang="scss" scoped>
.pics-box {
  overflow: hidden;
  .goodspic-wrap {
    float: left;
    margin-right: 5px;
    overflow: hidden;
    .goodspic {
      position: relative;
      float: left;
      width: 120px;
      height: 120px;
      margin: 0 5px 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      .goodspic-mask {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.2);
        cursor: pointer;
        &.on {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .iconfont {
          margin: 0 8px;
          font-size: 20px;
          color: #fff;
        }
      }
      .goodspic-mask:hover{
        display: block;
      }
    }
    .goodspic:hover{
      .goodspic-mask{
        display: block;
      }
    }
  }

  .upload-box {
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    .iconfont {
      font-size: 30px;
      color: #ccc;
    }
  }
}
.bm-view {
  width: 100%;
  height: 300px;
}
#qqmap_rslist {
  border-right: 1px solid #e7e7eb;
}
#qqmap_container {
  float: left;
  width: 800px;
  height: 400px;
}
.time-hours {
  margin-bottom: 20px;
}
.setting-remove {
  display: inline-block;
}
.box-card {
  width: 480px;
}

.global_footer{
  position:fixed;
  right:0;
  bottom:0;
  width:calc(100% - 250px);
  background: #fff;
  padding: 12px 24px;
  line-height: 44px;
  border-top: 1px solid #f0f0f0;
  box-shadow: 0 -6px 16px -8px rgba(0 ,0, 0,0.08),
  0 -9px 28px 0 rgba(0, 0, 0 , 0.05),
  0 -12px 48px 16px rgba(0 ,0, 0, 0.03);
  transition: width .3s cubic-bezier(.645,.045,.355,1);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
