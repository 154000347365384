import { render, staticRenderFns } from "./pricecheckindex.vue?vue&type=template&id=6ece4255&scoped=true"
import script from "./pricecheckindex.vue?vue&type=script&lang=js"
export * from "./pricecheckindex.vue?vue&type=script&lang=js"
import style0 from "./pricecheckindex.vue?vue&type=style&index=0&id=6ece4255&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ece4255",
  null
  
)

export default component.exports