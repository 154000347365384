<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1"v-loading="postloading" element-loading-text="生成中,请勿刷新页面！">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input  class="input-b" placeholder="请输入平台优惠券标识" v-model="params.platform_coupon_id"     size="medium">
            <template slot="append">
              <el-button  @click="getBindLists">搜索</el-button>
            </template>
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-button
            v-if="login_type === 'disti'"
            type="primary"
            size="medium"
            icon="el-icon-circle-plus"
            @click="handleNew">
            绑定优惠券
          </el-button>
        </el-col>

      </el-row>
      <el-tabs type="border-card">
        <el-table :data="list" style="width: 100%" :height="wheight-190" v-loading="loading" element-loading-text="数据加载中">
          <el-table-column prop="card_id" width="100" label="优惠券ID"></el-table-column>
          <el-table-column prop="card_name" width="200" label="优惠券标题"></el-table-column>
          <el-table-column prop="source" min-width="60" label="所属平台">
            <template slot-scope="scope" >
              <el-tag v-if="scope.row.source === 'EC'">EC员工购</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="platform_coupon_id" min-width="100" label="平台优惠券标识">

            <template slot-scope="scope" >
              <el-tag effect="plain" type="success">{{ scope.row.platform_coupon_id }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="description" min-width="100" label="绑定说明"></el-table-column>
          <el-table-column prop="bind_time" width="160" label="绑定时间">
            <template slot-scope="scope" >
              <span v-if="scope.row.bind_time">{{ scope.row.bind_time | datetime("yyyy-MM-dd hh:mm:ss") }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="bind_time" width="100" label="操作">
            <template slot-scope="scope" >
              <el-button type="text"  size="mini" icon="el-icon-delete" @click="deleteBind(scope.row.card_id,scope.row.source)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count' :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>
    </div>
    <sideBar :visible.sync="show_sideBar" :title="'新增绑定信息'">
      <el-form>
        <el-form-item label="平台优惠券标识">
          <el-input v-model="form.platform_coupon_id" />
        </el-form-item>
        <el-form-item label="绑定说明">
          <el-input v-model="form.description" />
        </el-form-item>
        <el-form-item label="所属平台">
          <template>
            <el-select v-model="form.source" placeholder="请选择">
              <el-option
                v-for="item in sources"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-form-item>

        <el-form-item label="">
          <el-col :span="20">
              <el-button type="primary" class="el-icon-plus" @click="relGiftsCouponClick" size="mini"round>选优惠券</el-button>
            <template>
              <el-table :data="couponList" stripe border	 highlight-current-row	 style="width: 100%;margin-top: 10px">
                <el-table-column prop="id" label="ID" width="50"></el-table-column>
                <el-table-column prop="title" label="名称" width="100"></el-table-column>
                <el-table-column prop="option" label="操作" >
                  <template slot-scope="scope">
                    <el-button type="text"  size="mini" icon="el-icon-delete"  @click="deleteCouponRow">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </sideBar>
    <CouponPicker
      unwantedGift
      :visible="giftCouponVisible"
      :statusIn="true"
      :searchFirstOrder = false
      :get-status="setGiftCouponStatus"
      :rel-items-ids="couponList"
      receive="condition"
      is-spec
      @pickCoupon="pickCouponAction"
      @closeDialog="pickCouponDialogAction"
    ></CouponPicker>
    <router-view></router-view>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ImportDialog from '@/components/importDialog'
import {getBindLists,saveBind,delBind} from '@/api/coupon'
import SideBar from '@/components/element/sideBar'
import CouponPicker from '@/components/coupon_picker'

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
    ImportDialog,
    CouponPicker,
    SideBar
  },
  data () {
    return {
      sources: [{
        value: 'EC',
        label: 'EC员工购'
      }],
      setGiftCouponStatus: false,
      giftCouponVisible: false,
      postloading: false,
      ImportDialogTitle: '',
      is_activity_end: false,
      activity_id: 0,
      distributorDialogVisible: false,
      distributorList: [],
      form: {
        source: '',
        card_id: '',
        description: '',
        platform_coupon_id: '',
      },
      show_sideBar: false,
      couponList: [],

      totalStoreCount: 0,
      distributorStatusLoading: false,
      activeName: 'all',
      cursymbol: '￥',
      loading: false,
      dialogLoading: true,
      create_time: '',
      params: {
        page: 1,
        pageSize: 10,
        activity_title: '',
        platform_coupon_id:''
      },
      activityItemParams: {
        page: 1,
        pageSize: 20,
      },
      activityItemTotalCount: 0,
      activityItemListsData: [],
      activityItemListsCouponData: [],
      total_count: 0,
      list: [],
      activityItemDialog: false,
      activityGiftDialog: false,
      ItemLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {
    handleNew() {
      this.show_sideBar = true
      this.resetData()
    },
    resetData() {
      this.form = {
        source: '',
        card_id: '',
        description: '',
        platform_coupon_id: ''
      }
      this.couponList = []
    },
    relGiftsCouponClick () {
      this.giftCouponVisible = true
      this.setGiftCouponStatus = true
    },
    pickCouponAction (data) {
      this.giftCouponVisible = false
      // 优惠券数据
      if (data.length > 1) {
        this.$message({type: 'error', message: '只允许选择一张优惠券'})
        return
      }
      this.couponList = data
      data.forEach((item, index) => {
        this.form.card_id = item.id
      })
      console.log('11111',this.form)
    },
    pickCouponDialogAction (data) {
      this.giftCouponVisible = false
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.loading = false
      this.getBindLists()
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getOfflineActivityList()
    },
    dateStrToTimeStamp (str) {
      return Date.parse(new Date(str))/1000
    },
    getBindLists() {
      this.loading = true
      let filter = {
        ...this.params,
        status: this.activeName
      }
      getBindLists(filter).then(response => {
        this.list = response.data.data.list
        console.log(this.list);
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      }).catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },
    save() {
      if (!this.form.platform_coupon_id) {
        this.$message({type: 'error', message: '请填写平台优惠券标识'})
        return
      }
      if (!this.form.description) {
        this.$message({type: 'error', message: '请填写绑定说明'})
        return
      }
      if (!this.form.source) {
        this.$message({type: 'error', message: '请选择所属平台'})
        return
      }
      if (!this.form.card_id) {
        this.$message({type: 'error', message: '请选择优惠券'})
        return
      }
      // 如果没有id，则表示为新增
      saveBind(this.form).then(res => {
        this.$message({type: 'success', message: '操作成功'})
        this.show_sideBar = false
        this.params.page = 1
        this.resetData()
        this.getBindLists()
      })
    },
    deleteCouponRow() {
      this.couponList = []
      this.form.card_id = ''
    },
    deleteBind(card_id,source) {
      // 如果没有id，则表示为新增
      let delData ={
        card_id:card_id,
        source:source,
      }
      delBind(delData).then(res =>{
        this.$message({ type: 'success', message: '删除成功' })
        this.show_sideBar = false
        this.params.page = 1
        this.resetData()
        this.getBindLists()
      })
    },
  },
  mounted () {
    this.getBindLists()
  },
  watch: {
    '$route' () {
      this.getBindLists()
    }
  },
}
</script>
<style scoped lang="scss">
.empty-box {
  font-size: 18px;
  color: #999;
  text-align: center;
  height: 300px;
  line-height: 50px;
}
</style>
