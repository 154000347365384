<template>
  <div>
    <el-row :gutter="20">
      <el-col>
        <el-input class="input-b" placeholder="参数名称" v-model="params.attribute_name" @change="brandSearch">
          <el-button slot="append" icon="el-icon-search" @click="brandSearch"></el-button>
        </el-input>
        <el-button type="primary" size="medium" icon="el-icon-circle-plus" @click="handleNew" v-if="login_type == 'admin' || login_type == 'staff' || login_type == 'disti'">新增参数</el-button>
        <ImportDialog
          buttonName="导入参数"
          buttonSize="medium"
          fileName="导入参数"
          fileType="upload_goods_params"
        />
        <div style="display: inline-block">
          <el-button type="primary" size="medium" @click="handleExport" style="" icon="el-icon-upload2">导出数据</el-button>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </div>
      </el-col>
    </el-row>
    <el-card>
      <el-table :data="list" :height="wheight-170" v-loading="loading" element-loading-text="数据加载中" :default-sort = "{prop: 'bind_date', order: 'descending'}">
        <el-table-column type="expand">
          <template slot-scope="props">
            <span class="sku-value" v-for="(item, index) in props.row.attribute_values.list" :key="index">
              （{{item.attribute_value_id}}）{{item.attribute_value}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" v-if="login_type == 'admin' || login_type == 'staff' || login_type == 'disti'">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(scope)">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="attribute_id" label="参数ID" min-width="100"></el-table-column>
        <!-- <el-table-column label="参数类型">
          <template slot-scope="props">
            {{JSON.parse(props.row.is_show) ? '高级筛选' : '纯显示'}}
          </template>
        </el-table-column> -->
        <el-table-column prop="attribute_name" label="参数名称" min-width="150"></el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.attribute_sort"
              :controls="false"
              :min="1"
              :precision="0"
              @change="(v) => handleSortChange(scope.row, v)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="attribute_memo" label="参数备注" min-width="150"></el-table-column>
      </el-table>
      <div v-if="total_count > params.pageSize" class="content-padded content-center">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="params.page"
          :page-sizes="[10,20,50]"
          :total="total_count"
          :page-size="params.pageSize">
        </el-pagination>
      </div>
    </el-card>
    <sideBar :visible.sync="show_sideBar" :title="'新增参数'">
      <el-form>
        <el-form-item label="参数名称">
          <el-input v-model="form.attribute_name" />
        </el-form-item>
        <el-form-item label="参数备注">
          <el-input v-model="form.attribute_memo" />
        </el-form-item>
        <!-- <el-form-item label="参数类型">
          <el-radio-group v-model="form.is_show">
            <el-radio :label="true">支持商品高级筛选</el-radio>
            <el-radio :label="false">仅用于商品详情展示</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="参数值">
          <div class="clearfix"></div>
          <Draggable
            handle='.icon-bars'
            :animation="300"
            :scroll = "true"
            :list="form.attribute_values"
          >
            <div v-for="(item, index) in form.attribute_values" :key="index" class="view-flex view-flex-middle key-item">
              <div class="view-flex-item content-padded-right">
                <el-input v-model="item.attribute_value" placeholder="参数值名称"></el-input>
              </div>
              <div class='iconfont icon-bars' style="margin-right: 4px;"></div>
              <div class="iconfont icon-trash-alt1" @click="removeItem(index)"></div>
            </div>
          </Draggable>
          <el-button type="default" @click="addItem" size="small">添加参数值</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </sideBar>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { getGoodsAttr, addGoodsAttr, updateGoodsAttrNew, deleteGoodsAttr, updateGoodsAttrSort } from '../../../../api/goods'
  import sideBar from '@/components/element/sideBar'
  import Draggable from "vuedraggable"
  import ImportDialog from '@/components/importDialog'

  export default {
    components: {
      sideBar,
      Draggable,
      ImportDialog
    },
    data () {
      return {
        login_type:'defalt',
        currentIndex: '',
        form: {
          attribute_type: 'item_params',
          attribute_id: '',
          attribute_name: '',
          attribute_memo: '',
          is_show: true,
          attribute_values: [],
        },
        params: {
          page: 1,
          pageSize: 20,
          attribute_type: 'item_params',
          attribute_name:''
        },
        list: [],
        loading: false,
        imgDialog: false,
        isGetImage: false,
        imgIndex: 0,
        brand_name: '',
        total_count: 0,
        show_sideBar: false
      }
    },
    methods: {
      handleDelete(data){
        this.$confirm('确认删除该参数？')
          .then(_ => {
            deleteGoodsAttr(data.row.attribute_id).then(res => {
              this.list.splice(data.$index, 1)
              this.$message({ type: 'success', message: '操作成功' })
            })
          })
          .catch(_ => {});
      },
      handleNew() {
        this.show_sideBar = true
        this.resetData()
      },
      resetData() {
        this.form = {
          is_image: false,
          attribute_type: 'item_params',
          attribute_id: '',
          attribute_name: '',
          attribute_memo: '',
          is_show: true,
          attribute_values: [],
        }
      },
      handleEdit(data) {
        this.show_sideBar = true
        this.form = {
          attribute_id: data.attribute_id,
          attribute_type: data.attribute_type,
          attribute_name: data.attribute_name,
          attribute_memo: data.attribute_memo,
          is_show: JSON.parse(data.is_show),
          attribute_values: data.attribute_values.list
        }
      },
      addItem() {
        if (this.form.attribute_values.length > 20) {
          this.$message({ type: 'warning', message: '最多添加20项'})
          return
        }
        let item = {
          attribute_value: ''
        }
        this.form.attribute_values.push(item)
      },
      handleCurrentChange (page_num) {
        this.params.page = page_num
        this.getList()
      },
      handleSizeChange (pageSize) {
        this.params.page = 1
        this.params.pageSize = pageSize
        this.getList()
      },
      handleExport() {
        this.getList(1)
      },
      async handleSortChange(row, v) {
        const { attribute_id } = row
        let data = {
          attribute_id,
          attribute_sort: v
        }
        await updateGoodsAttrSort(data)
        this.$message.success('操作成功')
        this.getList()
      },
      removeItem(index) {
        this.$confirm('确认删除当前值？')
          .then(_ => {
            this.form.attribute_values.splice(index, 1)
          })
          .catch(_ => {});
      },
      save() {
        let params = JSON.parse(JSON.stringify(this.form))
        if(params.attribute_values.length === 0 && params.is_show == true) {
          this.$message({ type: 'error', message: '参数类型为高级筛选类型，参数值不能为空'})
          return
        }
        params.attribute_values = JSON.stringify(params.attribute_values)
        // 如果没有id，则表示为新增
        if (!this.form.attribute_id) {
          addGoodsAttr(params).then(res =>{
            this.$message({ type: 'success', message: '操作成功' })
            this.params.page = 1
            this.resetData()
            this.getList()
          })
        } else {
          updateGoodsAttrNew(params).then(res =>{
            this.$message({ type: 'success', message: '操作成功' })
            this.getList()
          })
        }
      },
      getList(is_export = 0) {
        this.loading = true
        let params = {
          ...this.params,
          is_export
        }
        getGoodsAttr(params).then(res => {
          if (is_export) {
            this.$message.success('导出成功，请前往导出列表查看')
          } else {
            this.list = res.data.data.list
            this.total_count = res.data.data.total_count
          }
          this.loading = false
        })
      },
      // 品牌搜索
      brandSearch(){
        this.getList()
      }
    },
    mounted () {
      this.getList()
      this.login_type = this.$store.getters.login_type
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    }
  }
</script>
<style scoped lang="scss">
  .sku-value {
    margin-right: 10px;
  }
  .sku-img {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    vertical-align: middle;
  }
  .key-item {
    padding-bottom: 10px;
    .iconfont {
      color: #999;
    }
  }
</style>
