<style scoped lang="scss">
.store-name {
  padding-bottom: 5px;
  font-size: 16px;
  color: #333;
}
.store-contact {
  color: #888;
  span {
    margin-right: 10px;
  }
}
.store-address {
  color: #888;
}
.mt10 {
  margin-top: 10px;
}
</style>
<template v-loading="lzLoading"
          element-loading-text="加载中...">
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1 && $route.path.indexOf('_template') === -1">
      <el-row :gutter="20">
        <el-col>
          <el-select v-model="params.is_valid" @change="isValidSelectHandle" placeholder="是否启用" class="mt10">
            <el-option
              v-for="(item, index) in isValidList"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-cascader
            placeholder="根据地区筛选"
            :options="regions"
            filterable
            :props="{ checkStrictly: true }"
            @change="RegionChangeSearch"
            class="mt10"
            clearable
          ></el-cascader>
          <el-input class="input-m mt10" placeholder="发货点名称" v-model="params.name" clearable @clear="numberSearch"></el-input>
          <el-input class="mt10" style="width: 500px" placeholder="发货点编号" v-model="params.code" clearable @clear="numberSearch"></el-input>
          <el-input class="input-m mt10" placeholder="所属经销商" v-model="params.dealer_name" clearable @clear="numberSearch"></el-input>
          <el-input class="input-m mt10" placeholder="所属经销商HQID" v-model="params.dealer_hqid" clearable @clear="numberSearch"></el-input>
          <el-input class="input-m mt10" placeholder="分销商VAD" v-model="params.distributor_vad" clearable @clear="numberSearch"></el-input>
          <el-input class="input-m mt10" placeholder="渠道RTM" v-model="params.channel_rtm" clearable @clear="numberSearch"></el-input>
          <el-button icon="el-icon-search" @click="numberSearch" type="primary" class="mt10">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="clearSearch" type="primary" class="mt10">重置</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col style="display: flex">
          <el-button
            style="margin-left: 10px"
            v-if="!is_distributor "
            type="primary"
            plain
            icon="el-icon-circle-plus"
            @click="dialogOpen()"
          >添加临时发货点</el-button>
          <ImportDialog v-if="this.operator_type != 'distributor' && this.operator_type != 'dealer'" buttonName="导入临时发货点" style="margin-left: 10px" fileName="导入临时发货点模版" fileType="upload_temporary_place"></ImportDialog>
          <span>
            <el-button v-if="login_type !== 'admin'" type="primary" @click="exportData" v-loading="exportloading" style="margin-left: 10px">导出</el-button>
            <el-popover v-if="login_type !== 'admin'"
              placement="top-start"
              width="200"
              trigger="hover"
              content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
          </span>
        </el-col>
      </el-row>
      <el-card>
        <el-table :data="list" v-loading="loading" element-loading-text="加载中..." row-key="distributor_id">
          <el-table-column width="80" prop="id" label="ID"></el-table-column>
          <el-table-column label="发货点名称">
            <template slot-scope="scope">
              <div class="store-name">{{scope.row.name}}</div>
              <div class="store-contact">
                <span v-if="scope.row.code">
                  <i class="el-icon-s-shop"></i>
                  {{scope.row.code}}
                </span>
              </div>
              <div v-if="scope.row.address" class="store-address">
                <i class="el-icon-place"></i>
                {{scope.row.address}}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="240" label="所属经销商">
            <template slot-scope="scope">
              <div>
                {{scope.row.dealer_name}}
              </div>
              <div>
                {{scope.row.dealer_hqid}}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="200" label="分销商VAD">
            <template slot-scope="scope">
              <div>
                {{scope.row.distributor_vad}}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="200" label="渠道RTM">
            <template slot-scope="scope">
              <div>
                {{scope.row.channel_rtm}}
              </div>
            </template>
          </el-table-column>

          <el-table-column width="240" label="顺丰配置">
            <template slot-scope="scope">
              <div>
                项目编码：{{scope.row.project_code}}
              </div>
              <div>
                客户编码：{{scope.row.customer_code}}
              </div>
              <div>
                站点编码：{{scope.row.start_station_code}}
              </div>
            </template>
          </el-table-column>

          <el-table-column width="100" v-if="login_type !=='distributor' && login_type !== 'dealer' && login_type !== 'admin'" label="状态">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.is_valid !== 'delete'"
                type="text"
              >
                <div v-if="scope.row.is_valid == 'true'">
                  <span class="green">
                    启用<i class="el-icon-s-tools"></i>
                  </span>
                </div>
                <div v-else>
                  <span class="red" >
                    禁用
                    <i class="el-icon-s-tools"></i>
                  </span>
                </div>
              </el-button>
              <span v-else class="muted">废弃</span>
            </template>
          </el-table-column>
          <el-table-column v-if="login_type !=='distributor' && login_type !== 'dealer' && login_type !== 'admin'" width="180" label="操作">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.is_valid !== 'delete'"
                :to="{ path: matchHidePage('editor'), query: { id: scope.row.id}}"
              >编辑</router-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.page"
            :page-sizes="[2, 10, 20, 50, 100]"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total_count"
          ></el-pagination>
        </div>
      </el-card>

      <el-dialog
        title="修改状态"
        width="30%"
        :visible.sync="editValidDialog"
        :before-close="handleCancelLabelsDialog"
        :open="is_valid_updated = false"
      >
        <template>
          <el-radio-group v-model="editValidData.is_valid">
            <el-radio label="true" v-if="editValidData.examine_status == 4 || editValidData.examine_status == 0">启用</el-radio>
            <el-radio label="false">禁用</el-radio>
            <el-radio label="delete">废弃</el-radio>
            <el-radio label="audit" v-if="login_type == 'disti' && (editValidData.examine_status == 0 || editValidData.examine_status == 4)">下架</el-radio>
          </el-radio-group>
        </template>
      </el-dialog>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getTemporaryDeliveryPlaceList } from '../../../api/marketing'
import district from '../../../common/district.json'
import ImportDialog from '@/components/importDialog'

import store from '@/store'
// 取选中地区的值
function getCascaderObj (val, opt) {
  return val.map(function (value, index, array) {
    for (var itm of opt) {
      if (itm.value === value) {
        opt = itm.children
        return itm
      }
    }
    return null
  })
}

export default {
  data () {
    return {
      exportloading: false,
      lzLoading: false,
      is_distributor: false,
      isValidList: [
        { name: '全部', value: '' },
        { name: '启用', value: 'true' },
        { name: '禁用', value: 'false' },
        { name: '废弃', value: 'delete' },
      ],
      total_count: 0,
      detailDialog: false,
      loading: false,
      params: {
        page: 1,
        pageSize: 20,
        name: '',
        contact_phone: '',
        is_valid: '',
        province: '',
        city: '',
        area: '',
        code: '',
        dealer_name: '',
        dealer_hqid: '',
        distributor_vad: '',
        channel_rtm: '',
      },
      form: {
        distributor_id: null,
        mobile: '',
        address: '',
        name: '',
        is_valid: 'true',
        is_op_distribution: 'false',
        regions_id: [],
        regions: [],
        shop_id: 0,
        contact: '',
        is_distributor: 'true'
      },
      list: [],
      regions: district,

      editValidDialog: false,
      editDisDialog: false,
      editValidData: {
        distributor_id: '',
        is_valid: ''
      },
      dialog:false,
      title: '',
      operator_type: ''
    }
  },
  components: { ImportDialog },
  computed: {
    ...mapGetters(['wheight', 'login_type'])
  },
  watch: {
    'editValidData.is_valid': function (val, oldVal) {
      if (val && oldVal && !this.is_valid_updated) {
        this.editValidSubmit(val, oldVal)
      } else {
        this.is_valid_updated = false
      }
    } ,
  },
  methods: {
    exportData() {
      let params = {
        ...this.params,
        is_export: 1
      };
      getTemporaryDeliveryPlaceList(params).then((response) => {
        this.$message.success('导出成功')
      })
    },
    isValidSelectHandle () {
      this.params.page = 1
      this.getList()
    },
    numberSearch (e) {
      this.params.page = 1
      this.getList()
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getList()
    },
    dialogOpen (detail = null) {
      this.$router.push({ path: this.matchHidePage('editor') })
    },
    getList () {
      this.loading = true
      getTemporaryDeliveryPlaceList(this.params).then((response) => {
        if (response.data.data.list) {
          this.list = response.data.data.list
          this.total_count = response.data.data.total_count
        }
        this.loading = false
      })
    },
    RegionChangeSearch (value) {
      var vals = getCascaderObj(value, this.regions)
      if (vals.length == 1) {
        this.params.province = vals[0].label
        this.params.city = ''
        this.params.area = ''
      } else if (vals.length == 2) {
        this.params.province = vals[0].label
        this.params.city = vals[1].label
        this.params.area = ''
      } else if (vals.length == 3) {
        this.params.province = vals[0].label
        this.params.city = vals[1].label
        this.params.area = vals[2].label
      } else {
        this.params.province = ''
        this.params.city = ''
        this.params.area = ''
      }
      this.params.page = 1
      this.getList()
    },
    handleCancelLabelsDialog () {
      this.editValidDialog = false
      this.tag.dialog = false
    },
    handleCancelLabelsDialogDis () {
      this.editDisDialog = false
      this.tag.dialog = false
    },
    editValid (row) {
      this.editValidDialog = true
      this.editValidData = row
      this.is_valid_updated = true
    },

    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getList()
    },
    onCopy () {
      this.$notify.success({
        message: '复制成功',
        showClose: true
      })
    },

    clearSearch(){
      this.resetFilter();
      this.getList();
    },

    resetFilter(){
      this.params = {
        page: 1,
        pageSize: 20,
        name: '',
        mobile: '',
        is_valid: '',
        province: '',
        city: '',
        area: '',
        dealer_name: '',
        dealer_hqid: '',
        distributor_vad: '',
        channel_rtm: ''
      }
    }
  },

  mounted () {
    if (store.getters.login_type === 'distributor') {
      this.is_distributor = true
    }
    this.getList()
    this.operator_type = store.getters.login_type
  },
  beforeRouteUpdate (to, from, next) {
    next()
    if (to.path.indexOf('_editor') === -1 && to.path.indexOf('_detail') === -1) {
      this.getList()
    }
  }
}
</script>
