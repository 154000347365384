<template>
  <div class="section-body">
    <div class="name_text">赠品：{{ info.name }}</div>

    <el-row class="filter-header">
      <el-col :span="24">
        <el-date-picker v-model="chooseDate" type="datetimerange" unlink-panels start-placeholder="开始日期"
          end-placeholder="结束日期" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
        <el-button size="small" type="primary" @click="listSearch">查询</el-button>
      </el-col>
    </el-row>

    <el-table :data="itemList">
      <el-table-column v-if="info.type == '1'"  prop="name" label="门店" width="200">
      </el-table-column>
      <el-table-column label="库存变更类型" width="200">
        <template slot-scope="scope">
          <span>{{ scope.row.type == '1' ? '增量' : '全量' }}</span>
        </template>>
      </el-table-column>
      <el-table-column prop="stock" label="库存变更记录">
      </el-table-column>
      <el-table-column label="变更时间" width="200">
        <template slot-scope="scope">
          <span>{{ timestampToTime(scope.row.updated) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="operator_user" label="操作账号">
      </el-table-column>
      <el-table-column label="操作类型">
        <template slot-scope="scope">
          <span>{{scope.row.operator_type}}</span>
        </template>>
      </el-table-column>
    </el-table>

    <div class="content-center content-top-padded">
      <el-pagination background layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" :current-page.sync="listParams.page" :page-sizes="[10, 20, 50]"
        :total="total_count" :page-size="listParams.page_size">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getLotteryGiftStockLog } from '@/api/lotteryGift'

export default {
  props: {
    info: {
      type: Object,
      default: {}
    }
  },
  watch: {
    info: {
      deep: true,
      handler(value) {
        if (value) {
          this.setData(value)
        }
      }
    },
  },
  data() {
    return {
      itemList: [],
      total_count: 0,
      chooseDate: [],
      listParams: {
        page: 1,
        page_size: 10,
        gid: '',
        begin_time: '',
        end_time: ''
      }
    }
  },
  methods: {
    setData(val) {
      this.listParams.gid = val.id
      this.lotteryGiftStockLog()
    },
    listSearch() {
      if (this.chooseDate && this.chooseDate.length > 0) {
        this.listParams.begin_time = this.dateStrToTimeStamp(this.chooseDate[0])
        this.listParams.end_time = this.dateStrToTimeStamp(this.chooseDate[1])
      } else {
        this.listParams.begin_time = ''
        this.listParams.end_time = ''
      }
      this.listParams.page = 1
      this.lotteryGiftStockLog()
    },
    dateStrToTimeStamp(str) {
      return Date.parse(new Date(str)) / 1000
    },
    lotteryGiftStockLog() {
      let params = { ...this.listParams }
      getLotteryGiftStockLog(params).then((res) => {
        let list = res.data.data
        if (list.total_count > 0) {
          this.itemList = list.list
          this.total_count = list.total_count
        } else {
          this.itemList = []
          this.total_count = 0
        }
      })
    },
    handleCurrentChange(page_num) {
      this.listParams.page = page_num
      this.lotteryGiftStockLog()
    },
    handleSizeChange(pageSize) {
      this.listParams.page = 1
      this.listParams.page_size = pageSize
      this.lotteryGiftStockLog()
    },
    timestampToTime(timestamp) {
      // return timestamp
      var date = new Date(timestamp * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var YY = date.getFullYear() + '-'
      var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return YY + MM + DD + ' ' + hh + mm + ss
    },
  },
  mounted() {
    this.setData(this.info)
  }
}
</script>

<style scoped lang="scss">
.name_text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px
}
.el-input__inner{
  width: 425px;
}
</style>
