import { createSetting } from '@shopex/finder'
import { batchDelete, devicesSort, devicesShow } from '../api'
import { useDialog } from '@shopex/finder'

const commonActions = [
  { label: '取消', key: 'close', size: 'default' },
  { label: '提交', key: 'save', type: 'primary', size: 'default' }
]

export const tableSetting = (vm) => createSetting({
  search: [
    { name: '设备名称', key: 'name' },
    { name: '设备ID', key: 'device_id' },
    {
      name: '设备分类',
      key: 'category_id',
      type: 'select',
      options: vm.categoryList,
      clearable: true
    },
  ],
  columns: [
    { name: 'ID', key: 'device_id', minWidth: 120 },
    { name: '设备名称', key: 'name', minWidth: 150 },
    {
      name: '设备图片',
      key: 'pic',
      minWidth: 150,
      render: (h, { row }) => {
        return (
          <el-image
            src={row.pic}
            fit="cover"
            style="width: 60px; height: 60px"
          />
        )
      }
    },
    { name: '设备分类', key: 'category_name', minWidth: 150 },
    {
      name: '排序',
      key: 'sort',
      showType: 'input-number',
      minWidth: '120px',
      headRender: (h) => {
        return (
          <div>
            优先级
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              content="排序数值越大，活动展示的位置越靠前，数值相同则参考创建时间由近到远排序"
            >
              <span slot="reference"><i class="el-icon-info"></i></span>
            </el-popover>
          </div>
        )
      },
      componentProps: {
        min: 0,
        precision: 0,
        controls: false,
        placeholder: '请输入',
        change: (v, row) => {
          devicesSort({ sort: v, device_id: row.device_id }).then(res => {
            vm.$message.success('修改成功!')
            vm.refresh()
          }).catch(err => {
            vm.$message.error('修改失败!')
            vm.refresh()
          })
        }
      }
    },
    {
      name: '添加时是否展示',
      key: 'is_show',
      showType: 'switch',
      minWidth: '80px',
      componentProps: {
        activeValue: '1',
        inactiveValue: '0',
        change: (v, row) => {
          devicesShow({ is_show: v, device_id: row.device_id }).then(res => {
            vm.$message.success('修改成功!')
            vm.refresh()
          }).catch(err => {
            vm.$message.error('修改失败!')
            vm.refresh()
          })
        }
      }
    },
  ],
  actions: [
    {
      name: '添加设备',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async () => {
          useDialog(null, {
            title: `添加设备`,
            width: '50%',
            categoryList: vm.categoryList,
            component: () => import('../detail.vue'),
            actions: commonActions
          }).then((args) => {
            if(args) {
              vm.refresh()
            }
          })
        }
      }
    },
    {
      name: '导入',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openImportDialog()
        }
      }
    },
    {
      name: '编辑',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: async (row) => {
          useDialog(null, {
            title: `编辑设备`,
            width: '50%',
            categoryList: vm.categoryList,
            id: row[0].device_id,
            component: () => import('../detail.vue'),
            actions: commonActions
          }).then((args) => {
            if(args) {
              vm.refresh()
            }
          })
        }
      }
    },
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: async (rows) => {
          vm.$confirm('此操作将删除该设备, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            await batchDelete(rows[0].device_id)
            vm.$message.success('删除成功')
            vm.refresh()
          })
        }
      }
    },
  ]
})
