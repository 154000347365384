<template>
  <div>
    <el-card>
      <div style="width: 70%;">
        <el-form v-loading="loading" label-width="180px" :model="form">
          <el-form-item label="物流费用">
            <el-radio-group v-model="form.freight_type">
              <el-radio :label="'cash'">按现金</el-radio>
              <el-radio :label="'point'">按积分</el-radio>
            </el-radio-group>
            <br>
            <span class="tip">说明：运费使用现金或积分支付</span>
          </el-form-item>
          <el-form-item v-if="form.freight_type == 'point'" label="积分商城汇率设置">
              <el-input  type="number"  v-model="form.proportion" placeholder="" style="width: 120px;" :min="1" :max="9999999"/>积分 抵扣1元人民币
          </el-form-item>
          <el-form-item v-if="form.freight_type == 'point'" label="积分取整设置">
            <el-radio-group v-model="form.rounding_mode">
              <el-radio :label="'down'">向下取整</el-radio>
              <el-radio :label="'up'">向上取整</el-radio>
            </el-radio-group>
            <br>
            <span class="tip">说明：运费使用现金或积分支付</span>
          </el-form-item>
          <el-form-item size="large">
            <el-button>取消</el-button>
            <el-button type="primary" @click="save">保存</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-card>


  </div>
</template>
<script>
  import {mapGetters} from 'vuex'
  import {getSetting, saveSetting} from '../../../api/pointsmall'

  export default {
    components: {},

    data () {
      return {
        loading: false,
        form: {
          freight_type: 'cash',
          proportion: 0,
          rounding_mode: 'down'
        }
      }
    },
    mounted () {
      this.getInfo()
    },
    methods: {
      // 输入限制小数点两位
      handleInput2 (e) {
        // 通过正则过滤小数点后两位
        e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
      },
      // 获取列表
      getInfo () {
        this.loading = true
        getSetting().then(res => {
          this.form.freight_type = res.data.data.freight_type
          this.form.proportion = res.data.data.proportion
          this.form.rounding_mode = res.data.data.rounding_mode
          this.loading = false
        })
      },
      // 保存数据
      save () {
        saveSetting(this.form).then(res => {
          this.$message({type: 'success', message: '操作成功'})
          this.getInfo()
        })
      }
    },
    computed: {
      ...mapGetters([
        'wheight'
      ])
    }
  }
</script>
<style scoped lang="scss">
  .tip {
    font-size: 12px;
    color: #909399;
  }
</style>
