<template>
  <div>
    <mallListDistributor v-if="store" :rel-store="store" />
  </div>
</template>

<script>
  import MallList from '@/components/function/mallList'
  import mallListDistributor from '@/components/function/mallListDistributor'
    export default {
      components: {
        MallList,
        mallListDistributor
      },
      data () {
        return {
          store: null
        }
      },
      mounted () {
        const {distributor_id, address, name} = this.$route.query
        this.store = {
          id: distributor_id,
          address,
          name
        }
      }
    }
</script>
