<template>
  <div class="container">
    <div v-if="$route.path.indexOf('detail') === -1">
      <el-form :inline="true" :model="pagination_info" ref="pagination_info" class="demo-form-inline">
        <el-form-item label="评分" prop="region1">
          <el-select v-model="pagination_info.relation_star" multiple placeholder="请选择" :clearable="true">
            <el-option v-for="(item, index) in relationStar" :key="'num' + index" :label="item.title"
              :value="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="btn_input" @click="onDialog('shop')">{{ shop_num }} <i
              class="el-icon-circle-plus icon"></i></el-button>
        </el-form-item>
        <el-form-item v-if="agency">
          <el-button class="btn_input" @click="onDialog('sgency')">{{ agency_num }} <i
              class="el-icon-circle-plus icon"></i></el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getAlternateDistributors(0)">查询</el-button>
          <el-button type="primary" @click="exportAlternateDistributors">导出</el-button>
        </el-form-item>
      </el-form>

      <el-card v-loading="listLoading">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="店铺">
          </el-table-column>
          <el-table-column prop="shop_code" label="Apple ID">
          </el-table-column>
          <el-table-column label="评分">
            <template slot-scope="scope">
              <div class="elRate">
                 <span style="margin-right:5px;">{{ scope.row.avg_score_float ? scope.row.avg_score_float : 0 }}</span>
<!--                <div>
                                   <i v-for="(x, d) in scope.row.avg_score" :key="d" class="el-icon-star-on"></i>
                                 </div>-->
                <el-rate
                  v-model="scope.row.avg_score / 10"
                  disabled
                  :colors="['#479CE7', '#479CE7', '#479CE7']"
                  text-color="#000">
                </el-rate>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="dealer_hqid" label="经销商HQID">
          </el-table-column>
          <el-table-column prop="dealer_name" label="所属经销商">
          </el-table-column>
          <el-table-column prop="evaluation_number" label="评价次数">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="onsee(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <div class="pagination" style="padding-bottom: 20px; text-align: center">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :page-sizes="[2, 10, 20, 50, 100]" :page-size="pagination_info.pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="pagination_info.total">
        </el-pagination>
      </div>

      <DistributorSelect :store-visible="distributorVisible" :is-valid="true" :relDataIds="distributor_info"
        :get-status="setDistributorStatus" @chooseStore="chooseDistributorAction"
        @closeStoreDialog="closeDialogAction" returnType="selectRow"></DistributorSelect>


      <DealerSelect :store-visible="DealerVisible" :is-valid="isValid" :rel-data-ids="relDealers"
        :get-status="DealerStatus" @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogAction"
        :isSingleSelect="is_single_select"></DealerSelect>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { getDistributorList } from '@/api/marketing'
import DistributorSelect from '@/components/function/distributorSelect'
import DealerSelect from '@/components/function/dealerSelect'
import { mapGetters } from 'vuex'


export default {
  components: {
    DistributorSelect,
    DealerSelect,
  },
  data() {
    return {
      listLoading: false,
      tableData: [],
      relationStar: [
        { title: '不太满意（一星）', label: '1' },
        { title: '有待改进（二星）', label: '2' },
        { title: '还不错（三星）', label: '3' },
        { title: '满意（四星）', label: '4' },
        { title: '非常满意（五星）', label: '5' }
      ],
      rateText: [
        '不太满意（一星）',
        '有待改进（二星）',
        '还不错（三星）',
        '满意（四星）',
        '非常满意（五星）',
      ],
      pagination_info: {
        page: 1,
        pageSize: 20,
        relation_star: [],
        distributor_ids: '',
        dealer_ids: '',
        total: 0
      },
      shop_num: '选择店铺',
      agency_num: '选择经销商',
      agency: true,
      dialog_type: 1,
      distributorVisible: false,
      setDistributorStatus: false,
      distributor_info: [],
      DealerVisible: false,
      isValid: true,
      relDealers: [],
      DealerStatus: false,
      is_single_select: false,

      colors: ['#409EFF', '#409EFF', '#409EFF']
    }
  },
  computed: {
    ...mapGetters([
      'login_type'
    ])
  },
  methods: {
    onsee(i) {
      let routeDate = this.$router.resolve({path: this.matchHidePage('detail'), query: {distributor_id: i.distributor_id}})
      window.open(routeDate.href, '_blank')
    },
    getAlternateDistributors(is_export = 0) {
      this.listLoading = true
      let params = {
        ...this.pagination_info,
        export: is_export,
      }
      if (is_export === 1) {
        params.export_type = 'evaluation'
      }
      getDistributorList(params).then((response) => {
        if (is_export === 1) {
          this.$message.success('导出成功，请前往导出列表查看')
        } else {
          if (response.data.data.list) {
            /*var arr = response.data.data.list
            arr.forEach((item) => {
              item.avg_score = Math.floor(item.avg_score / 10)
            })*/
            this.tableData = response.data.data.list
            this.pagination_info.total = response.data.data.total_count
          }
        }
        this.listLoading = false
      })
    },
    exportAlternateDistributors() {
      this.getAlternateDistributors(1);
    },
    handleCurrentChange(val) {
      this.pagination_info.page = val
      this.getAlternateDistributors()
    },
    handleSizeChange(v) {
      this.pagination_info.pageSize = v
      this.getAlternateDistributors()
    },
    chooseDistributorAction(val) {
      this.distributor_info = val
      let arr = []
      val.forEach(i => {
        arr.push(i.distributor_id)
      });
      this.pagination_info.distributor_ids = arr.join(',')
      if (val.length > 0) {
        this.shop_num = `已选择${val.length}门店`
      } else {
        this.shop_num = '选择店铺'
      }
      this.closeDialogAction()
    },
    DealerChooseAction(val) {
      this.relDealers = val
      let arr = []
      val.forEach(i => {
        arr.push(i.dealer_id)
      });
      this.pagination_info.dealer_ids = arr.join(',')
      if (val.length > 0) {
        this.agency_num = `已选中${val.length}个经销商`
      } else {
        this.agency_num = '选择经销商'
      }
      this.closeDialogAction()
    },
    closeDialogAction() {
      if (this.dialog_type == 1) {
        this.distributorVisible = false
      } else {
        this.DealerStatus = false
        this.DealerVisible = false
      }
    },
    onDialog(type) {
      if (type == 'shop') {
        this.dialog_type = 1
        this.distributorVisible = true
      } else {
        this.dialog_type = 2
        this.DealerVisible = true
        this.DealerStatus = true
      }
    },
    filter(i) {
      let text = ''
      switch (i) {
        case '1':
          text = '不太满意'
          break;
        case '2':
          text = '有待改进'
          break;
        case '3':
          text = '还不错'
          break;
        case '4':
          text = '满意'
          break;
        case '5':
          text = '非常满意'
          break;
        default:
      }
      return text
    },
    transformation(num) {
      return Math.floor(num)
    }
  },
  mounted() {
    if(this.login_type == 'distributor'){
      this.agency = false;
    }
    this.getAlternateDistributors()
  }
}

</script>
<style scoped lang="scss">
.pagination {
  margin-top: 20px;
  text-align: right
}

.btn_input {
  .el-icon-circle-plus {
    margin-left: 20px !important;
  }
}

.elRate {
  display: flex;
  align-items: center;
}

.el-icon-star-on {
  color: #409EFF;
  font-size: 18px;
}
</style>
