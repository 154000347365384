<template>
  <div>
    <SpFinder
      ref="finder"
      row-key="goods_id"
      :split-count="10"
      :url="table_url"
      :setting="setting"
      fixed-row-action
      reserve-selection
      row-actions-fixed-align="left"
    />
    <ImportDialog
      ref="importAddDialog"
      v-show="false"
      buttonName="新增商品导入"
      buttonSize="medium"
      fileName="新增商品导入"
      fileType="upload_drafts_items"
    />
    <ImportDialog
      ref="importUpdateDialog"
      v-show="false"
      buttonName="更新商品导入"
      buttonSize="medium"
      fileName="更新商品导入"
      fileType="upload_update_drafts_items"
    />
  </div>
</template>
<script>
import { tableSetting } from './config'
import { List, handleExport } from './api'
import { getCategory, getGoodsAttr } from '@/api/goods'
import ImportDialog from '@/components/importDialog'

export default {
  components: {
    ImportDialog
  },
  data () {
    return {
      table_url: List,
      goodsBranchParams: {
        page: 1,
        pageSize: 1000,
        attribute_type: 'brand',
        attribute_name:''
      },
      goodsBranchList: [],
      categoryList: [],
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {
    this.getCategory()
  },
  methods: {
    getGoodsBranchList (searchVal = '') {
      this.goodsBranchParams.attribute_name = searchVal
      getGoodsAttr(this.goodsBranchParams).then(response => {
        const { data: { list } } = response.data
        this.goodsBranchList = list.map(item => ({
          label: item.attribute_name,
          value: item.attribute_id
        }))
      })
    },
    getCategory() {
      getCategory({is_show:false}).then(response => {
        this.categoryList = response?.data?.data
      })
    },
    openImportAddDialog() {
      this.$refs.importAddDialog.visible = true
    },
    openImportUpdateDialog() {
      this.$refs.importUpdateDialog.visible = true
    },
    openExportDialog() {
      const searchparams = this.$refs.finder.getSearchParams()
      const selection = this.$refs.finder.$refs.finderTable.getSelection()
      let params = {
        ...searchparams,
        is_export: 1,
        goods_id: selection?.map(item => item.goods_id)
      }
      handleExport(params).then(_ => {
        this.$message.success('导出成功，请前往导出列表查看')
      })
    },
    refresh() {
      this.$refs.finder.refresh()
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .search-field-value {
  width: auto !important;
}
::v-deep .sp-finder .cell .sp-finder-actions__wrap {
  justify-content: center !important;
}
::v-deep .goods-title {
  padding-bottom: 5px;
}
::v-deep .goods-code {
  color: #888;
  font-size: 13px;
  i {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.el-tooltip__popper {
  max-width: 80%;
}
</style>
