import { createSetting } from '@shopex/finder'
import { batchDelete } from '../api'

const ITEM_STATUS_ENUM = {
  onsale: '前台可销售',
  only_show: '前台仅展示',
  instock: '不可销售'
}

const TYPE_WAREHOUSE_ENUM = {
  1: '非配件',
  2: '配件'
}

const ITEM_STATUS_OPTIONS = formatEnumToOptions(ITEM_STATUS_ENUM)
const TYPE_WAREHOUSE_OPTIONS = formatEnumToOptions(TYPE_WAREHOUSE_ENUM)

/**
 * 将枚举值格式化成下拉
 * @param {Object} obj 枚举对象
 * @return {Array} 格式化后的下拉数组
*/
function formatEnumToOptions (obj) {
  let options = []
  for (let key in obj) {
    options.push({
      label: obj[key],
      value: key
    })
  }
  return options
}

export const tableSetting = (vm) => createSetting({
  search: [
    { name: '商品名称', key: 'item_name' },
    { name: '商品货号', key: 'item_bn' },
    {
      name: '商品分类',
      key: 'category',
      type: 'cascader',
      options: vm.categoryList,
      props: {
        checkStrictly: true,
        emitPath: false,
        value:'category_id',
      },
      showAllLevels: false,
      filterable: true,
      clearable: true
    },
    {
      name: '商品状态',
      key: 'approve_status',
      type: 'select',
      options: ITEM_STATUS_OPTIONS
    },
    {
      name: '商品标签',
      key: 'tag_id',
      type: 'cascader',
      options: vm.tagList,
      props: {
        value:'tag_id',
        label: 'tag_name'
      },
      filterable: true,
      clearable: true
    },
    {
      name: '市场分类',
      key: 'type_warehouse',
      type: 'select',
      options: TYPE_WAREHOUSE_OPTIONS
    },
  ],
  columns: [
    { name: '商品ID', key: 'item_id', minWidth: 120 },
    { name: '商品', key: 'item_name', minWidth: 150 },
    { name: '商品货号', key: 'item_bn', minWidth: 150 },
    { name: '商品规格', key: 'item_spec_desc', minWidth: 150 },
    { name: '市场分类',
      key: 'type_warehouse',
      minWidth: 150,
      render: (h, {row}) => {
        if(![1,2].includes(row.type_warehouse)) return ''
        return h('el-button', {
          props: {
            size: "mini",
            plain: true,
            type: row.type_warehouse === 1 ? 'warning' : 'primary'
          }
        }, TYPE_WAREHOUSE_ENUM[row.type_warehouse])
      }
    },
    { name: '销售价',
      key: 'price',
      minWidth: 150,
      formatter: (v) => '¥' + v / 100
    },
    { name: '商品状态',
      key: 'approve_status',
      minWidth: 150,
      formatter: (v) => ITEM_STATUS_ENUM[v]
    },
  ],
  actions: [
    {
      name: '导入',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openImportDialog()
        }
      }
    },
    {
      name: '导出',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openExportDialog()
        }
      }
    },
    {
      name: '批量删除',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async (rows) => {
          const ids = rows.map(v => v.item_id)
          await batchDelete(ids)
          vm.$message.success('删除成功')
          vm.refresh()
        }
      }
    },
  ]
})
