<template>
  <div>
      <!-- 绑定优惠活动 开始 -->
      <el-dialog
        title="优惠活动"
        width="80%"
        :visible.sync="visible"
        :before-close="handlePreferentialCancel"
      >
        <template>
          <el-table
            :data="preferentials"
            v-loading="bindPreferentialLoading"
            row-key="id"
            @selection-change="handlePreferentialSingleSelectionChange"
            ref="singleTable"
            style="width: 100%">
            <!-- <el-table-column type="selection" align="center" label="全选" :reserve-selection="true" width="50"></el-table-column> -->
            <el-table-column
              label="日期"
              width="100"
              :render-header="renderHeader"
            >
              <template slot-scope="scope">
                <input type="checkbox" :checked="checked(scope.row)" @click="e => check(e, scope.row)" />
              </template>
            </el-table-column>
            <el-table-column
              prop="id"
              label="ID"
              width="80">
            </el-table-column>
            <el-table-column
              prop="activity_title"
              label="活动标题">
            </el-table-column>
            <el-table-column
              prop="start_time"
              label="开始时间"
              width="180">
            </el-table-column>
            <el-table-column
              prop="end_time"
              label="结束时间"
              width="180">
            </el-table-column>
          </el-table>
          <el-pagination
            background
            @current-change="handleAllPreferentialPageChange"
            :current-page="allPreferentialPageNation.page"
            :page-size="allPreferentialPageNation.page_size"
            layout="total, prev, pager, next"
            :total="allPreferentialPageNation.total"
          ></el-pagination>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handlePreferentialCancel">取消</el-button>
          <el-button type="primary" @click="setSingleDistributorPreferential">保存</el-button>
        </div>
      </el-dialog>
      <!-- 绑定优惠活动 结束 -->
  </div>
</template>
<script>
import { getDistributorRelPreferential, setDistributorRelPreferential } from '@/api/marketing'
import { getActivityList } from '@/api/promotions'

export default {
  props: ['visible', 'distributor_id'],
  data() {
    return {
      preferentialVisible: false,
      selectPreferentialDistributorId: 0,
      // 所有优惠活动
      preferentials: [],
      bindPreferentialLoading: false,
      single_preferential_ids: [],
      preferentialPageNation: {
        page: 1,
        page_size: 20,
        total: 0
      },
      allPreferentialPageNation: {
        page: 1,
        page_size: 20,
        total: 0
      },
    }
  },
  watch: {
    'editValidData.is_valid': function(val, oldVal) {
      if(val && oldVal && !this.is_valid_updated) {
        this.editValidSubmit(val, oldVal)
      } else {
        this.is_valid_updated = false
      }
    },
    distributorRelPreferential: function(val) {
      if(val && val.length > 0) {
        this.preferentials && this.preferentials.forEach(row => {
          if(val.findIndex(item => item.id === row.id) > -1) {
            this.$refs.singleTable.toggleRowSelection(row,true);
          }
        })
      }
    },
    preferentials: function(val) {
      if(this.visible && this.distributorRelPreferential && this.distributorRelPreferential.length > 0) {
        this.preferentials && this.preferentials.forEach(row => {
          if(this.distributorRelPreferential.findIndex(item => item.id === row.id) > -1) {
            this.$refs.singleTable.toggleRowSelection(row,true);
          }
        })
      }
    },
    visible: function(val) {
      if(val) {
        this.getDistributorPreferentialList()
      }
    }
  },
  methods: {
    renderHeader (h) {
      return h(
          'input',
          {
            attrs: {
              type: 'checkbox',
              checked: this.checkedAll(),
              id: 'checkAll'
            },
            on: {
              click: this.checkAll
            },
          }
        );
    },
    checkedAll () {
      const checked = this.preferentials.every(item => {
        return this.single_preferential_ids.findIndex(i => i === item.id) > -1
      })
      return checked
    },
    checkAll (e) {
      if(e.target.checked) {
        const single_preferential_ids = [...this.single_preferential_ids, ...this.preferentials.map(item => item.id)]
        this.single_preferential_ids = Array.from(new Set(single_preferential_ids))
      } else {
        this.single_preferential_ids = this.single_preferential_ids.filter(item => this.preferentials.findIndex(i => i.id === item) < 0)
      }
    },
    checked (row) {
      return this.single_preferential_ids.findIndex(item => item === row.id) > -1
    },
    check (e, row) {
      let checked = false
      if(e.target.checked) {
        this.single_preferential_ids = [...this.single_preferential_ids, row.id]
        checked = this.checkedAll()
      } else {
        this.single_preferential_ids = this.single_preferential_ids.filter(item => item !== row.id )
      }
      this.setCheckedAll(checked)
    },
    setCheckedAll (checked) {
      document.getElementById('checkAll').checked = checked
    },
    handlePreferentialCancel () { // 店铺绑定活动窗口关闭
      this.bindPreferentialVisible = false
      this.selectPreferentialDistributorId = 0
      this.allPreferentialPageNation.page = 1
      this.getPreferentialList()
      this.$refs.singleTable.clearSelection()
      this.$emit('cancel')
    },
    getDistributorPreferentialList () { // 获取店铺绑定活动列表
      this.bindPreferentialLoading = true
      let page = this.preferentialPageNation.page;
      let pageSize = this.preferentialPageNation.page_size;
      let params = {distributor: this.distributor_id, page:page, page_size:pageSize}
      getDistributorRelPreferential(params).then(response => {
        this.single_preferential_ids = response.data.data.list.map(item => item.id)
        this.bindPreferentialLoading = false
      }).catch(error => {
        this.bindPreferentialLoading = false
      })
    },
    getPreferentialList (fn) { // 获取所有优惠活动列表
      let page = this.allPreferentialPageNation.page
      let pageSize = this.allPreferentialPageNation.page_size
      let params = {page: page, pageSize: pageSize, type: ['service','activity','welfare']}
      getActivityList(params).then(response => {
        this.preferentials = response.data.data.list
        this.allPreferentialPageNation.total = response.data.data.total_count
        fn && fn()
      })
    },
    handleAllPreferentialPageChange (page_num) { // 活动页数变化
      this.allPreferentialPageNation.page = page_num
      this.getPreferentialList(() => {
        this.setCheckedAll(this.checkedAll())
      })
      
    },
    handlePreferentialSingleSelectionChange(val) {
      let single_preferential_ids = this.single_preferential_ids && this.single_preferential_ids.length > 0 ? [...this.single_preferential_ids] : []
      for (let i in val) {
        if(single_preferential_ids.findIndex(item => item === val[i].id) < 0) {
          single_preferential_ids.push(val[i].id)
        }
        
      }
      this.single_preferential_ids = single_preferential_ids
    },
    // 保存店铺绑定活动
    setSingleDistributorPreferential () {
      let distributors = this.distributor_id
      let preferential = this.single_preferential_ids.toString()
      let params = {distributor: distributors, ids: preferential}
      setDistributorRelPreferential(params).then(response => {
        if (response.data.data.result == true) {
          this.$message.success('绑定成功')
          this.handlePreferentialCancel()
        } else {
          this.$message.error('绑定失败')
        }
      })
    }
  },

  mounted() {
    this.getPreferentialList()
  },
}
</script>
