<template>
  <div>
    <SpFinder
      ref="finder"
      row-key="device_id"
      :split-count="10"
      :url="table_url"
      :setting="setting"
      fixed-row-action
      reserve-selection
      :hooks="{
        beforeSearch
      }"
    />
    <ImportDialog
      ref="importDialog"
      v-show="false"
      buttonName="导入"
      buttonSize="medium"
      fileName="导入设备模版"
      fileType="upload_devices"
    />
  </div>
</template>
<script>
import { tableSetting } from './config'
import { List, getCategory, getTagList } from './api'
import ImportDialog from '@/components/importDialog'

export default {
  components: {
    ImportDialog
  },
  data () {
    return {
      table_url: List,
      categoryList: [],
      tagList: []
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {
    this.getCategory()
  },
  methods: {
    getCategory() {
      getCategory().then(res => {
        this.categoryList = res?.data?.data?.map(item => ({
          label: item.category_name,
          value: item.category_id
        }))
      })
    },
    getTagList() {
      getTagList().then(res => {
        this.tagList = res?.data?.data
      })
    },
    openImportDialog() {
      this.$refs.importDialog.visible = true
    },
    refresh() {
      this.$refs.finder.refresh()
    },
    beforeSearch (param) {
      return {
        ...param
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .search-field-value {
  width: auto !important;
}
::v-deep .sp-finder .cell .sp-finder-actions__wrap {
  justify-content: center !important;
}

</style>
