<template>
  <div>
    <el-row :gutter="20">
      <el-col>
        店铺商品同步记录
        <el-button type="primary" style="margin-left: 15px" icon="el-icon-refresh" @click="getDataList" :loading="loading" circle></el-button>
      </el-col>
    </el-row>
    <el-card>
      <el-table :data="logList" v-loading="loading" element-loading-text="数据加载中">
        <el-table-column
          prop="id"
          label="任务ID"
          width="100">
        </el-table-column>
        <el-table-column
          prop="items_count"
          label="SKU总数">
        </el-table-column>
        <el-table-column
          prop="distributor_count"
          label="店铺总数">
        </el-table-column>

        <el-table-column
          prop="total_count"
          label="总数">
        </el-table-column>
        <el-table-column
          prop="count_status_succ"
          label="成功数">
        </el-table-column>
        <el-table-column
          prop="count_status_fail"
          label="失败数">
        </el-table-column>
        <el-table-column
          label="进度"
          width="200">
          <template  slot-scope="scope">
            <el-progress type="circle" :percentage="scope.row.rate" :color="colors" width="50"></el-progress>
          </template>
        </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="downJobDetail(scope.row)">导出</el-button>
            </template>
          </el-table-column>
          <el-table-column
          prop="created"
          label="开始时间">
        </el-table-column>
        <el-table-column
          prop="finish_time"
          label="完成时间">
        </el-table-column>

      </el-table>
      <div class="content-padded content-center">
        <el-pagination
          background
          layout="total, prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          :total="total_count"
          :page-size="params.pageSize">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
  import { getItemSyncDistributorLog, exportSyncDistributorLog } from '../../../api/goods'
  export default {
    data () {
      return {
        logList: [],
        loading: false,
        total_count: 0,
        params: {
          page: 1,
          pageSize: 10,
        },
        form: {

        },
        exportData: {},        
        colors: [
          {color: '#f56c6c', percentage: 20},
          {color: '#e6a23c', percentage: 40},
          {color: '#1989fa', percentage: 60},
          {color: '#6f7ad3', percentage: 80},
          {color: '#5cb87a', percentage: 100}
        ]
      }
    },
    methods: {
      handleCurrentChange (page_num) {
        this.params.page = page_num
        this.getDataList()
      },
      searchData () {
        this.params.page = 1
        this.getDataList()
      },
      downJobDetail(rowdata,){
        console.log("rowdata==================",rowdata)
        console.log("rowdata==================",rowdata.id)
        if (rowdata.id.length) {
          this.exportData.id = rowdata.id;
          exportSyncDistributorLog(this.exportData).then(res => {
            console.log(res.data)
            console.log(res.data.data.total_count)
            if (res.data.data.status == true) {
              this.$message({
                type: 'success',
                message: '已加入执行队列，请在设置-导出列表中下载'
              });
            } else {
              this.$message({
                type: 'error',
                message: '导出失败'
              });
            }
          });
        }        
      },
      getDataList () {
        this.loading = true
        getItemSyncDistributorLog(this.params).then(response => {
          this.logList = response.data.data.list
          this.total_count = response.data.data.total_count
          this.loading = false
        }).catch(error => {
          this.loading = false
          this.$message({
            type: 'error',
            message: '获取列表信息出错'
          })
        })
      },
    },
    mounted () {
      this.getDataList()
    }
  }
</script>
<style scoped lang="scss">
</style>
