
<template>
  <div>
    <el-row class="filter-header" :gutter="20">
      <el-col>
        <el-input class="input-m" placeholder="商品名称" v-model="params.item_name"></el-input>
        <el-input class="input-m" placeholder="商品货号" v-model="params.item_bn"></el-input>
        <el-select v-model="params.goods_type" placeholder="请选择类型">
          <el-option v-for="item in statusOption" :key="item.value" :label="item.title" size="mini"
            :value="item.value"></el-option>
        </el-select>
        <el-button type="primary" @click="goodsSearch">查询</el-button>
        <el-button type="primary" @click="handleGoodsDialogShow(0)">添加推荐商品</el-button>
      </el-col>
    </el-row>

    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane v-for="(item, index) in tabList" :key="index" :label="item.name" :name="item.activeName">
        <el-table :data="ItemsList" v-loading="loading" row-key="goods_id">
          <el-table-column label="商品ID" prop="goods_id" width="110"></el-table-column>
          <el-table-column label="推荐商品" prop="item_name" width="300"></el-table-column>
          <el-table-column label="商品状态">
            <template slot-scope="scope">
              <span v-if="scope.row.approve_status == 'onsale'">前台可销</span>
              <span v-else-if="scope.row.approve_status == 'only_show'">前台仅展示</span>
              <span v-else>不可销售</span>
            </template>
          </el-table-column>
          <el-table-column label="展示状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_show" active-value="1" inactive-value="0" active-color="#409eff"
                inactive-color="#ff4949" @change="goodsUpdateStatus(scope.row, 'is_show')">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="itemName" label="展示排序" width="100">
            <template slot-scope="scope">
              <el-input type="number" v-model="scope.row.sort" @change="goodsUpdateStatus(scope.row, 'sort')"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="itemName" label="关联商品">
            <template slot-scope="scope">
              <span>
                {{ scope.row.rel_item_type == 'all' ? '全部商品' : '' }}
                {{ scope.row.rel_item_type == 'none' ? '未关联不展示' : '' }}
                {{ scope.row.rel_item_type == 'some' ? `已关联${scope.row.rel_goods_num}个商品` : '' }}
              </span>
              <el-button v-if="scope.row.rel_item_type == 'some' && scope.row.rel_goods_num > 0" type="text"
                @click="checkRelDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="itemName" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleGoodsDialogShow(1, scope.row)">关联</el-button>
              <el-button type="text" @click="goodsDelete(scope.row)">删除</el-button>
              <ImportDistributor buttonName="导入" buttonType="text" fileName="批量导入关联商品"
                fileType="upload_order_recommend_goods" :relId="scope.row.id" @handleClose="importDialogClose">
              </ImportDistributor>
            </template>
          </el-table-column>
        </el-table>

        <div class="content-center content-top-padded">
          <el-pagination background layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
            @size-change="handleSizeChange" :current-page.sync="params.page" :page-sizes="[10, 20, 50]"
            :total="total_count" :page-size="params.page_size">
          </el-pagination>
        </div>

      </el-tab-pane>
    </el-tabs>

    <el-dialog title="查看关联商品" :visible.sync="selectedDistributorVisible" width="50%">
      <el-button type="primary" @click="batchDelete">批量删除</el-button>
      <el-table :data="relList" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column label="商品ID" prop="item_id"></el-table-column>
        <el-table-column label="商品名称" prop="item_name"></el-table-column>
        <el-table-column prop="itemName" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="relItemDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="content-center content-top-padded">
        <el-pagination background layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleRelCurrentChange" @size-change="handleRelSizeChange" :current-page.sync="relParams.page"
          :page-sizes="[10, 20, 50]" :total="relList_total_count" :page-size="relParams.page_size">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="selectedDistributorVisible = false">关 闭</el-button>
      </span>
    </el-dialog>

    <GoodsSelector :single="itemVisibleType == 0 ? true : false" :items-visible="itemVisible" :get-status="setItemStatus"
      :rel-items-ids="relItems" :item-type="item_type" :recommend="itemVisibleType == 1 ? true : false"
      @chooseStore="handleGoodsSubmit" @closeStoreDialog="handleGoodsDialogHide"></GoodsSelector>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { recommendGoodsLists, recommendGoodsCreate, recommendGoodsUpdateStatus, recommendGoodsUpdateRel, recommendGoodsDelete, recommendGoodsRelList, recommendGoodsRelDelete } from '@/api/goods'
import GoodsSelector from '@/components/goodsSelect/index'
import ImportDistributor from '@/components/importDistributor'


export default {
  components: {
    GoodsSelector,
    ImportDistributor
  },
  props: [],
  data() {
    return {
      params: {
        page: 1,
        page_size: 20,
        item_name: '',
        item_bn: '',
        goods_type: 'recommend',
        is_show: ''
      },
      loading: false,
      statusOption: [
        { title: '推荐商品', value: 'recommend' },
        { title: '关联商品', value: 'rel' }
      ],
      activeName: 'all',
      tabList: [
        { name: "全部", activeName: 'all' },
        { name: "展示", activeName: 'showTrue' },
        { name: "不展示", activeName: 'showFalse' },
      ],
      total_count: 0,
      selectedDistributorVisible: false,
      ItemsList: [],
      single: false,
      itemVisible: false,
      itemVisibleType: null, //0 添加推荐 1 推荐商品关联商品
      setItemStatus: false,
      relItems: [],
      item_type: 'normal',
      goodsId_list: [],
      rel_item_type: null,
      multipleSelection: [],
      relList: [],
      relList_total_count: 0,
      relDetail: {},
      relParams: {
        page: 1,
        page_size: 20,
      }
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    recommendGoodsList(type) {
      if (type) {
        this.params.page = 1
      }
      let params = {
        ...this.params
      }
      if (this.activeName != 'all') {
        params.is_show = this.activeName == 'showTrue' ? true : false
      }
      recommendGoodsLists(params).then(res => {
        let data = res.data.data
        this.ItemsList = data.list
        this.total_count = data.total_count
      })
    },
    // 列表筛选查询
    goodsSearch() {
      this.recommendGoodsList(true)
    },
    handleCurrentChange(page_num) {
      this.params.page = page_num
      this.recommendGoodsList()
    },
    handleSizeChange(pageSize) {
      this.params.page_size = pageSize
      this.recommendGoodsList(true)
    },
    // 选择商品弹窗
    async handleGoodsDialogShow(type, item) {
      if (type == 1) {
        this.relDetail = item
        await this.goodsRelList() // 获取已关联商品
        this.relItems = this.relList
      }
      setTimeout(() => {
        this.itemVisible = true
        this.setItemStatus = true
        this.itemVisibleType = type
      },500)
     
    },
    // 返回已选择的商品列表
    handleGoodsSubmit(data, store, relItemType) {
      if (this.itemVisibleType == 1) {
        if ((relItemType == null || relItemType == 'some') && (data === null || data.length <= 0)) return
      } else {
        if (data === null || data.length <= 0) return
      }

      let arr = []
      data.forEach(i => {
        arr.push(i.goods_id)
      });
      this.goodsId_list = arr
      this.relItems = data
      this.rel_item_type = relItemType
      this.handleGoodsDialogHide()
      if (this.itemVisibleType == 0) {
        this.goodsCreate()
      } else {
        this.goodsUpdateRel()
      }
    },
    // 关闭选择商品弹窗
    handleGoodsDialogHide() {
      this.itemVisible = false
      this.setItemStatus = false
    },
    // 添加推荐商品
    goodsCreate() {
      let params = {
        goods_id: this.goodsId_list[0]
      }
      recommendGoodsCreate(params).then(res => {
        this.goodsId_list = [] //清空已选择商品
        this.recommendGoodsList(true)
      })
    },
    // 修改订单推荐商品的关联商品
    async goodsUpdateRel() {
      let params = {
        id: this.relDetail.id,
        goods_id: this.goodsId_list,
        rel_item_type: this.rel_item_type
      }
      recommendGoodsUpdateRel(params).then(res => {
        this.goodsId_list = [] //清空已选择商品
        this.recommendGoodsList()
      })
    },
    // 修改订单推荐商品状态和排序
    goodsUpdateStatus(item, type) {
      let params = {
        id: item.id
      }
      if (type === 'is_show') {
        params.is_show = item.is_show == 1 ? true : false
      }
      if (type === 'sort') {
        params.sort = item.sort
      }
      recommendGoodsUpdateStatus(params).then(res => {
        this.recommendGoodsList()
      })
    },
    // 删除推荐商品
    goodsDelete(item) {
      let params = {
        id: item.id
      }
      recommendGoodsDelete(params).then(res => {
        this.recommendGoodsList(true)
      })
    },
    handleClick() {
      this.recommendGoodsList(true)
    },
    // 查看关联商品
    checkRelDetail(item) {
      this.selectedDistributorVisible = !this.selectedDistributorVisible
      this.relDetail = item
      this.goodsRelList()
    },
    async goodsRelList() {
      let params = {
        id: this.relDetail.id,
        ...this.relParams
      }
      await recommendGoodsRelList(params).then(res => {
        let data = res.data.data
        this.relList = data.list
        this.relList_total_count = data.total_count
      })
    },
    handleRelCurrentChange(page_num) {
      this.relParams.page = page_num
      this.goodsRelList()
    },
    handleRelSizeChange(pageSize) {
      this.relParams.page_size = pageSize
      this.goodsRelList()
    },
    relItemDelete(item) {
      this.goodsRelDelete(item.goods_id)
    },
    // 批量删除关联商品
    batchDelete() {
      let arr = []
      this.multipleSelection.forEach(item => {
        arr.push(item.goods_id)
      })
      this.goodsRelDelete(arr)
    },
    // 批量选择关联商品
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    goodsRelDelete(goodsId) {
      let params = {
        id: this.relDetail.id,
        goods_id: goodsId
      }
      recommendGoodsRelDelete(params).then(res => {
        this.goodsRelList()
        this.recommendGoodsList()
      })
    },
    importDialogClose() {
      this.recommendGoodsList()
    }
  },
  mounted() {
    this.recommendGoodsList()
  }
}
</script>

<style lang="scss"></style>
