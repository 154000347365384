import fetch from '@/utils/fetch'

export const List = '/goods/drafts/spu/list'

export function getSkuData (params) {
  return fetch({
    url: `/goods/drafts/sku/list`,
    method: 'get',
    params
  })
}

/** 删除商品 */
export function batchDelete (goods_id) {
  return fetch({
    url: '/goods/drafts/delete/spu',
    method: 'post',
    data: {
      goods_id
    }
  })
}

/** 删除sku */
export function batchDeleteSku (item_id) {
  return fetch({
    url: '/goods/drafts/delete/sku',
    method: 'post',
    data: {
      item_id
    }
  })
}

export function handleExport (query) {
  return fetch({
    url: List,
    method: 'get',
    params: query
  })
}

/** 批量提交草稿箱商品 */
export function batchSubmitSku (goods_id) {
  return fetch({
    url: '/goods/drafts/submit',
    method: 'post',
    data: {
      goods_id
    }
  })
}

/** 修改草稿箱商品sku */
export function updateSku (id, data) {
  return fetch({
    url: `/goods/drafts/update/${id}`,
    method: 'post',
    data: {
      data: JSON.stringify(data)
    }
  })
}
