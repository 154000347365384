import fetch from '@/utils/fetch'

export const List = '/goods/fq_pay/lists'

/** 删除 */
export function batchDelete (item_id) {
  return fetch({
    url: '/goods/fq_pay/delete',
    method: 'post',
    data: {
      item_id
    }
  })
}

export function handleExport (query) {
  return fetch({
    url: List,
    method: 'get',
    params: query
  })
}
