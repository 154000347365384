<template>
  <div class="detail-cell-copitext-col">
    <span>
      {{ value }}
    </span>
    <div v-if="value" class="copy-box" @click="handlerClick">
      <i class="el-icon-search"></i>
    </div>
  </div>
</template>

<script>
import { useDialog } from '@shopex/finder'

export default {
  name: 'SearchCell',
  props: {
    value: String,
  },
  methods: {
    handlerClick() {
      useDialog(null, {
        title: `错误信息`,
        width: '500px',
        value: this.value,
        component: () => import('./error-msg.vue'),
        actions: [
          { label: '取消', key: 'close', size: 'default' }
        ]
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.detail-cell-copitext-col {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    cursor: pointer;
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .copy-box {
    cursor: pointer;
    display: block;
    z-index: 100;
    align-items: center;
    width: 15px;
    justify-content: center;
    color: #999;
    margin-left: 4px;
  }
}
.detail-cell-copitext-col:hover {
  .copy-box {
    color: #0d84ff;
  }
}
</style>
