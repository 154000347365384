<template>
  <div class="evaluate_shop_details" v-loading="tableLoading">

    <div class="shop-info" v-loading="shopLoading">
      <div>
        <div class="shop-node">{{ shopInfo.store_name }}</div>
        <div class="shop-id">Apple ID：{{ shopInfo.shop_code }}</div>
        <div class="shop-dealer_name">{{ shopInfo.dealer_info.name }}</div>
      </div>
      <div class="shop-score">
        <div class="shop-score_num">{{ transformation(shopInfo.avg_score) }}</div>
        <div class="shop-score_people">共 {{ shopInfo.evaluation_number }} 次评价</div>
      </div>
      <div class="shop-evaluate_tag">
        <el-tag type="primary" style="margin: 2px" :hit="true" plain v-for="(item, index) in topTag" :key="'ag' + index
        ">{{ item.tag_name }}&nbsp; {{ item.count }}</el-tag>
      </div>
    </div>

    <el-divider></el-divider>

    <el-form :inline="true" :model="pagination_info" ref="pagination_info" class="demo-form-inline">
      <el-form-item label="订单号">
        <el-input v-model="pagination_info.order_id" placeholder="请输入订单号"></el-input>
      </el-form-item>

      <el-form-item label="服务类型">
        <el-select v-model="pagination_info.order_receipt_type" placeholder="请选择" :clearable="true">
          <el-option v-for="(item, index) in orderReceiptType" :key="'num' + index" :label="item.title"
            :value="item.label"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="评分">
        <el-select v-model="pagination_info.star_value" placeholder="请选择" :clearable="true">
          <el-option v-for="(item, index) in relationStar" :key="'num' + index" :label="item.title"
            :value="item.label"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="评价时间">
        <el-date-picker v-model="pagination_info.time" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="searchEvaluationList(0)">查询</el-button>
        <el-button type="primary" @click="distributorEvaluationExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-divider></el-divider>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="0"></el-tab-pane>
      <el-tab-pane :label="'待联系(' + waitContactCount + ')'" name="1"></el-tab-pane>
    </el-tabs>

    <el-card>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="订单号" width="220px">
          <template slot-scope="scope">
            <span>{{ scope.row.order_id }}</span>
            <router-link
              target="_blank"
              :to="{ path: prefix + '/order/entitytrade/tradenormalorders/tradenormalorders_detail', query: { orderId: scope.row.order_id } }">查看</router-link>
          </template>
        </el-table-column>
        <el-table-column prop="order_receipt_type" label="服务类型" width="120px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.order_receipt_type === 'ziti'">到店自提</el-tag>
            <el-tag v-if="scope.row.order_receipt_type === 'logistics'">同城速递</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="评价级别" width="220px">
          <template slot-scope="scope">
            <div>
<!--              <span style="margin-right:10px;">{{ filter(scope.row.star_value / 10) }}</span>
              <div>
                <i v-for="(x, d) in (scope.row.star_value / 10)" :key="d" class="el-icon-star-on"></i>
              </div>-->
              <el-rate
                v-model="scope.row.star_value / 10"
                disabled
                show-text
                :texts="rateText"
                :colors="['#FF9900', '#FF9900', '#FF9900']"
                text-color="#FF9900">
              </el-rate>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="评价详情">
          <template slot-scope="scope">
            <div class="table-tags">
              <el-tag style="margin: 2px" type="primary" :hit="true" plain size="middle" v-for="(e, i) in scope.row.tags"
                :key="'tag_' + i">{{ e.tag_name }}</el-tag>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="shop_code" label="评价时间" width="180px">
          <template slot-scope="scope">
            <span>{{ formatTimeStampToStr(scope.row.created) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="联系状态" width="90px">
          <template slot-scope="scope">
            <div v-if="scope.row.allow_contact == '1'">
              <el-button type="danger" plain size="mini"
                v-if="scope.row.contact_status == '0'">待联系</el-button>
              <el-button type="primary" plain size="mini" v-else>已联系</el-button>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="90px">
          <template slot-scope="scope">
            <div v-if="scope.row.allow_contact == '1'">
              <el-button type="text" v-if="scope.row.contact_status == '0'"
                @click="CompleteContact(scope.row)">完成联系</el-button>
              <el-popover v-else placement="top-end" title="店员备注" width="200" trigger="hover"
                :content="scope.row.shop_remark">
                <el-button slot="reference" type="text">查看备注</el-button>
              </el-popover>
            </div>

          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
        :total="pagination_info.total">
      </el-pagination>
    </div>


    <el-dialog title="完成客户联系" :visible.sync="dialogFormVisible">
      <el-form :model="dialog_form" ref="dialog_form" label-width="80px">
        <el-form-item label="店员备注" prop="order_receipt_type">
          <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="dialog_form.textarea">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddSubmit()">提交</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getDistributorEvaluationList, getDistributorInfo, getDealerList, postEvaluationFinishcontact } from '@/api/marketing'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      tableLoading: false,
      shopLoading: false,
      prefix: '',
      tableData: [],
      orderReceiptType: [
        { title: '到店即提', label: 'ziti' },
        { title: '同城速递', label: 'logistics' }
      ],
      relationStar: [
        { title: '不太满意（一星）', label: '1' },
        { title: '有待改进（二星）', label: '2' },
        { title: '还不错（三星）', label: '3' },
        { title: '满意（四星）', label: '4' },
        { title: '非常满意（五星）', label: '5' }
      ],
      rateText: [
        '不太满意',
        '有待改进',
        '还不错',
        '满意',
        '非常满意',
      ],
      pagination_info: {
        page: 1,
        page_size: 10,
        total: 0,
        order_id: '',
        star_value: '',
        tag_name: '',
        order_receipt_type: '',
        time: '',
        is_export: false,
      },
      waitContactCount: 0,
      activeName: '0',
      dialogFormVisible: false,
      dialog_form: {
        textarea: ""
      },
      distributor_id: "",
      topTag: [],
      shopInfo: {},
      dealer_name: "",
      shopRemarkId:''
    }
  },
  computed: {
    ...mapGetters([
      'login_type'
    ])
  },
  methods: {
    onSee(info) {

    },
    searchEvaluationList () {
      this.pagination_info.page = 1
      this.distributorEvaluationList(0)
    },
    distributorEvaluationList(is_export = 0) {
      this.tableLoading = true
      this.pagination_info.is_export = is_export
      var params = {
        ...this.pagination_info,
        distributor_id: this.distributor_id,
        status: this.activeName,
      }
      if (params.time && params.time.length > 0) {
        params.begin_time = params.time[0]
        params.end_time = params.time[1]
      }
      getDistributorEvaluationList(params).then((res) => {
        if (is_export === 1) {
          this.$message.success('导出成功，请前往导出列表查看');
        } else {
          var data = res.data.data
          this.tableData = data.list
          if (data.top_tag && data.top_tag.length > 0) {
            this.topTag = data.top_tag
          }
          this.pagination_info.total = data.total_count
          this.waitContactCount = data.wait_contact_count
        }
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
        throw err
      })
    },
    distributorEvaluationExport() {
      this.distributorEvaluationList(1)
    },
    handleCurrentChange(val) {
      this.pagination_info.page = val
      this.distributorEvaluationList()
    },
    handleSizeChange(v) {
      this.pagination_info.page_size = v
      this.distributorEvaluationList()

    },
    CompleteContact(i) {
      this.shopRemarkId = i.id
      this.dialogFormVisible = true
    },
    onAddSubmit() {
      if (this.dialog_form.textarea == '') {
        return this.$message({
          message: '请填写备注内容',
          type: 'warning'
        });
      }
      var params = {
        id: this.shopRemarkId,
        shop_remark: this.dialog_form.textarea
      }
      postEvaluationFinishcontact(params).then((res) => {
        this.dialogFormVisible = false
        this.dialog_form.textarea = ''
        this.distributorEvaluationList()
      })
    },
    filter(i) {
      i = Math.floor(i)
      let text = ''
      switch (i) {
        case 1:
          text = '不太满意'
          break;
        case 2:
          text = '有待改进'
          break;
        case 3:
          text = '还不错'
          break;
        case 4:
          text = '满意'
          break;
        case 5:
          text = '非常满意'
          break;
        default:
      }
      return text
    },
    transformation(num) {
      num = (num / 10).toFixed(1)
      return num
    },
    handleClick() {
      this.distributorEvaluationList()
    },
    formatTimeStampToStr(timeStamp) {
      // 时间戳转时间字符串
      var date = new Date()
      date.setTime(timeStamp * 1000)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      var second = date.getSeconds()
      minute = minute < 10 ? '0' + minute : minute
      second = second < 10 ? '0' + second : second
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
    },
    distributorInfo() {
      this.shopLoading = true
      getDistributorInfo({ distributor_id: this.distributor_id }).then((res) => {
        this.shopInfo = res.data.data
        this.shopLoading = false
      }) . catch(error => {
        this.shopLoading = false
        throw error
      })
    }
  },
  mounted() {
    switch (this.login_type) {
      case 'disti': // 代理端
        this.prefix = '/distiadmin'
        break;
      case 'dealer': // 经销商端
        this.prefix = '/dealeradmin'
        break;
      case 'distributor': // 店铺端
        this.prefix = '/shopadmin';
        break;
      default: // 平台  包括admin  staff
        this.prefix = '';
    }
    let { distributor_id } = this.$route.query
    this.distributor_id = distributor_id
    this.distributorEvaluationList()
    this.distributorInfo()
  }
}

</script>
<style scoped lang="scss">
.evaluate_shop_details {
  .el-tabs {
    .el-tabs__content {
      display: none !important;
    }
  }


  .shop-info {
    display: flex;

    .shop-node {
      font-size: 20px;
      font-weight: 700;
    }

    .shop-id {
      font-size: 16px;
      margin: 10px 0 10px 0;
      font-weight: 400;
      color: #8c939d;
    }

    .shop-dealer_name {
      font-size: 16px;
    }

    .shop-score {
      margin: 0 40px 0 60px;

      .shop-score_num {
        font-size: 30px;
        font-weight: 700;
      }

      .shop-score_people {
        font-size: 14px;
        margin-top: 10px
      }
    }

    .shop-evaluate_tag {
      width: 400px;

      .el-button {
        margin: 0 10px 10px 0;
      }
    }
  }

  .pagination {
    margin-top: 20px;
    text-align: right
  }

  .btn_input {
    .el-icon-circle-plus {
      margin-left: 20px !important;
    }
  }

  .elRate {
    display: flex;
    align-items: center;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .table-tags {
    .el-button {
      margin: 0 5px 5px 0;
    }
  }

  .el-icon-star-on {
    color: #409EFF;
    font-size: 18px;
  }
}
</style>
