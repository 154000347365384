import { createSetting } from '@shopex/finder'

export const tableSetting = (vm) => createSetting({
  search: [],
  columns: [
    { name: '券ID', key: 'card_id', minWidth: 150 },
    { name: '券名称', key: 'title', minWidth: 150 },
    { name: '券类型',
      key: 'card_type',
      minWidth: 120,
      formatter: v => vm.$options.filters['formatCardStr'].call(vm, v)
    },
    { name: '卡券有效期',
      key: 'time',
      minWidth: 200,
      render: (h, { row }) => {
        const isexceed = (
          <span style="color: #F56C6C">{
            Date.parse(new Date()) > row.end_date * 1000
              ? "【已过期】"
              : ""
          }</span>
        )
        if(row.date_type !==  'DATE_TYPE_FIX_TIME_RANGE' && row.end_date > 0) {
          return (
            <span>
              统一过期时间：{vm.$options.filters['datetime'].call(vm, row.end_date)}
              { isexceed }
            </span>
          )
        } else {
          return (
            <span>
              {vm.$options.filters['datetime'].call(vm, row.begin_date)} ~ {vm.$options.filters['datetime'].call(vm, row.end_date)}
              { isexceed }
            </span>
          )
        }
      }
    },
  ],
  actions: [
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (rows) => {
          vm.handleDelete(rows)
        }
      }
    },
  ]
})
