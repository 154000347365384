<style scoped lang="scss">
.main {
  min-width: 1200px;
  //   overflow: auto;
}
.unbind-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 285px;
  .iconfont {
    display: block;
    margin-bottom: 20px;
    font-size: 70px;
    line-height: 1;
    color: #eaeaea;
  }
  p {
    margin-bottom: 20px;
  }
}

.section-card {
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  &.realtime-statics {
    height: 285px;
  }
  &.chart-statics {
    height: 318px;
  }
  .section-card-header {
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
    .section-card-title {
      margin-right: 20px;
      font-size: 16px;
      font-weight: 600;
    }
    .header-small {
      flex: 1;
      font-size: 12px;
      color: #ccc;
    }
    .header-filters {
      display: flex;
      .filter-item {
        position: relative;
        padding: 0 15px;
        color: #999;
        font-size: 12px;
        &::after {
          position: absolute;
          left: 0;
          top: 20%;
          bottom: 20%;
          width: 1px;
          background: #efefef;
          content: "";
        }
        &:first-child::after {
          content: none;
        }
      }
    }
  }
}

.company {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 285px;
  .logo {
    margin-bottom: 20px;
    width: 70px;
    height: 70px;
    border-radius: 100%;
  }
  .name {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .operate {
    padding-bottom: 50px;
  }
  .validity-period {
    display: flex;
    font-size: 12px;
    color: #999;
    .iconfont {
      margin-right: 10px;
      font-size: 12px;
    }
  }
}

.analysis {
  padding-left: 20px;
  padding-top: 10px;
  .label {
    font-size: 12px;
    color: #999;
  }
  .real-data {
    font-size: 20px;
    font-family: "Helvetica Neue";
    font-weight: 500;
  }
  .history-data {
    font-size: 12px;
    color: #ccc;
  }
}

.notices {
  padding-left: 20px;
  .notices-group {
    padding: 10px 0;
  }
  .subtitle {
    padding-bottom: 10px;
    font-size: 14px;
    color: #666;
  }
  .notice-item {
    font-size: 12px;
    color: #999;
    cursor: pointer;
  }
}

.quick-link {
  padding-left: 20px;
  .link-item {
    float: left;
    width: 230px;
    margin: 10px 0;
    color: #666;
    cursor: pointer;
    .iconfont {
      display: inline-block;
      margin-right: 10px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
    }
  }
}
.fn-b-20 {
  margin-bottom: 20px;
}
.to-become {
  // float: left;
  // position: relative;
  // top:-30px;
  background-color: #fef4f3;
  border: 1px solid #f25d4c;
  //   min-width: 1200px;
  width: 75%;
  position: absolute;
  top: 5px;
  display: flex;
  //   justify-content: space-between;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  .text-icon {
    line-height: 38px;
    height: 40px;
    display: flex;
    // text-align: center;
  }
  .icon-wr {
    line-height: 38px;
    font-size: 20px;
    color: #f05e51;
  }
  .btn {
    padding-left: 40px;
    line-height: 38px;
    padding-bottom: 6px;
  }
}
.buy {
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
  .buy-item {
    width: 320px;
    padding: 0;
    .buy-item-hd {
      padding: 10px;
      height: 120px;
      text-align: center;
      color: white;
      h3 {
        margin: 10px;
        font-size: 24px;
        color: white;
      }
      &_s {
        // background: url('/static/img/saas/try_1.png');
      }
      &_b {
        // background: url('/static/img/saas/biaozun.png');
        color: white;
        h3 {
          color: white;
        }
      }
      &_l {
        // background: url('/static/img/saas/liansuo.png');

        h3 {
          color: white;
        }
      }
    }
    .buy-item-ft {
      min-height: 450px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .buy-item-btn {
        width: 100px;
      }
      .buy-item-ft-text {
        text-align: center;
        .suo {
          margin-top: 100px;
          margin-bottom: 30px;
          width: 40px;
          // height:150px;
        }
      }

      p {
        padding: 8px;
      }
      .check-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.buy-hd {
  text-align: center;
  margin-bottom: 40px;
  h3 {
    font-size: 24px;
    color: #333333;
    font-weight: bold;
  }
}
.card-right {
  width: 85%;
  background-color: #fafafa;
  text-align: center;
  .phone-card {
    width: 150px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .phone-icon {
    width: 16px;
    height: 17px;
    margin-right: 10px;
  }
  .phone-num {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    // // height: 25px;
    // word-wrap: break-word;
    // word-break: normal;
    margin-bottom: 10px;
  }
  .phone-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 110px;
    height: 30px;
    margin: 0 auto;
    border: 1px solid #5ea7ec;
    text-align: center;
    border-radius: 3px;
    padding: 0 10px;
    span {
      color: #5ea7ec;
    }
  }
}
.demo-img {
  // background: url('/static/img/saas/bcg_1.png');
  width: 100%;
  text-align: center;
  // padding: 40px 0;
  .sl-img-hd {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .img-demo {
    width: 70%;
    margin: 0 auto;
  }
}
.sl-img {
  // padding: 0;
  // background: url('/static/img/saas/bcg_2.png');
  width: 100%;
  text-align: center;
  // padding: 40px 0;
  .sl-img-hd {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    i {
      margin-right: 10px;
    }
  }
  .img-demo {
    width: 70%;
    margin: 0 auto;
  }
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.title-span {
  text-align: center;
  margin-top: -30px;
}
.dialog-footer {
  text-align: center;

  .btn-ft {
    margin: 0 20px;
    width: 140px;
  }
}
</style>

<template>
  <div class="main" v-if="bool">
    <el-row :gutter="20" v-if="login_type=='disti'">
      <div class="block">
        <el-date-picker
          v-model="value_day"
          align="right"
          type="date"
          value-format="yyyy-MM-dd"              
          placeholder="选择日期"
          @change="dateChange"
          :picker-options="pickerOptions">
        </el-date-picker>
        <el-input class="input-m" placeholder="渠道RTM" 
        @change="dateChange"
        v-model="channel_rtm" clearable 
        @clear="handleIconClick">
        </el-input>
      </div>
    </el-row >
          
    <el-row :gutter="20" v-if="login_type=='disti'">
      <el-col :span="10">
        <section class="section-card" v-loading="userloading">
          <div class="section-card-header">
            <div class="section-card-title">订单总量</div>
          </div>
          <div class="notices">
            <div class="notices-group">
                <el-table
                  :data="sum_data_order"
                  style="width: 100%"
                  show-summary
                  :default-sort = "{prop: 'date', order: 'descending'}"
                  >
                    <el-table-column
                      prop="item_name"
                      label="商品"
                      sortable
                      width="200">
                    </el-table-column>                  
                    <el-table-column
                      style="float: right;"
                      align="right"

                      prop="countorder"
                      label="合计"
                      
                      width="100">
                    </el-table-column>        
                    <el-table-column
                      style="float: right;"
                      align="right"

                      prop="kuaidi"
                      label="快递"
                      
                      width="100">
                    </el-table-column>   
                    <el-table-column
                      style="float: right;"
                      align="right"

                      prop="ziti"
                      label="自提"
                      
                      width="100">
                    </el-table-column>                                                    
                </el-table>                    
                
            </div>
          </div>
        </section>
      </el-col>
      <el-col :span="10">
        <section class="section-card" v-loading="userloading">
          <div class="section-card-header">
            <div class="section-card-title">库存总量</div>
          </div>
          <div class="notices">
            <div class="notices-group">
                <el-table
                  :data="sum_data_inventory"
                  style="width: 100%"
                  show-summary
                  :default-sort = "{prop: 'date', order: 'descending'}"
                  >
                    <el-table-column
                      prop="item_name"
                      label="商品"
                      sortable
                      width="200">
                    </el-table-column>                  
                    <el-table-column
                      style="float: right;"
                      align="right"

                      prop="stores"
                      label="库存剩余"
                      
                      width="100">
                    </el-table-column>                                             
                </el-table>
            </div>
          </div>
        </section>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import { mapGetters } from "vuex";

const phone = require("@/../static/img/saas/phone.png");
const qq = require("@/../static/img/saas/qq.png");
const check = require("@/../static/img/saas/check.png");
const customerService = require("@/../static/img/saas/customerService.jpg");
const suo = require("@/../static/img/saas/suo.png");
const bcg_2 = require("@/../static/img/saas/bcg_2.png");
const try_1 = require("@/../static/img/saas/try.png");
const liansuo = require("@/../static/img/saas/liansuo.png");
const bcg_1 = require("@/../static/img/saas/bcg_1.png");
const demo = require("@/../static/img/saas/demo.png");
const biaozun = require("@/../static/img/saas/biaozun.png");

        // background: url('/static/img/saas/biaozun.png');

import { Message } from "element-ui";
import store from "../../../store";
import {
  getWechatPreAuthUrl,
  getWechatBind,
  getAuthorizerInfo,
  getUserWeekSummary,
} from "../../../api/wechat";
import {
  getActivateInfo,
  updateCompanyInfo,
  getResourceList,
  getCompanyStatistics,
  ydleadsInfo,
} from "../../../api/company";
import chart from "chart.js";
import countDown from "../../../components/countdown";

export default {
  data() {
    return {
      img: {
        phone,
        qq,
        check,
        customerService,
        suo,
        bcg_2,
        try_1,
        liansuo,
    		bcg_1,
    		demo,
    		biaozun
      },
      bool: true,
      userloading: true,
      dialogVisible: false,
      dingInfo: {
        goods_name: "标准版",
        call_name: "",
        sex: "男",
        mobile: "",
      },
      authorizerData: {
        principal_name: "",
      },
      activateInfo: {},
      url: "",
      isBind: false,
      isBindFail: false,
      state: 1,
      industryVisible: false,
      guideHidden: true,
      storeHidden: false,
      webchatHidden: false,
      waitingDialog: false,
      checkedGuide: {},
      checkedStore: {},
      staticsData: "",
      userTimeArr: [],
      userData: [],
      vipData: [],
      svipData: [],
      sum_data_inventory: [],
      sum_data_order: [],

      orderInventoryData: [],

      i: -1,
      guideList: [
        {
          id: "1",
          icon: "icon-marketing",
          name: "超市便利",
          desc: "大型商超，超市，便利店，小卖部等",
        },
        {
          id: "2",
          icon: "icon-baking",
          name: "烘培奶茶",
          desc: "蛋糕店，奶茶店，甜品店，面包房等",
        },
        {
          id: "3",
          icon: "icon-play",
          name: "娱乐休闲",
          desc: "KTV，桌球，桑拿汗蒸，按摩足浴等",
        },
        {
          id: "4",
          icon: "icon-fresh",
          name: "水果生鲜",
          desc: "水果摊，生鲜菜场等",
        },
        {
          id: "5",
          icon: "icon-housekeeping",
          name: "生活服务",
          desc: "保洁，保姆，钟点工，月嫂等",
        },
        {
          id: "6",
          icon: "icon-beauty",
          name: "美容健身",
          desc: "健身房，美容店，美发店，美甲店等",
        },
        {
          id: "7",
          icon: "icon-coffee",
          name: "咖啡桌游",
          desc: "咖啡馆，桌游室，棋牌室，轰趴馆等",
        },
        {
          id: "8",
          icon: "icon-food",
          name: "餐饮",
          desc: "小吃店，菜馆，自助餐，快餐店等",
        },
      ],
      storeList: [
        { id: 1, icon: "icon-store", text: "1家门店", bgOther: false },
        { id: 2, icon: "icon-stores", text: "2-4家门店", bgOther: true },
        { id: 3, icon: "icon-stores", text: "5-30家门店", bgOther: true },
        { id: 4, icon: "icon-stores", text: "31-100家门店", bgOther: true },
        { id: 3, icon: "icon-stores", text: "100家以上", bgOther: true },
      ],

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      value_day: '',      
      channel_rtm: '',
      params: {
        channel_rtm: '',
        value_day: '',                 
      },

      currGuide: {},
      links: [
        {
          link: "goodsphysical_editor",
          icon: "box",
          color: "#fa888b",
          text: "新增实体商品",
        },
        {
          link: "servicegoods_editor",
          icon: "user-clock",
          color: "#b992f8",
          text: "新增服务商品",
        },
        {
          link: "groupsindex_editor",
          icon: "comments-dollar",
          color: "#fa9678",
          text: "新增拼团活动",
        },
        {
          link: "membermarketing",
          icon: "ticket-alt",
          color: "#fa8084",
          text: "新增优惠券",
        },
        {
          link: "marketingseckill_editor",
          icon: "stopwatch",
          color: "#f9ca6b",
          text: "新增秒杀活动",
        },
        {
          link: "marketingcommunityactivity_editor",
          icon: "door-open",
          color: "#65d7f3",
          text: "新增社区活动",
        },
        {
          link: "marketingdeliveryorder",
          icon: "dolly-flatbed",
          color: "#fb9f5a",
          text: "配送单处理",
        },
        {
          link: "tradenormalorders",
          icon: "clipboard-list",
          color: "#7cc0f4",
          text: "实体订单处理",
        },
        {
          link: "aftersaleslist",
          icon: "user-tie",
          color: "#fa8084",
          text: "售后处理",
        },
        {
          link: "popularizewithdraw",
          icon: "donate",
          color: "#fa9679",
          text: "提现处理",
        },
      ],
    };
  },
  methods: {
      handleIconClick () {
        this.channel_rtm = ""
        this.getdata()
      },

    dingHandel(type) {
      this.dialogVisible = true;
      this.dingInfo.goods_name = type;
    },
    submit() {
      let obj = JSON.parse(JSON.stringify(this.dingInfo));
      ydleadsInfo(obj).then((res) => {
        this.$message({
          message: "订购提交成功",
          type: "success",
        });
        this.bool = true;
        this.dialogVisible = false;
        this.dingInfo = {};
      });
    },
    toLink(link) {
      this.$router.push({ path: this.matchInternalRoute(link) });
    },
    handleBuy() {
      this.bool = false;
    },
    handleBind() {
      if (this.url) {
        var metadata = document.getElementsByTagName("meta");
        for (var i = 0; i < metadata.length; i++) {
          if (metadata[i]["name"] == "referrer") {
            metadata[i]["content"] = "default";
          }
        }
        window.open(this.url, "_blank");
      }
    },
    accountactivate() {
      this.$router.push({
        path: this.matchInternalRoute("assetaccountactivation"),
      });
    },
    // 获取用户列表
    getUrl: function () {
      // NProgress.start()
      let params = {
        callback_url: this.wxAuthCallbackUrl + "auth/woa",
      };
      getWechatPreAuthUrl(params).then((response) => {
        this.url = response.data.data.url;
      });
    },
    guideChoose: function (item, index) {
      this.currGuide = item;
      this.i = index;
      this.guideHidden = false;
      this.storeHidden = true;
      this.state = 2;
      this.checkedGuide = item;
      let params = {
        industry: item.name,
      };
      updateCompanyInfo(params).then((res) => {});
    },
    storeChoose: function (item, index) {
      this.state = 3;
      this.storeHidden = false;
      this.webchatHidden = true;
      this.checkedStore = item;
    },
    bindWebChat: function () {
      this.webchatHidden = false;
      this.waitingDialog = true;
      this.industryVisible = false;
      if (this.url) {
        for (var i = 0; i < metadata.length; i++) {
          if (metadata[i]["name"] == "referrer") {
            metadata[i]["content"] = "default";
          }
        }
        window.open(this.url, "_blank");
      }
    },
    doneBind: function () {
      if (!this.isBind) {
        this.industryVisible = true;
        this.webchatHidden = true;
      }
      this.waitingDialog = false;
    },
    bindWebChatagain: function () {
      if (this.url) {
        for (var i = 0; i < metadata.length; i++) {
          if (metadata[i]["name"] == "referrer") {
            metadata[i]["content"] = "default";
          }
        }
        window.open(this.url, "_blank");
      }
    },
    unBind() {
      this.industryVisible = false;
      this.waitingDialog = false;
    },

    dateChange(){
      this.getdata()
    },  
    
    getdata(){
      this.params = {
        value_day: this.value_day,
        channel_rtm: this.channel_rtm
      };
      getCompanyStatistics(this.params).then((res) => {
            this.staticsData = res.data.data;
            let memberData = res.data.data.member_data;
            this.sum_data_inventory = res.data.data.sum_data_inventory;
            this.sum_data_order     = res.data.data.sum_data_order;
            for (var key in memberData) {
              this.userTimeArr.push(key);
              this.userData.push(memberData[key].newAddMember);
              this.vipData.push(memberData[key].vipMember);
              this.svipData.push(memberData[key].svipMember);
            }

              this.userloading = false;

            // this.$nextTick(() => {
            //   this.chartInit();
            //   this.userloading = false;
            // });
          });        
    },

    chartInit() {
      window.chartColors = {
        red: "rgb(255, 99, 132)",
        orange: "rgb(255, 159, 64)",
        yellow: "rgb(255, 205, 86)",
        green: "rgb(75, 192, 192)",
        blue: "rgb(54, 162, 235)",
        purple: "rgb(153, 102, 255)",
        grey: "rgb(201, 203, 207)",
      };
      var config = {
        type: "line",
        data: {
          labels: this.userTimeArr,
          datasets: [
            {
              label: "新增人数",
              backgroundColor: window.chartColors.red,
              borderColor: window.chartColors.red,
              data: this.userData,
              fill: false,
            },
            {
              label: "新增vip",
              backgroundColor: window.chartColors.orange,
              borderColor: window.chartColors.orange,
              data: this.vipData,
              fill: false,
            },
            {
              label: "新增svip",
              backgroundColor: window.chartColors.yellow,
              borderColor: window.chartColors.yellow,
              data: this.svipData,
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: "近7天用户趋势",
          },
          tooltips: {
            mode: "index",
            intersect: true,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "",
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: false,
                },
              },
            ],
          },
        },
      };
      var ctx = document.getElementById("canvas").getContext("2d");
      window.myLine = new Chart(ctx, config);
    },
  },

  computed: {
    ...mapGetters(["template_name", "login_type"])
  },

  mounted() {
    // this.bool = false
    getActivateInfo().then((res) => {
      this.activateInfo = res.data.data;
      if (!res.data.data.is_valid) {
        this.$router.push({
          path: this.matchInternalRoute("assetaccountactivation"),
        });
      }
      // this.activateInfo.source = 'demo'

      this.activateInfo.vue_ecshopx_verion = process.env.VUE_APP_PRODUCT_MODEL;
    });
    this.getUrl();
    // getCompanyStatistics(this.params).then((res) => {
    //   this.staticsData = res.data.data;
    //   let memberData = res.data.data.member_data;
    //   this.sum_data_inventory = res.data.data.sum_data_inventory;
    //   this.sum_data_order     = res.data.data.sum_data_order;
    //   for (var key in memberData) {
    //     this.userTimeArr.push(key);
    //     this.userData.push(memberData[key].newAddMember);
    //     this.vipData.push(memberData[key].vipMember);
    //     this.svipData.push(memberData[key].svipMember);
    //   }
      
    //   this.$nextTick(() => {
    //     this.chartInit();
    //     this.userloading = false;
    //   });
    // });
    this.getdata()

    if (this.$route.query) {
      this.isBindFail = this.$route.query.isBindFail;
    }
    if (this.isBindFail) {
      this.waitingDialog = true;
      return;
    }
    if (store.getters.is_authorizer) {
      this.isBind = true;
      getAuthorizerInfo().then((response) => {
        this.authorizerData = response.data.data;
      });
    } else {
      this.industryVisible = true;
    }
  },
};
</script>

