<template>
  <div>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="购物车配置" name="base">
        <div>
          购物车开关&nbsp;&nbsp;&nbsp;&nbsp;
          <el-switch v-model="form.show_cart" @change="changeSwitch" active-value="1" inactive-value="2">
          </el-switch>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div style="text-align: center; margin-top: 20px">
      <el-button type="primary" @click="setCartSetting">保存配置</el-button>
    </div>
  </div>
</template>
<script>

import { getCartSetting, setCartSetting } from '@/api/setting'

export default {
  components: {
  },
  data() {
    return {
      form: {
        show_cart: '1',
      },
      activeName: 'base'
    }
  },
  methods: {
    setCartSetting() {
      setCartSetting(this.form).then(res => {
        this.$message({ type: 'success', message: '保存成功' })
      })
    },
    getCartSetting() {
      getCartSetting().then(res => {
        let data = res.data.data
        this.form = {...data}
      })
    },
    changeSwitch(val) {
      this.form.open_status = val
    },
    changeSwitchHide(val) {
      this.form.hide_address = val
    }
  },
  mounted() {
    this.getCartSetting()
  }
}
</script>
<style scoped type="text/css" lang="scss"></style>
