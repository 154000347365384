import fetch from '@/utils/fetch'

export const List = '/promotions/activity/exchange/lists'
export const ActivityList = '/promotions/activity/exchange/distributor'

/** 新增 */
export function create (data) {
  return fetch({
    url: '/promotions/activity/exchange/save',
    method: 'post',
    data
  })
}

/** 中止活动 */
export function stop (id) {
  return fetch({
    url: `/promotions/activity/exchange/stop/${id}`,
    method: 'put'
  })
}

/** 详情 */
export function detail (id) {
  return fetch({
    url: `/promotions/activity/exchange/detail/${id}`,
    method: 'get'
  })
}

/** 更新 */
export function update (data) {
  return fetch({
    url: '/promotions/activity/exchange/save',
    method: 'post',
    data
  })
}

/** 删除订单兑礼活动门店 */
export function deleteItem (activityId, shop_code) {
  return fetch({
    url: `/promotions/activity/exchange/distributor_del/${activityId}`,
    method: 'delete',
    params: {
      shop_code
    }
  })
}

/** 导出 */
export function handleExport (query) {
  return fetch({
    url: List,
    method: 'get',
    params: query
  })
}

/** 中止 */
export function breakDown (id) {
  return fetch({
    url: '',
    method: 'post',
    data: {
      id
    }
  })
}

/** 新增渠道 */
export function createChannel (channel_name) {
  return fetch({
    url: '/members_vip/channel/create',
    method: 'post',
    data: {
      channel_name
    }
  })
}

/** 获取渠道列表 */
export function getChannelList () {
  return fetch({
    url: '/members_vip/channel/lists',
    method: 'get',
    params: {
      page: 1,
      pageSize: 9999
    }
  })
}
