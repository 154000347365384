import fetch from '@/utils/fetch'

export const List = '/aftersales/devices/list'

export function create (data) {
  return fetch({
    url: '/aftersales/devices',
    method: 'post',
    data
  })
}

export function update (data) {
  return fetch({
	  url: '/aftersales/devices',
	  method: 'put',
	  data
  })
}
export function detail (device_id) {
  return fetch({
	  url: '/aftersales/devices/info',
	  method: 'get',
	  params: {
      device_id
    }
  })
}

export function getCategory () {
  return fetch({
    url: '/goods/category',
    method: 'get',
    params: {
      category_level: 1
    }
  })
}

export function getTagList (query) {
  return fetch({
    url: '/members/devices_tag/lists',
    method: 'get',
    params: query
  })
}

export function batchDelete (device_id) {
  return fetch({
    url: '/aftersales/devices',
    method: 'delete',
    params: {
      device_id
    }
  })
}

export function devicesSort (data) {
  return fetch({
	  url: '/aftersales/devices/sort',
	  method: 'put',
	  data
  })
}

export function devicesShow (data) {
  return fetch({
	  url: '/aftersales/devices/isShow',
	  method: 'put',
	  data
  })
}
