<template>
  <el-container>
    <el-main>
      <el-row class="filter-header" :gutter="20" :class="panel.search ? 'open': ''">
        <el-col>
          <el-select v-model="capitalTypeName" clearable @change="isSelectHandle" placeholder="请选择科目">
            <el-option
              v-for="(item, index) in capitalType"
              :key="index"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="params.pay_type" clearable @change="isSelectHandle" placeholder="请选择科目">
            <el-option
              v-for="(item, index) in payType"
              :key="index"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="params.fund_channel" clearable @change="isSelectHandle" placeholder="请选择实际支付类型">
            <el-option
              v-for="(item, index) in fundType"
              :key="index"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>

          <el-date-picker
            v-model="dateTime"
            type="datetimerange"
            :picker-options="pickerOptions"
            start-placeholder="开始日期时间"
            ange-separator="至"
            end-placeholder="结束日期时间"
            align="left"
            @change="dateChange"
            placeholder="选择日期时间范围">
          </el-date-picker>
          <el-button type="primary" @click="exportData" v-loading="exportloading" style="margin-left: 10px">导出流水</el-button>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
          <el-button class="collapse-btn" type="text" @click="panelCollapse('search')">
            <span v-if="!panel.search">更多筛选项 <i class="el-icon-arrow-down"></i></span>
            <span v-else>收起 <i class="el-icon-arrow-up"></i></span>
          </el-button>
        </el-col>
        <el-col v-show="panel.search">
          <el-input class="input-m mt10" placeholder="流水标识" v-model="params.capital_id" clearable @clear="numberSearch" @change="getAdaPayCapitalList(1)"></el-input>
          <el-input class="input-m mt10" placeholder="订单号" v-model="params.order_id" clearable @clear="numberSearch" @change="getAdaPayCapitalList(1)"></el-input>
          <el-input class="input-m mt10" placeholder="AppleID" v-model="params.shop_code" clearable @clear="numberSearch" @change="getAdaPayCapitalList(1)"></el-input>
          <el-input class="input-m mt10" placeholder="店铺名称" v-model="params.distributor_name" clearable @clear="numberSearch" @change="getAdaPayCapitalList(1)"></el-input>
        </el-col>
        <el-col v-show="panel.search">
          <el-input class="input-m mt10" placeholder="经销商名称" v-model="params.dealer_name" clearable @clear="numberSearch" @change="getAdaPayCapitalList(1)"></el-input>
          <el-input class="input-m mt10" placeholder="经销商HQID" v-model="params.dealer_hqid" clearable @clear="numberSearch" @change="getAdaPayCapitalList(1)"></el-input>
          <el-input class="input-m mt10" placeholder="分销商HQID" v-model="params.distributor_hqid" clearable @clear="numberSearch" @change="getAdaPayCapitalList(1)"></el-input>
          <el-input class="input-m mt10" placeholder="分销商VAD" v-model="params.distributor_vad" clearable @clear="numberSearch" @change="getAdaPayCapitalList(1)"></el-input>
          <el-input class="input-m mt10" placeholder="渠道RTM" v-model="params.channel_rtm" clearable @clear="numberSearch" @change="getAdaPayCapitalList(1)"></el-input>
        </el-col>
      </el-row>
      <el-table v-loading="loading" :data="capitalList" border :row-class-name="tableRowClassName">
        <el-table-column prop="capital_id" label="流水标识" align="center" show-overflow-tooltip fixed width="200"></el-table-column>
        <el-table-column prop="shop_code" label="AppleID" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="distributor_name" label="店铺" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="dealer_name" label="经销商名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="dealer_hqid" label="经销商HQID" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="distributor_hqid" label="分销商HQID" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="distributor_vad" label="分销商VAD" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="channel_rtm" label="渠道RTM" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="order_id" label="订单号" align="center" show-overflow-tooltip width="200">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              width="400"
              @show="getDeliveryInfo(scope.row)"
              trigger="click"
              title="合并支付订单"
              :popper-options="{
                boundariesElement: 'viewport',
                removeOnDestroy: true,
              }"
            >
            <el-card >
            <div  v-for="(infoGroup, index) in deliveryInfo"
                  :key="index">
                  <h6 v-if="infoGroup.order_id">{{ infoGroup.order_id }}   ：{{ (infoGroup.total_fee - infoGroup.tradein_fee)/100 }} 元  </h6>
                    <br />

            </div>
            </el-card>
              <!-- <br />  -->
              <!-- <el-timeline
                v-loading="deliveryLoading"
                style="max-height: 300px; overflow: auto"
              >
                <el-timeline-item
                  v-for="(infoGroup, index) in deliveryInfo"
                  :key="index"
                  placement="top"
                >

                </el-timeline-item>
              </el-timeline> -->

              <el-tag size="mini" slot="reference">
                {{scope.row.order_id}}
              </el-tag>
            </el-popover>
          </template>



        </el-table-column>
        <el-table-column prop="order_status_msg" label="订单状态" align="center" show-overflow-tooltip width="100"></el-table-column>
        <el-table-column prop="capital_type" label="科目" align="center" show-overflow-tooltip fixed="right" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.capital_type === 'trade'">入账</span>
            <span v-if="scope.row.capital_type === 'refund'">出账</span>
            <span v-if="scope.row.capital_type === 'cash'">出账</span>
          </template>
        </el-table-column>

        <el-table-column prop="pay_type" label="支付类型" align="center" show-overflow-tooltip fixed="right" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.pay_type === 'tradein'">旧机回收</span>
            <span v-if="scope.row.pay_type === 'adapay'">微信支付</span>
            <span v-if="scope.row.pay_type === 'adapay_fq_hua_bei'">花呗支付</span>
            <span v-if="scope.row.pay_type === 'adapay_fq_credit_card'">支付宝信用卡分期</span>
          </template>
        </el-table-column>
        <el-table-column prop="fund_channel_msg" label="实际支付类型" align="center" show-overflow-tooltip min-width="120"></el-table-column>
        <el-table-column prop="capital_money" label="流水金额" align="center" show-overflow-tooltip fixed="right" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.capital_type === 'trade'">+</span>
            <span v-else>-</span>
            {{ scope.row.capital_money / 100 | keepTwoDecimalFull }} 元
          </template>
        </el-table-column>
        <el-table-column prop="capital_time" label="流水时间" align="center" show-overflow-tooltip fixed="right" width="160">
          <template slot-scope="scope">{{ scope.row.capital_time | datetime('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" show-overflow-tooltip min-width="120" fixed="right"></el-table-column>

      </el-table>
      <div class="content-padded content-center">
        <el-pagination
          small
          background
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCapitalCurrentChange"
          @size-change="handleCapitalSizeChange"
          :current-page.sync="params.page"
          :total="capitalTotalCount"
          :pager-count="5"
          :page-size="params.pageSize"
          :page-sizes="[15, 50, 150, 200]"
        >
        </el-pagination>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import {getAdaPayCapitalList} from '@/api/trade';

export default {
  data() {
    return {
      loading:false,
      dateTime: [],
      deliveryInfo: [],
      deliveryLoading: true,

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 89);
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      panel: {
        search: false,
      },
      exportloading: false,
      capitalType: [
        {name: '全部', value: ''},
        {name: '出账', value: 'outcome'},
        {name: '入账', value: 'income'}
      ],
      payType: [
        {name: '全部', value: ''},
        {name: '旧机回收', value: 'tradein'},
        {name: '微信支付', value: 'adapay'},
        {name: '花呗支付', value: 'adapay_fq_hua_bei'},
        {name: '支付宝信用卡分期', value: 'adapay_fq_credit_card'}
      ],
      fundType: [
        {name: '全部', value: ''},
        {name: '旧机回收', value: 'tradein'},
        {name: '微信支付', value: 'adapay'},
        {name: '花呗支付', value: 'adapay_fq_hua_bei'},
        {name: '支付宝信用卡分期', value: 'adapay_fq_credit_card'},
        {name: '支付宝其他', value: 'adapay_fq_other'},
      ],
      capitalTypeName: '',

      isExport: false,
      params: {
        page: 1,
        pageSize: 15,
        capital_id: '',
        capital_type: '',
        start_time: '',
        end_time: '',
        order_id: '',
        distributor_name: '',
        dealer_name: '',
        dealer_hqid: '',
        distributor_hqid: '',
        distributor_vad: '',
        channel_rtm: '',
        pay_type: '',
        fund_channel: ''
      },
      capitalList: [],
      capitalTotalCount: 0,
    };
  },
  mounted() {
    let start = new Date(), end = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24);
    end.setTime(end.getTime());
    this.dateTime = [start, end];
    this.params.start_time = start;
    this.params.end_time = end;

    this.getAdaPayCapitalList();
  },
  methods: {

    getDeliveryInfo(row) {//, order_id
      // console.log(row);//order_list
      console.log(row.order_list);//order_list
      this.deliveryLoading = true
      this.deliveryInfo = row.order_list;
      this.deliveryLoading = false;

      // let query = {
      //   delivery_corp: row.delivery_corp,
      //   delivery_code: row.delivery_code,
      //   // order_id: row.order_id,
      // };
      // getDeliveryDetail(query).then((response) => {
      //   this.deliveryInfo = response.data.data;
      //   console.log(this.deliveryInfo);
      //   this.deliveryLoading = false;
      // });
    },


    tableRowClassName({row, rowIndex}) {
      if (row.capital_type !== 'trade') {
        return 'warning-row';
      }
      return '';
    },
    dateChange(val) {
      console.log(val, 'Change=date');
      if (val) {
        this.params.start_time = val[0];
        this.params.end_time = val[1];
      } else {
        this.params.start_time = '';
        this.params.end_time = '';
      }
      this.getAdaPayCapitalList()
    },
    getParams() {
      switch (this.capitalTypeName) {
        case 'income':
          this.params.capital_type = 'trade';
          break;
        case 'outcome':
          this.params.capital_type = ['refund', 'cash'];
          break;
        default:
          this.params.capital_type = '';
          break;
      }
    },
    getAdaPayCapitalList(page = 0) {
      if (page) {
        this.params.page = page;
      }
      this.loading = true;
      this.getParams();
      getAdaPayCapitalList(this.params).then((response) => {
        if (response.data.data.list) {
          this.capitalList = response.data.data.list
          this.capitalTotalCount = response.data.data.total_count
          this.loading = false;
        }
      });
    },
    handleCapitalCurrentChange(page_num) {
      this.params.page = page_num;
      this.getAdaPayCapitalList();
    },
    handleCapitalSizeChange(pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getAdaPayCapitalList();
    },
    isSelectHandle() {
      this.params.page = 1
      this.getAdaPayCapitalList();
    },

    numberSearch() {
      this.params.page = 1
      this.getAdaPayCapitalList()
    },
    panelCollapse(name) {
      this.panel[name] = !this.panel[name]
    },
    exportData() {
      this.exportloading = true
      if (this.capitalList.length <= 0) {
        this.$message({
          type: 'error',
          message: '请确认导出的内容暂无数据~！'
        });
        this.exportloading = false
        return;
      }
      getAdaPayCapitalList({...this.params, is_export: true}).then(response => {
        if (response.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          this.exportloading = false;
          return true;
        } else if (response.data.data.url) {
          this.downloadUrl = response.data.data.url
          this.downloadName = response.data.data.filename
          this.downloadView = true
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return false;
        }
      }).catch(error => {
        this.exportloading = false
        console.log(error);
        return false;
      });
    },
  },
};
</script>
