import { render, staticRenderFns } from "./distributorInvoice.vue?vue&type=template&id=73e12e2c&scoped=true"
import script from "./distributorInvoice.vue?vue&type=script&lang=js"
export * from "./distributorInvoice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73e12e2c",
  null
  
)

export default component.exports