<template>
  <div>
    <SpFinder
      ref="finder"
      row-key="id"
      :split-count="10"
      :url="table_url"
      :setting="setting"
      fixed-row-action
      reserve-selection
      :hooks="{
        beforeSearch
      }"
    />
    <ImportDialog
      ref="importDialog"
      v-show="false"
      buttonName="导入"
      buttonSize="medium"
      fileName="导入分期商品"
      fileType="upload_fq_pay_items"
    />
  </div>
</template>
<script>
import { tableSetting } from './config'
import { List, handleExport } from './api'
import { getCategory, getTagList } from '@/api/goods'
import ImportDialog from '@/components/importDialog'

export default {
  components: {
    ImportDialog
  },
  data () {
    return {
      table_url: List,
      categoryList: [],
      tagList: []
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {
    this.getCategory()
    this.getAllTagLists()
  },
  methods: {
    getCategory() {
      getCategory({is_show:false}).then(response => {
        this.categoryList = response?.data?.data
      })
    },
    getAllTagLists() {
      let params = {
        page: 1,
        pageSize: 500
      }
      getTagList(params).then(response => {
        this.tagList = response?.data?.data?.list
      })
    },
    openImportDialog() {
      this.$refs.importDialog.visible = true
    },
    openExportDialog() {
      const searchparams = this.$refs.finder.getSearchParams()
      const selection = this.$refs.finder.$refs.finderTable.getSelection()
      let params = {
        ...searchparams,
        is_export: 1,
        item_id: selection?.map(item => item.item_id)
      }
      handleExport(params).then(_ => {
        this.$message.success('导出成功，请前往导出列表查看')
      })
    },
    refresh() {
      this.$refs.finder.refresh()
    },
    beforeSearch (param) {
      return {
        ...param
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .search-field-value {
  width: auto !important;
}
::v-deep .sp-finder .cell .sp-finder-actions__wrap {
  justify-content: center !important;
}

</style>
