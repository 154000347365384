<template>
  <div>
    <el-form label-width="100px" :inline="true">
      <el-form-item label="选择日期范围">
        <el-col :span="25">
          <el-date-picker
            v-model="vdate"
            type="daterange"
            :picker-options="pickerOptions"
            alue-format="yyyy-MM-dd"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 100%;"
            value-format="yyyy-MM-dd"
            @change="dateChange"
            >
          </el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item label="商品名称">
        <el-col :span="20">
          <el-input v-model="params.item_name"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="门店AppleID">
        <el-col :span="20">
          <el-input v-model="params.shop_code"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="门店名称">
        <el-col :span="20">
          <el-input v-model="params.shop_name"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="经销商HQID">
        <el-col :span="20">
          <el-input v-model="params.dealer_hqid"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="经销商名称">
        <el-col :span="20">
          <el-input v-model="params.dealer_name"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="分销商HQID">
        <el-col :span="20">
          <el-input v-model="params.distributor_hqid"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="分销商名称">
        <el-col :span="20">
          <el-input v-model="params.distributor_vad"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="渠道RTM">
        <el-col :span="20">
          <el-input v-model="params.channel_rtm"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="配送方式">
        <el-col :span="20">
          <el-input v-model="params.receipt_type_zn"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col>
          <el-button type="primary" @click="searchData" style="margin-left: 10px">搜索</el-button>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-col>
          <el-button type="primary" @click="exportData" v-loading="exportloading" :disabled="exportloading" style="margin-left: 10px">导出
          </el-button>
          <el-popover placement="top-start" width="200" trigger="hover" content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </el-col>
      </el-form-item>
    </el-form>
    <template>
        <div  v-if="false" >
          <span style="float: left; margin-left: 200px; color: blue; font-size: 36px">
          成功付款: {{all_num_payed}}  销售数量: {{all_countorder}}  取消订单: {{all_num_cancel}}  成功支付金额: {{all_total_fee_all}}
          </span>
        </div>
        <el-table
        ref='activityTable'
        :data="dataList" stripe border
        style="width: 100%">
          <el-table-column prop="order_date" label="日期"></el-table-column>
          <el-table-column label="店铺信息">
            <el-table-column prop="shop_code" label="门店AppleID"></el-table-column>
            <el-table-column prop="shop_name" label="门店名称"></el-table-column>
            <el-table-column prop="dealer_hqid" label="经销商HQID"></el-table-column>
            <el-table-column prop="dealer_name" label="经销商名称"></el-table-column>
            <el-table-column prop="distributor_hqid" label="分销商HQID"></el-table-column>
            <el-table-column prop="distributor_vad" label="分销商名称"></el-table-column>
            <el-table-column prop="channel_rtm" label="渠道RTM"></el-table-column>
          </el-table-column>
          <el-table-column label="商品信息">
            <el-table-column prop="item_bn" label="商品编码"></el-table-column>
            <el-table-column prop="item_name" label="商品名称"></el-table-column>
            <el-table-column prop="item_spec_desc" label="商品规格"></el-table-column>
            <el-table-column prop="price" label="商品价格（门店设置的价格）"></el-table-column>
          </el-table-column>
          <el-table-column label="订单信息">
            <el-table-column prop="receipt_type_zn" label="订单类型"></el-table-column>
            <el-table-column prop="num_payed" label="成功付款"></el-table-column>
            <el-table-column prop="num_cancel" label="取消数量（付款后核销/发货前取消）"></el-table-column>
            <el-table-column prop="countorder" label="销售数量（已取货/已发货）"></el-table-column>
            <el-table-column prop="total_fee_all" label="销售金额"></el-table-column>
          </el-table-column>

        </el-table>
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="params.page"
          :page-sizes="[10,20,50]"
          :total="total_count"
          :page-size="params.pageSize">
        </el-pagination>
</template>
  </div>
</template>

<script>
  import {
    getorderdatapos
  } from '../../../api/datacube'

  export default {
    name: "posdata",
    data() {
      return {
        vdate: '',
        item_title: '',
        shop_code: '',
        shop_name: '',
        dealer_hqid: '',
        dealer_name: '',
        distributor_hqid: '',
        distributor_name: '',
        channel_rtm: '',
        receipt_type_zn: '',

      exportloading: false,

        params: {
          page: 1,
          pageSize: 10,
          start:'',
          end: '',

          item_name : '',
          shop_code : '',
          shop_name : '',
          dealer_hqid : '',
          dealer_name : '',
          distributor_hqid : '',
          distributor_vad : '',
          channel_rtm : '',
          receipt_type_zn : '',
        },

        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              end.setTime(end.getTime() );//- 3600 * 1000 * 24 * 1
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              end.setTime(end.getTime() );//- 3600 * 1000 * 24 * 1
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              end.setTime(end.getTime() );//- 3600 * 1000 * 24 * 1
              picker.$emit('pick', [start, end]);
            }
          }]
        },

        total_count: 0,
        all_num_payed: 0,
        all_countorder: 0,
        all_num_cancel: 0,
        all_total_fee_all: 0,
        dataList: {}
      }
    },
    methods: {
      dateChange (val) {
        this.params.start = val[0]
        this.params.end = val[1]
        this.getDataList(true)
      },
      searchData() {

        // var start = new Date();
        // var end = new Date();
      var start = this.params.start ;
      var end   = this.params.end ;
      //   start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
      //   end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
        this.vdate = [start, end]
        this.getDataList()
      },
      handleCurrentChange(page_num) {
        this.$refs.activityTable.clearSelection();
        this.params.page = page_num;
        this.getDataList();
      },
      handleSizeChange(pageSize) {
        this.$refs.activityTable.clearSelection();


        console.log(pageSize)
        console.log(this.params.pageSize)

        this.params.page = 1;
        this.params.pageSize = pageSize;

        console.log(pageSize)
        console.log(this.params)

        this.getDataList();
      },
      getDataList() {
        this.loading = true
        console.log(this.params)

        getorderdatapos(this.params).then(res => {
          this.dataList = res.data.data.list
          this.total_count = res.data.data.total_count
          this.all_num_payed     = res.data.data.sum_rec.all_num_payed
          this.all_countorder    = res.data.data.sum_rec.all_countorder
          this.all_num_cancel    = res.data.data.sum_rec.all_num_cancel
          this.all_total_fee_all = res.data.data.sum_rec.all_total_fee_all
          this.loading = false
        }).catch(error => {
          this.$message({
            type: 'error',
            message: '获取统计信息出错'
          })
        })
      },

    exportData() {
      this.exportloading = true
      if (this.dataList.length <= 0) {
        this.$message({
          type: 'error',
          message: '请确认导出的内容暂无数据~！'
        });
        this.exportloading = false
        return;
      }
      getorderdatapos({...this.params, is_export: true}).then(response => {
        if (response.data.data.status) {
          this.$message({
            type: 'success',
            message: '已加入执行队列，请在设置-导出列表中下载'
          })
          this.exportloading = false;
          return true;
        } else if (response.data.data.url) {
          this.downloadUrl = response.data.data.url
          this.downloadName = response.data.data.filename
          this.downloadView = true
        } else {
          this.$message({
            type: 'error',
            message: '无内容可导出 或 执行失败，请检查重试'
          })
          return false;
        }
      }).catch(error => {
        this.exportloading = false
        console.log(error);
        return false;
      });
    },
    },
    mounted() {
      var start = new Date();
      var end = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      end.setTime(end.getTime() );//- 3600 * 1000 * 24 * 1

    this.params.start = start;
    this.params.end   = end;

      this.vdate = [start, end]
      this.getDataList()
    }
  }
</script>

<style scoped>

</style>
