import { createSetting } from '@shopex/finder'
import { batchDelete, batchDeleteSku, batchSubmitSku } from '../api'
import { useDialog } from '@shopex/finder'
import { formatTimeStampToStr } from '@/utils/index'
import { MessageBox } from 'element-ui'
import SearchSell from '../components/search-cell'

const ITEM_STATUS_ENUM = {
  wait: '未提交',
  error: '提交失败'
}

// 是否
const IS_NO_OPTIONS = [
  {label: '是', value: true},
  {label: '否', value: false}
]

const commonActions = [
  { label: '取消', key: 'close', size: 'default' },
  { label: '保存', key: 'save', type: 'primary', size: 'default' }
]
let currentVm = undefined

function handlerCopy(v, vm) {
  let oInput = document.createElement('input')
  oInput.value = v
  document.body.appendChild(oInput)
  oInput.select()
  document.execCommand('Copy')
  vm.$message.success('复制成功')
  oInput.remove()
}

export const tableSetting = (vm) => createSetting({
  search: [
    { name: '商品名称', key: 'item_name' },
    { name: '商品货号', key: 'item_bn' },
    // {
    //   name: '商品品牌',
    //   key: 'goods_brand',
    //   type: 'select',
    //   options: vm.goodsBranchList,
    //   remote: true,
    //   remoteMethod: vm.getGoodsBranchList,
    //   filterable: true,
    //   clearable: true
    // },
    // {
    //   name: '商品分类',
    //   key: 'item_category',
    //   type: 'cascader',
    //   options: vm.categoryList,
    //   props: {
    //     checkStrictly: true,
    //     emitPath: false,
    //     value:'category_id',
    //   },
    //   showAllLevels: false,
    //   filterable: true,
    //   clearable: true
    // }
  ],
  columns: [
    { name: '商品ID', key: 'item_id', width: 80 },
    { name: '商品名称',
      key: 'item_name',
      minWidth: 150,
      render: (h, {row}) => {
        return (
          <div>
            <div class="goods-title">
              {row.item_name}
            </div>
            <div class="goods-code">
              <div>
                货号：{row.item_bn}
                <el-tooltip effect="dark" content="复制" placement="top-start">
                  <i onClick={() => handlerCopy(row.item_bn, vm)} class="el-icon-document-copy"></i>
                </el-tooltip>
              </div>
             {row.origin_bn && <div>
                原始货号：{row.origin_bn}
                <el-tooltip effect="dark" content="复制" placement="top-start">
                  <i onClick={() => handlerCopy(row.origin_bn, vm)} class="el-icon-document-copy"></i>
                </el-tooltip>
              </div>}
            </div>
          </div>
        )
      }
    },
    { name: '商品分类',
      key: 'item_category',
      minWidth: 150,
      formatter: v => {
        return v?.map(item => `【${item}】`).join(',')
      }
    },
    { name: '状态',
      key: 'submit_status',
      width: 100,
      formatter: (v) => ITEM_STATUS_ENUM[v]
    },
    {
      name: '错误信息',
      key: 'error_msg',
      minWidth: 150,
      showOverflowTooltip: false,
      render(_, {row}) {
        return <SearchSell value={row.error_msg}/>
      }
    },
    { name: '创建时间', key: 'created', formatter: v => formatTimeStampToStr(v), minWidth: 150 },
    { name: '更新时间', key: 'updated', formatter: v => formatTimeStampToStr(v), minWidth: 150 }
  ],
  actions: [
    {
      name: '新增商品导入',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openImportAddDialog()
        }
      }
    },
    {
      name: '导出商品',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openExportDialog()
        }
      }
    },
    {
      name: '更新商品导入',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.openImportUpdateDialog()
        }
      }
    },
    {
      name: '批量提交商品',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async (rows) => {
          MessageBox.confirm('请确认是否批量提交商品？', '提示').then(async () => {
            const ids = rows.map(v => v.goods_id)
            try {
              await batchSubmitSku(ids)
              vm.$message.success('提交成功')
            } finally {
              vm.refresh()
            }
          })
        }
      }
    },
    {
      name: '批量删除',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: async (rows) => {
          MessageBox.confirm('请确认是否批量删除？', '提示').then(async () => {
            const ids = rows.map(v => v.goods_id)
            await batchDelete(ids)
            vm.$message.success('删除成功')
            vm.refresh()
          })
        }
      }
    },
    {
      name: 'SKU明细',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          const name = row[0].item_name
          const id  = row[0].goods_id
          useDialog(null, {
            title: `SKU明细(${name})`,
            width: '80%',
            id,
            component: () => import('../detail.vue'),
            actions: commonActions
          })
        }
      }
    },
  ]
})

/**
 * @param {string} name
 * @param {string} key
 * @param {boolean} isBatch - 是否批量填充
 * @param {boolean} isRequired - 是否必填
*/
const cusInputCol = (name, key, isBatch = true, isRequired = true) => {
  return {
    name,
    key,
    minWidth: 180,
    headRender: (h) => {
      return (
        <div style={{display: 'flex', alignItems: 'center'}}>
          {
            isBatch ?
            <el-input
              size={'mini'}
              placeholder={name + '（批量）'}
              value={currentVm.headData[key]}
              onInput={(v) => {
                currentVm.$set(currentVm.headData, key, v)
              }}
              onChange={(v) => {
                currentVm.headerDataChange(key)
              }}
            /> : name
          }
          {isRequired && <span style={{color: 'red'}}>*</span>}
        </div>
      )
    },
    render: (h, { row }) => {
      const props = {
        size: 'default',
        placeholder: '请输入'
      }
      return <el-input {...props} value={row[key]} onInput={(v) => (row[key] = v.replace(/^\s+/, ''))} />
    }
  }
}

const cusSelectCol = (name, key, options, isBatch = true, isRequired = false) => {
  return {
    name,
    key,
    minWidth: 150,
    showType: "select",
    headRender: (h) => {
      return (
        <div style={{display: 'flex', alignItems: 'center'}}>
          {
            isBatch ?
            <el-select
              size={'mini'}
              placeholder={name + '（批量）'}
              value={currentVm.headData[key]}
              onChange={(v) => {
                currentVm.$set(currentVm.headData, key, v)
                currentVm.headerDataChange(key)
              }}
            >
              {
                options.map((item) => {
                  return <el-option key={item.value} label={item.label} value={item.value} />
                })
              }
            </el-select> : name
          }
          {isRequired && <span style={{color: 'red'}}>*</span>}
        </div>
      )
    },
    componentProps: {
      options
    }
  }
}

const cusNumberCol = (name, key, precision = 0, isBatch = false, isRequired = false) => {
  return {
    name,
    key,
    showType: 'input-number',
    minWidth: 150,
    componentProps: {
      controls: false,
      size: 'default',
      precision,
      min: 0,
      placeholder: '请输入'
    },
    headRender: (h) => {
      return (
        <div style={{display: 'flex', alignItems: 'center'}}>
          {
            isBatch ?
            <el-input-number
              controls={false}
              size={'mini'}
              precision={precision}
              min={0}
              placeholder={name + '（批量）'}
              value={currentVm.headData[key]}
              onInput={(v) => {
                currentVm.$set(currentVm.headData, key, v)
              }}
              onChange={(v) => {
                currentVm.headerDataChange(key)
              }}
            /> : name
          }
          {isRequired && <span style={{color: 'red'}}>*</span>}
        </div>
      )
    }
  }
}

export const detailTableSetting = (vm) => {
  currentVm = vm
  return createSetting({
    search: [],
    columns: [
      { name: '商品ID',key: 'item_id',width: 100 },
      cusInputCol('商品主类目(一级)', 'item_main_category_1'),
      cusInputCol('商品主类目(二级)', 'item_main_category_2'),
      cusInputCol('商品主类目(三级)', 'item_main_category_3'),
      cusInputCol('商品名称', 'item_name'),
      cusInputCol('副标题', 'brief', true, false),
      cusInputCol('品牌', 'goods_brand'),
      cusInputCol('排序', 'sort', true, false),
      cusInputCol('商品分类', 'item_category'),
      cusInputCol('分类页标签', 'item_tag_name', true, false),
      cusSelectCol('是否为赠品', 'is_gift', IS_NO_OPTIONS),
      cusSelectCol('是否教育优惠商品', 'is_edu', IS_NO_OPTIONS),
      cusSelectCol('是否企业购商品', 'is_ec', IS_NO_OPTIONS),
      cusSelectCol('特殊商品类型', 'item_tier', [
        {label: '普通商品', value: '1'},
        {label: 'AC+', value: '2'},
        {label: 'iup', value: '3'}
      ]),
      cusSelectCol('是否主品', 'is_main_product', IS_NO_OPTIONS),
      cusSelectCol('是否仅自提', 'is_ziti', IS_NO_OPTIONS),
      cusSelectCol('是否可搭售', 'is_addcart_goods', IS_NO_OPTIONS),
      cusSelectCol('企业微信是否可分享', 'wecom_share', IS_NO_OPTIONS),
      cusSelectCol('是否支持自动接单', 'auto_receiving', IS_NO_OPTIONS),
      // cusInputCol('商品头图路径', 'item_header_img_path'),
      cusInputCol('商品头图档案名称', 'item_header_img_name'),
      // cusInputCol('首页商品封面图路径', 'item_home_img_path'),
      cusInputCol('首页商品封面图档案名称', 'item_home_img_name'),
      // cusInputCol('商品视频路径', 'item_video_path', true, false),
      cusInputCol('商品视频档案名称', 'item_video_name', true, false),
      cusSelectCol('视频播放形式', 'video_auto_play', [
        {label: '手动播放', value: false},
        {label: '自动播放', value: true},
      ]),
      // cusInputCol('视频封面路径', 'item_video_img_path', true, false),
      cusInputCol('视频封面档案名称', 'item_video_img_name', true, false),
      // cusInputCol('商品详情图路径', 'intro_img', true, false),
      cusInputCol('商品详情图档案名称', 'intro_img_name', true, false),
      cusInputCol('商品参数', 'item_params', true, false),
      cusSelectCol('商品状态', 'approve_status',  [
        {label: '前台可销售', value: '0'},
        {label: '前台仅展示', value: '1'},
        {label: '不可销售', value: '2'},
      ], false, true),
      cusInputCol('商品货号', 'item_bn', false, true),
      cusInputCol('原始货号', 'origin_bn', false, true),
      cusNumberCol('销售参考价', 'price', 2, false, true),
      cusInputCol('SKU描述', 'sku_describe', true, false),
      cusSelectCol('是否默认显示SKU', 'is_default', IS_NO_OPTIONS, false, true),
      cusSelectCol('是否默认选择SKU', 'is_default_chose', IS_NO_OPTIONS, false, true),
      cusInputCol('图片规格名称', 'spec_img_name', true, false),
      cusInputCol('图片规格值', 'spec_img_value', false, false),
      cusNumberCol('图片规格值排序', 'spec_img_sort'),
      // cusInputCol('图片规格值图片路径', 'spec_img_pic_path', true, false),
      cusInputCol('图片规格值档案名称', 'spec_img_pic_name', false, false),
      cusInputCol('图片规格备注', 'spec_img_remark', true, false),
      // cusInputCol('商品规格图路径', 'spec_img_sku_img_path', true, false),
      cusInputCol('商品规格图档案名称', 'spec_img_sku_img_name', true, false),
      cusInputCol('文字规格名称(1)', 'spec_text_1_name', true, false),
      cusInputCol('文字规格值(1)', 'spec_text_1_value', false, false),
      cusNumberCol('文字规格值排序(1)', 'spec_text_1_sort'),
      cusInputCol('文字规格备注(1)', 'spec_text_1_remark', true, false),
      // cusInputCol('文字规格值图片路径(1)', 'spec_text_1_pic_path', true, false),
      cusInputCol('文字规格值档案名称(1)', 'spec_text_1_pic_name', false, false),
      cusInputCol('文字规格名称(2)', 'spec_text_2_name', true, false),
      cusInputCol('文字规格值(2)', 'spec_text_2_value', false, false),
      cusNumberCol('文字规格值排序(2)', 'spec_text_2_sort'),
      cusInputCol('文字规格备注(2)', 'spec_text_2_remark', true, false),
      // cusInputCol('文字规格值图片路径(2)', 'spec_text_2_pic_path', true, false),
      cusInputCol('文字规格值档案名称(2)', 'spec_text_2_pic_name', false, false),
      cusInputCol('文字规格名称(3)', 'spec_text_3_name', true, false),
      cusInputCol('文字规格值(3)', 'spec_text_3_value', false, false),
      cusNumberCol('文字规格值排序(3)', 'spec_text_3_sort'),
      cusInputCol('文字规格备注(3)', 'spec_text_3_remark', true, false),
      // cusInputCol('文字规格值图片路径(3)', 'spec_text_3_pic_path', true, false),
      cusInputCol('文字规格值档案名称(3)', 'spec_text_3_pic_name', false, false),
    ],
    actions: [
      {
        name: '删除',
        type: 'button',
        buttonType: 'text',
        action: {
          handler: async (row) => {
            MessageBox.confirm('请确认是否删除？', '提示').then(async () => {
              const id = row[0].item_id
              await batchDeleteSku(id)
              vm.$message.success('删除成功')
              vm.refresh()
            })
          }
        }
      },
    ]
  })
}
