import { render, staticRenderFns } from "./add.vue?vue&type=template&id=2b66b30c&scoped=true"
import script from "./add.vue?vue&type=script&lang=js"
export * from "./add.vue?vue&type=script&lang=js"
import style0 from "./add.vue?vue&type=style&index=0&id=2b66b30c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b66b30c",
  null
  
)

export default component.exports