<template>
  <div>
    <div v-if="$route.path.indexOf('_detail') === -1">
      <el-row class="filter-header" :gutter="20">
        <el-col>
          <el-autocomplete
            class="inline-input"
            v-model="currentShop"
            :fetch-suggestions="queryStoreSearch"
            placeholder="请输入店铺名称"
            @select="storeSearch"
          ></el-autocomplete>
          <el-date-picker class="input-m" v-model="create_time" type="daterange" value-format="yyyy/MM/dd" placeholder="选择日期范围" @change="dateChange"></el-date-picker>
          <el-input class="input-m" placeholder="订单号" v-model="identifier">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
          <el-input class="input-m" placeholder="售后单号" v-model="aftersales_bn">
            <el-button slot="append" icon="el-icon-search" @click="aftersalesBnSearch"></el-button>
          </el-input>
<!--          <el-input class="input-m" placeholder="收货人手机号" v-model="receiver_mobile">-->
<!--            <el-button slot="append" icon="el-icon-search" @click="mobileSearch"></el-button>-->
<!--          </el-input>-->
          <el-select v-model="aftersales_status" clearable @change="aftersalesStatusSelectHandle" placeholder="售后状态">
            <el-option
              v-for="(item, index) in aftersalesStatusList"
              :key="index"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="aftersales_type" clearable @change="TypeHandle" placeholder="请选择售后类型">
            <el-option
              v-for="(item, index) in aftersalesTypeList"
              :key="index"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="is_ec" clearable @change="EcHandle" placeholder="是否为员工购">
            <el-option
              v-for="(item, index) in is_ecs"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button type="primary" @click="exportData">导出</el-button>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
<!--          <el-button type="primary" @click="aftersalesRemindAction">售后提醒内容</el-button>-->
        </el-col>
      </el-row>
      <el-card>
        <el-table :data="list" v-loading="loading" element-loading-text="数据加载中">
          <el-table-column prop="create_time" width="200" label="售后单" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="order-num">
                <router-link target="_blank" :to="{ path: matchHidePage('detail'), query: { aftersales_bn: scope.row.aftersales_bn }}">{{scope.row.aftersales_bn}}</router-link>
                <el-tooltip effect="dark" content="复制" placement="top-start">
                  <i v-clipboard:copy="scope.row.aftersales_bn" v-clipboard:success="onCopy" class="el-icon-document-copy"></i>
                </el-tooltip>
              </div>
              <div class="order-time">
                <el-tooltip effect="dark" content="申请时间" placement="top-start">
                  <i class="el-icon-time"></i>
                </el-tooltip>
                {{scope.row.create_time | datetime('yyyy-MM-dd hh:mm:ss')}}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="200" label="订单" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="order-num">
                <router-link target="_blank" :to="{ path: prefix + '/order/entitytrade/tradenormalorders/tradenormalorders_detail', query: { orderId: scope.row.order_id }}">{{scope.row.order_id}}</router-link>
                <el-tooltip effect="dark" content="复制" placement="top-start">
                  <i v-clipboard:copy="scope.row.order_id" v-clipboard:success="onCopy" class="el-icon-document-copy"></i>
                </el-tooltip>
              </div>
              <div class="order-time" v-if="scope.row.ecid !== '' && scope.row.ecid!=undefined">
              <el-tag size="mini" type="warning">员工购</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="150" label="店铺AppleID" property="shop_code" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="150" label="店铺名称" property="distributor_name" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="150" label="经销商名称" property="dealer_name" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="150" label="经销商HQID" property="dealer_hqid" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="150" label="分销商HQID" property="distributor_hqid" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="150" label="分销商VAD" property="distributor_vad" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="150" label="渠道RTM" property="channel_rtm" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="150" label="收货人手机号" property="receiver_mobile" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column width="100" label="售后类型" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.aftersales_type === 'ONLY_REFUND'" type="info" size="mini">仅退款</el-tag>
              <el-tag v-if="scope.row.aftersales_type === 'SEVEN_REFUND_GOODS'" type="info" size="mini">7天无理由</el-tag>
              <el-tag v-if="scope.row.aftersales_type === 'REFUND_GOODS'" type="warning" size="mini">7天质量问题</el-tag>
              <el-tag v-if="scope.row.aftersales_type === 'EXCHANGING_GOODS'" type="danger" size="mini">15天内换货</el-tag>
            </template>
          </el-table-column>
          <el-table-column width="100" label="售后状态" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.aftersales_status == '0'" size="mini">待处理</el-tag>
              <el-tag v-if="scope.row.aftersales_status == '1'" size="mini">处理中</el-tag>
              <el-tag v-if="scope.row.aftersales_status == '2'" type="success" size="mini">已处理</el-tag>
              <el-tag v-if="scope.row.aftersales_status == '3'" type="success" size="mini">已驳回</el-tag>
              <el-tag v-if="scope.row.aftersales_status == '4'" type="success" size="mini">已关闭</el-tag>
              <el-tag v-if="scope.row.aftersales_status == '5'" type="success" size="mini">已收货</el-tag>
              <el-tag v-if="scope.row.aftersales_status == '6'" type="success" size="mini">强制关闭</el-tag>
            </template>
          </el-table-column>
          <el-table-column width="200" label="操作" align="center">
            <template slot-scope="scope">
              <el-link :underline="false">
              <router-link :to="{ path: matchHidePage('detail'), query: { aftersales_bn: scope.row.aftersales_bn}}">详情</router-link>
              </el-link>
              <el-link v-if="login_type !== 'disti' && showComfireReceipt(scope.row)" type="primary" @click="handleConfirmReceipt(scope.row)" :underline="false">确认收货</el-link>
              <el-link v-if="scope.row.aftersales_status != '6' && scope.row.progress == '8' && scope.row.aftersales_type != 'EXCHANGING_GOODS' && login_type !== 'disti'" type="primary" @click="handleRefund(scope.row)" :underline="false">退款</el-link>
              <el-link v-if="scope.row.aftersales_status != '6' && scope.row.progress == '8' && scope.row.aftersales_type == 'EXCHANGING_GOODS' && login_type !== 'disti'" type="primary" @click="handleExchangingGoods(scope.row)" :underline="false">换货</el-link>
              <el-link v-if="(scope.row.aftersales_status < '2' || scope.row.aftersales_status == '5') && login_type !== 'disti'" type="primary" @click="ForcedClosure(scope.row)" :underline="false">强制关闭售后</el-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :current-page.sync="params.page"
            :page-sizes="[10,20,50]"
            :total="total_count"
            :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-card>
      <!-- 售后提醒内容 -开始 -->
      <el-dialog :title="aftersalesRemindTitle" :visible.sync="aftersalesRemindVisible"  :before-close="handleCancel" width="65%">
        <template>
          <el-form ref="aftersalesRemindForm" :model="aftersalesRemindForm" class="demo-ruleForm" label-width="100px">
            <el-form-item label="提醒内容">
              <template>
                <vue-html5-editor :content="aftersalesRemindForm.intro.toString()" ref="editor" @change="updateContent" :height="360"></vue-html5-editor>
              </template>
              <span class="frm-tips">例如：由于商品的特殊性，如涉及机油类产品需寄回，建议使用京东快递，快递公司联系电话955XX。</span>
            </el-form-item>
            <el-form-item label="是否启用">
              <el-switch v-model="aftersalesRemindForm.is_open" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
              <br />
              <span class="frm-tips">如开启展示，则后台所输入内容将会展示在前端消费者提交售后申请的页面上，内容不超过200字</span>
            </el-form-item>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleCancel">取消</el-button>
          <el-button type="primary" @click="submitAftersalesRemind">确定</el-button>
        </div>
      </el-dialog>
      <el-dialog title="关闭售后" :visible.sync="forcedClosureVisible" :before-close="handleForcedClosure" width="57%">
        <template>
          <el-form ref="cancelForm" :model="forcedClosure" class="" label-width="100px">
            <el-form-item label="订单号:">
              <el-col :span="20">{{forcedClosure.order_id}}</el-col>
            </el-form-item>
            <el-form-item label="关闭原因:">
              <el-row>
                <el-col :span="20">
                  <template>
                    <el-select v-model="reason" filterable placeholder="请选择关闭售后原因" @change="reasonSelect">
                      <el-option
                        v-for="(item) in reasonList"
                        :key="item"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                    <el-input v-show="input" v-model="forcedClosure.other_reason" type="textarea" :rows="3" resize="none"  style="width: 200px; margin-top: 10px" placeholder="请填写关闭原因"></el-input>
                  </template>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="handleForcedClosure">取消</el-button>
          <el-button type="primary" @click="doForcedClose">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { getDistributorList } from '../../../api/marketing'
  import {getAftersalesList, exportList, reviewAftersales, getAftersalesRemind, setAftersalesRemind, refundCheck, sendConfirm,updateAfterSalesStatus} from '../../../api/aftersales'
  import {getAftersalesReason} from "../../../api/aftersalesreason";
  export default {
    data () {
      return {
        prefix: '',
        input:false,
        reason: '',
        reasonList: [],
        forcedCloseReason:[],
        forcedClosureVisible:false,
        forcedClosure: {
          order_id: '',
          aftersales_bn: '',
          aftersales_status: '',
          refuse_reason: '',
        },
        currentShop: '',
        loading: false,
        create_time: '',
        params: {
          page: 1,
          pageSize: 20
        },
        aftersalesStatusList: [
          {name: '待处理', value: '0'},
          {name: '处理中', value: '1'},
          {name: '已收货', value: '5'},
          {name: '已处理', value: '2'},
          {name: '已驳回', value: '3'}
        ],
        is_ecs: [
          {
            value: 'true',
            label: '员工购'
          }, {
            value: 'false',
            label: '非员工购'
          }
        ],
        aftersalesTypeList: [
          {name: '仅退款', value: 'ONLY_REFUND'},
          {name: '7天无理由', value: 'SEVEN_REFUND_GOODS'},
          {name: '7天质量问题', value: 'REFUND_GOODS'},
          {name: '15天内换货', value: 'EXCHANGING_GOODS'}
        ],
        aftersales_type: '',
        is_ec: '',
        aftersales_status: '',
        aftersales_bn: '',
        time_start_begin: '',
        time_start_end: '',
        total_count: 0,
        list: [],
        identifier: '',
        mobile: '',
        receiver_mobile: '',
        wxShopsList: [],
        aftersalesRemindForm: {
          intro: '',
          is_open: false
        },
        aftersalesRemindVisible: false,
        aftersalesRemindTitle: '售后提醒内容'
      }
    },
    computed: {
      ...mapGetters([
        'wheight',
        'login_type'
      ])
    },
    methods: {

      showComfireReceipt(row){
        if (row.aftersales_status != '6' && row.progress == '2' && row.aftersales_type != 'ONLY_REFUND') {

          // 7天无理由退货 邮寄 时，没有 物流单号就不显示 确认收货按钮  2021年08月30日19:02:51
          if (row.aftersales_type == 'SEVEN_REFUND_GOODS' && row.sendback_data.is_logistics == 'true' && row.sendback_data.delivery_code == '') {
           // return false; // 谢金萍讲，有可能 用户不邮寄了，可能出差顺便送回去了，就不要 不显示 确认收货 按钮，言外之意就是 只要  审核了，就一直显示确认收货按钮 2021年08月30日19:11:58
          }

          return true;
        }
        // scope.row.aftersales_status != '6' && scope.row.progress == '2' && scope.row.aftersales_type != 'ONLY_REFUND'
      },

      handleForcedClosure() {
        this.forcedClosureVisible = false
        this.forcedClosure.order_id = ''
        this.forcedClosure.aftersales_bn = ''
        this.forcedClosure.refuse_reason = ''
        this.reason = ''
      },
      getAftersalesReasonList(){
        getAftersalesReason([]).then(res => {
          this.reasonList = res.data.data;
        })
      },
      // 原因选择
      reasonSelect(val) {
        console.log(val,'===============')
        if (val == 12) {
          this.input = true
        } else {
          this.input = false
        }
        this.forcedClosure.refuse_reason = val
      },
      // 强制关闭 售后
      ForcedClosure(row) {
        this.forcedClosureVisible = true;
        this.forcedClosure.order_id = row.order_id;
        this.forcedClosure.aftersales_bn =row.aftersales_bn;
        console.log(row, '=====');
      },
      doForcedClose() {
        let data = {
          ...this.forcedClosure,
          aftersales_status: 6,
        };
        updateAfterSalesStatus(data).then(res => {
          if (res.data.data){
            this.$message({
              message: '操作强制关闭完成',
              type: 'success',
              duration: 3 * 1000
            });
            this.handleForcedClosure();
            this.getAftersalesList(this.params);
          } else {
            this.$message.error('操作失败！');
            return false;
          }
        });
      },

      // 操作 确认收货
      handleConfirmReceipt(row) {
        this.$confirm('请确认是否收到货？', '确认收货提示', {
          confirmButtonText: '确认收货',
          showCancelButton: false,
          type: 'warning',
          center: true,
          // showClose: false,
          // closeOnClickModal: false,
          // closeOnPressEscape: false,
        }).then(() => {
          console.log('确认收货');
          this.doConfirmReceipt(row);
        }).catch(() => {
          console.log('暂不处理');
        });
      },

      // 操作 同意退款 - 拒绝退款
      handleRefund(row) {
        let showCancelButton = true;
        // 7天质量  隐藏 拒绝退款按钮 和 7 天无理由的均隐藏
        if (row.aftersales_type === 'SEVEN_REFUND_GOODS') {
          // showCancelButton = false;
        }
        this.$confirm('请确认已收到消费者的退货！是否退款？', '退款提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '同意退款',
          cancelButtonText: '拒绝退款',
          center: true,
          showCancelButton,
        }).then(() => {
          this.doRefund(row, 1);
        }).catch(action => {
          if (action === 'cancel') {
            console.log('拒绝');
            this.doRefund(row, 0);
          } else {
            console.log('关闭');
          }
        });
      },

      // 操作 换货 - 同意 / 拒绝
      handleExchangingGoods(row) { // 是否换货
        this.$confirm('请确认是否换货？', '换货提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '同意换货',
          cancelButtonText: '拒绝换货',
          center: true,
        }).then(() => {
          this.doSendConfirm(row,1);
        }).catch( action => {
          if (action == 'cancel') {
            this.doSendConfirm(row,0);
          } else {
            console.log('关闭');
          }
        });
      },

      // 确认收货
      doConfirmReceipt(row) { // 确认收货
        updateAfterSalesStatus({
          order_id: row.order_id,
          aftersales_bn: row.aftersales_bn,
          aftersales_status: 5,
          progress: 8,
        }).then(response => {
          if (response.data.data) {
            this.$message({
              message: '操作确认收货完成',
              type: 'success',
              duration: 3 * 1000
            });
            this.getAftersalesList(this.params);
          } else {
            this.$message.error('操作失败！');
            return false;
          }
        });
      },

      doSendConfirm(row, type) {
        let msg = '操作成功，拒绝退货！', typeMsg = 'info';
        if (type) {
          msg = '操作成功，同意退货！';
          typeMsg = 'success';
        }
        sendConfirm({
          type,
          aftersales_bn: row.aftersales_bn,
          corp_code: '0000000',
          logi_no: '管理员操作换货' + msg,
        }).then(response => {
          if (response.data.data) {
            this.$message({
              message: msg,
              type: typeMsg,
              duration: 3 * 1000
            });
            this.getAftersalesList(this.params)
          } else {
            this.$message.error('操作失败！');
            return false
          }
        });
      },

      doRefund(row, check_refund) {
        let msg = '操作成功，拒绝退款！', type = 'info';
        if (check_refund) {
          msg = '操作成功，同意退款！';
          type = 'success';
        }
        refundCheck({
          aftersales_bn: row.aftersales_bn,
          check_refund: check_refund,
          refund_fee: row.refund_fee,
          refund_point: row.refund_point,
          refunds_memo: '管理员操作退款' + msg,
        }).then(response => {
          if (response.data.data) {
            this.$message({
              message: msg,
              type: type,
              duration: 3 * 1000
            });
            this.getAftersalesList(this.params)
          } else {
            this.$message.error('操作失败！');
            return false
          }
        });
      },
      onCopy () {
        this.$notify.success({
          message: '复制成功',
          showClose: true
        });
      },
      shopHandle (val) {
        this.currentShop = val
        this.params.shop_id = val
        this.params.page = 1
        this.getParams()
        this.getAftersalesList(this.params)
      },
      TypeHandle (val) {
        this.params.aftersales_type = val
        this.getParams()
        this.getAftersalesList(this.params)
      },
      EcHandle (val) {
        this.params.is_ec = val
        this.getParams()
        this.getAftersalesList(this.params)
      },
      aftersalesStatusSelectHandle () {
        this.params.aftersales_status = this.aftersales_status
        this.params.page = 1
        this.getParams()
        this.getAftersalesList(this.params)
      },
      search (e) {
        this.params.page = 1
        this.getParams()
        this.getAftersalesList(this.params)
      },
      aftersalesBnSearch() {
        this.getParams()
        this.getAftersalesList(this.params)
      },
      mobileSearch() {
        this.params.page = 1
        this.getParams()
        this.getAftersalesList(this.params)
      },
      dateChange (val) {
        if (val.length > 0) {
          this.time_start_begin = this.dateStrToTimeStamp(val[0] + ' 00:00:00')
          this.time_start_end = this.dateStrToTimeStamp(val[1] + ' 23:59:59')
        } else {
          this.time_start_begin = ''
          this.time_start_end = ''
        }
        this.params.page = 1
        this.getParams()
        this.getAftersalesList(this.params)
      },
      sourceSearch(item) {
        this.params.source_id = item.source_id
        this.params.page = 1
        this.getParams()
        this.getAftersalesList(this.params)
      },
      handleCurrentChange (val) {
        this.params.page = val
        this.loading = false
        this.getParams()
        this.getAftersalesList(this.params)
      },
      handleSizeChange (pageSize) {
        this.loading = false
        this.params.page = 1
        this.params.pageSize = pageSize
        this.getParams()
        this.getAftersalesList(this.params)
      },
      getParams () {
        this.params.time_start_begin = this.time_start_begin
        this.params.time_start_end = this.time_start_end
        this.params.order_type = this.order_type
        this.params.aftersales_bn = this.aftersales_bn
        this.params.order_id = this.identifier
        this.params.receiver_mobile = this.receiver_mobile
      },
      dateStrToTimeStamp (str) {
        return Date.parse(new Date(str))/1000
      },
      getAftersalesList(filter) {
        this.loading = true
        getAftersalesList(filter).then(response => {
          this.list = response.data.data.list
          this.total_count = Number(response.data.data.total_count)
          this.loading = false
        })
      },
      querySearch(queryString, cb) {
        var restaurants = this.source_list;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        }
      },
      getStoreList () {
        this.loading = true
        let params = {page: 1, pageSize: 500}
        getDistributorList(params).then(response => {
          if(response.data.data.list) {
            response.data.data.list.forEach(row => {
              this.wxShopsList.push({'value': row.name, 'distributor_id': row.distributor_id})
            })
          }
          this.loading = false
        })
      },
      queryStoreSearch(queryString, cb) {
        var restaurants = this.wxShopsList
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      storeSearch(item) {
        this.params.distributor_id = item.distributor_id
        this.params.page = 1
        this.getParams()
        this.getAftersalesList(this.params)
      },
      exportData () {
          this.getParams()
          this.params.page = 1
          exportList(this.params).then(response => {
              if (response.data.data.status) {
                  this.$message({
                      type: 'success',
                      message: '已加入执行队列，请在设置-导出列表中下载'
                  })
                  return
              } else if (response.data.data.url) {
                  this.downloadUrl = response.data.data.url
                  this.downloadName = response.data.data.filename
                  this.downloadView = true
              } else {
                  this.$message({
                      type: 'error',
                      message: '没有相关数据可导出'
                  })
              }
          })
      },
      aftersalesRemindAction () {
        // 请求提醒数据
        getAftersalesRemind().then(response => {
          if(response.data.data) {
            this.aftersalesRemindForm = response.data.data
          }
        })
        this.aftersalesRemindVisible = true

      },
      updateContent: function (data) {
        this.aftersalesRemindForm.intro = data
      },
      handleCancel () {
        this.aftersalesRemindVisible = false
        this.aftersalesRemindForm.intro = ''
        this.aftersalesRemindForm.is_open = false
      },
      submitAftersalesRemind () {
        let params = {
          intro: this.aftersalesRemindForm.intro,
          is_open: this.aftersalesRemindForm.is_open,
        }
        setAftersalesRemind(params).then(response => {
          this.$message({
            type: 'success',
            message: '保存成功'
          }),
          this.aftersalesRemindVisible = false
        })
      }
    },
    mounted () {
      switch (this.login_type) {
        case 'disti': // 代理端
          this.prefix = '/distiadmin'
          break;
        case 'dealer': // 经销商端
          this.prefix = '/dealeradmin'
          break;
        case 'distributor': // 店铺端
          this.prefix = '/shopadmin';
          break;
        default: // 平台  包括admin  staff
          this.prefix = '';
      }
      this.getStoreList()
      this.getAftersalesList(this.params)
      this.getAftersalesReasonList();
    }
  }
</script>
