import { createSetting } from '@shopex/finder'
import { MessageBox } from 'element-ui'
import { delUploadInfo } from '../api'

export const goodSetting = (vm) => createSetting({
  search: [],
  columns: [
    { name: '商品货号', key: 'item_bn', minWidth: 150 },
    { name: '商品名称', key: 'item_name', minWidth: 150 },
  ],
  actions: [
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          MessageBox.confirm('请确认是否删除当前商品？', '提示').then(async () => {
            await delUploadInfo(row[0])
            vm.$message.success('删除成功')
            vm.goodsFinderRefrsh()
          })
        }
      }
    }
  ]
})

export const shopSetting = (vm) => createSetting({
  search: [],
  columns: [
    { name: '店铺 Apple ID', key: 'shop_code', minWidth: 150 },
    { name: '店铺名称', key: 'distributor_name', minWidth: 150 },
  ],
  actions: [
    {
      name: '删除',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          MessageBox.confirm('请确认是否删除当前店铺？', '提示').then(async () => {
            await delUploadInfo(row[0])
            vm.$message.success('删除成功')
            vm.shopFinderRefrsh()
          })
        }
      }
    }
  ]
})
