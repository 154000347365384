<template>
  <div>
    <div>
      <el-row :gutter="20">
        <el-col :span="10">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>维修产品</span>
              <el-button v-if="login_type == 'disti'" style="float: right;" type="primary" size="mini"
                @click="handleEditProduct('')">添加维修产品</el-button>
            </div>
            <el-table :height="wheight - 260" v-loading="productListLoading" :data="productList" highlight-current-row
              style="width: 100%" @current-change="handleCurrentProductChange">
              <el-table-column label="ID" prop="id" width="50px"></el-table-column>
              <el-table-column label="产品名称" prop="product_name"></el-table-column>
              <el-table-column label="现场维修" prop="sort" width="90px">
                <template slot-scope="scope">
                  <el-switch
                    @change="changeProductFieldRepairable(scope.row)"
                    v-model="scope.row.field_repairable"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-value="1"
                    inactive-value="0">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="排序" prop="sort" width="90px">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.sort" size="mini" @change="changeProductSort(scope.row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="110px">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleEditProduct(scope.row)">编辑</el-button>
                  <el-button type="text" @click="deleteRepairProduct(scope.row)" style="color: red">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>

        <el-col :span="14">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>维修内容</span>
              <el-button style="float: right;" type="primary" size="mini"
                @click="handleEditContent('')"  v-if="login_type == 'disti'">添加维修内容</el-button>
            </div>
            <el-table ref="contentTable" :height="wheight - 260" v-loading="contentListLoading" :data="contentList"
              row-key="id" style="width: 100%" @select="handleSelectContentChange"
              @select-all="handleSelectContentChange">
              <el-table-column type="selection" :reserve-selection="true" width="55">
              </el-table-column>
              <el-table-column label="ID" prop="id" width="50px"></el-table-column>
              <el-table-column label="维修内容" prop="content_name">
              </el-table-column>
              <el-table-column label="排序" prop="sort" width="90px">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.sort" size="mini" @change="changeContentSort(scope.row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="110px">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleEditContent(scope.row)">编辑</el-button>
                  <el-button type="text" @click="deleteRepairContent(scope.row)" style="color: red">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </el-row>

      <el-row type="flex" justify="center" style="margin-bottom: 20px">
        <el-col :span="4">
          <el-button size="medium" type="success" @click="submitRepairProductRelContent" :loading="submitLoading">
            保存更改
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-dialog title="编辑维修产品" :visible.sync="productDialogVisible" width="30%">
      <el-form :model="productForm" label-width="100px" ref="productForm">
        <el-form-item prop="id"></el-form-item>
        <el-form-item prop="sort"></el-form-item>
        <el-form-item label="产品名称" prop="product_name">
          <el-input v-model="productForm.product_name" class="input-b"></el-input>
        </el-form-item>
        <el-form-item label="icon图片">
          <div>
            <div>
              <div @click="handleHomeImgChange" class="upload-box">
                <img v-if="productForm.icon" :src="wximageurl + productForm.icon" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </div>
            <imgPicker :dialog-visible="homeimgDialog" :sc-status="isGetImage" @chooseImg="pickImg"
              @closeImgDialog="closeHomeImgDialog"></imgPicker>
            <div class="frm-tips">
              <p>1. 最多可上传1个图片，文件格式为bmp、png、jpeg、jpg或gif，大小不超过2M（建议尺寸：500px * 500px）</p>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeProductDialog">取 消</el-button>
        <el-button type="primary" @click="editProductInfo">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑维修内容" :visible.sync="contentDialogVisible" width="30%">
      <el-form :model="contentForm" ref="contentForm" label-width="100px">
        <el-form-item prop="id"></el-form-item>
        <el-form-item prop="sort"></el-form-item>
        <el-form-item label="维修内容">
          <el-input v-model="contentForm.content_name" class="input-b" prop="content_name"></el-input>
        </el-form-item>
        <el-form-item label="内容描述">
          <el-input v-model="contentForm.content_desc" class="input-b" prop="content_desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeContentDialog">取 消</el-button>
        <el-button type="primary" @click="editContentInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getRepairProductList,
  createRepairProduct,
  updateRepairProduct,
  getRepairContentList,
  createRepairContent,
  updateRepairContent,
  deleteRepairProduct,
  deleteRepairContent,
  getRepairProductInfo,
  saveRepairProductContent,
} from '@/api/aftersales'
import imgPicker from '@/components/imageselect'
import ImportDialog from '@/components/importDialog'

export default {
  provide() {
    return {
    }
  },
  components: {
    ImportDialog,
    imgPicker
  },
  data() {
    return {
      submitLoading: false,
      productDialogVisible: false,
      contentDialogVisible: false,
      productListLoading: false,
      contentListLoading: false,
      selectProductId: 0,
      contentIds: [],
      productForm: {
        id: 0,
        product_name: '',
        sort: 0,
        icon: ''
      },
      contentForm: {
        id: 0,
        content_name: '',
        content_desc: '',
        sort: 0,
      },
      productParams: {
        page: 1,
        pageSize: -1,
      },
      contentParams: {
        page: 1,
        pageSize: -1,
      },
      productList: [],
      contentList: [],
      homeimgDialog: false,
      isGetImage: false,
      type: 'product'//product 产品、content内容
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {

    changeProductFieldRepairable(row) {
      let params = {
        id: row.id,
        field_repairable: row.field_repairable
      }
      updateRepairProduct(params).then(response => {
        if (response.data.data.success === true) {
          this.$message.success('排序更新成功');
          this.getRepairProductList();
        } else {
          this.$message.error('排序更新失败')
        }
      })
    },
    handleHomeImgChange() {
      this.homeimgDialog = true
      this.isGetImage = true
    },
    pickImg(data) {
      this.productForm.icon = data.url
      this.homeimgDialog = false
    },

    closeHomeImgDialog() {
      this.homeimgDialog = false
    },
    submitRepairProductRelContent() {
      this.submitLoading = true
      if (!this.selectProductId) {
        this.$message.warning('请选择维修产品')
        this.submitLoading = false
        return
      }
      let params = {
        product_id: this.selectProductId,
        content_ids: this.contentIds,
      }
      saveRepairProductContent(params).then(response => {
        if (response.data.data.success === true) {
          this.$message.success('保存成功')
        } else {
          this.$message.error('保存失败')
        }
        this.submitLoading = false
        this.getRepairContentList()
      })
    },
    handleSelectContentChange(selection, row) {
      if (!this.selectProductId) {
        this.$message.warning('请先选择维修产品')
        if (!row) {
          this.$refs.contentTable.clearSelection()
        } else {
          this.$refs.contentTable.toggleRowSelection(row)
        }
        return
      }
      if (selection) {
        this.contentIds = []
        selection.forEach(item => {
          this.contentIds.push(item.id)
        })
      }
      console.log(this.contentIds)
    },
    handleCurrentProductChange(val) {
      if(!val) return
      this.selectProductId = val.id
      this.getRepairProductInfo()
    },
    getRepairProductInfo() {
      this.contentListLoading = true
      let params = {
        id: this.selectProductId
      }
      this.$refs.contentTable.clearSelection()
      getRepairProductInfo(params).then(response => {
        if (response.data.data.content_lists) {
          this.contentIds = []
          response.data.data.content_lists.forEach(contentId => {
            this.contentIds.push(contentId.id)
          })
          this.contentIds.forEach(contentId => {
            this.contentList.forEach(content => {
              if (content.id == contentId) {
                this.$refs.contentTable.toggleRowSelection(content);
              }
            })
          })
          this.contentListLoading = false
        }
      })
    },
    deleteRepairProduct(row) {
      this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id
        }
        deleteRepairProduct(params).then(response => {
          if (response.data.data.success === true) {
            this.$message.success('删除成功')
          } else {
            this.$message.error('删除失败')
          }
          this.getRepairProductList()
        })
      }).catch(() => {
      });
    },
    deleteRepairContent(row) {
      this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          id: row.id
        }
        deleteRepairContent(params).then(response => {
          if (response.data.data.success === true) {
            this.$message.success('删除成功')
          } else {
            this.$message.error('删除失败')
          }
          this.getRepairContentList()
        })
      }).catch(() => {
      });
    },
    changeProductSort(row) {
      let params = {
        id: row.id,
        sort: row.sort
      }
      updateRepairProduct(params).then(response => {
        if (response.data.data.success === true) {
          this.$message.success('排序更新成功');
          this.getRepairProductList();
        } else {
          this.$message.error('排序更新失败')
        }
      })
    },
    changeContentSort(row) {
      let params = {
        id: row.id,
        sort: row.sort
      }
      updateRepairContent(params).then(response => {
        if (response.data.data.success === true) {
          this.$message.success('排序更新成功');
          this.getRepairContentList();
        } else {
          this.$message.error('排序更新失败')
        }
      })
    },
    closeProductDialog() {
      this.productDialogVisible = false
    },
    closeContentDialog() {
      this.contentDialogVisible = false
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      console.log('reset', formName, this.productForm)
    },
    async handleEditProduct(row = '') {
      this.productDialogVisible = true
      this.$nextTick(() => {
        this.resetForm('productForm')
        if (row) {
          this.productForm.id = row.id
          this.productForm.product_name = row.product_name
          this.productForm.sort = row.sort
          this.productForm.icon = row.icon
        } else {
          this.productForm = {
            id: '',
            product_name: '',
            sort: '',
            icon: ''
          }
        }
      })
    },
    async handleEditContent(row = '') {
      this.contentDialogVisible = true

      this.$nextTick(() => {
      this.resetForm('contentForm')
        if (row) {
          this.contentForm.id = row.id
          this.contentForm.content_name = row.content_name
          this.contentForm.content_desc = row.content_desc
          this.contentForm.sort = row.sort
        } else {
        this.contentForm = {
          id: '',
          content_name: '',
          content_desc: '',
          sort: ''
        }
        }
      })
    },
    getRepairProductList() {
      this.productListLoading = true
      let params = this.productParams
      getRepairProductList(params).then(response => {
        this.productList = response.data.data.list
        this.selectProductId=''
        this.productListLoading = false
      }).catch(error => {
        this.$message.error('维修产品列表获取失败')
        this.productListLoading = false
      })
    },
    getRepairContentList() {
      this.contentListLoading = true
      let params = this.productParams
      getRepairContentList(params).then(response => {
        this.contentList = response.data.data.list
        this.contentListLoading = false
      }).catch(error => {
        this.$message.error('维修内容列表获取失败')
        this.contentListLoading = false
      })
    },

    editProductInfo() {
      let params = this.productForm
      if (params.id) {
        updateRepairProduct(params).then(response => {
          if (response.data.data.success) {
            this.$message.success('更新成功')
          } else {
            this.$message.error('更新失败')
          }
          this.productDialogVisible = false
          this.getRepairProductList()
        })
      } else {
        createRepairProduct(params).then(response => {
          if (response.data.data.success) {
            this.$message.success('添加成功')
          } else {
            this.$message.error('添加失败')
          }
          this.productDialogVisible = false
          this.getRepairProductList()
        })
      }
    },
    editContentInfo() {
      let params = this.contentForm
      if (params.id) {
        updateRepairContent(params).then(response => {
          if (response.data.data.success) {
            this.$message.success('更新成功')
          } else {
            this.$message.error('更新失败')
          }
          this.contentDialogVisible = false
          this.getRepairContentList()
        })
      } else {
        createRepairContent(params).then(response => {
          if (response.data.data.success) {
            this.$message.success('添加成功')
          } else {
            this.$message.error('添加失败')
          }
          this.contentDialogVisible = false
          this.getRepairContentList()
        })
      }
    },
  },
  mounted() {
    this.getRepairProductList()
    this.getRepairContentList()
  },
  watch: {
    '$route'() {
      this.getOfflineActivityList()
    }
  },
}
</script>
