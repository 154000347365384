<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1">
      <el-row :gutter="20">
        <el-col :md="4" :lg="8">
          <el-button
            v-if="login_type === 'disti'"
            size="mini"
            type="primary"
            icon="plus"
            @click="clickCreate">
            添加
          </el-button>
        </el-col>
      </el-row>
      <el-table :data="list" style="width: 100%" v-loading="loading" element-loading-text="数据加载中">
        <el-table-column prop="id" width="60" label="编号"></el-table-column>
        <el-table-column prop="series_name" label="系列名称"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag
              size="mini"
              type="success"
              v-if="scope.row.status === '1'"
            >启用</el-tag>
            <el-tag
              size="mini"
              type="warning"
              v-if="scope.row.status === '0'"
            >禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <div>
              <el-button
                v-if="login_type === 'disti'"
                type="text"
                @click="clickUpdate(scope.$index, scope.row)"
              >
                编辑
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="content-padded content-center">
        <el-pagination
          layout="total, prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          :total='total_count' :page-size="params.pageSize">
        </el-pagination>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { seriesList } from '@/api/goods'

export default {
  data () {
    return {
      loading: false,
      params: {
        page: 1,
        pageSize: 20
      },
      total_count: 0,
      list: []
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {
    clickCreate () {
      console.log(this.matchHidePage('editor'))
      this.$router.push({path: this.matchHidePage('editor')})
    },
    clickUpdate (index, row) {
      this.$router.push({path: this.matchHidePage('editor'), query: {id: row.id}})
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.loading = false
      this.getList()
    },
    getList () {
      this.loading = true
      let filter = this.params
      seriesList(filter).then(response => {
        this.list = response.data.data.list
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      })
    },
  },
  mounted () {
    this.getList()
  }
}
</script>
